import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../theme/components/Breadcrumb";
import { getAllTickerSettings, getWalletsList, getWalletsSettingsList } from "../../redux/actions/walletsActions";
import CompanyBalanceTransactions from "./Transactions";
import { getCompaniesList } from "../../redux/actions/companiesActions";
import CompanyBalanceOverview from "./Overview";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";

export const CompanyBalance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId, balanceId } = useParams();


  const companiesList = useSelector(companiesSelectors.companiesList)
  const selectedCompanyData = companiesList?.find((company) => Number(company?.companyId) === Number(companyId));
  const selectedCompanyBalance = useMemo(() => {
    return selectedCompanyData?.balances?.find((balance) => Number(balance?.balanceId) === Number(balanceId))
  }, [balanceId, selectedCompanyData])

  useEffect(() => {
    dispatch(getCompaniesList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
  }, [dispatch])

  return (
    <PageWrapper>
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to={`/companies/${companyId}`} pageName={t('Company')} />
            <Typography variant="h2" className={classes.title}>
              {selectedCompanyData?.companyName}
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CompanyBalanceOverview companyId={companyId} balanceId={selectedCompanyBalance?.balanceId} ticker={selectedCompanyBalance?.currency} balance={selectedCompanyBalance?.balance} address={selectedCompanyBalance?.address} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <CompanyBalanceTransactions balanceTicker={selectedCompanyBalance?.currency} balanceId={selectedCompanyBalance?.balanceId} companyName={selectedCompanyData?.companyName} />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CompanyBalance;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  description: {
    fontSize: 20,
    marginLeft: 28,
    alignSelf: 'center',
    marginTop: 7,
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
