import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Input } from "../../../theme/components/Input";
import { closeCreateCardDialog, openCreateCardDialog } from "../../../redux/actions/dialogActions";
import { getUserCards } from "../../../redux/actions/cardsActions";
import CreateCardDialog from "../../../theme/dialogs/CreateCardDialog/CreateCardDialog";
import InfoDialog from "../../../theme/dialogs/InfoDialog";
import { accountSelectors } from "../../../redux/selectors/accountSelectors";
import visa from "../../../assets/icons/visa.svg";
import mastercard from "../../../assets/icons/mastercard.svg";
import ExpandMoreIcon from "../../../assets/icons/back_black.svg";
import ExpandMobileIcon from "../../../assets/icons/expand_mobile.svg";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { cardValidator } from "../../../utils/functions/customValidators/cardValidator";
import { useColoredTitle } from "../../../utils/hooks/useColoredTitle";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { cardsSelectors } from "../../../redux/selectors/cardsSelectors";
import { isSucceeded } from "../../../utils/functions/requestStatuses";

const WithdrawalCard = ({ handleSelectedCard }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState({
    cardName: "",
    cardNumber: "",
  });
  const [showVerificationCardDialog, setShowVerificationCardDialog] = useState(false);
  const [errors, setErrors] = useState(null);
  const cards = useSelector(({ cards }) => cards.data);
  const isWithdrawBlocked = useSelector(state =>
    accountSelectors.isWithdrawBlocked(state)
  );
  const isMobile = useIsMobile();
  const createCardRequestStatus = useSelector(cardsSelectors.createCardRequestStatus);
  const prevCreateCardRequestStatus = usePrevious(createCardRequestStatus)
  const { renderTitle } = useColoredTitle({ withMargin: true })

  useEffect(() => {
    if (prevCreateCardRequestStatus !== null && (createCardRequestStatus !== prevCreateCardRequestStatus) && isSucceeded(createCardRequestStatus)) {
      dispatch(closeCreateCardDialog());
      setShowVerificationCardDialog(true)
    }
  }, [createCardRequestStatus, dispatch, prevCreateCardRequestStatus])

  useEffect(() => {
    dispatch(getUserCards());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCard?.cardNumber || !validateCard()) {
      return;
    }
    handleSelectedCard({
      value: selectedCard?.cardNumber,
      errorCard: errors?.cardNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  const openAllCards = () => {
    dispatch(openCreateCardDialog());
  };

  const validateCard = () => {
    let valid = true;
    let errForm = {};
    errForm = {
      ...errForm,
      cardNumber: cardValidator("cardNumber", selectedCard?.cardNumber),
    };
    errForm?.cardNumber.invalid && (valid = false);
    setErrors(errForm);
    return valid;
  };

  const handleChangeCard = ({ nativeEvent: { data }, target: { value } }) => {
    setErrors({ ...errors, cardNumber: "" });
    setSelectedCard({ ...selectedCard, cardName: "" });
    if (!data) {
      setSelectedCard({ ...selectedCard, cardNumber: value, cardName: "" });
    }
    const card = value.replaceAll(" ", "");
    if (/^[0-9]*$/.test(card) && card.length < 17) {
      if (card.length <= 4) {
        setSelectedCard({ ...selectedCard, cardNumber: card, cardName: "" });
      }
      if (card.length > 4 && card.length <= 8) {
        setSelectedCard({
          ...selectedCard,
          cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)}`,
          cardName: "",
        });
      }
      if (card.length > 8 && card.length <= 12) {
        setSelectedCard({
          ...selectedCard,
          cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)} ${card.slice(
            8,
            12
          )}`,
          cardName: "",
        });
      }
      if (card.length > 12 && card.length <= 16) {
        setSelectedCard({
          ...selectedCard,
          cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)} ${card.slice(
            8,
            12
          )} ${card.slice(12, 16)}`,
          cardName: "",
        });
      }
    }
  };

  return (
    <>
      <CreateCardDialog
        cards={cards}
        handleSelectedCard={card => setSelectedCard(card)}
      />
      <Grid
        container
        className={classes.root}
        alignItems='center'
        justifyContent='space-between'>
        <Input
          disabled={isWithdrawBlocked}
          className={clsx(classes.input, classes.cardInput)}
          label={selectedCard?.cardName || t("Select card number")}
          variant='outlined'
          name={"card"}
          value={selectedCard?.cardNumber || ""}
          onChange={handleChangeCard}
          onClick={openAllCards}
          onBlur={() => validateCard()}
          error={errors?.cardNumber?.invalid}
          helperText={t(errors?.cardNumber?.errorMessage)}
          focused={false}
          InputProps={{
            readOnly: true,
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position='end' style={{ paddingRight: 0 }}>
                {Boolean(selectedCard?.cardNumber) &&
                  !errors?.cardNumber?.invalid && (
                    <img
                      src={
                        selectedCard?.cardNumber[0] === "5" ? mastercard : visa
                      }
                      alt={
                        selectedCard?.cardNumber[0] === "5"
                          ? "mastercard"
                          : "visa"
                      }
                      style={{ marginLeft: 4, height: 20 }}
                    />
                  )}

                <IconButton
                  className={classes.select}
                  disabled={isWithdrawBlocked}
                  onClick={openAllCards}>
                  <img
                    src={isMobile ? ExpandMobileIcon : ExpandMoreIcon}
                    alt='expandMoreIcon'
                    loading={"lazy"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <InfoDialog
        withNoButton
        open={showVerificationCardDialog}
        handleClose={() => setShowVerificationCardDialog(false)}
      >
        <Grid container direction="column" className={classes.confirmationDialog}>
          <Grid item alignItems="center">
            {renderTitle(t("VERIFICATION CARD"), { style: { textAlign: 'center', marginBottom: '40px' } })}
          </Grid>
          <Typography
            variant={"body1"}
            align={"center"}
          >
            {t("Your card details have been sent for verification.")}
          </Typography>
        </Grid>
      </InfoDialog>
    </>
  );
};

export default WithdrawalCard;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 24,
    width: "100%",
    color: theme.palette.text.dark,
    background: theme.palette.text.primary,
    borderRadius: 12,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  input: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  cardInput: {
    "& .MuiOutlinedInput-root": {
      "&>input": {
        cursor: 'pointer'
      }
    },
  },
  select: {
    marginLeft: 4,
    "& img": {
      transform: "rotate(-90deg)",
      [theme.breakpoints.down("xs")]: {
        transform: "rotate(0deg)",
      },
    },
  },
  confirmationDialog: {
    padding: '40px 20px',
  }
}));
