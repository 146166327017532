import { setErrorMessage } from "./validators";
import { enqueueSnackbar } from "../../../redux/actions/notistackActions";
import { FILE_TYPES } from "../../constants/kyc";
import i18next from "../../../i18n.config";
import moment from "moment";

export function imageValidator(dispatch, file) {
  // size
  if (file?.size > 10485760) {
    dispatch(
      enqueueSnackbar({
        message: i18next.t(setErrorMessage("imageSize")),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
          autoHideDuration: 5000,
        },
      })
    );

    return {
      invalid: true,
      errorMessage: setErrorMessage("imageSize"),
    };
  }

  // type
  if (!FILE_TYPES.includes(file?.type)) {
    dispatch(
      enqueueSnackbar({
        message: i18next.t(setErrorMessage("fileType")),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
          autoHideDuration: 3000,
        },
      })
    );
    return {
      invalid: true,
      errorMessage: setErrorMessage("fileType"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function validateInput(name, value, form, isFilter = false) {
  //  new RegExp(/[^a-zA-Zа-яА-Я ]/iu);
  const onlyLettersRegex = new RegExp(/^[a-zA-Zа-яА-Я_+]+$/);
  // required

  const fieldArrayRequired = [
    "firstName",
    "lastName",
    "birthDay",
    "birthMonth",
    "birthYear",
    "gender",
    "countryAlphaCode",
    "city",
    "street",
    "house",
    "apartment",
    "postIndex",
  ];

  if (!value && !isFilter && fieldArrayRequired.includes(name)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  if (
    !!value &&
    (name === "firstName" || name === "middleName" || name === "lastName") &&
    !onlyLettersRegex.test(value)
  ) {
    // only letters
    return {
      invalid: true,
      errorMessage: setErrorMessage("onlyLetters"),
    };
  }

  // maxLength 100
  if (
    (value + "").length > 100 &&
    !isFilter &&
    fieldArrayRequired.includes(name)
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("maxLength(100)"),
    };
  }
  // maxLength 10
  if (name === "postIndex" && (value + "").length > 10) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("maxLength(10)"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}

export function DOBValidator(form) {
  if (
    form?.birthDay &&
    form?.birthMonth &&
    form?.birthYear &&
    moment(
      `${form?.birthYear}-${form?.birthMonth}-${form?.birthDay}`
    ).isSameOrAfter(moment().subtract(18, "year"))
  ) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("dob"),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}
