import { setErrorMessage } from "./validators";

export function cardValidator(name, value) {
  const luhn = value => {
    let nCheck = 0;
    if (value && /[0-9-\s]+/.test(value)) {
      value = value.replace(/\D/g, "");

      value.split("").forEach((v, n) => {
        let nDigit = parseInt(v, 10);

        if (!((value.length + n) % 2) && (nDigit *= 2) > 9) {
          nDigit -= 9;
        }

        nCheck += nDigit;
      });
    }
    return nCheck % 10 === 0;
  };

  // required
  const fieldArray = ["cardName", "cardNumber"];

  if (!value && fieldArray.includes(name)) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }
  // cardNumber

  if (name === "cardNumber" && value.replaceAll(" ", "").length < 16) {
    return {
      invalid: true,
      errorMessage: setErrorMessage("cardNumberContain"),
    };
  }

  if (name === "cardNumber" && !luhn(value.replaceAll(" ", ""))) {
    return {
      invalid: true,
      errorMessage: setErrorMessage(name),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}
