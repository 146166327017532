import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import DialogWrapper from "../DialogWrapper";
import CoinName from "../../components/CoinName";
import { Button } from "../../components/Button";
import { closeCreateAutoInvestPlanDialog } from "../../../redux/actions/dialogActions";
import { useValidateForm } from "../../../utils/hooks/useValidateForm";
import { autoInvestPlanValidator } from "../../../utils/functions/customValidators/validators";
import { InputRegular } from "../../components/Input";
import DialogTo from "../../../pages/Exchange/DialogTo";
import DialogFrom from "../../../pages/Exchange/DialogFrom";
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow_down_white.svg'
import InvestPlanCyclesList, { INVEST_PLAN_CYCLE, INVEST_PLAN_CYCLES_LIST } from "./components/InvestPlanCyclesList";
import WeeksIntervalList from "./components/WeeksIntervalList";
import { DAYS_OF_MONTH, DAYS_OF_WEEK_LIST, DAYS_OF_WEEK_STACK, timezones } from "../../../utils/constants/date";
import { SelectInputRegular } from "../../components/SelectRegular";
import SummaryFooter from "./components/SummaryFooter";
import moment from "moment";
import { roundValue } from "../../../utils/functions/roundValue";
import { EXCHANGE_FEE } from "../../../utils/constants/exchangeFee";
import { createAutoInvestPlan, updateAutoInvestPlan } from "../../../redux/actions/autoInvestActions";
import WalletTypeSelect, { INVEST_PLAN_WALLET_TYPE, WALLET_TYPE_LIST } from "./components/WalletTypeSelect";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { isSucceeded } from "../../../utils/functions/requestStatuses";
import { formatTicker } from "../../../utils/functions/string";

moment.weekdays(true)

const DEFAULT_DATE_FORMAT = "DD/MM/yyyy HH:mm"
const DATE_FORMAT_WITHOUT_TIME = "DD/MM/yyyy --:--"
const DEFAULT_TIMEZONE_INPUT_VALUE = 'Europe/Kiev'

const initialForm = {
  planName: "",
  amountPerPeriod: "",
  coinAllocationTo: "",
  coinInvestedFrom: "",
  addressToDistributeReward: "",
  recurringCycle: INVEST_PLAN_CYCLES_LIST[0],
  dayOfMonth: null,
  dayOfWeek: DAYS_OF_WEEK_LIST[0],
  timezone: DEFAULT_TIMEZONE_INPUT_VALUE,
  time: null,
  isOneTimeInvest: false,
  walletType: WALLET_TYPE_LIST[0],
};

const resetedInitialForm = {
  planName: "",
  amountPerPeriod: "",
  addressToDistributeReward: "",
  recurringCycle: INVEST_PLAN_CYCLES_LIST[0],
  dayOfMonth: null,
  dayOfWeek: DAYS_OF_WEEK_LIST[0],
  timezone: DEFAULT_TIMEZONE_INPUT_VALUE,
  time: null,
  isOneTimeInvest: false,
  walletType: WALLET_TYPE_LIST[0],
};

export const getOnlyVisiblePairs = ({ settings, pairs }) => {
  const visiblePairs = (pairs || [])?.filter((pair) => {
    return (
      settings?.find(
        (setting) =>
          setting?.ticker === pair?.assetFrom && setting?.visible
      ) &&
      settings?.find(
        (setting) => setting?.ticker === pair?.assetTo && setting?.visible
      )
    );
  }) || []
  return visiblePairs
}

const parseEditedInvestPlanData = (data, setForm) => {
  if (data) {
    const currentRecurringCycle = INVEST_PLAN_CYCLES_LIST.find((cycle) => cycle.value === data?.recurringCycle?.type)
    const currentDayOfWeek = DAYS_OF_WEEK_LIST.find((item) => item.value === data?.recurringCycle?.dayOfWeek)
    const walletType = data?.addressToDistributeReward ? WALLET_TYPE_LIST[1] : WALLET_TYPE_LIST[0]

    setForm({
      ...initialForm,
      planName: data?.programName,
      amountPerPeriod: data?.amount,
      coinAllocationTo: data?.assetTo,
      coinInvestedFrom: data?.assetFrom,
      recurringCycle: currentRecurringCycle || INVEST_PLAN_CYCLES_LIST[0],
      dayOfMonth: data?.recurringCycle?.dayOfMonth || null,
      dayOfWeek: currentDayOfWeek || DAYS_OF_WEEK_LIST[0],
      timezone: data?.recurringCycle?.timeZone || null,
      time: data?.recurringCycle?.time || null,
      isOneTimeInvest: data?.recurringCycle?.type === INVEST_PLAN_CYCLE.ONE_TIME ? true : false,
      addressToDistributeReward: data?.addressToDistributeReward || "",
      walletType,
    })
  }
}

const CreateAutoInvestPlanDialog = ({ open, data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialForm);
  const [showAlocationCoinsModal, setShowAlocationCoinsModal] = useState(false)
  const [showInvestedCoinModal, setShowInvestedCoinModal] = useState(false)
  const { validateForm, validateInput, errors } = useValidateForm({ form, customValidator: autoInvestPlanValidator });
  const { settings, allTickerSettings } = useSelector(({ wallets }) => wallets);
  const { pairs } = useSelector(
    ({ exchange }) => exchange
  );
  const wallets = useSelector(({ wallets }) => wallets.data);
  const updateInvestPlanRequestStatus = useSelector(({ autoInvest }) => autoInvest.updateInvestPlanRequestStatus);
  const prevUpdateInvestPlanRequestStatus = usePrevious(updateInvestPlanRequestStatus)

  const selectedRecurringCycleValue = form?.recurringCycle?.value
  const isRecurrinCycleIsNotHourly = useMemo(() => {
    return selectedRecurringCycleValue === INVEST_PLAN_CYCLE.WEEKLY ||
      selectedRecurringCycleValue === INVEST_PLAN_CYCLE.DAILY ||
      selectedRecurringCycleValue === INVEST_PLAN_CYCLE.MONTHLY
  }, [selectedRecurringCycleValue])

  const createdDate = useMemo(() => moment(moment.now()).format(DEFAULT_DATE_FORMAT), [])
  const firstAutoInvestDate = useMemo(() => {
    if (form?.isOneTimeInvest) {
      return moment().format(DEFAULT_DATE_FORMAT)
    } else {
      const currentDate = moment()
      const selectedHours = form?.time?.split?.(':')?.[0]
      const selectedMinutes = form?.time?.split?.(':')?.[1]
      const selectedDayOfMonth = form?.dayOfMonth
      const selectedDayOfWeek = form?.dayOfWeek ? DAYS_OF_WEEK_STACK.indexOf(form?.dayOfWeek?.value) + 1 : null
      if (!isRecurrinCycleIsNotHourly) {
        currentDate.set({
          hour: currentDate.hour() + 1, minute: 0
        })
        return currentDate.format(DEFAULT_DATE_FORMAT)
      }
      if (selectedHours && selectedMinutes) {
        const isSelectedTimeAlreadyPassed = currentDate.isAfter(moment({ hour: selectedHours, minute: selectedMinutes }))
        const isSelectedDateAlreadyPassed = currentDate.isAfter(moment({ hour: selectedHours, minute: selectedMinutes, date: selectedDayOfMonth }))
        let selectedWeek = currentDate.week()
        if (selectedRecurringCycleValue === INVEST_PLAN_CYCLE.WEEKLY && selectedDayOfWeek) {
          selectedWeek = currentDate.weekday() < selectedDayOfWeek ? currentDate.week() : currentDate.week() + 1
          if (currentDate.weekday() === selectedDayOfWeek && !isSelectedTimeAlreadyPassed) {
            selectedWeek = currentDate.week()
          }
        }
        currentDate.set({
          hour: selectedHours,
          minute: selectedMinutes,
          ...(selectedRecurringCycleValue === INVEST_PLAN_CYCLE.MONTHLY && selectedDayOfMonth ? {
            months: isSelectedDateAlreadyPassed ? currentDate.month() + 1 : currentDate.month(),
            date: selectedDayOfMonth,
          } : {}),
          ...(selectedRecurringCycleValue === INVEST_PLAN_CYCLE.WEEKLY && selectedDayOfWeek ? {
            weeks: selectedWeek,
            day: selectedDayOfWeek
          } : {}),
          ...(selectedRecurringCycleValue === INVEST_PLAN_CYCLE.DAILY && selectedDayOfWeek ? {
            date: isSelectedTimeAlreadyPassed ? currentDate.date() + 1 : currentDate.date()
          } : {}),

        })
      } else {
        return currentDate.format(DATE_FORMAT_WITHOUT_TIME)
      }
      return currentDate.format(DEFAULT_DATE_FORMAT)
    }
  }, [form?.dayOfMonth, form?.dayOfWeek, form?.isOneTimeInvest, form?.time, isRecurrinCycleIsNotHourly, selectedRecurringCycleValue])

  const allocationCoinsList = useMemo(() => {
    const visiblePairs = getOnlyVisiblePairs({ pairs, settings })
    const filteredPairs = [...new Map(visiblePairs.map(item => [item['assetTo'], item])).values()]
    return filteredPairs
  }, [pairs, settings])


  const investedCoinsList = useMemo(() => {
    const visiblePairs = getOnlyVisiblePairs({ pairs, settings })
    const filteredPairs = visiblePairs.filter((i) => i.assetTo === form.coinAllocationTo)
    return filteredPairs
  }, [form.coinAllocationTo, pairs, settings])

  const investedFromWallet = useMemo(() => {
    let wallet = wallets?.find((i) => i.ticker === form?.coinInvestedFrom);
    return wallet;
  }, [form.coinInvestedFrom, wallets]);

  const minScaleInvestedAssetFrom = useMemo(() => {
    return allTickerSettings?.find(
      (setting) => setting?.ticker === form?.coinInvestedFrom
    )?.minScale;
  }, [allTickerSettings, form?.coinInvestedFrom]);

  const coinPairSettings = useMemo(
    () =>
      investedCoinsList.find(
        (i) => i.assetFrom === form?.coinInvestedFrom && i.assetTo === form?.coinAllocationTo
      ),
    [form?.coinAllocationTo, form?.coinInvestedFrom, investedCoinsList]
  );

  useEffect(() => {
    // PRESELECT INVEST COIN PLAN
    if (allocationCoinsList.length > 0 && !data) {
      let initialPair = allocationCoinsList[0];
      setForm({
        ...initialForm,
        coinInvestedFrom: initialPair?.assetFrom,
        coinAllocationTo: initialPair?.assetTo,
      });
    }
  }, [allocationCoinsList, data])

  useEffect(() => {
    if (allocationCoinsList.length > 0 && form?.coinAllocationTo) {
      // IF EDITED DATA NOT EXIST FIND MARKET PAIRS AND PRESELECTED AVAILABLE
      if (form?.coinAllocationTo !== data?.assetTo) {
        let preselectedPair = allocationCoinsList?.find((pair) => pair?.assetTo === form?.coinAllocationTo);
        setForm({
          ...form,
          coinInvestedFrom: preselectedPair?.assetFrom || "",
        });
      } else {
        // IF EDITED DATA EXIST KEEP PAIR AS IN PLAN
        setForm({
          ...form,
          coinInvestedFrom: data?.assetFrom || ""
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.coinAllocationTo])

  useEffect(() => {
    parseEditedInvestPlanData(data, setForm)
  }, [data])

  // const { data } = useSelector(({ wallets }) => wallets);

  const resetFormValues = useCallback(() => {
    setForm({ ...form, ...resetedInitialForm })
  }, [form])

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    validateInput(event)
  };

  const handleClose = useCallback(() => {
    dispatch(closeCreateAutoInvestPlanDialog())
    resetFormValues()
  }, [dispatch, resetFormValues])

  const handleAddAlocationCoinClick = () => {
    setShowAlocationCoinsModal(true)
  }

  const handleSelectInvestedCoin = () => {
    setShowInvestedCoinModal(true)
  }

  const handleAlocationCoinDialogClose = (selectedCoin) => {
    if (selectedCoin && typeof selectedCoin === 'string') {
      setForm({ ...form, 'coinAllocationTo': selectedCoin })
    }
    setShowAlocationCoinsModal(false)
  }

  const handleInvestedCoinDialogClose = (selectedCoin) => {
    if (selectedCoin && typeof selectedCoin === 'string') {
      setForm({ ...form, 'coinInvestedFrom': selectedCoin })
    }
    setShowInvestedCoinModal(false)
  }

  const handleCustomFormFieldChange = (fieldName) => (value) => {
    setForm({ ...form, [fieldName]: value })
  }

  const handleSubmit = useCallback(() => {
    const isValid = validateForm();
    if (isValid) {
      const recurringCycleValue = form?.recurringCycle?.value
      const isRewardDistributedToMoneyBox = form?.walletType?.value === INVEST_PLAN_WALLET_TYPE.MONEYBOX
      const submitData = {
        programName: form?.planName,
        assetFrom: form?.coinInvestedFrom,
        assetTo: form?.coinAllocationTo,
        amount: form?.amountPerPeriod,
        sendToCompanyBalance: isRewardDistributedToMoneyBox,
        addressToDistributeReward: isRewardDistributedToMoneyBox ? form?.addressToDistributeReward : null,
        recurringCycle: form?.isOneTimeInvest ? {
          type: INVEST_PLAN_CYCLE.ONE_TIME,
          dayOfWeek: null,
          dayOfMonth: null,
          time: null,
          timeZone: null,
        } : {
          type: recurringCycleValue,
          dayOfWeek: recurringCycleValue === INVEST_PLAN_CYCLE.WEEKLY ? form?.dayOfWeek?.value : null,
          dayOfMonth: recurringCycleValue === INVEST_PLAN_CYCLE.MONTHLY ? form?.dayOfMonth : null,
          time: isRecurrinCycleIsNotHourly && !form?.isOneTimeInvest ? form?.time : null,
          timeZone: form?.timezone,
        },
        ...(data?.id ? { programId: data?.id, enabled: data?.enabled } : {})
      }
      if (data) {
        dispatch(updateAutoInvestPlan(submitData))
      } else {
        dispatch(createAutoInvestPlan(submitData))
      }
    }
  }, [data, dispatch, form, isRecurrinCycleIsNotHourly, validateForm])

  useEffect(() => {
    if (prevUpdateInvestPlanRequestStatus !== updateInvestPlanRequestStatus && isSucceeded(updateInvestPlanRequestStatus)) {
      handleClose()
    }
  }, [handleClose, prevUpdateInvestPlanRequestStatus, updateInvestPlanRequestStatus])

  return (
    <DialogWrapper open={open} handleClose={handleClose} dialogClassName={classes.dialog} title={data ? t("Edit invest plan") : t("Create new plan")}>
      <div className={classes.root}>
        <DialogTo open={showAlocationCoinsModal} data={allocationCoinsList} onClose={handleAlocationCoinDialogClose} />
        <DialogFrom open={showInvestedCoinModal} filteredData={investedCoinsList} onClose={handleInvestedCoinDialogClose} />
        <Grid
          container
          justifyContent={"space-between"}
          direction="column"
        >
          <Grid item md={7}>
            <InputRegular
              error={errors?.planName?.invalid || Boolean(errors?.planName?.errorMessage)}
              helperText={errors?.planName?.errorMessage}
              className={classes.input}
              label={t("Name invest plan")}
              variant='outlined'
              name={"planName"}
              value={form?.planName || ""}
              onChange={handleChange}
              onBlur={validateInput}
              type={"text"}
              InputProps={{
                form: {
                  autoComplete: "off",
                },
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={12}>
            {
              form?.coinAllocationTo ?
                <Grid container justifyContent="space-between" alignItems="center" className={classes.inputRowWrapper}>
                  <Grid item md={7}>
                    <InputRegular
                      error={errors?.coinAllocationTo?.invalid || Boolean(errors?.coinAllocationTo?.errorMessage)}
                      helperText={errors?.coinAllocationTo?.errorMessage}
                      className={classes.input}
                      label={t("Coin allocation")}
                      variant='outlined'
                      name={"coinAllocationTo"}
                      value={form?.coinAllocationTo?.toUpperCase?.() || ""}
                      onChange={handleChange}
                      onBlur={validateInput}
                      type={"text"}
                      InputProps={{
                        readOnly: true,
                        form: {
                          autoComplete: "off",
                        },
                        startAdornment: (
                          <CoinName
                            name={form?.coinAllocationTo}
                            ticker={form?.coinAllocationTo}
                            showOnlyIcon
                            isSmallIcon
                          />
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {/* <Typography className={classes.minLabelText}>
                      {t('The sum of allocation must add up to 100%')}
                    </Typography> */}
                  </Grid>
                  <Button
                    variant='contained'
                    size="small"
                    onClick={handleAddAlocationCoinClick}
                    disableElevation
                    className={clsx(classes.btn, classes.addBtn)}>
                    {t("Change coin")}
                  </Button>
                </Grid>
                :
                <Grid container justifyContent="space-between" alignItems="center">
                  <Box mb='16px'>
                    <Typography className={classes.label}>
                      {t('Coin allocation')}
                    </Typography>
                    <Typography className={classes.noCoinText}>
                      {t('No coin has been selected')}
                    </Typography>
                  </Box>
                  <Button
                    variant='contained'
                    size="small"
                    onClick={handleAddAlocationCoinClick}
                    disableElevation
                    className={clsx(classes.btn, classes.addBtn)}>
                    + {t("Add coins")}
                  </Button>
                </Grid>
            }
          </Grid>
          <Grid container>
            <Typography className={classes.label}>
              {t("Wallets")}
            </Typography>
            <WalletTypeSelect
              inputName={"addressToDistributeReward"}
              defaultWalletType={form?.walletType}
              inputValue={form?.addressToDistributeReward}
              onInputChange={handleChange}
              onInputBlur={validateInput}
              inputError={errors?.addressToDistributeReward}
              onSelect={handleCustomFormFieldChange('walletType')}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <InputRegular
                error={errors?.amountPerPeriod?.invalid || Boolean(errors?.amountPerPeriod?.errorMessage)}
                helperText={errors?.amountPerPeriod?.errorMessage}
                className={classes.input}
                label={t("Amount per period")}
                variant='outlined'
                name={"amountPerPeriod"}
                value={form?.amountPerPeriod || ""}
                onChange={handleChange}
                onBlur={validateInput}
                type={"number"}
                InputProps={{
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {
                !errors?.amountPerPeriod?.errorMessage &&
                <Typography className={classes.minLabelText}>
                  {allocationCoinsList.length > 0 &&
                    `${t('The minimum amount is')} ${coinPairSettings?.minAmount || 0} ${formatTicker(form?.coinInvestedFrom)}  Max: ${coinPairSettings?.maxAmount || 0
                    } ${formatTicker(form?.coinInvestedFrom)}`}
                </Typography>
              }
            </Grid>
            <Grid item md={5}>
              <InputRegular
                error={errors?.coinInvestedFrom?.invalid || Boolean(errors?.coinInvestedFrom?.errorMessage)}
                helperText={errors?.coinInvestedFrom?.errorMessage}
                className={classes.input}
                label={`${t("Available")}: ${investedFromWallet?.balance ? roundValue(investedFromWallet?.balance, minScaleInvestedAssetFrom) : 0} ${form?.coinInvestedFrom?.toUpperCase()}`}
                variant='outlined'
                name={"coinInvestedFrom"}
                value={form?.coinInvestedFrom?.toUpperCase?.() || "Choose coin"}
                onChange={handleChange}
                onBlur={validateInput}
                type={"text"}
                onClick={handleSelectInvestedCoin}
                InputProps={{
                  readOnly: true,
                  style: {
                    cursor: 'pointer',
                  },
                  form: {
                    autoComplete: "off",
                  },
                  startAdornment: form?.coinInvestedFrom ? (
                    <CoinName
                      name={form?.coinInvestedFrom}
                      ticker={form?.coinInvestedFrom}
                      showOnlyIcon
                      isSmallIcon
                    />
                  ) : null,
                  endAdornment: (
                    <ArrowDownIcon />
                  )
                }}
                InputLabelProps={{
                  disabled: true,
                  shrink: true,
                  style: {
                    fontSize: 12,
                    marginTop: 4,
                  }
                }}
              />
            </Grid>
          </Grid>
          <InvestPlanCyclesList defaultValue={form?.recurringCycle} isOneTimeInvestDefaultValue={form?.isOneTimeInvest} onChangeOneTimeInvestSwitcher={handleCustomFormFieldChange('isOneTimeInvest')} onSelect={handleCustomFormFieldChange('recurringCycle')} />
          {
            !form?.isOneTimeInvest &&
            <div className={classes.investCycleTimingContainer}>
              <Grid container item md={12} className={classes.repeatsOnSectionContainer}>
                <Typography className={classes.label}>
                  {t("Repeats On")}
                </Typography>
                {
                  selectedRecurringCycleValue === INVEST_PLAN_CYCLE.WEEKLY ?
                    <WeeksIntervalList defaultValue={form.dayOfWeek?.dayOfWeekNumber} onSelect={handleCustomFormFieldChange('dayOfWeek')} /> : null
                }
                {
                  selectedRecurringCycleValue === INVEST_PLAN_CYCLE.MONTHLY ?
                    <Grid item container wrap="nowrap" direction="row" alignItems="center" className={classes.inputWithMarginWrapper} >
                      <Typography className={classes.selectLabel}>
                        {t('Date of the month')}
                      </Typography>
                      <SelectInputRegular
                        onChange={handleChange}
                        value={form?.dayOfMonth || ""}
                        name={"dayOfMonth"}
                        onBlur={validateInput}
                        error={
                          Boolean(errors?.dayOfMonth?.errorMessage) ||
                          errors?.dayOfMonth?.invalid
                        }
                        helperText={t(errors?.dayOfMonth?.errorMessage)}
                        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                        className={classes.selectInput}
                        inputProps={{ IconComponent: () => null }}
                      >
                        {DAYS_OF_MONTH.map((day, index) => (
                          <MenuItem key={index} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </SelectInputRegular>
                    </Grid> : null
                }
              </Grid>
              <Grid container item md={12} spacing={2}>
                {
                  isRecurrinCycleIsNotHourly &&
                  <Grid item md={4} container wrap="nowrap" direction="row" alignItems="center">
                    <Typography className={classes.selectLabel}>
                      {t('Time')}
                    </Typography>
                    <InputRegular
                      error={errors?.time?.invalid || Boolean(errors?.time?.errorMessage)}
                      helperText={errors?.time?.errorMessage}
                      className={clsx(classes.input, classes.timeInput)}
                      variant='outlined'
                      name={"time"}
                      value={form?.time || ""}
                      onChange={handleChange}
                      onBlur={validateInput}
                      type={"time"}
                      InputProps={{
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ IconComponent: () => null }}
                    />
                  </Grid>
                }
                <Grid md={isRecurrinCycleIsNotHourly ? 8 : 10} item container wrap="nowrap" direction="row" alignItems="center" className={isRecurrinCycleIsNotHourly ? {} : classes.inputWithMarginWrapper}>
                  <Typography className={classes.selectLabel}>
                    {t('Time Zone')}
                  </Typography>
                  <SelectInputRegular
                    onChange={handleChange}
                    value={form?.timezone || ""}
                    name={"timezone"}
                    onBlur={validateInput}
                    error={
                      Boolean(errors?.timezone?.errorMessage) ||
                      errors?.timezone?.invalid
                    }
                    helperText={t(errors?.timezone?.errorMessage)}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={clsx(classes.selectInput, classes.selectInputWide)}
                    inputProps={{ IconComponent: () => null }}
                  >
                    {Object.keys(timezones).map((timezoneId, index) => (
                      <MenuItem key={index} value={timezoneId}>
                        {`${timezones[timezoneId]} [${timezoneId}]`}
                      </MenuItem>
                    ))}
                  </SelectInputRegular>
                </Grid>
              </Grid>
            </div>
          }
          <SummaryFooter amountPerPeriod={`${form?.amountPerPeriod || '0'} ${form?.coinInvestedFrom}`} createDate={createdDate} firstAutoInvestDate={firstAutoInvestDate} transactionFee={`${EXCHANGE_FEE} %`} />
        </Grid>
      </div>
      <Grid container justifyContent='center'>
        <Button
          variant='contained'
          onClick={handleSubmit}
          className={classes.btn}>
          {t("Confirm")}
        </Button>
      </Grid>
    </DialogWrapper >
  );
};

export default CreateAutoInvestPlanDialog;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialog: {
    overflowY: 'scroll'
  },
  btn: {
    minWidth: 180,
    height: 42,
    marginTop: 10,
    marginBottom: 10,
    "& .MuiButton-label": {
      color: theme.palette.text.tertiary,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  addBtn: {
    minWidth: 120,
    height: 48,
    border: 'none',
    "&.MuiButton-contained": {
      borderWidth: 0,
      backgroundColor: '#2B2F38',
      borderRadius: 15,
      "&:hover": {
        opacity: 0.8,
        backgroundColor: '#2B2F38'
      },
    }
  },
  label: {
    fontSize: 14,
  },
  selectLabel: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    marginTop: -5,
    marginRight: 15,
  },
  noCoinText: {
    fontSize: 14,
    color: theme.palette.text.darkLighter,
    margin: '20px 0px',
  },
  inputRowWrapper: {
    flexDirection: 'row',
    flexFlow: 'row',
  },
  dropdownStyle: {
    maxHeight: 250,
    marginTop: -6,
  },
  selectInput: {
    width: 100,
    textAlign: 'center',
  },
  timeInput: {
    marginTop: '10px !important',
    marginBottom: '12px !important',
    '& .MuiOutlinedInput-root > input': {
      padding: 14,
      textAlign: 'center',
    }
  },
  inputWithMarginWrapper: {
    marginTop: 9.5,
    marginBottom: 14,
  },
  selectInputWide: {
    width: '100%',
  },
  repeatsOnSectionContainer: {
    marginTop: 24
  },
  investCycleTimingContainer: {
    marginBottom: 20,
  },
  minLabelText: {
    fontSize: 11,
    color: theme.palette.text.secondary,
    marginTop: -24,
  }
}));
