export const RequestStatus = {
  NOT_ASKED: 'NOT_ASKED',
  LOADING: 'LOADING',
  UPDATING: 'UPDATING',
  LOAD_MORE: 'LOAD_MORE',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  NOT_FOUND: 'NOT_FOUND',
}

export const isNotAsked = (status) => status === RequestStatus.NOT_ASKED

export const isLoading = (status) => status === RequestStatus.LOADING

export const isUpdating = (status) => status === RequestStatus.UPDATING

export const isLoadMore = (status) => status === RequestStatus.LOAD_MORE

export const isSucceeded = (status) => status === RequestStatus.SUCCEEDED

export const isFailed = (status) => status === RequestStatus.FAILED

export const isNotFound = (status) => status === RequestStatus.NOT_FOUND
