import {
  CHECK_INTERNAL_ADDRESS_SUCCESS,
  CLEAR_CHECK_INTERNAL_ADDRESS,
  FILTER_WALLETS_LIST,
  GET_CURRENCIES_SUCCESS,
  GET_WALLET_FEE_SUCCESS,
  GET_WALLETS_LIST_FAILURE,
  GET_WALLETS_LIST_STARTED,
  GET_WALLETS_LIST_SUCCESS,
  GET_ALL_TICKER_SETTINGS_FAILURE,
  GET_ALL_TICKER_SETTINGS_STARTED,
  GET_ALL_TICKER_SETTINGS_SUCCESS,
  GET_TICKER_SETTINGS_FAILURE,
  GET_TICKER_SETTINGS_STARTED,
  GET_TICKER_SETTINGS_SUCCESS,
  SET_WALLETS_PARAMS,
  SORT_WALLETS_LIST,
  WITHDRAW_SUCCESS,
  GET_WALLETS_SETTINGS_LIST_FAILURE,
  GET_WALLETS_SETTINGS_LIST_STARTED,
  GET_WALLETS_SETTINGS_LIST_SUCCESS,
  UPDATE_WALLET_SETTINGS_FAILURE,
  UPDATE_WALLET_SETTINGS_SUCCESS,
  UPDATE_WALLET_SETTINGS_STARTED,
  SET_WITHDRAWAL_CARD,
  RECEIVE_FIAT_STARTED,
  RECEIVE_FIAT_SUCCESS,
  RECEIVE_FIAT_FAILURE,
  CLEAR_TICKER_SETTINGS,
} from "../types";

const decimalCoins = ["uaht", "usdt-trc20", "usdt-erc20"];

export default function wallets(
  state = {
    params: { number: 0, size: 30 },
    data: [],
    filteredData: [],
    currencies: [],
    fees: null,
    allTickerSettings: null,
    tickerSettings: null,
    error: null,
    isInternal: false,
    settings: [],
    isWithdrawalCard: false,
    invoice: null,
  },
  action
) {
  switch (action.type) {
    case SET_WALLETS_PARAMS:
      return { ...state, params: action.payload.params };
    case GET_WALLETS_LIST_STARTED:
    case UPDATE_WALLET_SETTINGS_STARTED:
    case GET_TICKER_SETTINGS_STARTED:
    case GET_WALLETS_SETTINGS_LIST_STARTED:
    case GET_ALL_TICKER_SETTINGS_STARTED:
      return { ...state, error: null };
    case RECEIVE_FIAT_STARTED:
      return { ...state, invoice: null, error: null };
    case GET_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        filteredData: action.payload.data,
      };
    case SORT_WALLETS_LIST:
      return {
        ...state,
        filteredData: [...state.data].sort((a, b) =>
          action.payload.direction === "up"
            ? a[action.payload.key] > b[action.payload.key]
              ? 1
              : -1
            : a[action.payload.key] < b[action.payload.key]
            ? 1
            : -1
        ),
      };
    case FILTER_WALLETS_LIST:
      return {
        ...state,
        filteredData: [...state.data].filter((i) =>
          JSON.stringify(i.ticker).includes(action.payload)
        ),
      };
    case GET_WALLETS_SETTINGS_LIST_SUCCESS:
      return { ...state, settings: action.payload.data };
    case UPDATE_WALLET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: state?.settings?.map((i) =>
          i?.ticker === action.payload?.data?.ticker
            ? {
                ...i,
                visible: action.payload?.data?.visible,
              }
            : i
        ),
      };
    case GET_CURRENCIES_SUCCESS:
      return { ...state, currencies: action.payload.data };

    case RECEIVE_FIAT_SUCCESS:
      return { ...state, invoice: action.payload.data };

    case GET_WALLETS_LIST_FAILURE:
    case GET_TICKER_SETTINGS_FAILURE:
    case GET_WALLETS_SETTINGS_LIST_FAILURE:
    case UPDATE_WALLET_SETTINGS_FAILURE:
    case RECEIVE_FIAT_FAILURE:
    case GET_ALL_TICKER_SETTINGS_FAILURE:
      return { ...state, error: action.payload.error };
    case GET_WALLET_FEE_SUCCESS:
      return { ...state, fees: action.payload };
    case GET_ALL_TICKER_SETTINGS_SUCCESS:
      return {
        ...state,
        allTickerSettings: action.payload.map((i) => {
          if (decimalCoins.includes(i.ticker)) {
            return { ...i, minScale: 2 };
          }
          return i;
        }),
      };
    case GET_TICKER_SETTINGS_SUCCESS:
      return {
        ...state,
        tickerSettings: decimalCoins.includes(action.payload.ticker)
          ? { ...action.payload, minScale: 2 }
          : action.payload,
      };
    case CLEAR_TICKER_SETTINGS:
      return {
        ...state,
        tickerSettings: null,
      };
    case CHECK_INTERNAL_ADDRESS_SUCCESS:
      return { ...state, isInternal: action.payload };
    case WITHDRAW_SUCCESS:
    case CLEAR_CHECK_INTERNAL_ADDRESS:
      return { ...state, isInternal: false };

    case SET_WITHDRAWAL_CARD:
      return { ...state, isWithdrawalCard: action.payload };

    default:
      return state;
  }
}
