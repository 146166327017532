import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Button } from "../../../components/Button";

import { imageValidator } from "../../../../utils/functions/customValidators/kycValidator";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../../../redux/actions/notistackActions";
import SelfieBankCardImage from '../../../../assets/images/selfieBankCard.svg'
import { cardsSelectors } from "../../../../redux/selectors/cardsSelectors";
import { isLoading } from "../../../../utils/functions/requestStatuses";

const AddSelfiePhoto = ({ onSubmit, defaultPhotoFile }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [cardPhoto, setCardPhoto] = useState(defaultPhotoFile || null);
    const createCardRequestStatus = useSelector(cardsSelectors.createCardRequestStatus);
    const dispatch = useDispatch();
    const isLoaderNeeded = isLoading(createCardRequestStatus)

    const fileInputRef = useRef(null);

    const imageUrl = useMemo(() => {
        if (cardPhoto) {
            return window.URL.createObjectURL(cardPhoto);
        } else {
            return "";
        }
    }, [cardPhoto]);

    const handlePhotoUpload = () => {
        fileInputRef?.current?.click?.();
    };

    const handleTakeAPhoto = () => {
        // 
    }

    const onFileChanged = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (imageValidator(dispatch, e.target.files[0])?.invalid) {
            return;
        }
        const file = e.target.files[0];
        setCardPhoto(file)
    };

    const validateStep = () => {
        if (!cardPhoto) {
            dispatch(
                enqueueSnackbar({
                    message: t("This is a required verification step."),
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error",
                        autoHideDuration: 3000,
                    },
                })
            );
            return true;
        }
        if (
            cardPhoto &&
            imageValidator(dispatch, cardPhoto)?.invalid
        ) {
            return true;
        }
        return false;
    };

    const handleSubmitCardPhoto = () => {
        if (validateStep()) {
            return;
        }
        onSubmit(cardPhoto)
    };

    return (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12} sm={12} className={classes.widgetWrap} container>
                <div className={clsx(classes.photoBox, !imageUrl ? {} : classes.withBorder)}>
                    <div className={clsx(classes.imageContainer, imageUrl ? classes.uploadedImageContainer : {})}>
                        {!imageUrl ? (
                            <div className={clsx(classes.titleWrap)}>
                                <img
                                    src={SelfieBankCardImage || ""}
                                    alt="document"
                                    id="showDocumentImage"
                                    className={classes.img}
                                />
                            </div>
                        ) : (
                            <div className={clsx(classes.imgWrap)}>
                                <img
                                    src={imageUrl || ""}
                                    alt="document"
                                    id="showDocumentImage"
                                    className={clsx(classes.img, classes.imgUploaded)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} className={classes.descriptionWrapper}>
                <div className={classes.descriptionBox}>
                    <Typography variant="body2" className={classes.descriptionTitle}>
                        {t("Upload your selfie with a bank card, used in the previous step.")}
                    </Typography>
                    <Typography variant="body2" className={classes.description}>
                        {t("Upload a color image of your selfie with a bank card.")}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={12} container justifyContent="center">
                <Grid>
                    <input
                        type="file"
                        onChange={onFileChanged}
                        hidden={true}
                        id="fileSelect"
                        ref={fileInputRef}
                        accept=".jpg, .jpeg, .png"
                    ></input>
                </Grid>

                <Grid
                    container
                    justifyContent="center"
                    spacing={5}
                    direction="column"
                    className={classes.btnBox}
                >
                    <Button
                        className={clsx(classes.button, classes.btnDisabled)}
                        variant="contained"
                        disabled
                        style={{ marginTop: 32 }}
                        onClick={handleTakeAPhoto}
                    >
                        {t("Take a photo")}
                    </Button>
                    <Button
                        className={clsx(classes.button)}
                        variant="contained"
                        style={{ marginTop: 32 }}
                        disabled={isLoaderNeeded}
                        onClick={handlePhotoUpload}
                    >
                        {t("Upload a photo")}
                    </Button>

                    <Button
                        className={classes.button}
                        variant="contained"
                        style={{ marginTop: 32 }}
                        disabled={isLoaderNeeded}
                        onClick={handleSubmitCardPhoto}
                    >
                        {t("Next")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddSelfiePhoto;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 20,
        marginTop: 20,
    },
    button: {
        marginTop: 8,
        alignSelf: 'center',
    },
    imageContainer: {
        width: "100%",
        height: "100%",
        background: theme.palette.background.paper,
        zIndex: 2,
        position: "relative",
        borderRadius: 20
    },
    uploadedImageContainer: {
        padding: 10,
    },
    withBorder: {
        border: "1px solid #FFFFFF",
    },
    photoBox: {
        display: "inline-block",
        width: 300,
        height: 170,
        position: "relative",
        transition: "all .3s ease",
        margin: "10px",
        borderRadius: 20,
        alignSelf: 'center',
    },
    imgWrap: {
        height: "100%",
        position: "relative",
        margin: 0,
        overflow: "hidden",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      imgUploaded: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      },
    widgetWrap: {
        justifyContent: "center",
        alignItems: 'center',
    },
    btnDisabled: {
        borderColor: `${theme.palette.text.secondary} !important`,
        "& .MuiButton-label": {
            color: theme.palette.text.secondary,
        },
    },
    descriptionBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",

        marginBottom: 0,
        marginTop: 18,
        width: '67%',
    },
    descriptionTitle: {
        fontSize: 16,
        color: theme.palette.text.quaternary,
        marginBottom: 10,
    },
    description: {
        fontWeight: 400,
        fontStyle: "normal",
        whiteSpace: "pre-line",
        padding: "4px 0px",
    },
    descriptionWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },
}));
