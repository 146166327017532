import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button } from "../../../theme/components/Button";
import VerificationPending from "../../../assets/icons/kyc/kyc_progress.svg";
import VerificationConfirmed from "../../../assets/icons/kyc/kyc_success.svg";
import { VERIFICATION_STATUS } from "../../../utils/constants/kyc";
import { coloredFirstLetter } from "../../../utils/functions/coloredFirstLetter";

const VerificationInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { verificationStatus } = useSelector(({ kyc }) => kyc);

  const isConfirmed = useMemo(() => {
    if (verificationStatus) {
      return verificationStatus === VERIFICATION_STATUS.CONFIRMED;
    }
  }, [verificationStatus]);

  const handleBackMain = e => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/");
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      className={classes.root}>
      <Grid item container>
        <Typography
          variant='h4'
          className={classes.title}>
          {isConfirmed
            ? coloredFirstLetter(t("Verification was successful!"))
            : coloredFirstLetter(t("The verification procedure is in progress!"))}
        </Typography>
      </Grid>

      <Grid container alignContent='center'>
        <Grid item xs={12} md={8} className={classes.wrapper}>
          <div className={classes.descriptionBox}>
            <Typography variant='h5' className={classes.description}>
              {isConfirmed
                ? t(
                  "Congratulations, your identity has been successfully verified! Now you can make deposits and make withdrawals."
                )
                : t(
                  "Thank you! Your application is being processed. Now you can close the window and wait for a letter with the result of the check."
                )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={false} sm={false} md={4}></Grid>
      </Grid>
      <Grid container xs={12} sm={6} md={4} className={classes.widgetWrap}>
        {isConfirmed ? (
          <div className={clsx(classes.imgWrap)}>
            <img
              src={VerificationConfirmed}
              alt='confirmed'
              className={classes.img}
            />
          </div>
        ) : (
          <div className={clsx(classes.imgWrap)}>
            <img
              src={VerificationPending}
              alt='pending'
              className={classes.img}
            />
          </div>
        )}
      </Grid>
      <Grid
        container
        justifyContent='center'
        spacing={5}
        className={classes.btnBox}>
        <Grid item>
          <Button
            className={classes.btn}
            variant='contained'
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={handleBackMain}>
            {t("Wallet")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerificationInfo;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "32px 24px",
  },
  title: {
    fontWeight: 400,
    marginBottom: 44,
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  widgetWrap: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
  },
  imgWrap: {
    width: "220px",
    height: "220px",
    position: "relative",
    margin: 0,
    marginBottom: 50,
  },
  img: {
    height: "100%",
    display: "block",
    maxHeight: "100%",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    paddingBottom: "24px",
  },
  description: {
    fontWeight: 400,
    fontStyle: "normal",
    whiteSpace: "pre-line",
  },
  btn: {
    minWidth: 180,
    height: 42,
    marginTop: "0px !important",
    "&.MuiButton-outlined": {
      padding: "12px 24px",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 160,
    },
  },
}));
