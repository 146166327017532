import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import Address from "../../pages/WalletsDetails/Address";
import DialogWrapper from "./DialogWrapper";
import { closeReceiveDialog } from "../../redux/actions/dialogActions";
import {
  clearAddresses,
  getAddresses,
} from "../../redux/actions/addressesActions";
import {
  clearTickerSettings,
  getTickerSettings,
} from "../../redux/actions/walletsActions";

const ReceiveDialog = ({ handleBackReceive, customData = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedWallet } = useSelector(({ dialogs }) => dialogs.receive);
  const selectedWalletAddress = useSelector(({ addresses }) => addresses.data[0]);
  const tickerSettings = useSelector(({ wallets }) => wallets.tickerSettings);

  const ticker = customData ? customData?.ticker : selectedWallet?.ticker
  const address = customData ? {
    address: customData?.address,
  } : selectedWalletAddress

  useEffect(() => {
    if (ticker) {
      if (selectedWallet) {
        dispatch(getAddresses(ticker));
      }
      if ((selectedWallet || customData?.ticker)) {
        dispatch(getTickerSettings(ticker));
      }
    }
  }, [customData?.ticker, dispatch, selectedWallet, ticker]);

  useEffect(() => {
    return () => {
      dispatch(clearAddresses());
      dispatch(clearTickerSettings());
    };
  }, [dispatch]);

  return (
    <DialogWrapper
      open={Boolean(selectedWallet)}
      handleClose={() => dispatch(closeReceiveDialog())}
      handleBack={handleBackReceive}
      title={`${t("Receive")} ${selectedWallet?.cname || selectedWallet?.name
        } (${ticker?.toUpperCase()}) ${t("wallet")}`}
    >
      {Boolean(address?.address) ? (
        <Grid container justifyContent={"center"}>
          <Grid item>
            <div className={classes.qr}>
              <QRCode
                value={address?.address}
                size={134}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Address
              variant={"outlined"}
              address={address}
              short
              tickerSettings={tickerSettings}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant={"body1"} className={classes.text}>
          {t("To deposit funds, please add the appropriate wallet in the wallet settings.")}
        </Typography>
      )}

    </DialogWrapper>
  );
};

export default ReceiveDialog;

const useStyles = makeStyles((theme) => ({
  titleDialog: {
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
  qr: {
    marginBottom: 32,
    padding: 8,
    backgroundColor: "#fff",
  },
  text: {
    margin: '20px 0px',
  }
}));
