import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Paper, Grid } from "@material-ui/core";
import { Button } from "../../theme/components/Button";
import { SwitchButton } from "../../theme/components/SwitchButton";
import ChangePassword from "./ChangePassword";
import {
  getAccountData,
  clearTwoFaQrCode,
  enableTwoFA,
  disableTwoFA,
} from "../../redux/actions/accountActions";
import TwoFaQrCodeDialog from "../../theme/dialogs/TwoFaQrCodeDialog";
import DeleteConfirmDialog from "../../theme/dialogs/DeleteConfirmDialog";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";
import clsx from "clsx";
import KYCStatus from "./KYC";

const SettingsTabs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, passError, qrCode, isTwoFA } = useSelector(
    state => state.account
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { renderTitle } = useColoredTitle();

  useEffect(() => {
    dispatch(getAccountData());
  }, [dispatch]);

  useEffect(() => {
    if (isTwoFA) {
      dispatch(clearTwoFaQrCode());
    }
  }, [dispatch, isTwoFA]);

  const handleTwoFA = () => {
    data?.twofa ? setShowDeleteDialog(true) : dispatch(enableTwoFA());
  };

  const handleCloseQrCodeDialog = () => {
    dispatch(clearTwoFaQrCode());
  };

  const handleConfirmDisableTwoFA = ({ accept }) => {
    if (accept) {
      dispatch(disableTwoFA()).then(response => {
        response && dispatch(getAccountData());
      });
    }
    setShowDeleteDialog(false);
  };

  return (
    <>
      <TwoFaQrCodeDialog
        open={Boolean(qrCode)}
        handleClose={handleCloseQrCodeDialog}
        data={qrCode}
      />
      <DeleteConfirmDialog
        open={showDeleteDialog}
        title={t('Turn off two-factor authentication?')}
        message={t("Are you sure that you to turn off two-factor authentication?")}
        handleClose={handleConfirmDisableTwoFA}
        btnOkText='Disable'
      />
      <Paper className={classes.root} elevation={4}>
        {/* HIDE 2FA */}
        <Grid item container xs={6} className={classes.title}>
          {renderTitle(t('Account Settings'))}
        </Grid>

        <Grid
          container
          wrap='nowrap'
          justifyContent='center'>
          <Grid item container alignContent='center' className={classes.sectionHeader}>
            <Typography className={classes.sectionTitle} variant='h4'>Google Authenticator (2FA)</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={4}>
            <Typography
              className={classes.statusText}>
              {t("2FA status")}
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-start' xs={6} sm={4}>
            <Button
              disabled
              variant='contained'
              onClick={handleTwoFA}
              className={clsx(classes.btnTwofa, data?.twofa ? classes.activated : classes.deactivated)}>
              {data?.twofa ? t("Activated") : t("Deactivated")}
            </Button>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={12} sm={4} className={classes.switcherContainer}>
            <SwitchButton checked={data?.twofa || false}
              onChange={() => handleTwoFA()}
            />
          </Grid>
          <Grid item container justifyContent='flex-start' xs={12} >
            <Typography
              className={classes.descriptionText}>
              {/* {data?.twofa ? t("Active") : t("Deactivated")} */}
              {t("Google Authenticator is a software-based authenticator by Google.")}
            </Typography>
          </Grid>
        </Grid>
        {!data?.socialLogin && <ChangePassword passError={passError} />}
        <KYCStatus />
      </Paper >
    </>
  );
};

export default SettingsTabs;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 90,
    background: theme.palette.primary.light,
    padding: "32px 24px",
    gap: 24
  },
  details: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 24,
    [theme.breakpoints.down("xs")]: {
      gap: 16,
      padding: "40px 16px",
    },
  },

  title: {
    marginBottom: 24
  },

  sectionTitle: {
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 700,
  },

  sectionHeader: {
    borderBottom: `1px solid rgba(255,255,255,0.2)`,
    paddingBottom: 8,
    marginTop: 24,
    marginBottom: 24,
  },

  statusText: {
    fontSize: 20,
    fontWeight: 700,
  },

  descriptionText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: 4,
  },

  topPaper: {
    padding: "18px 22px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      gap: 4,
      padding: "40px 16px",
    },
  },
  twofaIcon: {
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
      marginRight: "20px",
    },
  },

  changeBtn: {
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
      marginBottom: 48,
    },
  },
  btnTwofa: {
    minWidth: 150,
    height: 42,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },

  input: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 14px) scale(1)",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
  },
  activated: {
    color: theme.palette.success.main,
    borderColor: `${theme.palette.success.main} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
  },
  deactivated: {
    color: theme.palette.error.main,
    borderColor: `${theme.palette.error.main} !important`,
    "& .MuiButton-label": {
      color: theme.palette.error.main,
    },
  },
  switcherContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'flex-start',
    },
  }
}));
