import { RequestStatus } from "../../utils/functions/requestStatuses";
import { AUTO_INVEST_PROGRAM } from "../types";

export default function autoInvest(
  state = {
    autoInvestPlansList: [],
    updateInvestPlanRequestStatus: RequestStatus.NOT_ASKED,
    error: null,
  },
  action
) {
  switch (action.type) {
    case AUTO_INVEST_PROGRAM.CREATE_AUTO_INVEST_PLAN.REQUEST:
    case AUTO_INVEST_PROGRAM.UPDATE_AUTO_INVEST_PLAN.REQUEST:
      return { ...state, error: null, updateInvestPlanRequestStatus: RequestStatus.LOADING }
    case AUTO_INVEST_PROGRAM.CHANGE_AUTO_INVEST_PLAN_STATUS.REQUEST:
    case AUTO_INVEST_PROGRAM.DELETE_AUTO_INVEST_PLAN_BY_ID.REQUEST:
    case AUTO_INVEST_PROGRAM.GET_AUTO_INVEST_PLAN_BY_ID.REQUEST:
    case AUTO_INVEST_PROGRAM.GET_ALL_AUTO_INVEST_PLANS.REQUEST:
      return { ...state, error: null };
    case AUTO_INVEST_PROGRAM.CREATE_AUTO_INVEST_PLAN.FAILURE:
    case AUTO_INVEST_PROGRAM.UPDATE_AUTO_INVEST_PLAN.FAILURE:
      return {...state, error: action.payload.error, updateInvestPlanRequestStatus: RequestStatus.FAILED }
    case AUTO_INVEST_PROGRAM.CHANGE_AUTO_INVEST_PLAN_STATUS.FAILURE:
    case AUTO_INVEST_PROGRAM.DELETE_AUTO_INVEST_PLAN_BY_ID.FAILURE:
    case AUTO_INVEST_PROGRAM.GET_AUTO_INVEST_PLAN_BY_ID.FAILURE:
    case AUTO_INVEST_PROGRAM.GET_ALL_AUTO_INVEST_PLANS.FAILURE:
      return { ...state, error: action.payload.error };
    case AUTO_INVEST_PROGRAM.CREATE_AUTO_INVEST_PLAN.SUCCESS:
    case AUTO_INVEST_PROGRAM.UPDATE_AUTO_INVEST_PLAN.SUCCESS:
      return {
        ...state,
        updateInvestPlanRequestStatus: RequestStatus.SUCCEEDED,
      }
    case AUTO_INVEST_PROGRAM.CHANGE_AUTO_INVEST_PLAN_STATUS.SUCCESS: {
      const updatedAutoInvestPlanList = state.autoInvestPlansList?.map((item) => {
        return item?.id === action?.payload?.id ? action.payload : item
      })
      return {
        ...state,
        autoInvestPlansList: updatedAutoInvestPlanList,
      }
    }
    case AUTO_INVEST_PROGRAM.GET_AUTO_INVEST_PLAN_BY_ID.SUCCESS:
    case AUTO_INVEST_PROGRAM.DELETE_AUTO_INVEST_PLAN_BY_ID.SUCCESS:
      return {
        ...state,
      }
    case AUTO_INVEST_PROGRAM.GET_ALL_AUTO_INVEST_PLANS.SUCCESS:
      return {
        ...state,
        autoInvestPlansList: action.payload,
      };
    default:
      return state;
  }
}
