const isWithdrawBlocked = state => state.account.permissions.withdrawBlocked;
const isExchangeBlocked = state => state.account.permissions.exchangeBlocked;
const isAccountBlocked = state => state.account.permissions.accountBlocked;
const isKycPassed = state => state.account.permissions.kycPassed;

export const accountSelectors = {
  isWithdrawBlocked,
  isExchangeBlocked,
  isAccountBlocked,
  isKycPassed,
};
