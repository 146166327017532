import {
  // ADD_NEW_ADDRESS_FAILURE,
  // ADD_NEW_ADDRESS_STARTED,
  // ADD_NEW_ADDRESS_SUCCESS, DELETE_ADDRESS_FAILURE, DELETE_ADDRESS_STARTED, DELETE_ADDRESS_SUCCESS,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_STARTED,
  GET_ADDRESSES_SUCCESS,
  CLEAR_ADDRESSES,
} from "../types";
import api from "../../services/api";

export const getAddressesStarted = () => ({
  type: GET_ADDRESSES_STARTED,
});

export const getAddressesSuccess = data => ({
  type: GET_ADDRESSES_SUCCESS,
  payload: {
    data,
  },
});

export const getAddressesFailure = error => ({
  type: GET_ADDRESSES_FAILURE,
  payload: {
    error,
  },
});

export const getAddresses = ticker => {
  return async dispatch => {
    dispatch(getAddressesStarted());
    api.addresses
      .getList(ticker)
      .then(res => dispatch(getAddressesSuccess(res.data)))
      .catch(error => dispatch(getAddressesFailure(error)));
  };
};

export const clearAddresses = () => ({
  type: CLEAR_ADDRESSES,
});

// export const addNewAddressStarted = () => ({
// 	type: ADD_NEW_ADDRESS_STARTED
// });
//
// export const addNewAddressSuccess = data => ({
// 	type: ADD_NEW_ADDRESS_SUCCESS,
// 	payload: {
// 		data
// 	}
// });
//
// export const addNewAddressFailure = error => ({
// 	type: ADD_NEW_ADDRESS_FAILURE,
// 	payload: {
// 		error
// 	}
// });
//
// export const addNewAddress = (ticker, name) => {
// 	return async (dispatch) => {
// 		dispatch(addNewAddressStarted());
// 		api.addresses.addNew(ticker, {name})
// 			.then(res => {
// 				dispatch(addNewAddressSuccess(res.data))
// 				dispatch(getAddresses(ticker));
// 			})
// 			.catch(error => dispatch(addNewAddressFailure(error)));
// 	}
// };
//
// export const deleteAddressStarted = () => ({
// 	type: DELETE_ADDRESS_STARTED
// });
//
// export const deleteAddressSuccess = data => ({
// 	type: DELETE_ADDRESS_SUCCESS,
// 	payload: {
// 		data
// 	}
// });
//
// export const deleteAddressFailure = error => ({
// 	type: DELETE_ADDRESS_FAILURE,
// 	payload: {
// 		error
// 	}
// });
//
// export const deleteAddress = (address_id, ticker) => {
// 	return async (dispatch) => {
// 		dispatch(deleteAddressStarted());
// 		api.addresses.deleteById(address_id)
// 			.then(res => dispatch(deleteAddressSuccess(res.data)))
// 			.catch(error => dispatch(deleteAddressFailure(error)))
// 			.finally(() => dispatch(getAddresses(ticker)));
// 	}
// };
