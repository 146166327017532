import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../theme/PageWrapper";
import Breadcrumb from "../../theme/components/Breadcrumb";
import Chart from "../../theme/components/Chart";
import Overview from "./Overview";
import Address from "./Address";
import Transactions from "./Transactions";
import { useEffect } from "react";
import { getAddresses } from "../../redux/actions/addressesActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllTickerSettings } from "../../redux/actions/walletsActions";
import clsx from "clsx";

const chartIgnore = ["UAHT", "USDT-TRC20", "USDT-ERC20"];

const WalletsDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ticker } = useParams();
  const dispatch = useDispatch();
  const address = useSelector(({ addresses }) => addresses.data[0]);
  const tickerSettings = useSelector(({ wallets }) =>
    wallets?.allTickerSettings?.find(i => i.ticker === ticker)
  );

  useEffect(() => {
    if (!address || address.ticker !== ticker) {
      dispatch(getAddresses(ticker));
      dispatch(getAllTickerSettings());
    }
  }, [dispatch, address, ticker]);

  return (
    <PageWrapper>
      {ticker && (
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={12} className={classes.breadcrumb}>
            <Breadcrumb to={"/"} pageName={"Wallets"} down={0} />
          </Grid>

          <Grid item xs={12} md={5} lg={4} className={classes.boxCoin}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Overview ticker={ticker} tickerSettings={tickerSettings} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Address address={address} tickerSettings={tickerSettings} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} lg={8} className={classes.boxAction}>
            {!chartIgnore.includes(ticker.toUpperCase()) && (
              <Box className={clsx(classes.block, classes.chartWrap)}>
                <Typography variant={"h4"} style={{ marginBottom: 16 }}>
                  {t("Chart").toUpperCase()}
                </Typography>
                <div style={{ height: 330 }} className={classes.chart}>
                  <Chart ticker={ticker} />
                </div>
              </Box>
            )}
            <Box className={classes.block}>
              <Typography variant={"h4"} style={{ marginTop: 16 }}>
                {t("Transactions").toUpperCase()}
              </Typography>
              <Transactions />
            </Box>
          </Grid>
        </Grid>
      )}
    </PageWrapper>
  );
};

export default WalletsDetails;

const useStyles = makeStyles(theme => ({
  root: {},
  breadcrumb: {
    paddingBottom: 32,
    marginTop: 20,
    marginBottom: 18,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 16,
    },
  },
  block: {
    padding: 24,
    background: theme.palette.primary.light,
    borderRadius: 20,
  },
  boxCoin: {
    paddingBottom: "20px !important",
    paddingRight: "14px !important",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 32,
      paddingRight: "8px !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "8px !important",
    },
  },
  boxAction: {
    paddingLeft: "10px !important",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "8px !important",
      marginTop: "0px",
    },
  },
  chartWrap: {
    marginBottom: 20,
  },
  chart: {
    borderRadius: 12,
    background: theme.palette.background.paper,
  },
}));
