import { makeStyles, Paper } from "@material-ui/core";

const OverviewBlock = ({ children, className }) => {
  const { root } = useStyles();

  return (
    <Paper elevation={4} className={root}>
      {children}
    </Paper>
  );
};

export default OverviewBlock;

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300,
    background: theme.palette.background.paper,
    padding: "32px 24px",
    borderRadius: 20,
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down("md")]: {
    },
    [theme.breakpoints.down(650)]: {
      padding: "32px 16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "32px 20px",
    },
  },
}));
