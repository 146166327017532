import {
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_STARTED,
  GET_ADDRESSES_SUCCESS,
  CLEAR_ADDRESSES,
} from "../types";

export default function addresses(
  state = {
    data: [],
    error: null,
  },
  action
) {
  switch (action.type) {
    case GET_ADDRESSES_STARTED:
      return { ...state, error: null };
    case GET_ADDRESSES_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_ADDRESSES_FAILURE:
      return { ...state, error: action.payload.error };
    case CLEAR_ADDRESSES:
      return { ...state, data: [], error: null };
    default:
      return state;
  }
}
