import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import { Button } from "../../theme/components/Button";
import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import CoinName from "../../theme/components/CoinName";
import { roundValue } from "../../utils/functions/roundValue";
import TextReadableInput from "../../theme/components/TextReadableInput";
import { Countdown } from "../../theme/components/Countdown";
import { getCompanyBalanceTransferInvoiceById, makeDecisionByCompanyBalanceTransferInvoice } from "../../redux/actions/companiesActions";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import { isLoading } from "../../utils/functions/requestStatuses";

export const CompanyBalanceTransferInvoiceDecisionTypes = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
}

const WithdrawCompanyBalanceConfirm = ({ open, onClose, invoice, companyName, companyBalanceId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const tickerSettings = useSelector(({ wallets }) => wallets.tickerSettings);
  const selectedTransferInvoiceFullData = useSelector(companiesSelectors.selectedCompanyBalanceTransferInvoice)
  const getCompanyBalanceTransferInvoiceByIdRequestStatus = useSelector(companiesSelectors.getCompanyBalanceTransferInvoiceByIdRequestStatus)

  const isLoaderNeeded = isLoading(getCompanyBalanceTransferInvoiceByIdRequestStatus)
  const user = useSelector(({ account }) => account.data);

  const ticker = invoice?.currency
  const amount = invoice?.amount

  const marketPrice = useSelector(
    ({ market }) => market.data.find(i => i?.ticker === ticker)?.price || 0
  );

  const isConfirmedByMe = useMemo(() => {
    if (selectedTransferInvoiceFullData?.decisions?.find(decision => decision.userId === user?.id)) {
      return true;
    }
    return false;
  }, [selectedTransferInvoiceFullData, user?.id]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose])

  const handleAcceptOrDeclineWithdrawRequest = useCallback(
    (decisionType) => () => {
      if (invoice) {
        dispatch(makeDecisionByCompanyBalanceTransferInvoice({
          decision: decisionType,
          invoiceId: invoice.id,
          balanceId: companyBalanceId,
        }))
        onClose?.();
      }
    },
    [companyBalanceId, dispatch, invoice, onClose],
  );

  useEffect(() => {
    if (open) {
      dispatch(getCompanyBalanceTransferInvoiceById(invoice?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, open])

  return (
    <DialogWrapper
      open={Boolean(open)}
      handleClose={handleClose}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.headerWrapper}>
            <Typography variant="h3" className={classes.titleDialog}>
              {t('Withdrawal Request')}
            </Typography>
            <Typography variant="h4" className={classes.companyTitle}>
              {companyName}
            </Typography>
          </Box>
          <Grid container direction="row" justifyContent="space-between">
            <Grid xs={5}>
              <TextReadableInput label={t('Login')} value={invoice?.targetUser} />
            </Grid>
            <Grid xs={5}>
              <TextReadableInput label={t('Login')} >
                <Grid container direction="row" wrap="nowrap">
                  <CoinName
                    ticker={ticker}
                    showOnlyIcon
                    iconSize={20}
                  />
                  <Typography className={classes.balanceValue} variant="body1">
                    {`${roundValue(amount, tickerSettings?.minScale)} ${ticker?.toUpperCase()}`}
                    &nbsp;&nbsp;/
                  </Typography>
                  <Typography className={classes.balanceValueUSD} variant="body2">
                    {`${roundValue(amount * marketPrice, 2)} USD`}
                  </Typography>
                </Grid>
              </TextReadableInput>
            </Grid>
            <TextReadableInput label={t('Confirmation')}>
              <Grid container="row" justifyContent="space-between">
                <Typography>
                  {`${selectedTransferInvoiceFullData?.confirmations}/${selectedTransferInvoiceFullData?.minConfirmationsToAccept}`}
                </Typography>
                <Countdown
                  finishTime={selectedTransferInvoiceFullData?.finishTime}
                  showFinishText
                />
              </Grid>
            </TextReadableInput>
            <TextReadableInput noUnderline label={t('Description')} value={invoice?.description} />
          </Grid>
        </Grid>
        {!isLoaderNeeded && isConfirmedByMe && (
          <Grid container alignItems="center" justifyContent="center">
            <Typography className={classes.infoText}>
              {t('You have already confirmed the operation')}
            </Typography>
          </Grid>
        )}
        {!isLoaderNeeded && Boolean(selectedTransferInvoiceFullData?.isAllowedToMakeDecision) &&
          !isConfirmedByMe &&
          <Grid item xs={12} direction="row" container justifyContent="space-around">
            <Button
              className={classes.buttonCancel}
              variant="contained"
              onClick={handleAcceptOrDeclineWithdrawRequest(CompanyBalanceTransferInvoiceDecisionTypes.DECLINE)}
            >
              {t("Decline")}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleAcceptOrDeclineWithdrawRequest(CompanyBalanceTransferInvoiceDecisionTypes.ACCEPT)}
            >
              {t("Confirm")}
            </Button>
          </Grid>
        }
      </Grid>
    </DialogWrapper >
  );
};

export default WithdrawCompanyBalanceConfirm;

const useStyles = makeStyles((theme) => ({
  input: {
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.default,
        fontSize: 8,
        cursor: "pointer",
        textDecoration: "none",
        background: theme.palette.text.secondary,
        opacity: 0.8,
        borderRadius: "4px",
        padding: "2px 12px",
        marginRight: 20,
      },
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    margin: '0 auto',
    marginBottom: 32,
  },
  titleDialog: {
    marginBottom: 12,
    textAlign: 'center',
    color: theme.palette.border.gold,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  companyTitle: {
    marginBottom: 40,
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
  balanceValue: {
    marginLeft: 14,
  },
  balanceValueUSD: {
    color: theme.palette.text.secondary,
    marginLeft: 8,
    marginBottom: -8
  },
  infoText: {
    fontSize: 14,
    color: theme.palette.text.darkLighter,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 14
  },
  button: {
    marginTop: 10,
  },
  buttonCancel: {
    marginTop: 10,
    borderColor: `${theme.palette.error.main} !important`,
  }
}));
