import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "./theme/components/Loading";
import PrivateRoute from "./utils/PrivateRoute";
import Wallets from "./pages/Wallets";
import Transactions from "./pages/Transactions";
import WalletDetails from "./pages/WalletsDetails/index";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Notifier from "./utils/Notifier";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Button } from "./theme/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "./redux/actions/notistackActions";
import AddNewWalletDialog from "./theme/dialogs/AddNewWalletDialog";
import ReceiveCardDialog from "../src/pages/Receive/fiat/ReceiveCardDialog";
import Send from "./pages/Send";
import Settings from "./pages/Settings";
import Exchange from "./pages/Exchange";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { useCallback, useEffect, useRef } from "react";
import { getMarketList } from "./redux/actions/marketActions";
import Loader from "./pages/Loader";
import KYC from "./pages/KYC";
import ReferralProgram from "./pages/ReferralProgram";
import AutoInvest from "./pages/AutoInvest";
import Projects from "./pages/Projects";
import Moneybox from "./pages/Moneybox";
import MoneyboxDetails from "./pages/MoneyboxDetails";
import MoneyboxBalance from "./pages/MoneyboxBalance";
import Companies from "./pages/Companies";
import CompanyDetails from "./pages/CompanyDetails";
import CompanyBalance from "./pages/CompanyBalance";

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeOut = useRef();
  const { authorized } = useSelector(({ account }) => account);
  const getMarkets = useCallback(() => {
    if (authorized) {
      dispatch(getMarketList());
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        getMarkets();
      }, 30000);
    } else {
      clearTimeout(timeOut.current);
    }
  }, [dispatch, authorized]);

  useEffect(() => {
    getMarkets();
    return () => {
      clearTimeout(timeOut.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMarkets, authorized]);

  useEffect(() => {
    // fix token clear in dev mode on hot reload, investigate if it is needed at all
    const navigationEntries = window.performance.getEntriesByType('navigation');

    return () => {
      if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      } else {
        localStorage.clear();
        document.cookie = "";
      }
    }
  }, [])




  return (
    <SnackbarProvider
      action={(key) => (
        <Button
          onClick={() => dispatch(closeSnackbar(key))}
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        />
      )}
      iconVariant={{
        success: <DoneIcon />,
        error: <NotInterestedIcon />,
        warning: <ErrorOutlineOutlinedIcon />,
        info: <InfoOutlinedIcon />,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        variantSuccess: clsx(classes.root, classes.success),
        variantError: clsx(classes.root, classes.error),
        variantWarning: clsx(classes.root, classes.warning),
        variantInfo: clsx(classes.root, classes.info),
      }}
    >
      <Notifier />
      <AddNewWalletDialog />
      <ReceiveCardDialog />
      <BrowserRouter>
        <Loading />
        <Switch>
          <Route path="/login">
            <Loader />
          </Route>
          <Route path="/privacy" exact>
            <Privacy />
          </Route>
          <Route path="/terms" exact>
            <Terms />
          </Route>
          <PrivateRoute path="/" exact>
            <Wallets />
          </PrivateRoute>
          <PrivateRoute path="/wallet/:ticker" exact>
            <WalletDetails />
          </PrivateRoute>
          <PrivateRoute path="/projects" exact>
            <Projects />
          </PrivateRoute>
          <PrivateRoute path="/send" exact>
            <Send />
          </PrivateRoute>
          <PrivateRoute path="/exchange" exact>
            <Exchange />
          </PrivateRoute>
          <PrivateRoute path="/settings" exact>
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/transactions" exact>
            <Transactions />
          </PrivateRoute>
          <PrivateRoute path="/kyc" exact>
            <KYC />
          </PrivateRoute>
          <PrivateRoute path="/auto_invest" exact>
            <AutoInvest />
          </PrivateRoute>
          <PrivateRoute path="/moneybox" exact>
            <Moneybox />
          </PrivateRoute>
          <PrivateRoute path="/moneybox/:moneyboxId" exact>
            <MoneyboxDetails />
          </PrivateRoute>
          <PrivateRoute path="/moneybox/:moneyboxId/:balanceId" exact>
            <MoneyboxBalance />
          </PrivateRoute>
          <PrivateRoute path="/referral_program" exact>
            <ReferralProgram />
          </PrivateRoute>
          <PrivateRoute path="/companies" exact>
            <Companies />
          </PrivateRoute>
          <PrivateRoute path="/companies/:companyId" exact>
            <CompanyDetails />
          </PrivateRoute>
          <PrivateRoute path="/companies/:companyId/:balanceId" exact>
            <CompanyBalance />
          </PrivateRoute>

          <Redirect to={"/"} />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    padding: "8px 16px",
    "&>*": {
      fontSize: 12,
      lineHeight: "16px",
      maxWidth: 220,
      fontWeight: 500,
    },
    "& svg": {
      fontSize: 24,
      marginRight: 11,
    },
  },
  success: {
    backgroundColor: "#36523E",
    "& svg": {
      color: "#5BB55D",
    },
  },
  error: {
    backgroundColor: "#5F3238",
    "& svg": {
      color: "#FF2F51",
    },
  },
  warning: {
    backgroundColor: "#655129",
    "& svg": {
      color: "#F8B017",
    },
  },
  info: {
    backgroundColor: "#21354C",
    "& svg": {
      color: "#18A0FB",
    },
  },
}));
