import { roundValue } from "../roundValue";
import i18n from "../../../i18n.config";
export function maxPrecisionValidator(value, numberDigits) {
  if (typeof +value !== "number") {
    return {
      invalid: false,
      errorMessage: "",
    };
  }

  const str = value.toString();
  var after;
  if (str.indexOf("e") === -1) {
    const result = str.split(".").map(i => i.length);
    after = result[1] || 0;
    if (after > numberDigits) {
      return {
        invalid: true,
        errorMessage: i18n.t("Scale should be less or equal to {{value}}", {
          value: numberDigits,
        }),
      };
    }
  }

  const exponent = parseInt(str.split("-")[1], 10);
  const result = (roundValue(value, exponent) + "")
    .split(".")
    .map(i => i.length);
  after = result[1] || 0;
  if (after > numberDigits) {
    return {
      invalid: true,
      errorMessage: i18n.t("Scale should be less or equal to {{value}}", {
        value: numberDigits,
      }),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}
