import { REFERRAL_PROGRAM } from "../types";

export default function referral(
  state = {
    referralInfo: {},
    referralBalances: {},
    claimHistory: null,
    referralRewards: null,
    status: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case REFERRAL_PROGRAM.GET_REFERRAL_INFO.REQUEST:
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE.REQUEST:
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE_BY_ASSET.REQUEST:
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY.REQUEST:
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY_BY_ASSET.REQUEST:
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS.REQUEST:
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS_BY_ASSET.REQUEST:
      return { ...state, error: null };
    case REFERRAL_PROGRAM.CLAIM_FUNDS_FROM_REFERRAL_BALANCE.REQUEST:
      return { ...state, error: null, status: false };
    case REFERRAL_PROGRAM.GET_REFERRAL_INFO.FAILURE:
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE.FAILURE:
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE_BY_ASSET.FAILURE:
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY.FAILURE:
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY_BY_ASSET.FAILURE:
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS.FAILURE:
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS_BY_ASSET.FAILURE:
      return { ...state, error: action.payload.error };
    case REFERRAL_PROGRAM.CLAIM_FUNDS_FROM_REFERRAL_BALANCE.FAILURE:
      return { ...state, error: action.payload.error, status: false };
    case REFERRAL_PROGRAM.GET_REFERRAL_INFO.SUCCESS:
      return {
        ...state,
        referralInfo: action.payload,
      };
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE.SUCCESS:
    case REFERRAL_PROGRAM.GET_REFERRAL_BALANCE_BY_ASSET.SUCCESS:
      return {
        ...state,
        referralBalances: action.payload,
      };
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY.SUCCESS:
    case REFERRAL_PROGRAM.GET_CLAIM_HISTORY_BY_ASSET.SUCCESS:
      return {
        ...state,
        claimHistory: action.payload,
      };
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS.SUCCESS:
    case REFERRAL_PROGRAM.GET_REFERRAL_REWARDS_BY_ASSET.SUCCESS:
      return {
        ...state,
        referralRewards: action.payload,
      };
    case REFERRAL_PROGRAM.CLAIM_FUNDS_FROM_REFERRAL_BALANCE.SUCCESS:
      return {
        ...state,
        status: true,
      };
    case REFERRAL_PROGRAM.CLEAR_REFERRAL_STATUS:
      return {
        ...state,
        status: false,
        error: null,
      };
    default:
      return state;
  }
}
