import { connect } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import bgImage from "../assets/main_bg.svg";
import Header from "./components/Header/Header";
import clsx from "clsx";

const PageWrapper = ({ dispatch, children, bgColor }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, bgColor === 'secondary' ? classes.secondaryBg : classes.primaryBg)}>
      <Header />
      <Container maxWidth="lg">{children}</Container>
    </div>
  );
};

const mapStateToProps = ({ account }) => ({
  data: account.data,
});

export default connect(mapStateToProps)(PageWrapper);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 104,
    boxSizing: "border-box",
    minHeight: "100vh",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 80,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  primaryBg: {
    // background: 'radial-gradient(40% 400px at 44% 33%, #4AAF574D 0%, #181818 80%)'
    backgroundImage: `url(${bgImage})`,
    // backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20% -250px',
    backgroundSize: '1400px',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '1200px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '1200px',
    },
  },
  secondaryBg: {
    background: 'radial-gradient(50% 600px at 52% 4%, #324B9280, #324B9280 0%, #324B9280 0%, #181818 80%)'
  }
}));
