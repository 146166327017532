import { AUTO_INVEST_PROGRAM } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
// import { enqueueSnackbar } from "../../redux/actions/notistackActions";
// import i18next from "../../i18n.config";

export const createAutoInvestPlanAction = actionCreator(
  AUTO_INVEST_PROGRAM.CREATE_AUTO_INVEST_PLAN
);

export const createAutoInvestPlan = (data) => {
  return async (dispatch) => {
    dispatch(createAutoInvestPlanAction.started());
    api.autoInvest
      .createAutoInvestPlan(data)
      .then((res) => {
        dispatch(createAutoInvestPlanAction.done(res.data))
        dispatch(getAllAutoInvestPlans());
      })
      .catch((error) => dispatch(createAutoInvestPlanAction.failed(error)));
  };
};

export const updateAutoInvestPlanAction = actionCreator(
  AUTO_INVEST_PROGRAM.UPDATE_AUTO_INVEST_PLAN
);

export const updateAutoInvestPlan = (data) => {
  return async (dispatch) => {
    dispatch(updateAutoInvestPlanAction.started());
    api.autoInvest
      .updateAutoInvestPlan(data)
      .then((res) => {
        dispatch(updateAutoInvestPlanAction.done(res.data));
        dispatch(getAllAutoInvestPlans());
      })
      .catch((error) => dispatch(updateAutoInvestPlanAction.failed(error)));
  };
};

export const changeAutoInvestPlanStatusAction = actionCreator(
  AUTO_INVEST_PROGRAM.CHANGE_AUTO_INVEST_PLAN_STATUS
);

export const changeAutoInvestPlanStatus = (data) => {
  return async (dispatch) => {
    dispatch(changeAutoInvestPlanStatusAction.started());
    api.autoInvest
      .changeAutoInvestPlanStatus(data)
      .then((res) => {
        dispatch(changeAutoInvestPlanStatusAction.done(res.data));
      })
      .catch((error) =>
        dispatch(changeAutoInvestPlanStatusAction.failed(error))
      );
  };
};

export const deleteAutoInvestPlanAction = actionCreator(
  AUTO_INVEST_PROGRAM.DELETE_AUTO_INVEST_PLAN_BY_ID
);

export const deleteAutoInvestPlan = (planId) => {
  return async (dispatch) => {
    dispatch(deleteAutoInvestPlanAction.started());
    api.autoInvest
      .deleteAutoInvestPlanById(planId)
      .then((res) => {
        dispatch(deleteAutoInvestPlanAction.done(res.data));
        dispatch(getAllAutoInvestPlans());
      })
      .catch((error) =>
        dispatch(deleteAutoInvestPlanAction.failed(error))
      );
  };
};

export const getAutoInvestPlanByIdAction = actionCreator(
  AUTO_INVEST_PROGRAM.GET_AUTO_INVEST_PLAN_BY_ID
);

export const getAutoInvestPlanById = (params) => {
  return async (dispatch) => {
    dispatch(getAutoInvestPlanByIdAction.started());
    api.autoInvest
      .getAutoInvestPlanById(params)
      .then((res) => {
        dispatch(getAutoInvestPlanByIdAction.done(res.data));
      })
      .catch((error) => dispatch(getAutoInvestPlanByIdAction.failed(error)));
  };
};

export const getAllAutoInvestPlansAction = actionCreator(
  AUTO_INVEST_PROGRAM.GET_ALL_AUTO_INVEST_PLANS
);

export const getAllAutoInvestPlans = (params) => {
  return async (dispatch) => {
    dispatch(getAllAutoInvestPlansAction.started());
    api.autoInvest
      .getAllAutoInvestPlans(params)
      .then((res) => {
        dispatch(getAllAutoInvestPlansAction.done(res.data));
      })
      .catch((error) => dispatch(getAllAutoInvestPlansAction.failed(error)));
  };
};

