import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { InputRegular } from "../../../components/Input";
import { useCallback, useState } from "react";
import clsx from "clsx";
import { DAYS_OF_WEEK_LIST } from "../../../../utils/constants/date";

const WeeksIntervalList = ({ onSelect, defaultValue }) => {
  const classes = useStyles();
  const [selectedCycle, setSelectedCycle] = useState(DAYS_OF_WEEK_LIST[defaultValue || 0])

  const handleCycleClick = useCallback((value) => {
    setSelectedCycle(value)
    onSelect?.(value)
  }, [onSelect])

  return (
    <Grid container spacing={1} className={classes.root}>
      {
        DAYS_OF_WEEK_LIST.map((cycle, index) => {
          return (
            <Grid key={cycle.value} item className={classes.gridItem}>
              <InputRegular
                key={cycle.value}
                className={clsx(classes.input, selectedCycle.value === cycle.value && classes.selectedInput)}
                variant='outlined'
                name={cycle.value}
                value={cycle.label}
                type={"text"}
                onClick={() => handleCycleClick(cycle)}
                InputProps={{
                  readOnly: true,
                  form: {
                    autoComplete: "off",
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )
        })
      }
    </Grid>
  );
};

WeeksIntervalList.propTypes = {
  onSelect: PropTypes.func,
};

export default WeeksIntervalList;

const useStyles = makeStyles(theme => ({
  gridItem: {
    maxWidth: '14.2%',
  },
  root: {
    marginTop: 2,
    marginBottom: 12,
  },
  title: {
    fontSize: 14,
    marginBottom: 5,
  },
  input: {
    marginBottom: 0,
    marginTop: 0,
    boxSizing: 'border-box',
    border: `1px solid #2B2F38`,
    cursor: 'pointer',
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      padding: '14px 14px 14px 14px !important',
      textTransform: 'uppercase',
    },
    "& .MuiOutlinedInput-root": {
      "&>input": {
        color: theme.palette.text.secondary,
      }
    }
  },
  selectedInput: {
    border: `1px solid ${theme.palette.border.main}`,
    "& .MuiOutlinedInput-root": {
      "&>input": {
        color: theme.palette.text.tertiary,
      }
    }
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    '& .MuiSwitch-thumb': {
      height: 14,
      width: 14
    }
  },
  switcherElement: {
    height: 20,
    width: 34,
    borderRadius: 0,
  },
  switcherLabel: {
    marginRight: 5,
    fontSize: 12,
  }
}));
