import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import { getKycStatus } from "../../redux/actions/kycActions";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../theme/components/Breadcrumb";
import { getAllTickerSettings, getWalletsList, getWalletsSettingsList } from "../../redux/actions/walletsActions";
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";
import { getMoneyboxBalanceById, getMoneyboxById } from "../../redux/actions/moneyboxActions";
import MoneyboxBalanceOverview from "./Overview";
import MoneyboxBalanceTransactions from "./Transactions";

export const MoneyboxBalance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { moneyboxId, balanceId } = useParams();

  const selectedMoneyboxData = useSelector(moneyboxSelectors.selectedMoneybox)
  const selectedMoneyboxBalance = useSelector(moneyboxSelectors.selectedMoneyboxBalance)

  useEffect(() => {
    dispatch(getKycStatus());
    dispatch(getMoneyboxBalanceById(balanceId))
    dispatch(getMoneyboxById(moneyboxId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
  }, [dispatch])

  return (
    <PageWrapper>
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to={`/moneybox/${moneyboxId}`} pageName={t('Moneybox')} />
            <Typography variant="h2" className={classes.title}>
              {selectedMoneyboxData?.name}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {selectedMoneyboxData?.description}
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MoneyboxBalanceOverview balanceId={selectedMoneyboxBalance?.balanceId} ticker={selectedMoneyboxBalance?.currency} balance={selectedMoneyboxBalance?.balance} address={selectedMoneyboxBalance?.address} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <MoneyboxBalanceTransactions balanceTicker={selectedMoneyboxBalance?.currency} balanceId={selectedMoneyboxBalance?.balanceId} />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default MoneyboxBalance;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  description: {
    fontSize: 20,
    marginLeft: 28,
    alignSelf: 'center',
    marginTop: 7,
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
