import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import { getKycStatus } from "../../redux/actions/kycActions";
import AboutMoneybox from "./AboutMoneybox";
import { useTranslation } from "react-i18next";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import Breadcrumb from "../../theme/components/Breadcrumb";
import CreateMoneybox from "./CreateMoneybox";
import MoneyboxCard from "./MoneyboxCard";
import { getMarketList } from "../../redux/actions/exchangeActions";
import { getAllTickerSettings, getWalletsList, getWalletsSettingsList } from "../../redux/actions/walletsActions";
import { Button } from "../../theme/components/Button";
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";
import { deleteMoneybox, getMoneyboxList } from "../../redux/actions/moneyboxActions";
import CreateMoneyboxDialog from "./CreateMoneyboxDialog";
import DeleteWithWarningDialog from "../../theme/dialogs/DeleteWithWarningDialog";

export const Moneybox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [showCreateMoneybox, setShowCreateMoneybox] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedMoneybox, setSelectedMoneybox] = useState(null)
  const moneyboxList = useSelector(moneyboxSelectors.moneyboxList)

  useEffect(() => {
    dispatch(getKycStatus());
  }, [dispatch]);

  const showCreatePlanBlock = !moneyboxList?.length

  const handleShowCreateMoneyboxDialog = () => {
    setShowCreateMoneybox(true)
  }

  const handleSelectMoneybox = (id) => {
    history.push(`moneybox/${id}`)
  }

  const handleOpenDeleteDialog = (planData) => {
    setShowDeleteDialog(true)
    setSelectedMoneybox(planData)
  }

  const handleCloseDeleteConfirmDialog = ({ accept }) => {
    if (accept) {
      dispatch(deleteMoneybox(selectedMoneybox?.id))
    }
    setShowDeleteDialog(false);
    setSelectedMoneybox(null)
  };

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getMarketList());
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
    dispatch(getMoneyboxList());
  }, [dispatch])

  return (
    <PageWrapper>
      <CreateMoneyboxDialog
        open={showCreateMoneybox}
        onClose={() => setShowCreateMoneybox(false)}
      />
      <DeleteWithWarningDialog
        open={Boolean(showDeleteDialog)}
        title={t("Delete moneybox")}
        titleDescription={selectedMoneybox?.name}
        message={t("Are you sure you want to delete moneybox?")}
        handleClose={handleCloseDeleteConfirmDialog}
        warningText={t("You will not be able to delete this moneybox as long as there are assets on your balance sheet!")}
      />
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to='/projects' pageName='Projects' />
            <Typography variant="h2" className={classes.title}>
              {coloredFirstLetter(t('Moneybox'))}
            </Typography>
          </Box>
          {
            !showCreatePlanBlock &&
            <Button
              variant='contained'
              className={classes.btnSmall}
              onClick={handleShowCreateMoneyboxDialog}>
              {t("Create moneybox")}
            </Button>
          }
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid spacing={!showCreatePlanBlock ? 3 : 0} item xs={12} sm={12} md={8} container >
            {showCreatePlanBlock ?
              <Paper className={classes.paper}>
                <CreateMoneybox onCreatePlanClick={handleShowCreateMoneyboxDialog} />
              </Paper>
              : moneyboxList?.map((item) => (
                <Grid item xs={6} key={item?.id}>
                  <MoneyboxCard
                    data={item}
                    onDeleteClick={handleOpenDeleteDialog}
                    onClick={handleSelectMoneybox}
                  />
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <AboutMoneybox />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper >
  );
};

export default Moneybox;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
    textTransform: 'uppercase',
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
    marginRight: 24,
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
