import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputRegular } from "../../../components/Input";
import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { SwitchButton } from "../../../components/SwitchButton";

export const INVEST_PLAN_CYCLE = {
  ONE_TIME: 'ONE_TIME',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  HOURLY_1: 'HOURLY_1',
  HOURLY_6: 'HOURLY_6',
  HOURLY_12: 'HOURLY_12'
}

export const INVEST_PLAN_CYCLES_LIST = [
  {
    label: 'Daily',
    value: INVEST_PLAN_CYCLE.DAILY,
  },
  {
    label: 'Weekly',
    value: INVEST_PLAN_CYCLE.WEEKLY,
  },
  {
    label: 'Monthly',
    value: INVEST_PLAN_CYCLE.MONTHLY,
  },
  {
    label: '1 Hour',
    value: INVEST_PLAN_CYCLE.HOURLY_1,
  },
  {
    label: '6 Hour',
    value: INVEST_PLAN_CYCLE.HOURLY_6,
  },
  {
    label: '12 Hour',
    value: INVEST_PLAN_CYCLE.HOURLY_12,
  },
]

const InvestPlanCyclesList = ({ onSelect, defaultValue, isOneTimeInvestDefaultValue = false, onChangeOneTimeInvestSwitcher = () => { } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedCycle, setSelectedCycle] = useState(defaultValue || INVEST_PLAN_CYCLES_LIST[0])
  const [isOneTimeInvest, setIsOneTimeInvest] = useState(isOneTimeInvestDefaultValue || false)

  const handleCycleClick = useCallback((value) => {
    onSelect?.(value)
    setSelectedCycle(value)
  }, [onSelect])

  useEffect(() => {
    if (defaultValue && defaultValue?.value && defaultValue?.value !== selectedCycle?.value) {
      setSelectedCycle(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  useEffect(() => {
    if (isOneTimeInvestDefaultValue !== isOneTimeInvest) {
      setIsOneTimeInvest(isOneTimeInvestDefaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneTimeInvestDefaultValue])

  const handleChangeOneTimeInvestSwitcher = useCallback((event) => {
    onChangeOneTimeInvestSwitcher?.(!isOneTimeInvest)
    setIsOneTimeInvest(!isOneTimeInvest)
  }, [isOneTimeInvest, onChangeOneTimeInvestSwitcher])

  return (
    <Grid className={classes.root}>
      <Grid container flexDirection="row" justifyContent="space-between">
        <Typography className={classes.title}>
          {t("Recurring Cycle")}
        </Typography>
        <div className={classes.switcher}>
          <Typography className={[classes.switcherLabel]}>
            {t('One-Time')}
          </Typography>
          <SwitchButton checked={isOneTimeInvest}
            onChange={handleChangeOneTimeInvestSwitcher}
            className={classes.switcherElement}
          />
        </div>
      </Grid>
      {isOneTimeInvest ?
        <Box className={classes.oneTimePlanBlock}>
          <Typography className={classes.text}>
            {t('Plan will be immediately purchased once the submission is completed.')}
          </Typography>
        </Box>
        :
        <Grid container spacing={isOneTimeInvest ? 0 : 1}>
          {INVEST_PLAN_CYCLES_LIST.map((cycle, index) => {
            return (
              <Grid key={cycle.value} item md={4} style={{ margin: 0 }}>
                <InputRegular
                  key={cycle.value}
                  className={clsx(classes.input, selectedCycle.value === cycle.value && classes.selectedInput)}
                  variant='outlined'
                  name={cycle.value}
                  value={cycle.label}
                  type={"text"}
                  onClick={() => handleCycleClick(cycle)}
                  InputProps={{
                    readOnly: true,
                    form: {
                      autoComplete: "off",
                    },
                    style: {
                      cursor: 'pointer'
                    }
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      }
    </Grid>

  );
};

InvestPlanCyclesList.propTypes = {
  onSelect: PropTypes.func,
};

export default InvestPlanCyclesList;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    marginBottom: 16,
  },
  title: {
    fontSize: 14,
    marginBottom: 8,
  },
  input: {
    marginBottom: 0,
    marginTop: 0,
    boxSizing: 'border-box',
    border: `1px solid #2B2F38`,
    cursor: 'pointer',
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      padding: '14px 14px 14px 14px !important',
    }
  },
  selectedInput: {
    border: `1px solid ${theme.palette.border.main}`,
    "& .MuiOutlinedInput-root": {
      "&>input": {
        color: theme.palette.text.tertiary,
      }
    }
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    '& .MuiSwitch-thumb': {
      height: 14,
      width: 14
    }
  },
  switcherElement: {
    height: 20,
    width: 34,
    borderRadius: 0,
  },
  switcherLabel: {
    marginRight: 5,
    fontSize: 12,
  },
  oneTimePlanBlock: {
    background: '#2B2F38',
    borderRadius: 15,
    padding: '20px 10px',
    marginBottom: 20,
  },
  text: {
    color: theme.palette.text.secondary
  }
}));
