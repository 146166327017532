import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Button } from "../../theme/components/Button";

const CreatePlanBlock = ({ onCreatePlanClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleCreatePlan = (method) => {
    onCreatePlanClick?.()
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <Grid item alignItems="center" justifyContent="center" container>
        <Typography variant="h2" className={classes.title}>{t('Create your own')}<span style={{ color: theme.palette.text.tertiary }}> {t('Auto Invest Plan')}</span></Typography>
      </Grid>
      <span className={classes.divider}></span>
      <Grid item container>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h5"
            className={clsx(classes.description)}
          >
            {t(
              "Regularly invest in cryptocurrency with a pre-determined amount and accumulate your crypto holdings"
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='center'>
        <Button
          variant='contained'
          onClick={handleCreatePlan}
          className={classes.btn}>
          {t("Create a plan")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreatePlanBlock;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: { padding: "20px" },
  },
  divider: {
    display: "flex",
    alignItems: "center",
    marginTop: 22,
    height: 1,
    width: "60%",
    backgroundColor: theme.palette.divider,
  },
  title: {
    fontWeight: 700,
    marginTop: 28,
  },
  description: {
    marginBottom: 30,
    marginTop: 24,
    float: "right",
    fontWeight: 400,
  },
  disabled: {
    opacity: 0.3,
    cursor: "default",
  },

  btn: {
    minWidth: 180,
    height: 42,
    marginTop: 14,
    marginBottom: 10,
    "& .MuiButton-label": {
      color: theme.palette.text.tertiary,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
}));
