import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExchangeIcon } from "../../assets/exchange.svg";
import { getOrderList } from "../../redux/actions/exchangeActions";
import { useTransactionsStyles } from "../Transactions";
import { roundValue } from "../../utils/functions/roundValue";
import getStatusColor from "../../utils/functions/getStatusColor";

const History = ({ exchangePage }) => {
  const cls = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderList } = useSelector(({ exchange }) => exchange);

  // const orderList = {
  //   data: [
  //     {
  //       id: 10,
  //       uid: 1234,
  //       assetFrom: 'ETH',
  //       assetTo: 'BTC',
  //       symbol: 'ETHBTC',
  //       amount: 20,
  //       price: 200,
  //       total: 2,
  //       status: 'Completed',
  //       updateDate: '2023-07-27T10:05:13.957193Z',
  //       createDate: '2023-07-27T10:05:13.957193Z',
  //     },
  //     {
  //       id: 10,
  //       uid: 1234,
  //       assetFrom: 'ETH',
  //       assetTo: 'BTC',
  //       symbol: 'ETHBTC',
  //       amount: 20,
  //       price: 200,
  //       total: 2,
  //       status: 'Completed',
  //       updateDate: '2023-07-27T10:05:13.957193Z',
  //       createDate: '2023-07-27T10:05:13.957193Z',
  //     },
  //     {
  //       id: 10,
  //       uid: 1234,
  //       assetFrom: 'ETH',
  //       assetTo: 'BTC',
  //       symbol: 'ETHBTC',
  //       amount: 20,
  //       price: 200,
  //       total: 2,
  //       status: 'Completed',
  //       updateDate: '2023-07-27T10:05:13.957193Z',
  //       createDate: '2023-07-27T10:05:13.957193Z',
  //     },
  //     {
  //       id: 10,
  //       uid: 1234,
  //       assetFrom: 'ETH',
  //       assetTo: 'BTC',
  //       symbol: 'ETHBTC',
  //       amount: 20,
  //       price: 200,
  //       total: 2,
  //       status: 'Completed',
  //       updateDate: '2023-07-27T10:05:13.957193Z',
  //       createDate: '2023-07-27T10:05:13.957193Z',
  //     },
  //     {
  //       id: 10,
  //       uid: 1234,
  //       assetFrom: 'ETH',
  //       assetTo: 'BTC',
  //       symbol: 'ETHBTC',
  //       amount: 20,
  //       price: 200,
  //       total: 2,
  //       status: 'Completed',
  //       updateDate: '2023-07-27T10:05:13.957193Z',
  //       createDate: '2023-07-27T10:05:13.957193Z',
  //     },
  //   ]
  // }

  useEffect(() => {
    dispatch(getOrderList({ page: 0, size: 10 }));
  }, [dispatch]);

  return (
    <div className={clsx(exchangePage && cls.root)}>
      {exchangePage ? (
        <Box className={cls.header}>
          <Typography display="inline" variant={"h4"} className={clsx(cls.title, cls.coloredText)}>
            {t("Exchange")}{" "}
          </Typography>
          <Typography display="inline" variant={"h4"} className={cls.title}>{t("history")}</Typography>
        </Box>
      ) : (
        <></>
      )}
      {orderList?.data?.length > 0 ? (
        orderList?.data?.map(order => (
          <HistoryItem {...order} key={order?.id} />
        ))
      ) : (
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          className={cls.emptyText}>
          {t("Your exchange history is empty...")}
        </Typography>
      )}
    </div>
  );
};

export default History;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 24px',
    marginTop: 14,
    display: 'flex',
    background: theme.palette.primary.light,
    borderRadius: 20,
    minHeight: 240,
    flexDirection: 'column',
    [theme.breakpoints.down("sm")]: {
    },
  },
  header: {
    marginBottom: 28,
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  coloredText: {
    color: theme.palette.text.tertiary
  },
  emptyText: {
    margin: '60px 0px 50px',
    textAlign: 'center',
    alignSelf: 'center'
  }
}));

export const HistoryItem = ({
  symbol = "",
  amount = 0,
  total = 0,
  status = "New",
  updateDate = "",
  assetFrom = "",
  assetTo = "",
}) => {
  const classes = useTransactionsStyles();
  const { t } = useTranslation();
  const setCoinName = coin => {
    return coin.startsWith("USDT-") ? coin.slice(5) : coin;
  };
  const { allTickerSettings } = useSelector(({ wallets }) => wallets);
  const minScale = useMemo(() => {
    return {
      assetFrom: allTickerSettings?.find(i => i?.ticker === assetFrom)
        ?.minScale,
      assetTo: allTickerSettings?.find(i => i?.ticker === assetTo)?.minScale,
    };
  }, [allTickerSettings, assetFrom, assetTo]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={5} className={classes.type}>
          <ExchangeIcon className={classes.icon} style={{marginRight: '16px'}} />
          <div>
            <Typography
              variant={"body1"}
              className={classes.transactionText}
              noWrap>
              {/* { symbol?.slice(0, 3)?.toUpperCase() } → { symbol?.slice(3, 6)?.toUpperCase() } */}
              {setCoinName(assetFrom?.toUpperCase())} <span style={{ textTransform: 'lowercase' }}>{t("to")}</span>{" "}
              {setCoinName(assetTo?.toUpperCase())}
            </Typography>
            <Typography
              variant={"subtitle2"}
              style={{
                textOverflow: "ellipsis",
                color: status && getStatusColor(status),
              }}>
              {t(status)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={5} className={clsx(classes.amount, "sent")}>
          <Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
            {roundValue(amount, minScale?.assetFrom)} {assetFrom?.toUpperCase()}
          </Typography>
          <Typography variant={"subtitle2"} color={"textSecondary"} noWrap>
            {roundValue(total, minScale?.assetTo)} {assetTo?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.details}>
          <Typography
            variant={"subtitle2"}
            color={"textSecondary"}
            style={{ fontWeight: 700 }}
            noWrap>
            {moment(updateDate).format("DD.MM.yyyy")}
          </Typography>
          <Typography
            variant={"subtitle2"}
            color={"textSecondary"}
            style={{ fontWeight: 700 }}
            noWrap>
            {moment(updateDate).format("HH:mm:ss")}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
