import { InputAdornment, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { Input } from "./Input";

const Search = ({ value, onChange, className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Input
      className={clsx(classes.root, className)}
      placeholder={t("Search")}
      variant='outlined'
      autoComplete='on'
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchRoundedIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

Search.propType = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.object,
};

export default Search;

const useStyles = makeStyles(theme => ({
  root: {
    border: `0.08em solid ${theme.palette.primary.main}`,
    minWidth: 244,
    minHeight: 38,
    color: theme.palette.text.primary,
    "& .MuiInputAdornment-root > svg": {
      color: theme.palette.text.primary,
      fontSize: 16,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      background: theme.palette.primary.light,
      border: 'none',
    },
    "& .MuiOutlinedInput-root>input": {
      border: 'none',
      background: "none",
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "18px",
      padding: "9px 8px 9px 12px",
    },
    "& fieldset": {
      border: 'none'
    }
  },
}));
