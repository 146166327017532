import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import DialogWrapper from "./DialogWrapper";
import { dialogListStyles } from "./AddNewWalletDialog";
import Search from "../components/Search";
import RightOutlinedIcon from "../../assets/icons/right_outlined.svg";
import CoinName from "../components/CoinName";
// import {
//   getWalletsList,
// } from "../../redux/actions/walletsActions";
import { getCurrencies } from "../../redux/actions/walletsActions";

const SelectWalletDialog = ({ open, onClose, onSelect, visibleCurrenciesListTickers = null }) => {
  const classes = dialogListStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { data } = useSelector(({ wallets }) => wallets);
  const data = useSelector(({ wallets }) => wallets.currencies);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (open && data.length === 0) {
      // dispatch(getWalletsList({ number: 0, size: 100 }));
      dispatch(getCurrencies({ number: 0, size: 100 }));
    }
    return () => {
      setSearch("");
    };
  }, [dispatch, open, data]);

  return (
    <DialogWrapper open={open} handleClose={onClose} title={t("Select coin")}>
      <Grid container justifyContent="center">
        <Search
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
        />
      </Grid>
      <div className={classes.list}>
        {data
          ?.filter(({ ticker = "" }) =>
            visibleCurrenciesListTickers ? visibleCurrenciesListTickers?.includes(ticker) : true)
          .filter(
            ({ ticker = "", name = "" }) =>
              ticker.toLowerCase().includes(search.toLowerCase()) ||
              name.toLowerCase().includes(search.toLowerCase())
          )
          .map((wallet) => (
            <Paper
              elevation={4}
              key={wallet.id}
              className={clsx(classes.currency, "hover")}
              onClick={() => onSelect(wallet)}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <CoinName
                    name={wallet?.cname}
                    ticker={wallet?.ticker}
                    price={"= $42,234.76"}
                  />
                </Grid>
                <Grid item>
                  <img src={RightOutlinedIcon} alt="rightOutlinedIcon" />
                </Grid>
              </Grid>
            </Paper>
          ))}
      </div>
    </DialogWrapper>
  );
};

export default SelectWalletDialog;
