import { forwardRef } from "react";
import {
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "../../assets/icons/close.svg";
import ArrowBackIcon from "../../assets/icons/arrowBack.svg";
import clsx from "clsx";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DialogWrapper = ({ open, handleClose, children, handleBack, title, dialogClassName, isCenteredTitle }) => {
  const classes = useStyles();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      keepMounted
      className={clsx(classes.dialog, dialogClassName)}
      onClose={handleClose}>
      <Paper elevation={4} className={classes.root}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          wrap='nowrap'
          className={!title && !handleBack ? {} : classes.header}>
          <Grid item>
            {Boolean(handleBack) && (
              <IconButton size={"small"} className={classes.backButton} onClick={handleBack}>
                <img src={ArrowBackIcon} alt='backIcon' />
              </IconButton>
            )}
          </Grid>

          <Grid
            item
            container
            justifyContent='center'
            className={clsx(classes.title, isCenteredTitle ? classes.centeredTitle : undefined)}>
            <div></div>
            {title ? renderTitle(title) : null}
          </Grid>
          <Grid item className={classes.closeButtonWrapper}>
            <IconButton size={"small"} className={classes.closeButton} onClick={handleClose}>
              <img src={CloseIcon} alt='close' />
            </IconButton>
          </Grid>
        </Grid>

        <div className={classes.box}>{children}</div>
      </Paper>
    </Dialog>
  );
};

DialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default DialogWrapper;

const useStyles = makeStyles(theme => ({
  dialog: {
    overflow: 'visible',
  },
  root: {
    padding: theme.spacing(5),
    overflow: 'visible !important',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    height: 64,
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {},
  },
  title: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  centeredTitle: {
    justifyContent: 'center',
    marginLeft: '-35px',
  },
  closeButtonWrapper: {
    display: 'flex',
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: '50%',
    right: -50,
    top: -50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper
  },
  closeButton: {
    width: '100%',
    height: '100%'
  },
  backButton: {
    marginRight: 10,
    width: 26,
  },
  box: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  titleText: {
    fontWeight: 400
  }
}));
