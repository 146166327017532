import { Typography, Paper, makeStyles, Grid, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from '../../assets/icons/edit_plan_icon.svg'
import { useCallback } from "react";
import { roundValue } from "../../utils/functions/roundValue";
import clsx from "clsx";

const CompanyCard = ({ data, onDeleteClick, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDeleteCompany = useCallback((e) => {
    e?.stopPropagation?.();
    onDeleteClick?.(data)
  }, [data, onDeleteClick])

  const handleSelectCompany = useCallback(() => {
    onClick?.(data?.companyId)
  }, [data, onClick])

  const hasActiveInvoices = data?.activeInvoices > 0;

  return (
    <Paper elevation={4} className={clsx(classes.root, hasActiveInvoices ? classes.rootActive : null)} onClick={handleSelectCompany}>
      <Grid container className={classes.header}>
        <Grid item>
          <Typography className={classes.title}>
            {data?.companyName}
          </Typography>
          <Typography className={classes.description}>
            {`${data?.totalParticipants} ${data?.totalParticipants === 1 ? t('participant') : t('participants')}`}
          </Typography>
        </Grid>
        <Grid item className={classes.actionsBlock}>
          {
            hasActiveInvoices &&
            <div className={classes.invoicesCount}>
              <Typography>
                {data?.activeInvoices}
              </Typography>
            </div>
          }
          <DeleteIcon onClick={handleDeleteCompany} style={{ display: 'none' }} className={classes.actionIcon} />
        </Grid>
      </Grid>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        {
          hasActiveInvoices &&
          <Typography variant="body2" className={classes.newRequestText}>{t('New withdrawal requests')}</Typography>
        }
        <Divider className={clsx(hasActiveInvoices ? classes.dividerActive : classes.divider)} />
      </Grid>
      <Grid container className={classes.content}>
        <Grid item style={{ whiteSpace: 'nowrap' }}>
          <Typography className={classes.balanceTitle}>
            {t('Company balance')}
          </Typography>
          <Grid container direction="row" alignItems="baseline" wrap="nowrap">
            <Typography className={classes.balance}>
              {roundValue(data?.totalInUsd, 2)}
            </Typography>
            <Typography className={classes.ticker}>
              {'USDT'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    cursor: 'pointer',
    // [theme.breakpoints.down("sm")]: {
    //   padding: "20px",
    // },
  },
  rootActive: {
    border: `1px solid ${theme.palette.border.gold}`,
  },
  divider: {
    flex: 1,
    marginTop: 20.5,
    marginBottom: 20,
  },
  dividerActive: {
    background: theme.palette.border.gold,
    flex: 1,
  },
  header: {
    padding: '30px 24px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  content: {
    padding: '30px 30px 20px',
    justifyContent: 'space-between',
    paddingBottom: 30,
    flexWrap: 'nowrap'
  },
  title: {
    fontWeight: 500,
    fontSize: '2rem',
    textTransform: 'uppercase',
    marginBottom: 14,
    marginTop: 8,
  },
  balanceTitle: {
    fontWeight: 400,
    fontSize: '1.1rem',
    textTransform: 'capitalize',
    marginBottom: 7,
  },
  newRequestText: {
    color: theme.palette.border.gold,
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '20px',
  },
  description: {
    fontSize: 14,
    marginTop: 6,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  balance: {
    fontSize: '1.6rem',
    marginTop: 6,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  ticker: {
    marginTop: 6,
    fontWeight: 600,
    color: theme.palette.text.darkLighter,
    marginLeft: 8,
  },
  label: {
    fontSize: 13,
    marginTop: 6,
    fontWeight: 300,
  },
  actionIcon: {
    width: 34,
    height: 34,
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8,
    },
  },
  actionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: -30,
    marginRight: -10,
  },
  invoicesCount: {
    border: `2px solid ${theme.palette.border.gold}`,
    width: 28,
    height: 28,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.border.gold,
  }
}));


// data prop interface
// interface Company {
//   companyId: number;
//   companyName: string;
//   shortName: string;
//   description: string;
//   balances: CompanyBalance[];
//   activeInvoices: 0;
// }
// interface CompanyBalance {
//   currency: string;
//   address: string;
//   balance: number | string;
//   balanceId: number;
//   activeInvoices: 0;
// }