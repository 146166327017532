export const COLUMNS = [
  {
    key: "type",
    label: "Type of personal data",
  },
  {
    key: "time",
    label: "Time and manner of receipt",
  },
  {
    key: "activity",
    label: "Purpose and/or activity",
  },
  {
    key: "basis",
    label: "Legal basis for processing",
  },
];
export const DATA_TABLE = [
  {
    type: "Email",
    time: "During the registration/entry procedure/verification procedure",
    activity:
      "To register you as a new customer/To protect an account using 2FA",
    basis: "Consent/execution of the contract",
  },
  {
    type: "Documents to certify a natural person, subject to your consent to provide them: Government-issued identification (passport, driving licence, ID card, passport number, information on the driving licence or tax identification number); Confirmation of address; Photo.",
    time: "In the verification process",
    activity:
      "To comply with KYC requirements, in order to verify the User's identity",
    basis: "Consent of the Data Subject/Compliance with legal obligations",
  },
  {
    type: "Details of your device and use of the Site: Information sent by your device when using the Cryptoneed PRO/Site; Information about your use of the Cryptoneed PRO/Site, including the type and version of browser you are using; Information on the device you are using, including hardware model, operating system and version, as well as unique device identifiers, etc.;The IP address from which you are accessing our Site; The addresses of the sites from which you have been redirected to our Site and the addresses of the sites to which you go from our Site/source from which you go to download Cryptoneed PRO (if possible); The public data you have imported into Cryptoneed PRO; Public addresses of blockchain networks; The unique hash of the User; Other non-personalized traffic data.",
    time: "While using the Cryptoneed PRO / Website / our Services",
    activity:
      "To ensure the security of User accounts and to meet the requirements of AML Policy. We also use these types of data to protect Users from automated abuse, such as spam, phishing and Distributed Denial of Service (DDoS) attacks.",
    basis:
      "Consent of the data subject to the collection of Personal Data/Implementation of the Agreement",
  },
  {
    type: "Actions performed on a User Account: 1. trading activities; 2. transaction history; 3. Ordering conditions; 4. Orders created; 5. User Account status and notifications; 6. Default currency and language, time zone.",
    time: "While using the Cryptoneed PRO / Website / our Services",
    activity:
      "To ensure the security of User accounts and to meet the requirements of AML Policy ",
    basis:
      "Compliance with legal obligations / Fulfillment of the terms of the Agreement",
  },
  {
    type: "Cookie",
    time: "While using Cryptoneed PRO / Website",
    activity:
      "In order to improve the quality of the Services provided. You can easily delete any cookies created in the relevant folder by going to your browser settings. You can set your browser to automatically refuse all cookies, or provide information on where they are sent.",
    basis:
      "Consent of the Data Subject to collect Personal Data/ Implementation of the Agreement",
  },
  {
    type: "Information volunteered by you during communication with the Technical Support Team.",
    time: "During the use of Cryptoneed PRO or the process of providing you with support.",
    activity:
      "In order to contact you, respond to your queries, comments and/or suggestions and to improve the Services we provide to you. We hold this data for as long as is necessary to provide you with qualified technical support.",
    basis:
      "Consent of the data subject to the collection of Personal Data/Implementation of the Agreement",
  },
];
// "With regard to each of Your visits to our Website or with our mobile application we automatically may collect the Usage Data - following information:": "With regard to each of Your visits to our Website or with our mobile application we automatically may collect the Usage Data - following information:",
// "Information that Your browser sends whenever You use of the Wallet": "Information that Your browser sends whenever You use of the Wallet",
// "Information about Your use of the Wallet, including the type of browser and version You use;": "Information about Your use of the Wallet, including the type of browser and version You use;",
// "The pages that You visit, the time and date of Your visit, the time spent on those pages, the last access time of Your Wallet;": "The pages that You visit, the time and date of Your visit, the time spent on those pages, the last access time of Your Wallet;",
// "Information about the device you use including the hardware model, operating system and version, and unique device identifiers and other diagnostic data, but this information is anonymized and not tied to any particular person.": "Information about the device you use including the hardware model, operating system and version, and unique device identifiers and other diagnostic data, but this information is anonymized and not tied to any particular person.",
// "Cookies. We use cookies and similar tracking technologies to track the activity on the Wallet and hold certain information.": "Cookies. We use cookies and similar tracking technologies to track the activity on the Wallet and hold certain information.",
// "You can easily remove any cookies that have been created in the cookie folder on Your browser setting.": "You can easily remove any cookies that have been created in the cookie folder on Your browser setting.",
// "You can instruct Your browser to refuse all cookies or to indicate where a cookie is being sent.": "You can instruct Your browser to refuse all cookies or to indicate where a cookie is being sent.",
// "Personal Data which we collect for the providing of certain Feature of the Wallet:": "B. Personal Data which we collect for the providing of certain Feature of the Wallet:",
// "To perform Transaction between Virtual Currency and Fiat Money we collect following information about your bank details:": "To perform Transaction between Virtual Currency and Fiat Money we collect following information about your bank details:",
// "bank card number;": "bank card number;",
// "expiration date.": "expiration date.",
// "We collect Personal Data by receiving the above information from You and use it purpose states in this Privacy Policy.": "We collect Personal Data by receiving the above information from You and use it purpose states in this Privacy Policy.",
// "By submitting your bank details, you grant Use the right to share the Personal Data/ information about your bank details with third parties/service providers for purposes of fulfillment of Transactions.": "By submitting your bank details, you grant Use the right to share the Personal Data/ information about your bank details with third parties/service providers for purposes of fulfillment of Transactions.",
// "We share Your Personal Data with third parties/service providers with which we have signed agreements to make sure that Your Personal Data is secured.": "We share Your Personal Data with third parties/service providers with which we have signed agreements to make sure that Your Personal Data is secured.",
// "By submitting your Personal Data/ bank details, You agree that we do not bear any responsibility for the actions of such third parties/service providers, nor any actions of any other person, including actions related to the collection, processing, and transmission of such information. You agree that we are not liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses as a result of transferring by you to us Personal Data / information about your bank details.": "By submitting your Personal Data/ bank details, You agree that we do not bear any responsibility for the actions of such third parties/service providers, nor any actions of any other person, including actions related to the collection, processing, and transmission of such information. You agree that we are not liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses as a result of transferring by you to us Personal Data / information about your bank details.",
// "If no transactions between Virtual Currency and Fiat Money occur, the Personal Data/ the information about bank details are not collected.": "If no transactions between Virtual Currency and Fiat Money occur, the Personal Data/ the information about bank details are not collected.",
// "V. Purposes of collecting Personal Data / bank details:": "V. Purposes of collecting Personal Data / bank details:",
// "maintaining and providing You with the Wallet;": "maintaining and providing You with the Wallet;",
// "providing, maintaining, delivering or improving our Website, the Wallet provided through our mobile application;": "providing, maintaining, delivering or improving our Website, the Wallet provided through our mobile application;",
// "managing and developing our business and operations;": "managing and developing our business and operations;",
// "making more of such services available to You where possible and appropriate": "making more of such services available to You where possible and appropriate",
// "promotion of the Wallet and other marketing purposes;": "promotion of the Wallet and other marketing purposes;",
// "fulfilling our legal or regulatory requirements;": "fulfilling our legal or regulatory requirements;",
// "developing new products or services;": "developing new products or services;",
// "protecting our rights and property;": "protecting our rights and property;",
// "providing You with the information or services that You have requested;": "providing You with the information or services that You have requested;",
// "carrying out any actions for which we have received Your consent, carrying out any other purpose or reason for which the information was collected.": "carrying out any actions for which we have received Your consent, carrying out any other purpose or reason for which the information was collected.",
// "VI. Disclosure of Personal Data/ bank details:": "VI. Disclosure of Personal Data/ bank details:",
// "We will only disclose Personal Data/ information about Your bank details in the following ways:": "We will only disclose Personal Data/ information about Your bank details in the following ways:",
// "with Your consent or at Your instructions;": "with Your consent or at Your instructions;",
// "with third parties that perform work for us for providing You with certain features of the Wallet;": "with third parties that perform work for us for providing You with certain features of the Wallet;",
// "certain information You may choose to share may be displayed publicly;": "certain information You may choose to share may be displayed publicly;",
// "in response to a request for information if we believe disclosure is in accordance with or required by, any applicable law, regulation or legal process.": "in response to a request for information if we believe disclosure is in accordance with or required by, any applicable law, regulation or legal process.",
// "We collect, use, process, disclose and transfer your Personal Data /information about your bank details only in purpose to perform Transaction between Virtual Currency and Fiat Money.": "We collect, use, process, disclose and transfer your Personal Data /information about your bank details only in purpose to perform Transaction between Virtual Currency and Fiat Money.",
// "Terms associated with the collection, using, processing, disclosing, transferring Personal Data and information about your bank details to a third party, liability, etc., are set out in section B of Chapter IV this Privacy Policy.": "Terms associated with the collection, using, processing, disclosing, transferring Personal Data and information about your bank details to a third party, liability, etc., are set out in section B of Chapter IV this Privacy Policy.",
// "VII. Security of Personal Data / bank details:": "VII. Security of Personal Data / bank details:",
// "We protect Personal Data / bank details with appropriate physical, technological and organizational safeguards and security measures.": "We protect Personal Data / bank details with appropriate physical, technological and organizational safeguards and security measures.",
// "The access to Personal data is restricted and certain access rights are set.": "The access to Personal data is restricted and certain access rights are set.",
// "Storage / Retention of Your Personal Data / bank details:": "Storage / Retention of Your Personal Data / bank details:",
// "We store your Personal Data/ bank details within Application in an encoded format.": "We store your Personal Data/ bank details within Application in an encoded format.",
// "We do not store your Personal Data/ information about bank details on servers.": "We do not store your Personal Data/ information about bank details on servers.",
// "We confirm that any third-party with whom we share Your Personal Data will provide the same or equal protection of user data as stated in this Privacy Policy and required by law.": "We confirm that any third-party with whom we share Your Personal Data will provide the same or equal protection of user data as stated in this Privacy Policy and required by law.",
// "We retain the information of You only as long as it is necessary, in accordance with the purpose of collecting the data and required by law. When Personal Data and other information are no longer needed we have procedures either to destroy, delete, erase or convert it to an anonymous form.": "We retain the information of You only as long as it is necessary, in accordance with the purpose of collecting the data and required by law. When Personal Data and other information are no longer needed we have procedures either to destroy, delete, erase or convert it to an anonymous form.",
// "All Personal Data can be removed by You if You chose to. You can revoke consent and/or request deletion of Your Personal Data at any time contacting us at": "All Personal Data can be removed by You if You chose to. You can revoke consent and/or request deletion of Your Personal Data at any time contacting us at ",
// "We reserve the right to store/process personal data in accordance with the specific purpose of their storage/processing without limitation of storage and processing time in cases stipulated by law.": "We reserve the right to store/process personal data in accordance with the specific purpose of their storage/processing without limitation of storage and processing time in cases stipulated by law.",
// "Withdrawal of consent and/or deletion of Your Personal Data disables the use of certain Feature of the Wallet by You.": "Withdrawal of consent and/or deletion of Your Personal Data disables the use of certain Feature of the Wallet by You.",
// " Data subject’s rights:": "Data subject’s rights:",
// "You have the right to:": "You have the right to:",
// "a) modify and correct your Personal Data at any time and you are therefore responsible for the accuracy of Your Personal  Data;": "a) modify and correct your Personal Data at any time and you are therefore responsible for the accuracy of Your Personal  Data;",
// "b) ask about Your Personal Data from us to data portability and also the right to request us to restrict the processing of Your Personal Data and/or to delete it in case obligation to retain the data is not set out by the law;": "b) ask about Your Personal Data from us to data portability and also the right to request us to restrict the processing of Your Personal Data and/or to delete it in case obligation to retain the data is not set out by the law;",
// "c) object to processing of Your Personal Data and/or to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal;": "c) object to processing of Your Personal Data and/or to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal;",
// "Legal Basis for Processing Personal Data in the European Economic Area (EEA) stated in the General Data Protection Regulation (GDPR). We aim to take reasonable steps to allow You to correct, amend, delete, or limit the use of Your Personal Data.": "Legal Basis for Processing Personal Data in the European Economic Area (EEA) stated in the General Data Protection Regulation (GDPR). We aim to take reasonable steps to allow You to correct, amend, delete, or limit the use of Your Personal Data.",
// "If You are a resident of the European Economic Area (EEA), You have certain data protection rights stated in the General Data Protection Regulation:": "If You are a resident of the European Economic Area (EEA), You have certain data protection rights stated in the General Data Protection Regulation:",
// "The right to access, update or to delete the information we have on You. Whenever made possible, You can access, update or request the deletion of Your Personal Data directly. If You are unable to perform these actions yourself, please contact us to assist You.": "The right to access, update or to delete the information we have on You. Whenever made possible, You can access, update or request the deletion of Your Personal Data directly. If You are unable to perform these actions yourself, please contact us to assist You.",
// "The right of rectification. You have the right to have Your information rectified if that information is inaccurate or incomplete.": "The right of rectification. You have the right to have Your information rectified if that information is inaccurate or incomplete.",
// "The right to object. You have the right to object to our processing of Your Personal Data.": "The right to object. You have the right to object to our processing of Your Personal Data.",
// "The right of restriction. You have the right to request that we restrict the processing of Your personal information": "The right of restriction. You have the right to request that we restrict the processing of Your personal information",
// "The right to withdraw consent. You also have the right to withdraw Your consent at any time where we relied on Your consent to process Your personal information.": "The right to withdraw consent. You also have the right to withdraw Your consent at any time where we relied on Your consent to process Your personal information.",
// "If You wish to be informed what Personal Data we hold about You and if You want it to be removed from our systems, please contact us at": "If You wish to be informed what Personal Data we hold about You and if You want it to be removed from our systems, please contact us at ",
// "You can exercise your rights by contacting us at": "You can exercise your rights by contacting us at ",
// "You have the right to complain to a Data Protection Authority about our collection and use of Your Personal Data. For more information, please contact Your local data protection authority in the European Economic Area (EEA).": "You have the right to complain to a Data Protection Authority about our collection and use of Your Personal Data. For more information, please contact Your local data protection authority in the European Economic Area (EEA).",
// "Children's Privacy:": "Children's Privacy:",
// "Wallet does not address anyone under the age of 18 ('Children').": "Wallet does not address anyone under the age of 18 ('Children').",
// "We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that your Children has provided us with Personal Data, please contact us at": "We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that your Children has provided us with Personal Data, please contact us at ",
// "If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information.": "If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information.",
// " Amendments:": "Amendments:",
// "We may change, amend, delete or add this Privacy Policy at any time.": "We may change, amend, delete or add this Privacy Policy at any time.",
// "Any such changes will be effective upon the posting on a Website or mobile application. The effective date which is at the top of the Privacy Policy informs You about the latest version of Privacy Policy.": "Any such changes will be effective upon the posting on a Website or mobile application. The effective date which is at the top of the Privacy Policy informs You about the latest version of Privacy Policy.",
// "Your continued use of the Wallet and/or Website constitutes Your acceptance of such changes and Your consent to the processing of Personal Data according to the latest version. If You do not agree with any such changes, do not continue to use the Wallet and/or Website.": "Your continued use of the Wallet and/or Website constitutes Your acceptance of such changes and Your consent to the processing of Personal Data according to the latest version. If You do not agree with any such changes, do not continue to use the Wallet and/or Website.",
// "Questions and Complaints:": "Questions and Complaints:",
// "Any questions about this Policy, the collection, use, and disclosure of Personal Data or access to Your Personal Data which is required by law (or would be so subject had the storage in question taken place in a relevant EU member state if the case may be but not otherwise) to be disclosed should be directed to": "Any questions about this Policy, the collection, use, and disclosure of Personal Data or access to Your Personal Data which is required by law (or would be so subject had the storage in question taken place in a relevant EU member state if the case may be but not otherwise) to be disclosed should be directed to ",
// "In the event that You wish to make a complaint about how we process your personal data, please contact us in the first instance at": "In the event that You wish to make a complaint about how we process your personal data, please contact us in the first instance at ",
// "and we will endeavor to deal with your request as soon as possible.": "and we will endeavor to deal with your request as soon as possible.",
