import { useMemo, useState } from "react";
import { validateInput as validateInputMethod } from "../functions/customValidators/validators";

export const useValidateForm = ({ form, customValidator, customFieldValidator }) => {

	const [errors, setErrors] = useState({});

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			let errorField;
			if (customFieldValidator && customFieldValidator?.[key]) {
				errorField = customFieldValidator?.[key]?.(key, form[key])
			} else {
				errorField = customValidator ? customValidator(key, form[key]) : validateInputMethod(key, form[key])
			}
			errForm = { ...errForm, [key]: errorField };
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const validateInput = event => {
		const { name, value } = event.target;
		let errorField;
		if (customFieldValidator && customFieldValidator?.[name]) {
			errorField = customFieldValidator?.[name]?.(name, value)
		} else {
			errorField = customValidator ? customValidator(name, value) : validateInputMethod(name, value);
		}
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const isValid = useMemo(() => {
		return Object.keys(errors || {}).length === 0
	}, [errors])

	return {
		validateForm,
		validateInput,
		errors,
		setErrors,
		isValid,
	};
};
