import React from "react";
import PageWrapper from "../theme/PageWrapper";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  TableContainer,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation, Trans } from "react-i18next";
import { SUPPORT_EMAIL } from "../utils/constants/support";
import { COLUMNS, DATA_TABLE } from "../utils/constants/privacyPolicy";

export default function Privacy() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Box className={classes.container}>
        <Box className={classes.block}>
          <Typography variant="h2"><b className={classes.highlighted}>{t("PRIVACY")}</b> {t("POLICY")}</Typography>
          <Typography className={classes.mainDate}>
            {t("Last update: 23 March 2022")}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"І. "}
              {t(" Politics: ")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Trans>
                    {
                      "1. This Privacy Policy ('Privacy Policy') governs the collection, use and disclosure of Personal Data and other information when you use Cryptoneed PRO ('Wallet')."
                    }
                  </Trans>
                  <br />
                  {t(
                    "Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Trans>
                    {"2. The Privacy Policy is part of Terms of Use."}
                  </Trans>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "If you do not agree with this Privacy Policy, please do not use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "Please read the following terms and conditions carefully before using Cryptoneed PRO (may be referred to as the Wallet). By using the Wallet you acknowledge that you agree to the Privacy Policy and also accept the Terms of Use."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5. "}
                  {t(
                    "The wallet is intended solely for use by a legal entity or an adult individual (18 years of age). The use of the wallet by minors is prohibited and we are not responsible for any consequences caused by the use of the wallet by minors."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6. "}
                  {t(
                    "By providing us with your personal information, you also consent to the collection, storage, use and disclosure of your personal information in accordance with this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"ІI. "}
              {t(" Definition:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "Personal data means data about a living person that can be identified from this data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Usage data is data collected automatically, either generated when using the wallet or from the infrastructure itself (e.g. duration of page visits)."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Data controller means the person who determines the purposes and manner of processing any personal information."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We are the controller of your personal data for the purposes of this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("If you have any questions, please contact us at: ")}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Data Subject (or you) is any living individual who uses our Wallet and is the subject of Personal Data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Website means a website operated by Cryptoneed PRO and available at "
                  )}
                  <a
                    className={classes.link}
                    href="https://cryptoneed.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://cryptoneed.com
                  </a>
                  .
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"III. "}
              {t("Legal basis for processing your Personal Data:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "consent to the processing of Personal Data received from you;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "the processing is necessary in order to fulfil the agreement with you set out in the Terms of Use;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3) "}
                  {t(
                    "the processing is necessary to comply with a legal obligation to which the data controller is subject;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4) "}
                  {t(
                    "the processing is in our legitimate interests and does not violate your rights."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"IV. "}
              {t(" The types of information we collect:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "When you use the Wallet and/or visit our Website, you may provide us with these types of information:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>{t("Usage data/cookie files;")}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Personal data we collect to provide certain features of the Wallet"
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t("You must register to use the Wallet.")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "When using the wallet, you do not need to provide us with Personal Information unless otherwise stated in this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          {/* TABLE */}
          <Box className={classes.block}>
            <TableContainer className={classes.root}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    {COLUMNS.map(({ key, label, minWidth, colSpan, align }) => (
                      <th
                        key={key}
                        style={{ minWidth }}
                        colSpan={colSpan}
                        align={align || "left"}
                        className={clsx(
                          classes.th,
                          classes.paper,
                          key === "change24" && classes.textRight
                        )}
                      >
                        {t(label)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  {DATA_TABLE?.length > 0 &&
                    DATA_TABLE?.map((row, index) => (
                      <tr key={index}>
                        <td className={clsx(classes.td, classes.paper)}>
                          {t(row?.type)}
                        </td>
                        <td className={clsx(classes.td, classes.paper)}>
                          {t(row?.time)}
                        </td>
                        <td className={clsx(classes.td, classes.paper)}>
                          {t(row?.activity)}
                        </td>
                        <td className={clsx(classes.td, classes.paper)}>
                          {t(row?.activity)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TableContainer>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"A. "} {t("Usage data/ cookies;")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "With regard to each of Your visits to our Website or with our mobile application we automatically may collect the Usage Data - following information:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The information your browser sends whenever you use the Wallet Information about your use of the Wallet,including the type of browser and version you are using;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The pages you visit, the time and date of your visit, the time spent on those pages, the time your wallet was last accessed;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The pages that You visit, the time and date of Your visit, the time spent on those pages, the last access time of Your Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Information about the device you are using, including hardware model, operating system and version, as well as unique device identifiers and other diagnostic data, but this information is anonymous and not linked to any specific individual."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "Cookies. We use cookies and similar tracking technologies to track Wallet activity and store certain information."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You can easily delete any cookies created in the cookie folder in your browser settings."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You can tell your browser to reject all cookies or specify where the cookie is sent."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"B. "}
              {t(
                "Personal data we collect to provide certain features of the Wallet"
              )}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "To complete a transaction between virtual currency and fiat money, we collect the following information about your bank details:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>{t("the bank card number;")}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>{t("Deadline date.")}</ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "We collect Personal Data by obtaining the above information from you and using it for the purposes set out in this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3) "}
                  {t(
                    "By submitting your bank details you authorise the transfer of Personal Data/ your bank details to third parties/service providers for the purpose of carrying out Transactions."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4) "}
                  {t(
                    "We share your personal data with third parties/service providers with whom we have signed agreements to ensure the security of your personal data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5) "}
                  {t(
                    "By submitting your Personal Information/banking details, you agree that we shall not be liable for the actions of such third party/service providers or any other person, including actions relating to the collection, processing and transmission of such information. You agree that we shall not be liable for any indirect, incidental, special, consequential or punitive damages, including but not limited to lost profits, loss of data, use, goodwill or other intangible losses resulting from your transfer of Personal Information/ your bank details information to us."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6) "}
                  {t(
                    "If no transactions take place between Virtual Currency and Fiat Money, no Personal Information/Banking Details are collected."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"C. "}
              {t(
                "KYC (Know Your Customer) data ecosystem. Disclosure of Personal Data"
              )}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "Our main principle in collecting and storing Personal Data is to facilitate the use or improve the quality of our Services and offers."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "In order to facilitate your use of the Cryptoneed PRO Services, we have created a database in the KYC ecosystem, the creation and use of which is fully compliant with the General Data Protection Regulation, the law and the Personal Data Protection Regulations."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We protect the KYC Ecosystem Database through appropriate physical, technological and organisational safeguards and precautions. Access to the KYC Ecosystem Database is restricted by establishing certain access rights."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We do not sell your Personal Data or share it with third parties, except as set out in this Privacy Policy. Your Personal Data may only be disclosed to the extent expressly permitted by applicable law and this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We contract qualified professionals (third parties) to ensure the security and lawful collection and processing of Personal Data as part of the KYC Ecosystem Database."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The posting of your Personal Data in the KYC Ecosystem Database as well as its transfer to third parties shall only take place with your consent, which we request from you. You hereby give your consent to the transfer of Personal Data to third parties in accordance with the agreement made with the service provider of these third parties. If you do not give your consent, you are prohibited from using Cryptoneed PRO."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We may share your Personal Information with the following third parties:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "To external services or authorities when such a transfer is necessary to comply with legal obligations applicable to us or to protect your vital interests;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("Other third parties, subject to your consent.")}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "To complete a transaction between virtual currency and fiat money, we collect the following information about your bank details:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "When we disclose your Personal Data, we take all necessary steps to ensure that the third parties in question are committed to maintaining the confidentiality and security of your Personal Data. We will disclose your Personal Data in accordance with legal requirements, including entering into data processing agreements with relevant third parties to ensure that the data is processed solely in accordance with our instructions, applicable laws, regulations and for our intended purpose, and to ensure appropriate security measures are in place."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Your data, whether public or personal, may not be sold, used for exchange, transferred or made available to another company without your consent and regardless of the reason, except to provide the Services you have requested and to improve the quality of the Services we provide."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"D. "}
              {t("Storage/deletion of your Personal Data")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "We do not retain your data longer than necessary in accordance with the purpose of the data collection and legal requirements. When Personal Data and other information is no longer required, we will initiate a procedure to destroy, delete, erase or convert it into an encrypted format."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "All Personal Data may be deleted by you at your own discretion. You may withdraw consent and/or request the deletion of your Personal Data at any time by contacting us at "
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Where permitted by law, we reserve the right to store/process Personal Data in accordance with the specific purpose for which it is stored/processed, without limiting the duration of the storage and processing."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Withdrawal of your consent and/or deletion of your Personal Data deprives you of the ability to use certain features of Cryptoneed PRO."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We will delete your data without undue delay if one of the following reasons applies:"
                  )}
                </ListItemText>
              </ListItem>

              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Personal Data is no longer necessary to achieve the purpose for which it was collected or processed;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You have withdrawn your consent giving you the right to process the data (in the absence of other legitimate grounds for processing);"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You object to the processing of your Personal Data (in the absence of other more compelling and legitimate grounds for processing);"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("Personal data has been processed unlawfully;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Personal Data shall be deleted if it is necessary to comply with a legal obligation under Union or Member State law to which the Data Controller is subject."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Your Personal Data may be retained if it is necessary to comply with a legal obligation to which we are subject or to protect your vital interests or the vital interests of another individual in accordance with the Personal Data Protection Regulation."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>

          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"V. "}
              {t("Purposes for collecting Personal Data/banking details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("maintaining and providing you with a Wallet;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "providing, maintaining, delivering or improving our website, wallet provided through our mobile app;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("managing and developing our business and operations;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "providing you with more of these services where possible and appropriate;"
                  )}
                  ;
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("promotion of the Wallet and other marketing purposes;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("meeting our legal or regulatory requirements;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("the development of new products or services;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("protection of our rights and property;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "providing you with the information or services you have requested;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "carrying out any action for which we have obtained your consent, fulfilling any other purpose or reason for which the information has been collected."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"VI. "}
              {t("Disclosure of personal data/banking details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We will only disclose Personal Information/information about your bank details in the following ways:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t("with your consent or at your direction;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "with third parties who carry out work for us to provide you with certain functions of the Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "certain information you can share can be displayed publicly;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "in response to a request for information where we believe that disclosure is consistent with or required by any applicable law, regulation or legal process."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We collect, use, process, disclose and transfer your Personal Data/ your bank details only for the purpose of carrying out Transactions between Virtual Currency and Fiat Cash."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "The terms and conditions relating to the collection, use, processing, disclosure, transfer of Personal Data and information about your banking details to a third party, liability, etc. are set out in section B of Chapter IV of this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"VII. "}
              {t("Security of personal data/banking details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "We protect Personal Data/Banking Details by means of appropriate physical, technological and organizational protection and security measures."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Access to Personal Data is restricted and certain access rights have been established."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"VIII. "}
              {t("Storing/retaining your personal data/banking details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We store your Personal Data/banking details in the Appendix in an encrypted format."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We do not store your Personal Data/banking information on servers."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "We confirm that any third party to whom we transfer your Personal Data will provide the same or equal protection for your User Data as set out in this Privacy Policy and as required by law."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "We only hold information about you for as long as is necessary, in accordance with the purpose of the data collection and in accordance with the law. When Personal Data and other information is no longer needed, we have procedures in place to destroy, delete, erase or convert it into an anonymous form."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5. "}
                  {t(
                    "All Personal Data may be deleted by you at your choice. You can withdraw consent and/or request the deletion of your Personal Data at any time by contacting us at "
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6. "}
                  {t(
                    "We reserve the right to store/process personal data in accordance with the specific purpose of its storage/processing, without limiting the period of storage and processing where permitted by law."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"7. "}
                  {t(
                    "Withdrawal of consent and/or deletion of your Personal Data prevents you from using certain Features of the Wallet."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"IX. "}
              {t("Rights of the data subject:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t("You are entitled to:")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "a) change and correct your Personal Data at any time and you are therefore responsible for the accuracy of your Personal Data;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "b) request your Personal Data from us for data portability and the right to ask us to restrict the processing of your Personal Data and/or to delete it if the obligation to retain the data is not established by law;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "c) object to the processing of your Personal Data and/or withdraw your consent at any time without affecting the lawfulness of the processing on the basis of the consent prior to withdrawal;"
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "The legal basis for processing personal data in the European Economic Area (EEA) as set out in the General Data Protection Regulation (GDPR). We aim to take reasonable steps to allow you to correct, amend, delete or restrict the use of your personal data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "If you are a resident of the European Economic Area (EEA), you have certain data protection rights as set out in the General Data Protection Regulation:"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to access, update or delete the information we hold about you. When possible, you can access, update or request the deletion of your personal information directly. If you are unable to carry out these steps yourself, please contact us to assist you."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Right of correction. You have the right to have your information corrected if it is inaccurate or incomplete."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Right to object. You have the right to object to us processing your Personal Data."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to restriction. You have the right to request that we restrict the processing of your personal information."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to withdraw consent. You also have the right to withdraw your consent at any time when we have relied on your consent to process your personal information."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "If you would like to know what personal data we hold about you and if you would like to have it removed from our systems, please contact us at "
                      )}
                      <a
                        className={classes.link}
                        href={`mailto:${SUPPORT_EMAIL}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {SUPPORT_EMAIL}
                      </a>
                      .
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t("You can exercise your rights by contacting us at ")}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "You have the right to lodge a complaint with the data protection authority regarding our collection and use of your personal data. For more information, contact your local data protection authority in the European Economic Area (EEA)."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"X. "}
              {t("Confidentiality of children:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "The wallet is not addressed to persons under 18 years of age ('Children')."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We do not knowingly collect personal information from anyone under the age of 18. If you are a parent or guardian and know that your children have provided us with personal information, please contact us at "
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "If we become aware that we have collected children's personal information without verifying parental consent, we will take steps to delete that information."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary">
              {"XI. "}
              {t("Amendment")}
              {":"}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We may amend, supplement, delete or add to this Privacy Policy at any time."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "Any such changes will take effect when published on the website or mobile application. The effective date shown at the top of the Privacy Policy informs you of the latest version of the Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "Your continued use of the Wallet and/or website constitutes your agreement to such changes and your consent to the processing of personal data in accordance with the latest version. If you do not agree to any such changes, do not continue to use the Wallet and/or website."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color="textSecondary" style={{ textAlign: "justify" }}>
              {"XII. "}
              {t("Questions and complaints:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "Any questions concerning this Policy, the collection, use and disclosure of personal data or access to your personal data that are required by law (or would be required if the storage in question took place in the relevant EU Member State, if the case may be, but not otherwise) for disclosure should be directed to "
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "If you wish to make a complaint about the way we handle your personal data, please contact us in the first instance at "
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {SUPPORT_EMAIL}
                  </a>
                  {t(
                    ", and we will endeavour to consider your request as soon as possible."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    color: "#ffffff",
    marginTop: '40px',
  },
  block: {
    marginBottom: "24px",
    color: "#ffffff",
    "& li": {
      textAlign: "justify",
      paddingLeft: 24,
      paddingRight: 0,
    },
  },
  content: {
    background: '#212328',
    padding: '32px',
    borderRadius: '20px',
  },
  mainDate: {
    paddingLeft: '20px',
    color: theme.palette.text.quaternary,
    marginTop: '10px',
    marginBottom: '20px'
  },
  highlighted: {
    color: theme.palette.text.tertiary,
  },
  link: {
    color: theme.palette.action.active,
    fontWeight: "600",
  },
  // TABLE CSS
  root: {
    paddingBottom: 16,
    paddingLeft: 24,
  },
  table: {
    overflow: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.text.primary}`,
    borderCollapse: "collapse",
  },

  th: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderCollapse: "collapse",
    color: theme.palette.text.secondary,
    padding: "14px 18px",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "12px",
      fontSize: 10,
    },
  },
  td: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderCollapse: "collapse",
    color: theme.palette.text.primary,
    padding: "4px 18px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "4px 12px",
      fontSize: 10,
    },
  },
  spacing: {
    height: 8,
  },
}));
