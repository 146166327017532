import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import { getKycStatus } from "../../redux/actions/kycActions";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../theme/components/Breadcrumb";
import { getMarketList } from "../../redux/actions/exchangeActions";
import { getAllTickerSettings, getWalletsList, getWalletsSettingsList } from "../../redux/actions/walletsActions";
import DeleteConfirmDialog from "../../theme/dialogs/DeleteConfirmDialog";
import { Button } from "../../theme/components/Button";
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";
import { deleteMoneybox, getMoneyboxBalanceList, getMoneyboxList } from "../../redux/actions/moneyboxActions";
import CreateMoneyboxDialog from "../Moneybox/CreateMoneyboxDialog";
import MoneyboxOverview from "./Overview";
import MoneyboxBalancesTable from "./BalancesTable";

export const MoneyboxDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { moneyboxId } = useParams();
  const { t } = useTranslation();
  const [showCreateMoneybox, setShowCreateMoneybox] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const moneyboxList = useSelector(moneyboxSelectors.moneyboxList)
  const selectedMoneyboxData = useMemo(() => {
    return moneyboxList.find((moneybox) => Number(moneybox?.id) === Number(moneyboxId));
  }, [moneyboxId, moneyboxList])

  useEffect(() => {
    dispatch(getKycStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMoneyboxBalanceList(moneyboxId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShowCreateMoneyboxDialog = () => {
    setShowCreateMoneybox(true)
  }

  const handleOpenDeleteDialog = (planData) => {
    setShowDeleteDialog(true)
  }

  const handleCloseDeleteConfirmDialog = ({ accept }) => {
    if (accept) {
      dispatch(deleteMoneybox(selectedMoneyboxData?.id))
    }
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getMarketList());
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
    dispatch(getMoneyboxList());
  }, [dispatch])

  return (
    <PageWrapper>
      <CreateMoneyboxDialog
        open={showCreateMoneybox}
        moneyboxData={selectedMoneyboxData}
        onClose={() => setShowCreateMoneybox(false)}
      />
      <DeleteConfirmDialog
        open={Boolean(showDeleteDialog)}
        title={t("Delete moneybox")}
        message={t("Are you sure you want to delete selected moneybox?")}
        handleClose={handleCloseDeleteConfirmDialog}
        btnOkText={t("Delete")}
        confirm
      />
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to='/moneybox' pageName='Moneybox' />
            <Typography variant="h2" className={classes.title}>
              {selectedMoneyboxData?.name}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {selectedMoneyboxData?.description}
            </Typography>
          </Box>
          <Grid>
            <Button
              variant='contained'
              className={classes.btnSmall}
              onClick={handleShowCreateMoneyboxDialog}>
              {t("Edit moneybox")}
            </Button>
            <Button
              variant='contained'
              className={classes.btnSmall}
              onClick={handleOpenDeleteDialog}>
              {t("Delete")}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MoneyboxOverview totalInUsd={selectedMoneyboxData?.totalInUsd} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <MoneyboxBalancesTable />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default MoneyboxDetails;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  description: {
    fontSize: 20,
    marginLeft: 28,
    alignSelf: 'center',
    marginTop: 7,
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
