import { COMPANIES } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
import { addDoc, collection } from "firebase/firestore";
import { firebaseCollections, firebaseDB } from "../../services/firebase";

export const getCompaniesListAction = actionCreator(
  COMPANIES.GET_COMPANIES_LIST
);

export const getCompaniesList = () => {
  return async (dispatch) => {
    dispatch(getCompaniesListAction.started());
    api.companies
      .getCompaniesList()
      .then((res) => {
        dispatch(getCompaniesListAction.done(res.data));
      })
      .catch((error) => dispatch(getCompaniesListAction.failed(error)));
  };
};

export const addNewCompanyAction = actionCreator(
  COMPANIES.ADD_NEW_COMPANY
);

export const addNewCompany = ({
  companyName,
  shortName,
  selectedCoins,
  description,
  accountId,
  accountEmail,
  accountLogin,
}) => {
  return async (dispatch) => {
    dispatch(addNewCompanyAction.started());
    try {
      await addDoc(collection(firebaseDB, firebaseCollections.newCompanyRequests), {
        companyName,
        shortName,
        selectedCoins,
        description,
        accountId,
        accountEmail,
        accountLogin,
        time: new Date().toISOString(),
      })
      dispatch(addNewCompanyAction.done());
    } catch (error) {
      dispatch(addNewCompanyAction.failed(error));
    }
  };
};


export const transferCompanyBalanceAction = actionCreator(
  COMPANIES.TRANSFER_COMPANY_BALANCE
);

export const transferCompanyBalance = ({
  balanceId,
  amount,
  description }) => {
  return async (dispatch) => {
    dispatch(transferCompanyBalanceAction.started());
    api.companies
      .transferCompanyBalance({
        balanceId,
        amount,
        description
      })
      .then((res) => {
        dispatch(transferCompanyBalanceAction.done(res.data))
        dispatch(getCompaniesList());
        if (balanceId) {
          dispatch(getCompanyBalanceTransferHistory({ balanceId }))
        }
      })
      .catch((error) => dispatch(transferCompanyBalanceAction.failed(error)));
  };
};

export const getCompanyBalanceTransferInvoiceByIdAction = actionCreator(
  COMPANIES.GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID
);

export const getCompanyBalanceTransferInvoiceById = (invoiceId) => {
  return async (dispatch) => {
    dispatch(getCompanyBalanceTransferInvoiceByIdAction.started());
    api.companies
      .getCompanyBalanceTransferInvoiceById(invoiceId)
      .then((res) => {
        dispatch(getCompanyBalanceTransferInvoiceByIdAction.done(res.data));
      })
      .catch((error) => dispatch(getCompanyBalanceTransferInvoiceByIdAction.failed(error)));
  };
};


export const makeDecisionByCompanyBalanceTransferInvoiceAction = actionCreator(
  COMPANIES.MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE
);

export const makeDecisionByCompanyBalanceTransferInvoice = ({
  invoiceId,
  decision,
  balanceId }) => {
  return async (dispatch) => {
    dispatch(makeDecisionByCompanyBalanceTransferInvoiceAction.started());
    api.companies
      .makeDecisionByCompanyBalanceTransferInvoice({
        invoiceId,
        decision
      })
      .then((res) => {
        dispatch(makeDecisionByCompanyBalanceTransferInvoiceAction.done(res.data))
        dispatch(getCompaniesList());
        if (balanceId) {
          dispatch(getCompanyBalanceTransferHistory({ balanceId }))
        }
      })
      .catch((error) => dispatch(makeDecisionByCompanyBalanceTransferInvoiceAction.failed(error)));
  };
};

export const getCompanyBalanceTransferHistoryAction = actionCreator(
  COMPANIES.GET_COMPANY_BALANCE_TRANSFER_HISTORY
);

export const getCompanyBalanceTransferHistory = ({ balanceId, params }) => {
  return async (dispatch) => {
    dispatch(getCompanyBalanceTransferHistoryAction.started());
    api.companies
      .getCompanyBalanceTransferHistory({
        balanceId,
        params: {
          page: params?.page || 0,
          size: params?.size || 20
        }
      })
      .then((res) => {
        dispatch(getCompanyBalanceTransferHistoryAction.done(res.data));
      })
      .catch((error) => dispatch(getCompanyBalanceTransferHistoryAction.failed(error)));
  };
};

export const selectCompanyBalance = (payload) => ({
  type: COMPANIES.SELECT_COMPANY_BALANCE,
  payload,
})

export const resetCompanies = () => ({
  type: COMPANIES.RESET_COMPANIES,
});

