import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "./apiMiddleware";
import loadingMiddleware from "./loadingMiddleware";
import loading from "../reducers/loadingReducer";
import dialogs from "../reducers/dialogsReducer";
import account from "../reducers/accountReducer";
import wallets from "../reducers/walletsReducer";
import transactions from "../reducers/transactionsReducer";
import addresses from "../reducers/addressesReducer";
import notistack from "../reducers/notistackReducer";
import exchange from "../reducers/exchangeReducer";
import deviceLogs from "../reducers/deviceLogsReducer";
import market from "../reducers/marketReducer";
import cards from "../reducers/cardsReducer";
import kyc from "../reducers/kycReducer";
import language from "../reducers/languageReducer";
import referral from "../reducers/referralProgramReducer";
import autoInvest from '../reducers/autoInvestReducer'
import moneybox from '../reducers/moneyboxReducer'
import companies from '../reducers/companiesReducer'

const combinedReducer = combineReducers({
  notistack,
  loading,
  dialogs,
  account,
  wallets,
  transactions,
  addresses,
  exchange,
  deviceLogs,
  market,
  cards,
  kyc,
  language,
  referral,
  autoInvest,
  moneybox,
  companies
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT" || action.type === "BACK_TO_MAIN") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware, loadingMiddleware),
});
