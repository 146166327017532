import { Grid, IconButton, Paper, Typography, makeStyles } from "@material-ui/core"
import SlicedAddress from "./SlicedAddress"
import CopyWithAlert from "../../utils/CopyWithAlert"
import CopyIcon from "../../assets/icons/сopy_secondary.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const InputCopiable = ({ className, variant, value, label, short, showFullValue }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (<Paper
    variant={variant || "elevation"}
    elevation={0}
    className={clsx(classes.root, className, short && "short")}
  >
    <Grid container justifyContent={"space-between"} alignItems="center">
      <Grid item xs={7} sm={8}>
        <Typography variant={"subtitle2"} className={classes.text}>
          {t(label)}
        </Typography>
        <Typography variant={"body1"} className={classes.text}>
          <SlicedAddress address={value} short={short} showFullAddress={showFullValue} />

        </Typography>
      </Grid>
      <Grid item style={{ padding: 0 }}>
        <CopyWithAlert text={value || ""} label={t(label)}>
          <IconButton>
            <img src={CopyIcon} alt="copy_icon" />
          </IconButton>
        </CopyWithAlert>
      </Grid>
    </Grid>
  </Paper>)
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.text.primary,
    overflow: 'hidden',
    padding: "6px 4px 6px 16px",
    "&.short": {
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        "& svg": {
          fontSize: 18,
        },
      },
    },
  },
  text: {
    color: theme.palette.text.dark,
    textAlign: 'left'
  }
}));
