import { Typography, Paper, makeStyles, Grid, Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from '../../assets/icons/edit_plan_icon.svg'
import { useCallback } from "react";
import CoinName from "../../theme/components/CoinName";
import { roundValue } from "../../utils/functions/roundValue";


const MoneyboxCard = ({ data, onDeleteClick, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDeleteMoneybox = useCallback((e) => {
    e?.stopPropagation?.();
    onDeleteClick?.(data)
  }, [data, onDeleteClick])

  const handleSelectMoneybox = useCallback(() => {
    onClick?.(data?.id)
  }, [data, onClick])

  return (
    <Paper elevation={4} className={classes.root} onClick={handleSelectMoneybox}>
      <Grid container className={classes.header}>
        <Grid item>
          <Typography className={classes.title}>
            {data?.name}
          </Typography>
          <Typography className={classes.description}>
            {data?.description}
          </Typography>
        </Grid>
        <Grid item className={classes.actionsBlock}>
          <DeleteIcon onClick={handleDeleteMoneybox} className={classes.actionIcon} />
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={classes.content}>
        <Grid item style={{ whiteSpace: 'nowrap' }}>
          <Typography className={classes.balanceTitle}>
            {t('Total balance')}
          </Typography>
          <Grid container direction="row" alignItems="baseline" wrap="nowrap">
            <Typography className={classes.balance}>
              {roundValue(data?.totalInUsd, 2)}
            </Typography>
            <Typography className={classes.ticker}>
              {'USDT'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.assetsBlock} container>
          {
            (data?.assets ? Object.keys(data?.assets) : [])?.map((assetTicker, index) => {
              return (
                <Box className={classes.assetWrapper} key={assetTicker} index={index} style={{ left: index * 20, zIndex: index }}>
                  <CoinName
                    key={assetTicker}
                    ticker={assetTicker}
                    showOnlyIcon
                    iconSize={42}
                  />
                </Box>
              )
            })
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MoneyboxCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    cursor: 'pointer',
    // [theme.breakpoints.down("sm")]: {
    //   padding: "20px",
    // },
  },
  header: {
    padding: '30px 30px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: '30px 30px 20px',
    justifyContent: 'space-between',
    paddingBottom: 30,
    flexWrap: 'nowrap'
  },
  title: {
    fontWeight: 500,
    fontSize: '2rem',
    textTransform: 'uppercase',
    marginBottom: 14,
    marginTop: 8,
  },
  balanceTitle: {
    fontWeight: 400,
    fontSize: '1.1rem',
    textTransform: 'capitalize',
    marginBottom: 7,
  },
  description: {
    fontSize: 14,
    marginTop: 6,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  balance: {
    fontSize: '1.6rem',
    marginTop: 6,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  ticker: {
    marginTop: 6,
    fontWeight: 600,
    color: theme.palette.text.darkLighter,
    marginLeft: 8,
  },
  label: {
    fontSize: 13,
    marginTop: 6,
    fontWeight: 300,
  },
  actionIcon: {
    width: 34,
    height: 34,
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8,
    },
  },
  actionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: -30,
    marginRight: -10,
  },
  assetsBlock: {
    position: 'relative',
    flexDirection: 'row',
    marginLeft: 12,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  assetWrapper: {
    maxWidth: 20,
    marginBottom: 4
  }
}));
