import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Typography, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import clsx from "clsx";
import { Input } from "../../../theme/components/Input";
import { Button } from "../../../theme/components/Button";
import { SelectInput } from "../../../theme/components/Select";
import FormError from "../../../theme/components/FormError";
import {
  setActiveStep,
  clearVerificationMethod,
  setFilledStep,
} from "../../../redux/actions/kycActions.js";
import {
  MONTHS,
  YEARS,
  GENDERS,
  DAYS_MONTH,
} from "../../../utils/constants/kyc";
import {
  validateInput,
  DOBValidator,
} from "../../../utils/functions/customValidators/kycValidator";

const initialForm = {
  lastName: "",
  firstName: "",
  middleName: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  gender: "",
};

const PersonalInfo = ({ updateKYCForm, KYCForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep } = useSelector(({ kyc }) => kyc);
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let data = {};
    Object.keys(form).forEach((key) => (data[key] = KYCForm[key]));
    setForm(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDaysMonth = useCallback(() => {
    const daysMonth =
      form?.birthYear && !moment([+form?.birthYear]).isLeapYear()
        ? { ...DAYS_MONTH, February: 28 }
        : DAYS_MONTH;
    let days = [];
    for (
      let i = 1;
      i <= (form?.birthMonth ? daysMonth[MONTHS[form?.birthMonth - 1]] : 31);
      i++
    ) {
      days = [...days, i];
    }
    if (form?.birthDay && !days[form?.birthDay - 1]) {
      setForm({ ...form, birthDay: "" });
    }
    return days;
  }, [form]);

  const handleOnChange = ({ target: { name, value } }) => {
    setErrors({ ...errors, [name]: validateInput(name, value, form, false) });
    setForm({ ...form, [name]: value });
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateKYCForm(form);
      dispatch(setActiveStep(activeStep + 1));
      dispatch(setFilledStep(activeStep));
    }
  };
  const handleOnBack = () => {
    updateKYCForm(initialForm);
    dispatch(clearVerificationMethod());
  };

  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(form).forEach((key) => {
      errForm = { ...errForm, [key]: validateInput(key, form[key]) };
      errForm[key].invalid && (valid = false);
    });
    const dobError = DOBValidator(form);
    dobError.invalid && (valid = false);
    setErrors({ ...errForm, dob: dobError });

    return valid;
  };

  const handleInputValidation = (event) => {
    const { name, value } = event.target;
    const errorField = validateInput(name, value);
    setErrors({
      ...errors,
      [name]: errorField,
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <Grid item xs={12}>
        <form>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                label={t("Last Name")}
                variant="outlined"
                name={"lastName"}
                value={form?.lastName || ""}
                className={classes.input}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.lastName?.invalid}
                helperText={t(errors?.lastName?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                label={t("First Name")}
                variant="outlined"
                name={"firstName"}
                value={form?.firstName || ""}
                className={classes.input}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.firstName?.invalid}
                helperText={t(errors?.firstName?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                label={t("Patronymic")}
                variant="outlined"
                name={"middleName"}
                value={form?.middleName || ""}
                className={classes.input}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.middleName?.invalid}
                helperText={t(errors?.middleName?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid
              item
              className={classes.hiddenFirstLine}
              xs={12}
              sm={6}
              md={4}
            >
              <SelectInput
                label={t("Gender")}
                onChange={handleOnChange}
                className={classes.input}
                value={form?.gender || ""}
                name={"gender"}
                onBlur={handleInputValidation}
                helperText={t(errors?.gender?.errorMessage)}
              >
                {GENDERS?.map((gender, index) => (
                  <MenuItem key={index} value={gender.toUpperCase()}>
                    {gender}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container>
              <Typography
                variant={"body2"}
                className={classes.wrapIcon}
              >
                <ReportProblemOutlinedIcon
                  style={{ color: "#AF994A", marginRight: 8, fontSize: 24, marginBottom: 0 }}
                />
                {t(
                  "When entering data, tag the language for which document you have."
                )}
              </Typography>
            </Grid>
            <FormError
              isHidden={!Boolean(errors?.dob?.errorMessage)}
              errorMessage={t(errors?.dob?.errorMessage)}
              style={{ marginTop: 32 }}
            />
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={4} md={3}>
              <SelectInput
                label={t("Day")}
                onChange={handleOnChange}
                value={form?.birthDay || ""}
                name={"birthDay"}
                onBlur={handleInputValidation}
                error={
                  Boolean(errors?.birthDay?.errorMessage) ||
                  errors?.dob?.invalid
                }
                helperText={t(errors?.birthDay?.errorMessage)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {setDaysMonth().map((day, index) => (
                  <MenuItem key={index} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
              <SelectInput
                label={t("Month")}
                onChange={handleOnChange}
                value={form?.birthMonth || ""}
                name={"birthMonth"}
                onBlur={handleInputValidation}
                error={
                  Boolean(errors?.birthMonth?.errorMessage) ||
                  errors?.dob?.invalid
                }
                helperText={t(errors?.birthMonth?.errorMessage)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {MONTHS.map((month, index) => (
                  <MenuItem key={index} value={index + 1 + ""}>
                    {t(month)}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <SelectInput
                label={t("Year")}
                onChange={handleOnChange}
                value={form?.birthYear || ""}
                name={"birthYear"}
                onBlur={handleInputValidation}
                error={
                  Boolean(errors?.birthMonth?.errorMessage) ||
                  errors?.dob?.invalid
                }
                helperText={t(errors?.birthYear?.errorMessage)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {YEARS()?.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid
              item
              className={classes.hiddenSecondLine}
              xs={6}
              sm={4}
              md={3}
            >
              <SelectInput
                label={t("Gender")}
                onChange={handleOnChange}
                value={form?.gender || ""}
                name={"gender"}
                onBlur={handleInputValidation}
                helperText={t(errors?.gender?.errorMessage)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {GENDERS?.map((gender, index) => (
                  <MenuItem key={index} value={gender.toUpperCase()}>
                    {t(gender)}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={5}
        className={classes.btnBox}
      >
        <Grid item>
          <Button
            className={clsx(classes.btn, classes.btnBack)}
            variant="outlined"
            onClick={handleOnBack}
          >
            {t("Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.btn}
            variant="contained"
            onClick={handleNext}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "24px",
    [theme.breakpoints.down("sm")]: { padding: "56px 64px" },
    [theme.breakpoints.down("xs")]: { padding: "48px 32px" },
    [theme.breakpoints.down(390)]: { padding: "48px 16px" },
  },
  btnBox: {
    marginTop: 32,
    [theme.breakpoints.down("sm")]: { marginTop: "32px" },
    [theme.breakpoints.down("xs")]: { marginTop: "24px" },
  },
  btn: {
    minWidth: 165,
    height: 42,
    marginTop: "0px !important",
    "&.MuiButton-outlined": {
      padding: "12px 24px",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 152,
    },
  },
  btnBack: {
    borderColor: `${theme.palette.text.secondary} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.secondary,
    },
  },
  title: {
    fontWeight: 500,
    marginBottom: 48,
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  subtitle: {
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
    color: theme.palette.success.main,
    padding: "18px 0px 14px 0px",
  },
  description: {
    width: 372,
    marginBottom: 68,
    marginRight: 20,
    float: "right",
    [theme.breakpoints.down(1010)]: {
      marginRight: 16,
      width: 360,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: 50,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  hiddenFirstLine: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hiddenSecondLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  dropdownStyle: {
    maxHeight: 250,
    marginTop: -6,
  },
  input: {
    "& .MuiFormHelperText-root.Mui-error": {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      fontSize: 12,
    },
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: 32,
    marginBottom: 12,
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
}));
