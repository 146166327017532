import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  lighten,
} from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../theme/components/Breadcrumb";
import PageWrapper from "../../theme/PageWrapper";
import SettingsTabs from "./SettingsTabs";
import { getDeviceLogs } from "../../redux/actions/deviceLogsActions";
import { getAccountData, updateAccount } from "../../redux/actions/accountActions";
import PhoneOutlinedIcon from "../../assets/icons/phone.svg";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";
import { Input } from "../../theme/components/Input";
import { validateInput } from "../../utils/functions/customValidators/validators";
import { Button } from "../../theme/components/Button";
import VerificationStatus from "../../theme/components/VerificationStatus";

function LoginCard({ deviceName, location, time, ip }) {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        className={classes.device}
        alignItems='center'>
        <Grid item container xs={9} sm={10} direction={"row"} wrap={"nowrap"}>
          <Grid
            container
            direction={"row"}
            alignItems='center'
            wrap='nowrap'>
            <Grid item className={classes.icon}>
              <img src={PhoneOutlinedIcon} alt='phone' />
            </Grid>
            <Grid>
              <Typography
                variant='h6'
                className={classes.deviceTxt}
                color='textSecondary'>
                {deviceName.slice(0, 50)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography
            variant='subtitle2'
            className={classes.date}
            color='textSecondary'>
            {moment(time).format("HH:mm:ss")}
          </Typography>
          <Typography
            variant='subtitle2'
            className={classes.date}
            color='textSecondary'>
            {moment(time).format("DD.MM.yyyy")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

const DeviceLogs = ({ data = [] }) => {
  const { renderTitle } = useColoredTitle();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper className={classes.devicesBlock} elevation={4}>
      {renderTitle(t("Device log"))}
      {data.map((deviceLog, i) => (
        <LoginCard
          key={i}
          deviceName={deviceLog?.device}
          location={deviceLog?.location}
          time={deviceLog?.date}
          ip={deviceLog?.currentaddr}
        />
      ))}
    </Paper>
  )
};

function Settings({ dispatch, data }) {

  const [login, setLogin] = useState("");
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState({});

  const classes = useStyles();
  const size = 3;
  const isMobile = useIsMobile();
  const { page } = useSelector(({ deviceLogs }) => deviceLogs);
  const deviceLogs = useSelector(({ deviceLogs }) => deviceLogs?.data);

  const { t } = useTranslation();

  useEffect(() => {
    if (!data) {
      dispatch(getAccountData());
    }
  }, [dispatch, data]);

  useEffect(() => {
    dispatch(getDeviceLogs({ page, size }));
  }, [dispatch, page]);

  useEffect(() => {
    setLogin(data?.login);
    setForm({
      email: data?.email || "",
      imageUrl: data?.imageUrl || "",
      langKey: data?.langKey || "",
    });
  }, [data]);

  const handleInputValidation = event => {
    const { name, value } = event.target;
    const errorField = validateInput(name, value);
    setErrors({
      ...errors,
      [name]: errorField,
    });
  };

  const handleOnChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
    handleInputValidation({ target: { name, value } });
  };

  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(form).forEach(key => {
      errForm = { ...errForm, [key]: validateInput(key, form[key]) };
      errForm[key].invalid && (valid = false);
    });

    setErrors(errForm);
    return valid;
  };

  const handleUpdate = () => {
    if (!validateForm()) {
      return;
    }
    dispatch(updateAccount(form));
  };

  const { renderTitle } = useColoredTitle();

  return (
    <PageWrapper bgColor="secondary">
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        spacing={isMobile ? 2 : 3}>
        <Grid item lg={5} md={5} sm={12} xs={12} className={classes.root}>
          <Breadcrumb to='/' pageName='Wallets' down='32px' />
          <Paper className={classes.paper} elevation={4}>
            <Grid
              container
              alignItems='flex-start'>
              <Grid item container xs={6}>
                {renderTitle(t('User info'))}
              </Grid>
              <VerificationStatus isShort />
            </Grid>
            <Typography variant='body1'>
              <span className={classes.subtitle}> ID:</span> {data?.id}
            </Typography>
            <Input
              label={t("Username")}
              variant='outlined'
              name={"login"}
              value={login || ""}
              disabled
            />
            {data?.socialLogin ? (
              <Input
                disabled
                label='E-mail'
                variant='outlined'
                name={"email"}
                value={form?.email || ""}
                onChange={handleOnChange}
              />
            ) : (
              <Input
                label='E-mail'
                variant='outlined'
                name={"email"}
                value={form?.email || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.email?.invalid}
                helperText={t(errors?.email?.errorMessage)}
              />
            )}
            <Grid container justifyContent='center'>
              <Button
                variant='contained'
                onClick={handleUpdate}
                className={classes.btn}>
                {t("Update")}
              </Button>
            </Grid>

          </Paper>
          <DeviceLogs data={deviceLogs?.data || []} />
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <SettingsTabs />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
  },
  avatar: {
    width: "80px",
    height: "80px",
    marginRight: "24px",
  },
  box: {
    padding: "16px 16px 0",
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: "32px 24px",
    marginBottom: 16,
    gap: 24
  },
  btn: {
    minWidth: 180,
    height: 42,
    marginTop: 10,
    "& .MuiButton-label": {
      color: theme.palette.text.tertiary,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  devicesBlock: {
    padding: "32px 24px",
  },
  device: {
    marginTop: 20,
    '&:nth-child(2)': {
      marginTop: 36,
    }
  },

  userInfo: {
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "capitalize",
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  widget: {
    display: "flex",
    alignItems: "center",
    minWidth: 140,
    padding: "8px 12px",
    borderRadius: 4,
    fontWeight: 400,

    "& img": {
      marginRight: 12,
      width: 24,
      height: 24,
    },
  },
  confirmed: {
    background: "#008C39",
  },
  pending: {
    background: "#F9B630",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: lighten("#F9B630", 0.4),
    },
  },
  notConfirmed: {
    background: "#2A2E3A",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: lighten("#2A2E3A", 0.4),
    },
  },
  title: {
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      width: 24,
      height: 24,
    },
  },
  subtitle: {
  },
  deviceTxt: {
    fontWeight: "400",
    marginLeft: 8,
    color: theme.palette.text.primary,
  },
  date: {
    textAlign: "right",
  },
  icon: {
    marginRight: 10
  }
}));

const mapStateToProps = ({ account }) => ({
  data: account.data,
  error: account.error,
});

export default connect(mapStateToProps)(Settings);
