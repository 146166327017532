import { Typography, Paper, makeStyles, Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useColoredTitle } from "../../../utils/hooks/useColoredTitle";
import RewardSevenIcon from "../../../assets/reward_seven.svg";
import RewardThreeIcon from "../../../assets/reward_three.svg";

const AboutProgram = ({ balances, claimFundsFromBalance }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Paper className={classes.root}>
      {renderTitle(t("About Program"))}
      <Typography
        variant={"body1"}
        className={classes.description}
      >
        {t("By participating in our Referral Program, you're not just earning rewards;")}
      </Typography>
      <Box flexDirection={'row'}>

        <Typography
          display="inline"
          variant={"body1"}
          className={clsx(classes.description, classes.coloredText)}
        >
          {t("Double Benefit:")}{" "}
        </Typography>
        <Typography
          display="inline"
          variant={"body1"}
          className={classes.description}
        >
          {t("Our referral program offers not one, but two reward lines to boost your rewards! When your referral signs a user to the first line, you, like your referral, will have income from this user. It's a win-win situation for everyone involved!")}
        </Typography>
      </Box>
      <Grid direction="row" container justifyContent="space-around" className={classes.rewardContainer}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={RewardSevenIcon}
            alt="referrals_icon"
            className={classes.rewardIcon}
          />
          <Typography variant="body2" className={classes.description}>
            {t("R1 - first line of referrals")}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={RewardThreeIcon}
            alt="referrals_icon"
            className={classes.rewardIcon}
          />
          <Typography variant="body2" className={classes.description}>
            {t("R2 - second line of referrals")}
          </Typography>
        </Box>
      </Grid>
      <Typography className={classes.noteText}>
        {t("*income from the commission of exchange transactions and withdrawal of coins")}
      </Typography>
    </Paper>
  );
};

export default AboutProgram;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
  },
  coloredText: {
    color: theme.palette.primary.main
  },
  rewardIcon: {
    maxHeight: 72
  },
  noteText: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  rewardContainer: {
    marginTop: 40
  }
}));
