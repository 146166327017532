import { enqueueSnackbar } from "../redux/actions/notistackActions";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import i18next from "../i18n.config";
const CopyWithAlert = ({ text, label, children }) => {
  const dispatch = useDispatch();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() =>
        dispatch(
          enqueueSnackbar({
            message: `${label} ${i18next.t(
              "successfully copied to clipboard."
            )}`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        )
      }>
      {children}
    </CopyToClipboard>
  );
};

CopyWithAlert.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CopyWithAlert;
