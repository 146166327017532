// LOADING

import { generateActionTypes } from "./generateActionTypes";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// ACCOUNT

export const ACCOUNT_REGISTER_STARTED = "ACCOUNT_REGISTER_STARTED";
export const ACCOUNT_REGISTER_SUCCESS = "ACCOUNT_REGISTER_SUCCESS";
export const ACCOUNT_REGISTER_FAILURE = "ACCOUNT_REGISTER_FAILURE";
export const RESET_REGISTER_STATUS = "RESET_REGISTER_STATUS";

export const RESET_PASSWORD_INIT_STARTED = "RESET_PASSWORD_INIT_STARTED";
export const RESET_PASSWORD_INIT_SUCCESS = "RESET_PASSWORD_INIT_SUCCESS";
export const RESET_PASSWORD_INIT_FAILURE = "RESET_PASSWORD_INIT_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
export const FINISH_RESET_PASSWORD_STARTED = "FINISH_RESET_PASSWORD_STARTED";
export const FINISH_RESET_PASSWORD_SUCCESS = "FINISH_RESET_PASSWORD_SUCCESS";
export const FINISH_RESET_PASSWORD_FAILURE = "FINISH_RESET_PASSWORD_FAILURE";

export const ACCOUNT_ACTIVATE_STARTED = "ACCOUNT_ACTIVATE_STARTED";
export const ACCOUNT_ACTIVATE_SUCCESS = "ACCOUNT_ACTIVATE_SUCCESS";
export const ACCOUNT_ACTIVATE_FAILURE = "ACCOUNT_ACTIVATE_FAILURE";

export const ACCOUNT_LOGIN_STARTED = "ACCOUNT_LOGIN_STARTED";
export const ACCOUNT_LOGIN_SUCCESS = "ACCOUNT_LOGIN_SUCCESS";
export const LOGIN_TWO_FA_SUCCESS = "LOGIN_TWO_FA_SUCCESS";
export const ACCOUNT_LOGIN_FAILURE = "ACCOUNT_LOGIN_FAILURE";

export const BACK_TO_MAIN = "BACK_TO_MAIN";
export const LOG_OUT = "LOG_OUT";

export const GET_ACCOUNT_DATA_STARTED = "GET_ACCOUNT_DATA_STARTED";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAILURE = "GET_ACCOUNT_DATA_FAILURE";

export const UPDATE_ACCOUNT_STARTED = "UPDATE_ACCOUNT_STARTED";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const ENABLE_TWO_FA_STARTED = "ENABLE_TWO_FA_STARTED";
export const ENABLE_TWO_FA_SUCCESS = "ENABLE_TWO_FA_SUCCESS";
export const ENABLE_TWO_FA_FAILURE = "ENABLE_TWO_FA_FAILURE";

export const DISABLE_TWO_FA_STARTED = "DISABLE_TWO_FA_STARTED";
export const DISABLE_TWO_FA_SUCCESS = "DISABLE_TWO_FA_SUCCESS";
export const DISABLE_TWO_FA_FAILURE = "DISABLE_TWO_FA_FAILURE";

export const CHECK_IS_TWO_FA_STARTED = "CHECK_IS_TWO_FA_STARTED";
export const CHECK_IS_TWO_FA_SUCCESS = "CHECK_IS_TWO_FA_SUCCESS";
export const CHECK_IS_TWO_FA_FAILURE = "CHECK_IS_TWO_FA_FAILURE";

export const CHECK_CODE_TWO_FA_STARTED = "CHECK_CODE_TWO_FA_STARTED";
export const CHECK_CODE_TWO_FA_SUCCESS = "CHECK_CODE_TWO_FA_SUCCESS";
export const CHECK_CODE_TWO_FA_FAILURE = "CHECK_CODE_TWO_FA_FAILURE";

export const CHECK_INSTALL_TWO_FA_STARTED = "CHECK_INSTALL_TWO_FA_STARTED";
export const CHECK_INSTALL_TWO_FA_SUCCESS = "CHECK_INSTALL_TWO_FA_SUCCESS";
export const CHECK_INSTALL_TWO_FA_FAILURE = "CHECK_INSTALL_TWO_FA_FAILURE";

export const CLEAR_TWO_FA_QR_CODE = "CLEAR_TWO_FA_QR_CODE";

export const GET_ACCOUNT_PERMISSIONS_INFO = generateActionTypes(
  "GET_ACCOUNT_PERMISSIONS_INFO"
);

// WALLETS

export const GET_WALLETS_LIST_STARTED = "GET_WALLETS_LIST_STARTED";
export const GET_WALLETS_LIST_SUCCESS = "GET_WALLETS_LIST_SUCCESS";
export const GET_WALLETS_LIST_FAILURE = "GET_WALLETS_LIST_FAILURE";

export const SORT_WALLETS_LIST = "SORT_WALLETS_LIST";

export const FILTER_WALLETS_LIST = "FILTER_WALLETS_LIST";

export const SET_WALLETS_PARAMS = "SET_WALLETS_PARAMS";

export const GET_CURRENCIES_STARTED = "GET_CURRENCIES_STARTED";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

export const CREATE_NEW_WALLET_STARTED = "CREATE_NEW_WALLET_STARTED";
export const CREATE_NEW_WALLET_SUCCESS = "CREATE_NEW_WALLET_SUCCESS";
export const CREATE_NEW_WALLET_FAILURE = "CREATE_NEW_WALLET_FAILURE";

export const WITHDRAW_STARTED = "WITHDRAW_STARTED";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAILURE = "WITHDRAW_FAILURE";

export const GET_WALLET_FEE_STARTED = "GET_WALLET_FEE_STARTED";
export const GET_WALLET_FEE_SUCCESS = "GET_WALLET_FEE_SUCCESS";
export const GET_WALLET_FEE_FAILURE = "GET_WALLET_FEE_FAILURE";

export const GET_ALL_TICKER_SETTINGS_STARTED =
  "GET_ALL_TICKER_SETTINGS_STARTED";
export const GET_ALL_TICKER_SETTINGS_SUCCESS =
  "GET_ALL_TICKER_SETTINGS_SUCCESS";
export const GET_ALL_TICKER_SETTINGS_FAILURE =
  "GET_ALL_TICKER_SETTINGS_FAILURE";

export const GET_TICKER_SETTINGS_STARTED = "GET_TICKER_SETTINGS_STARTED";
export const GET_TICKER_SETTINGS_SUCCESS = "GET_TICKER_SETTINGS_SUCCESS";
export const GET_TICKER_SETTINGS_FAILURE = "GET_TICKER_SETTINGS_FAILURE";

export const CHECK_INTERNAL_ADDRESS_SUCCESS = "CHECK_INTERNAL_ADDRESS_SUCCESS";
export const CHECK_INTERNAL_ADDRESS_FAILURE = "CHECK_INTERNAL_ADDRESS_FAILURE";

export const CLEAR_CHECK_INTERNAL_ADDRESS = "CLEAR_CHECK_INTERNAL_ADDRESS";

export const GET_WALLETS_SETTINGS_LIST_STARTED =
  "GET_WALLETS_SETTINGS_LIST_STARTED";
export const GET_WALLETS_SETTINGS_LIST_SUCCESS =
  "GET_WALLETS_SETTINGS_LIST_SUCCESS";
export const GET_WALLETS_SETTINGS_LIST_FAILURE =
  "GET_WALLETS_SETTINGS_LIST_FAILURE";

export const UPDATE_WALLET_SETTINGS_STARTED = "UPDATE_WALLET_SETTINGS_STARTED";
export const UPDATE_WALLET_SETTINGS_SUCCESS = "UPDATE_WALLET_SETTINGS_SUCCESS";
export const UPDATE_WALLET_SETTINGS_FAILURE = "UPDATE_WALLET_SETTINGS_FAILURE";

export const RECEIVE_FIAT_STARTED = "RECEIVE_FIAT_STARTED";
export const RECEIVE_FIAT_SUCCESS = "RECEIVE_FIAT_SUCCESS";
export const RECEIVE_FIAT_FAILURE = "RECEIVE_FIAT_FAILURE";

export const SET_WITHDRAWAL_CARD = "SET_WITHDRAWAL_CARD";

export const CLEAR_TICKER_SETTINGS = "CLEAR_TICKER_SETTINGS";

// TRANSACTIONS

export const GET_TRANSACTIONS_LIST_STARTED = "GET_TRANSACTIONS_LIST_STARTED";
export const GET_TRANSACTIONS_LIST_SUCCESS = "GET_TRANSACTIONS_LIST_SUCCESS";
export const GET_TRANSACTIONS_LIST_FAILURE = "GET_TRANSACTIONS_LIST_FAILURE";

// ADDRESSES

export const GET_ADDRESSES_STARTED = "GET_ADDRESSES_STARTED";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_FAILURE = "GET_ADDRESSES_FAILURE";

export const CLEAR_ADDRESSES = "CLEAR_ADDRESSES";

// export const ADD_NEW_ADDRESS_STARTED = 'ADD_NEW_ADDRESS_STARTED';
// export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
// export const ADD_NEW_ADDRESS_FAILURE = 'ADD_NEW_ADDRESS_FAILURE';
//
// export const DELETE_ADDRESS_STARTED = 'DELETE_ADDRESS_STARTED';
// export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
// export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

// EXCHANGE

export const EXCHANGE = {
  GET_MARKET_LIST_STARTED: "EXCHANGE_GET_MARKET_LIST_STARTED",
  GET_MARKET_LIST_SUCCESS: "EXCHANGE_GET_MARKET_LIST_SUCCESS",
  GET_MARKET_LIST_FAILURE: "EXCHANGE_GET_MARKET_LIST_FAILURE",

  GET_MARKET_PRICE_STARTED: "EXCHANGE_GET_MARKET_PRICE_STARTED",
  GET_MARKET_PRICE_SUCCESS: "EXCHANGE_GET_MARKET_PRICE_SUCCESS",
  GET_MARKET_PRICE_FAILURE: "EXCHANGE_GET_MARKET_PRICE_FAILURE",

  CREATE_STARTED: "EXCHANGE_CREATE_STARTED",
  CREATE_SUCCESS: "EXCHANGE_CREATE_SUCCESS",
  CREATE_FAILURE: "EXCHANGE_CREATE_FAILURE",

  GET_ORDERS_STARTED: "EXCHANGE_GET_ORDERS_STARTED",
  GET_ORDERS_SUCCESS: "EXCHANGE_GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "EXCHANGE_GET_ORDERS_FAILURE",

  GET_MARKET_DETAILS_STARTED: "EXCHANGE_GET_MARKET_DETAILS_STARTED",
  GET_MARKET_DETAILS_SUCCESS: "EXCHANGE_GET_MARKET_DETAILS_SUCCESS",
  GET_MARKET_DETAILS_FAILURE: "EXCHANGE_GET_MARKET_DETAILS_FAILURE",

  CLEAR_REQUEST_STATUS: "CLEAR_REQUEST_STATUS",
  CLEAR_ERROR: "CLEAR_ERROR",
};

// MODALS

export const OPEN_DIALOG = {
  ADD_NEW_WALLET: "OPEN_DIALOG_ADD_NEW_WALLET",
  RECEIVE: "OPEN_DIALOG_RECEIVE",
  RECEIVE_CARD: "OPEN_DIALOG_RECEIVE_CARD",
  CREATE_CARD: "OPEN_DIALOG_CREATE_CARD",
  REPLENISHMENT_METHOD: "OPEN_REPLENISHMENT_METHOD_DIALOG",
  WITHDRAWAL_METHOD: "OPEN_WITHDRAWAL_METHOD_DIALOG",
  INFO: "OPEN_INFO_DIALOG",
  CREATE_AUTO_INVEST_PLAN: "OPEN_CREATE_AUTO_INVEST_PLAN_DIALOG",
};

export const CLOSE_DIALOG = {
  ADD_NEW_WALLET: "CLOSE_DIALOG_ADD_NEW_WALLET",
  RECEIVE: "CLOSE_DIALOG_RECEIVE",
  RECEIVE_CARD: "CLOSE_DIALOG_RECEIVE_CARD",
  CREATE_CARD: "CLOSE_DIALOG_CREATE_CARD",
  REPLENISHMENT_METHOD: "CLOSE_REPLENISHMENT_METHOD_DIALOG",
  WITHDRAWAL_METHOD: "CLOSE_WITHDRAWAL_METHOD_DIALOG",
  INFO: "CLOSE_INFO_DIALOG",
  CREATE_AUTO_INVEST_PLAN: "CLOSE_CREATE_AUTO_INVEST_PLAN_DIALOG"
};

// DEVICE LOGS

export const GET_DEVICE_LOGS_LIST_STARTED = "GET_DEVICE_LOGS_LIST_STARTED";
export const GET_DEVICE_LOGS_LIST_SUCCESS = "GET_DEVICE_LOGS_LIST_SUCCESS";
export const GET_DEVICE_LOGS_LIST_FAILURE = "GET_DEVICE_LOGS_LIST_FAILURE";

export const SET_DEVICE_LOGS_PARAMS = "SET_DEVICE_LOGS_PARAMS";

// MARKET

export const MARKET = {
  GET_MARKET_LIST_STARTED: "GET_MARKET_LIST_STARTED",
  GET_MARKET_LIST_SUCCESS: "GET_MARKET_LIST_SUCCESS",
  GET_MARKET_LIST_FAILURE: "GET_MARKET_LIST_FAILURE",
};

// CARDS

export const CARDS = {
  GET_USER_CARDS: generateActionTypes('GET_USER_CARDS'),
  CREATE_CARD: generateActionTypes('CREATE_CARD'),
  SET_DEFAULT_CARD: generateActionTypes('SET_DEFAULT_CARD'),
  GET_CARD_BY_ID: generateActionTypes('GET_CARD_BY_ID'),
  DELETE_USER_CARD: generateActionTypes('DELETE_USER_CARD'),

  CLEAR_ERROR: "CLEAR_ERROR",
};

// KYC

export const KYC = {
  SET_VERIFICATION_METHOD: "SET_VERIFICATION_METHOD",
  CLEAR_VERIFICATION_METHOD: "CLEAR_VERIFICATION_METHOD",
  SET_ACTIVE_STEP: "SET_ACTIVE_STEP",
  SET_FILLED_STEP: "SET_FILLED_STEP",
  GET_KYC_STATUS: generateActionTypes("GET_KYC_STATUS"),
  KYC_VERIFY: generateActionTypes("KYC_VERIFY"),
};

// LANGUAGE

export const LANGUAGE = {
  SET_LANGUAGE: "SET_LANGUAGE",
  CLEAR_LANGUAGE: "CLEAR_LANGUAGE",
};

// REFERRAL_PROGRAM

export const REFERRAL_PROGRAM = {
  GET_REFERRAL_INFO: generateActionTypes("GET_REFERRAL_INFO"),
  GET_REFERRAL_BALANCE: generateActionTypes("GET_REFERRAL_BALANCE"),
  GET_REFERRAL_BALANCE_BY_ASSET: generateActionTypes(
    "GET_REFERRAL_BALANCE_BY_ASSET"
  ),
  CLAIM_FUNDS_FROM_REFERRAL_BALANCE: generateActionTypes(
    "CLAIM_FUNDS_FROM_REFERRAL_BALANCE"
  ),
  GET_CLAIM_HISTORY: generateActionTypes("GET_CLAIM_HISTORY"),
  GET_CLAIM_HISTORY_BY_ASSET: generateActionTypes("GET_CLAIM_HISTORY_BY_ASSET"),
  GET_REFERRAL_REWARDS: generateActionTypes("GET_REFERRAL_REWARDS"),
  GET_REFERRAL_REWARDS_BY_ASSET: generateActionTypes(
    "GET_REFERRAL_REWARDS_BY_ASSET"
  ),
  CLEAR_REFERRAL_STATUS: "CLEAR_REFERRAL_STATUS",
};

export const AUTO_INVEST_PROGRAM = {
  CREATE_AUTO_INVEST_PLAN: generateActionTypes('CREATE_AUTO_INVEST_PLAN'),
  UPDATE_AUTO_INVEST_PLAN: generateActionTypes('UPDATE_AUTO_INVEST_PLAN'),
  CHANGE_AUTO_INVEST_PLAN_STATUS: generateActionTypes('CHANGE_AUTO_INVEST_PLAN_STATUS'),
  DELETE_AUTO_INVEST_PLAN_BY_ID: generateActionTypes('DELETE_AUTO_INVEST_PLAN_BY_ID'),
  GET_AUTO_INVEST_PLAN_BY_ID: generateActionTypes('GET_AUTO_INVEST_PLAN_BY_ID'),
  GET_ALL_AUTO_INVEST_PLANS: generateActionTypes('GET_ALL_AUTO_INVEST_PLANS')
}

export const MONEYBOX = {
  CREATE_MONEYBOX: generateActionTypes('CREATE_MONEYBOX'),
  UPDATE_MONEYBOX: generateActionTypes('UPDATE_MONEYBOX'),
  GET_MONEYBOX_BY_ID: generateActionTypes('GET_MONEYBOX_BY_ID'),
  DELETE_MONEYBOX: generateActionTypes('DELETE_MONEYBOX'),
  GET_MONEYBOX_LIST: generateActionTypes('GET_MONEYBOX_LIST'),
  CREATE_MONEYBOX_BALANCE: generateActionTypes('CREATE_MONEYBOX_BALANCE'),
  WITHDRAW_MONEYBOX_BALANCE: generateActionTypes('WITHDRAW_MONEYBOX_BALANCE'),
  DEPOSIT_MONEYBOX_BALANCE: generateActionTypes('DEPOSIT_MONEYBOX_BALANCE'),
  GET_MONEYBOX_BALANCES_LIST: generateActionTypes('GET_MONEYBOX_BALANCES_LIST'),
  GET_MONEYBOX_BALANCE_BY_ID: generateActionTypes('GET_MONEYBOX_BALANCE_BY_ID'),
  GET_MONEYBOX_BALANCE_HISTORY: generateActionTypes('GET_MONEYBOX_BALANCE_HISTORY')
}

export const COMPANIES = {
  GET_COMPANIES_LIST: generateActionTypes('GET_COMPANIES_LIST'),
  TRANSFER_COMPANY_BALANCE: generateActionTypes('TRANSFER_COMPANY_BALANCE'),
  GET_COMPANY_BALANCE_TRANSFER_HISTORY: generateActionTypes('GET_COMPANY_BALANCE_TRANSFER_HISTORY'),
  GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID: generateActionTypes('GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID'),
  MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE: generateActionTypes('MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE'),
  RESET_COMPANIES: 'RESET_COMPANIES',
  SELECT_COMPANY_BALANCE: 'SELECT_COMPANY_BALANCE',
  ADD_NEW_COMPANY: generateActionTypes('ADD_NEW_COMPANY'),
}
