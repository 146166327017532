import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ButtonBase,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sendStyles } from "../Send/SendContainer";
import { Input } from "../../theme/components/Input";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getCurrencies,
  getWalletsList,
  getAllTickerSettings,
  getWalletsSettingsList,
} from "../../redux/actions/walletsActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketList,
  exchangeClearRequestStatus,
  exchangeClearError,
} from "../../redux/actions/exchangeActions";
import DialogFrom from "./DialogFrom";
import DialogTo from "./DialogTo";
import { Button } from "../../theme/components/Button";
import ExchangeConfirm from "./ExchangeConfirm";
import { ErrorCard } from "../../theme/components/ErrorCard/ErrorCard";
import { accountSelectors } from "../../redux/selectors/accountSelectors";
import { maxPrecisionValidator } from "../../utils/functions/customValidators/maxPrecisionValidator";
import { EXCHANGE_FEE } from "../../utils/constants/exchangeFee";
import { roundValue } from "../../utils/functions/roundValue";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

const fixedExchangeRatePair = ["uaht"];

const ExchangeContainer = () => {
  const classes = sendStyles();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isMobile = useIsMobile();

  const wallets = useSelector(({ wallets }) => wallets.data);
  const isExchangeBlocked = useSelector((state) =>
    accountSelectors.isExchangeBlocked(state)
  );
  const { pairs, requestStatus, error } = useSelector(
    ({ exchange }) => exchange
  );
  const { settings, allTickerSettings } = useSelector(({ wallets }) => wallets);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filteredPairs, setFilteredPairs] = useState([]);
  const [amountError, setAmountError] = useState("");
  const [form, setForm] = useState({
    assetFrom: undefined,
    assetTo: undefined,
    amount: 0,
    toAmount: 0,
    isReverseCalculation: false,
  });

  const coinPairSettings = useMemo(
    () =>
      filteredPairs.find(
        (i) => i.assetFrom === form?.assetFrom && i.assetTo === form?.assetTo
      ),
    [filteredPairs, form?.assetFrom, form?.assetTo]
  );

  const fromWallet = useMemo(() => {
    let wallet = wallets?.find((i) => i.ticker === form?.assetFrom);
    return wallet;
  }, [form.assetFrom, wallets]);

  const minScaleAssetFrom = useMemo(() => {
    return allTickerSettings?.find(
      (setting) => setting?.ticker === form?.assetFrom
    )?.minScale;
  }, [allTickerSettings, form?.assetFrom]);

  const minScaleAssetTo = useMemo(() => {
    return allTickerSettings?.find(
      (setting) => setting?.ticker === form?.assetTo
    )?.minScale;
  }, [allTickerSettings, form?.assetTo]);

  const exchangeRate = useMemo(() => {
    if (!coinPairSettings || !minScaleAssetTo) {
      return 0;
    }
    const { commission, price, assetFrom, assetTo } = coinPairSettings;
    const isExchangeWithFiat = fixedExchangeRatePair.includes(assetFrom) || fixedExchangeRatePair.includes(assetTo)
    return roundValue(
      isExchangeWithFiat ? price : price + (price / 100) * commission,
      isExchangeWithFiat
        ? 2
        : minScaleAssetTo
    );
  }, [minScaleAssetTo, coinPairSettings]);

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
    dispatch(getCurrencies({ number: 0, size: 100 }));
    dispatch(getMarketList());
  }, [dispatch]);

  useEffect(() => {
    if (pairs?.length > 0 && settings?.length > 0) {
      setFilteredPairs(
        pairs?.filter((pair) => {
          return (
            settings?.find(
              (setting) =>
                setting?.ticker === pair?.assetFrom && setting?.visible
            ) &&
            settings?.find(
              (setting) => setting?.ticker === pair?.assetTo && setting?.visible
            )
          );
        })
      );
    }
  }, [pairs, settings]);

  useEffect(() => {
    if (filteredPairs.length > 0) {
      let initialPair = state?.ticker
        ? filteredPairs?.find((i) => i.assetFrom === state?.ticker)
        : filteredPairs[0];
      setForm({
        assetFrom: initialPair?.assetFrom,
        assetTo: initialPair?.assetTo,
        amount: 0,
      });
    }
  }, [filteredPairs, settings, state?.ticker]);

  useEffect(() => {
    if (requestStatus) {
      setForm({ ...form, amount: 0 });
      dispatch(exchangeClearRequestStatus());
    }
  }, [dispatch, requestStatus, form]);

  const onCloseFromDialog = (ticker) => {
    if (typeof ticker === "string") {
      setForm({
        ...form,
        assetFrom: ticker,
        assetTo: filteredPairs?.find((i) => i.assetFrom === ticker).assetTo,
        amount: 0,
        toAmount: 0,
      });
      error?.errorKey?.includes("card") && dispatch(exchangeClearError());
    }
    setAmountError("");
    setOpenFrom(false);
  };

  const onCloseToDialog = (ticker) => {
    if (typeof ticker === "string") {
      setForm({ ...form, assetTo: ticker, amount: 0, toAmount: 0 });
      error?.errorKey?.includes("card") && dispatch(exchangeClearError());
      setAmountError("");
    }
    setOpenTo(false);
  };

  const handleWithdraw = () => {
    if (Boolean(amountError)) {
      return;
    }
    if (coinPairSettings?.minAmount > form?.amount) {
      setAmountError(
        i18n.t("The sum must be equal to or greater than {{value}}", {
          value: coinPairSettings?.minAmount,
        })
      );
      return;
    }
    if (coinPairSettings?.maxAmount < form?.amount) {
      setAmountError(
        i18n.t("The amount must be equal to or less than {{value}}", {
          value: coinPairSettings?.maxAmount,
        })
      );
      return;
    }
    setOpenConfirm(true);
  };

  const handleChangeAmount = (value) => {
    if (
      (!+value || !coinPairSettings || !exchangeRate) &&
      form?.isReverseCalculation
    )
      return setForm({
        feeFrom: 0,
        amount: 0,
        toAmount: 0,
      });
    const { commission, price } = coinPairSettings;
    const feeFrom = (value / 100) * commission;
    const result = value - feeFrom;
    const toAmount = roundValue(
      form.assetFrom === "uaht" ? result / price : result * price,
      minScaleAssetTo
    );
    setForm({ ...form, feeFrom, amount: value, toAmount });
    amountValidation(roundValue(value));
  };

  const handleChangeToAmount = (value) => {
    if ((!+value || !coinPairSettings) && !form?.isReverseCalculation)
      return {
        feeFrom: 0,
        amount: 0,
        toAmount: 0,
      };
    const { commission, price } = coinPairSettings;
    const valueKnownPercentage = 100 - Number(commission);
    const valueWithFee = (value / valueKnownPercentage) * 100;
    const priceWithFee =
      form.assetFrom === "uaht"
        ? Number(valueWithFee) * price
        : Number(valueWithFee) / price;

    const amount = roundValue(priceWithFee, minScaleAssetFrom);
    const feeFrom = amount - value;

    setForm({
      ...form,
      feeFrom,
      amount,
      toAmount: value,
    });
    amountValidation(roundValue(amount, minScaleAssetFrom));
  };
  const amountValidation = (value) => {
    setAmountError(
      !value
        ? i18n.t("This is a required field!")
        : (fromWallet?.balance || 0) < +value
          ? i18n.t("Not enough money")
          : coinPairSettings.minAmount > +value
            ? i18n.t("The sum must be equal to or greater than {{value}}", {
              value: coinPairSettings?.minAmount,
            })
            : coinPairSettings.maxAmount < +value
              ? i18n.t("The amount must be equal to or less than {{value}}", {
                value: coinPairSettings?.maxAmount,
              })
              : maxPrecisionValidator(value, coinPairSettings?.maxAssetPrecision)
                ?.errorMessage
    );
  };

  return (
    <>
      {isExchangeBlocked && <ErrorCard text="The exchange is blocked" />}
      <Paper className={classes.root} elevation={4}>
        <Typography
          variant={"h4"}
          color={"textPrimary"}
          className={classes.title}
        >
          {t("Please enter and select details of your exchange")}
        </Typography>
        <Typography
          variant={"body2"}
          color={"textSecondary"}
          style={{
            textAlign: "right",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className={classes.smallText}
        >
          <span>
            {filteredPairs.length > 0 &&
              `Min: ${coinPairSettings?.minAmount || 0}  Max: ${coinPairSettings?.maxAmount || 0
              }`}
          </span>
          <span>
            {` ${t("Available")}: ${fromWallet?.balance
              ? roundValue(fromWallet?.balance, minScaleAssetFrom)
              : 0
              } ${form.assetFrom?.toUpperCase() || ""}`}
          </span>
        </Typography>
        <Input
          disabled={isExchangeBlocked}
          className={classes.input}
          label={`${t("Amount")} ${form?.assetFrom?.toUpperCase()}`}
          variant="outlined"
          name={"from"}
          value={
            !+form?.amount && form?.isReverseCalculation
              ? 0
              : !form?.isReverseCalculation
                ? form?.amount
                : `~ ${+form?.amount}`
          }
          onChange={({ target: { value } }) =>
            /^[0-9]*\.?[0-9]*$/.test(value) &&
            !(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
            value.length < 25 &&
            handleChangeAmount(value)
          }
          onFocus={() =>
            !form?.amount
              ? setForm({
                ...form,
                amount: "",
                toAmount: 0,
                isReverseCalculation: false,
              })
              : setForm({ ...form, isReverseCalculation: false })
          }
          onBlur={() =>
            !form?.amount && setForm({ ...form, amount: 0, toAmount: 0 })
          }
          error={Boolean(amountError)}
          helperText={t(amountError)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                {fromWallet && (
                  <Link
                    onClick={() => {
                      setAmountError("");
                      setForm({ ...form, isReverseCalculation: false });
                      handleChangeAmount(
                        roundValue(fromWallet?.balance, minScaleAssetFrom)
                      );
                    }}
                  >
                    MAX
                  </Link>
                )}
                <ButtonBase
                  className={classes.select}
                  disabled={isExchangeBlocked}
                  onClick={() => setOpenFrom(true)}
                >
                  {form?.assetFrom && (
                    <img
                      src={`https://cryptoneed.com/icons/${form?.assetFrom}.svg`}
                      alt={form?.assetFrom}
                      width={24}
                      height={24}
                      loading={"lazy"}
                    />
                  )}
                  {form?.assetFrom?.toUpperCase()}
                  <ExpandMoreIcon fontSize={"small"} />
                </ButtonBase>
              </InputAdornment>
            ),
          }}
        />
        <Input
          disabled={isExchangeBlocked}
          className={classes.input}
          style={{ marginTop: 16 }}
          label={`${t("Amount")} ${form?.assetTo?.toUpperCase()}`}
          variant="outlined"
          name={"to"}
          autoComplete="off"
          readOnly
          value={
            !+form?.toAmount && !form?.isReverseCalculation
              ? 0
              : form?.isReverseCalculation
                ? form?.toAmount
                : `~ ${+form?.toAmount}`
          }
          onChange={({ target: { value } }) =>
            /^[0-9]*\.?[0-9]*$/.test(value) &&
            !(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
            value.length < 25 &&
            handleChangeToAmount(value)
          }
          onFocus={() =>
            !form?.toAmount
              ? setForm({
                ...form,
                amount: 0,
                toAmount: "",
                isReverseCalculation: true,
              })
              : setForm({ ...form, isReverseCalculation: true })
          }
          onBlur={() =>
            !form?.toAmount
              ? setForm({
                ...form,
                amount: 0,
                toAmount: 0,
                isReverseCalculation: false,
              })
              : setForm({ ...form, isReverseCalculation: false })
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonBase
                  className={classes.select}
                  disabled={isExchangeBlocked}
                  onClick={() => setOpenTo(true)}
                >
                  {form.assetTo && (
                    <img
                      src={`https://cryptoneed.com/icons/${form.assetTo}.svg`}
                      alt={form.assetTo}
                      width={24}
                      height={24}
                      loading={"lazy"}
                    />
                  )}
                  {form.assetTo?.toUpperCase()}
                  <ExpandMoreIcon fontSize={"small"} />
                </ButtonBase>
              </InputAdornment>
            ),
          }}
        />
        <Grid
          container
          justifyContent={"space-between"}
          style={{
            marginBottom: isMobile ? 10 : 20,
          }}
        >
          <Grid item>
            <Typography variant={"body2"}>{t("Fee")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={"body"}>
              {EXCHANGE_FEE} %
            </Typography>
          </Grid>
        </Grid>
        {Boolean(form?.amount && form?.toAmount) && (
          <Grid
            container
            justifyContent={"space-between"}
            style={{ marginBottom: 32 }}
          >
            <Grid item>
              <Typography variant={"body2"}>{t("Exchange rate")}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body2"} >
                {fixedExchangeRatePair.includes(form?.assetFrom) ||
                  fixedExchangeRatePair.includes(form?.assetTo)
                  ? `1 ${(form?.assetFrom === "uaht"
                    ? form?.assetTo
                    : form?.assetFrom
                  ).toUpperCase()} = ${+exchangeRate} ${"uaht".toUpperCase()}`
                  : `1 ${form?.assetFrom.toUpperCase()} = ${+exchangeRate} ${form?.assetTo.toUpperCase()}`}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Button
          className={classes.btn}
          disabled={isExchangeBlocked || Number(form?.amount) === 0}
          onClick={handleWithdraw}
        >
          {t("Continue")}
        </Button>
      </Paper>
      <DialogFrom
        open={openFrom}
        onClose={onCloseFromDialog}
        filteredData={filteredPairs}
      />
      <DialogTo
        open={openTo}
        onClose={onCloseToDialog}
        data={filteredPairs.filter((i) => i.assetFrom === form.assetFrom)}
      />
      <ExchangeConfirm
        open={openConfirm}
        data={form}
        exchangeRate={exchangeRate}
        onClose={() => {
          setOpenConfirm(false);
        }}
      />
    </>
  );
};

export default ExchangeContainer;
