import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import OverviewBlock from "../../theme/components/OverviewBlock";
import { VerticalButton } from "../../theme/components/VerticalButton";
import {
  openReceiveDialog,
} from "../../redux/actions/dialogActions";
import ReceiveDialog from "../../theme/dialogs/ReceiveDialog";
import { roundValue } from "../../utils/functions/roundValue";
import { isLoading } from "../../utils/functions/requestStatuses";
import { useMemo, useState } from "react";
import WithdrawCompanyBalance from "./WithdrawCompanyBalance";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";

const CompanyBalanceOverview = ({ ticker, balance, balanceId, address, companyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showWithdrawBalanceModal, setShowWithdrawBalanceModal] = useState(false)

  const companiesList = useSelector(companiesSelectors.companiesList)
  const selectedCompanyData = companiesList?.find((company) => Number(company?.companyId) === Number(companyId));
  const selectedCompanyBalance = useMemo(() => {
    return selectedCompanyData?.balances?.find((balance) => Number(balance?.balanceId) === Number(balanceId))
  }, [balanceId, selectedCompanyData])

  const getCompaniesBalanceRequestStatus = useSelector(companiesSelectors.getCompaniesListRequestStatus)
  const isLoaderNeeded = isLoading(getCompaniesBalanceRequestStatus)
  const tickerSettings = useSelector(({ wallets }) =>
    wallets?.allTickerSettings?.find(i => i.ticker === ticker)
  );
  const currentWallet = useSelector(({ wallets }) =>
    wallets.data.find(i => i.ticker === ticker)
  );
  const marketPrice = useSelector(
    ({ market }) => market.data.find(i => i?.ticker === ticker)?.price || 0
  );

  const handleSelect = () => {
    dispatch(openReceiveDialog(currentWallet));
  };

  const handleWithdrawClick = () => {
    setShowWithdrawBalanceModal(true);
  }

  return (
    <OverviewBlock>
      <WithdrawCompanyBalance
        open={showWithdrawBalanceModal}
        onClose={() => setShowWithdrawBalanceModal(false)}
        companyBalanceData={selectedCompanyBalance}
      />
      <Grid container alignItems='center' spacing={0}>
        <Grid item>
          <img
            src={`https://cryptoneed.com/icons/${ticker}.svg`}
            alt={ticker}
            width={36}
            height={36}
            loading={"lazy"}
          />
        </Grid>
        <Grid item>
          <Typography variant={"h4"} className={classes.title}>
            {ticker?.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant={"h2"} className={classes.amountText}>
            {`${roundValue(
              balance,
              tickerSettings?.minScale
            )} ${ticker?.toUpperCase()}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant={"body1"} className={classes.priceText}>
            {`= ${roundValue(balance * marketPrice, 2)} USD`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-around"}
        style={{ marginTop: 76 }}>
        <Grid
          item
          style={{
            width: "calc((100% - 20px) / 3)",
          }}>
          <VerticalButton variant='outlined' onClick={handleSelect}>
            <GetAppOutlinedIcon />
            {t("Receive")}
          </VerticalButton>
        </Grid>
        <Grid
          item
          style={{
            width: "calc((100% - 20px) / 3)",
            display: "flex",
            justifyContent: "center",
          }}>
          <VerticalButton
            variant='outlined'
            onClick={handleWithdrawClick}>
            <PublishOutlinedIcon />
            {t("Withdraw")}
          </VerticalButton>
        </Grid>
      </Grid>
      <ReceiveDialog customData={{
        ticker,
        address,
        balance,
      }} />
      {
        isLoaderNeeded &&
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      }
    </OverviewBlock>
  );
};

export default CompanyBalanceOverview;

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  amountText: {
    fontWeight: 700,
    marginTop: 20,
  },
  priceText: {
    fontWeight: 300,
    color: theme.palette.text.darkLighter,
  },
  verticalButton: {
    width: "calc((100% - 20px) / 3)",
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#212328',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));
