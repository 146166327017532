import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTransactions } from "../../redux/actions/transactionsActions";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { Transaction, TransactionDialog } from "../Transactions";
import Pagination from "@material-ui/lab/Pagination";

const format = time => moment(time).startOf("day").format("DD.MM.yyyy");

const size = 10;

const Transactions = () => {
  const { t } = useTranslation();
  const { ticker } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { total } = useSelector(({ transactions }) => transactions);
  const transactions = useSelector(({ transactions }) =>
    transactions.data
      .filter(t => (ticker ? t?.ticker === ticker : true))
      .sort((a, b) => moment.utc(b.create).diff(moment.utc(a.create)))
      .reduce(
        (res, t) => ({
          ...res,
          [format(t.create)]: [...(res[format(t.create)] || []), t],
        }),
        {}
      )
  );
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    dispatch(getTransactions({ page, size }, ticker));
  }, [dispatch, page, ticker]);

  return (
    <>
      {Object.keys(transactions).length < 1 ? (
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          style={{
            textAlign: "center",
            marginTop: 24,
          }}>
          {t("You have no transactions...")}
        </Typography>
      ) : (
        Object.keys(transactions).map(date => (
          <div key={date}>
            <Typography
              variant={"body2"}
              color={"textSecondary"}
              style={{ textAlign: "right", marginBottom: 10, marginTop: 20 }}>
              {format() === date ? t("Today") : date}
            </Typography>
            {transactions[date].map(transaction => (
              <Transaction
                key={transaction?.id}
                transaction={transaction}
                setDetail={setDetail}
              />
            ))}
          </div>
        ))
      )}
      {total > size && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 32,
          }}>
          <Pagination
            count={Math.ceil(total / size)}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
            color='primary'
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
      <TransactionDialog
        transaction={detail}
        handleClose={() => setDetail(null)}
      />
    </>
  );
};

export default Transactions;
