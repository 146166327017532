import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import SlicedAddress from "../../theme/components/SlicedAddress";
import CopyIcon from "../../assets/icons/сopy_secondary.svg";
import CopyWithAlert from "../../utils/CopyWithAlert";

const Address = ({ variant, address, tickerSettings, short }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper
        variant={variant || "elevation"}
        elevation={0}
        className={clsx(classes.root, short && "short")}
      >
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Grid item xs={7} sm={8}>
            <Typography variant={"subtitle2"} className={classes.text}>
              {t("Address")}
            </Typography>
            <Typography variant={"body1"} className={classes.text}>
              <SlicedAddress address={address?.address} short={short} />
            </Typography>
          </Grid>
          <Grid item style={{ padding: 0 }}>
            <CopyWithAlert text={address?.address || ""} label={"Address"}>
              <IconButton>
                <img src={CopyIcon} alt="copy_icon" />
              </IconButton>
            </CopyWithAlert>
          </Grid>
        </Grid>
      </Paper>
      <Grid container>
        {!!tickerSettings && (
          <Typography
            variant={"body2"}
            color={"textPrimary"}
            className={classes.wrapIcon}
          >
            <ReportProblemOutlinedIcon
              style={{ color: "#AF994A", marginRight: 8, fontSize: 24 }}
            />
            {tickerSettings?.ticker === "uaht"
              ? t("For internal transactions")
              : tickerSettings?.minDeposit
              ? t("A deposit amount less than {{value}} cannot be credited.", {
                  value: +tickerSettings?.minDeposit,
                })
              : ""}
          </Typography>
        )}
      </Grid>

      {Boolean(address?.memoAddress) && (
        <>
          <Paper
            variant={variant || "elevation"}
            elevation={0}
            className={clsx(classes.root, short && "short", classes.memoRoot)}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={7} sm={8}>
                <Typography variant={"body1"} style={{ color: "#707594" }}>
                  Memo
                </Typography>
                <Typography variant={"body1"} style={{ color: "#000000" }}>
                  <SlicedAddress address={address?.memoAddress} short={short} />
                </Typography>
              </Grid>
              <Grid item>
                <CopyWithAlert
                  text={address?.memoAddress || ""}
                  label={"Address"}
                >
                  <IconButton size={"small"}>
                    <img src={CopyIcon} alt="copy" />
                  </IconButton>
                </CopyWithAlert>
              </Grid>
            </Grid>
          </Paper>
          <Grid container>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              className={classes.wrapIcon}
            >
              <ReportProblemOutlinedIcon
                style={{ color: "#AF994A", marginRight: 8, fontSize: 24 }}
              />
              {t(
                "To receive a transaction you must specify the address and memo."
              )}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};

Address.prpTypes = {
  address: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["contained", "outlined"]),
  short: PropTypes.bool,
};

export default Address;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.text.primary,
    padding: "10px 22px",
    "&.short": {
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        "& svg": {
          fontSize: 18,
        },
      },
    },
  },
  memoRoot: {
    marginTop: 32,
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
    },
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  text: {
    color: theme.palette.text.dark
  }
}));
