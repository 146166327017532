import {
	GET_TRANSACTIONS_LIST_FAILURE,
	GET_TRANSACTIONS_LIST_STARTED,
	GET_TRANSACTIONS_LIST_SUCCESS
} from "../types";
import api from "../../services/api";

export const getTransactionsStarted = () => ({
	type: GET_TRANSACTIONS_LIST_STARTED
});

export const getTransactionsSuccess = (data, total) => ({
	type: GET_TRANSACTIONS_LIST_SUCCESS,
	payload: {
		data,
		total
	}
});

export const getTransactionsFailure = error => ({
	type: GET_TRANSACTIONS_LIST_FAILURE,
	payload: {
		error
	}
});

export const getTransactions = (params, ticker) => {
	return async (dispatch) => {
		dispatch(getTransactionsStarted());
		if (ticker) {
			api.transactions.getListByTicker(ticker, params)
				.then(res => dispatch(getTransactionsSuccess(res.data, Number(res.headers['x-total-count']))))
				.catch(error => dispatch(getTransactionsFailure(error)));
		} else {
			api.transactions.getList(params)
				.then(res => dispatch(getTransactionsSuccess(res.data, Number(res.headers['x-total-count']))))
				.catch(error => dispatch(getTransactionsFailure(error)));
		}
	}
};
