import i18n from "../../../i18n.config";
export function withdrawValidator(
  value,
  minValue,
  maxValue,
  balance,
  total,
  // numberDigits,
  isInternal
) {
  if (!value) {
    return {
      invalid: true,
      errorMessage: "This is a required field!",
    };
  }

  if (!isInternal && Number(value) < Number(minValue)) {
    return {
      invalid: true,
      errorMessage: i18n.t(
        "The sum must be equal to or greater than {{value}}",
        { value: Number(minValue) }
      ),
    };
  }

  if (!isInternal && Number(total) < 0) {
    return {
      invalid: true,
      errorMessage: i18n.t("Insufficient amount entered for transaction."),
    };
  }

  if (Number(total) > Number(balance)) {
    return {
      invalid: true,
      errorMessage: i18n.t("Not enough money,"),
    };
  }

  if (Number(value) > Number(maxValue) || Number(total) > Number(maxValue)) {
    return {
      invalid: true,
      errorMessage: i18n.t("Sum must be equal to or less than {{value}}", {
        value: maxValue,
      }),
    };
  }

  // default
  return {
    invalid: false,
    errorMessage: "",
  };
}
