import { useMemo, useState } from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilityButton from "../../theme/components/VisibilityButton";
import getEmoji from "../../services/emoji";
import { VerticalButton } from "../../theme/components/VerticalButton";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import OverviewBlock from "../../theme/components/OverviewBlock";
import {
  closeReceiveDialog,
  openReceiveDialog,
} from "../../redux/actions/dialogActions";

import SelectWalletDialog from "../../theme/dialogs/SelectWalletDialog";
import ReceiveDialog from "../../theme/dialogs/ReceiveDialog";
import { roundValue } from "../../utils/functions/roundValue";
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";
import WithdrawMoneyboxBalance from "../MoneyboxBalance/WithdrawMoneyboxBalance";

const MONEYBOX_TRANSACTION_TYPES = {
  SEND: 'send',
  RECEIVE: 'receive'
}

const MoneyboxOverview = ({ totalInUsd = 0 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isReceiveDialog = useSelector(({ dialogs }) =>
    Boolean(dialogs?.receive?.selectedWallet)
  );
  const { data } = useSelector(({ market }) => market);
  const moneyboxBalancesList = useSelector(moneyboxSelectors.moneyboxBalancesList);
  const balanceListTickers = useMemo(() => moneyboxBalancesList?.map((item) => item.currency), [moneyboxBalancesList])
  const [visibility, setVisibility] = useState(true);
  const [open, setOpen] = useState(false);
  const [showWithdrawBalanceModal, setShowWithdrawBalanceModal] = useState(false);
  const [transactionType, setTransactionType] = useState('');
  const [selectedMoneyboxBalanceToTransfer, setSelectedMoneyboxBalanceToTransfer] = useState(null);
  const active = useMemo(() => {
    return open || isReceiveDialog;
  }, [open, isReceiveDialog]);

  const handleSelect = wallet => {
    const selectedMoneyboxBalance = moneyboxBalancesList.find((item) => wallet?.ticker === item.currency);

    setSelectedMoneyboxBalanceToTransfer({
      ticker: selectedMoneyboxBalance?.currency || '',
      currency: selectedMoneyboxBalance?.currency || '',
      address: selectedMoneyboxBalance?.address || '',
      balance: selectedMoneyboxBalance?.balance || '',
      balanceId: selectedMoneyboxBalance?.balanceId || '',
      moneyboxId: selectedMoneyboxBalance?.moneyboxId || '',
    })
    if (transactionType === MONEYBOX_TRANSACTION_TYPES.RECEIVE && selectedMoneyboxBalance) {
      dispatch(openReceiveDialog(wallet));
    }
    if (transactionType === MONEYBOX_TRANSACTION_TYPES.SEND && selectedMoneyboxBalance) {
      setShowWithdrawBalanceModal(true);
    }
    setOpen(false);
  };

  const btcTotal = useMemo(() => {
    const btcPrice = data?.find(i => i?.ticker === "btc")?.price;
    const round = 1000000;
    return Math.round((totalInUsd / btcPrice) * round) / round || 0;
  }, [data, totalInUsd]);


  const handleBackReceive = () => {
    dispatch(closeReceiveDialog());
    setOpen(true);
  };
  const handleClick = name => {
    switch (name) {
      case "receive":
        setOpen(true);
        setTransactionType(MONEYBOX_TRANSACTION_TYPES.RECEIVE)
        return;
      case "send":
        setOpen(true);
        setTransactionType(MONEYBOX_TRANSACTION_TYPES.SEND)
        return;
      default:
        setOpen(false);
    }
  };

  return (
    <>
      <OverviewBlock>
        <Grid container spacing={1} alignItems={"center"} className={classes.header}>
          <Grid item>
            <VisibilityButton
              visibility={visibility}
              size={36}
              onChange={setVisibility}
            />
          </Grid>
          <Grid item>
            <Typography
              variant={"h4"}
              className={classes.title}>
              {t("Total balance")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          style={{ marginTop: 8 }}>
          <Grid item>
            <Typography variant={"h2"} className={classes.priceText}>
              {visibility ? `$ ${roundValue(totalInUsd, 2)}` : `$ ${getEmoji()}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.btcEqText}>
              {"= "}
              {visibility ? btcTotal : getEmoji()}
              {" BTC"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-around"}
          style={{ marginTop: 76 }}>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='receiveBtn'
                style={{
                  color: active ? theme.palette.primary.active : "#fff",
                  borderColor: active ? theme.palette.primary.active : theme.palette.border.secondary,
                }}
                onClick={() => handleClick("receive")}
                className={active === "receive" ? "active" : ""}>
                <GetAppOutlinedIcon />
                {t("Receive")}
              </VerticalButton>
            </Grid>
          </Grid>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='send'
                onClick={() => handleClick("send")}>
                <PublishOutlinedIcon />
                {t("Withdraw")}
              </VerticalButton>
            </Grid>
          </Grid>
        </Grid>
      </OverviewBlock>
      <SelectWalletDialog
        open={open}
        onClose={() => setOpen(false)}
        onSelect={handleSelect}
        visibleCurrenciesListTickers={balanceListTickers}
      />
      <ReceiveDialog handleBackReceive={handleBackReceive} customData={selectedMoneyboxBalanceToTransfer} />
      <WithdrawMoneyboxBalance
        open={showWithdrawBalanceModal}
        onClose={() => setShowWithdrawBalanceModal(false)}
        moneyboxBalanceData={selectedMoneyboxBalanceToTransfer}
      />
    </>
  );
};

export default MoneyboxOverview;

export const useStyles = makeStyles(theme => ({
  header: {
    marginLeft: -12,
    marginTop: -14
  },
  title: {
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  priceText: {
    marginTop: -8,
    fontWeight: 700,
    marginLeft: 6,
  },
  btcEqText: {
    marginLeft: 6,
    fontWeight: 300,
    color: theme.palette.text.darkLighter,
  },
  verticalButton: {
    width: "calc((100% - 20px) / 3)",
  },
  positive: {
    color: theme.palette.success.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
  active: {
    color: `${theme.palette.primary.light} !important`,
    borderColor: `${theme.palette.primary.light} !important`,
  },
  attention: {
    color: theme.palette.attention.main,
  },
}));
