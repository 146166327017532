
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Button } from "../components/Button";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import DialogWrapper from "./DialogWrapper";

import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";

const DeleteWithWarningDialog = ({
  open,
  title,
  titleDescription,
  message,
  handleClose,
  btnOkText = "Delete",
  warningText = '',
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      dialogClassName={classes.dialogRootWrapper}
    >
      <Typography variant={"h4"} color={"textPrimary"} className={classes.titleText}>
        {coloredFirstLetter(title, theme.palette.error.main)}
      </Typography>
      <Typography variant="body2" className={classes.titleDescription}>
        {titleDescription}
      </Typography>
      <Typography
        variant={"body1"}
        align={"center"}
        color={"textPrimary"}
        className={classes.message}
      >
        {t(message)}
      </Typography>
      <Grid container justifyContent="center">
        <Button
          className={classes.btn}
          variant="contained"
          style={{
            minWidth: isMobile ? "100%" : "max(25%, 50px)",
            marginTop: 12,
          }}
          onClick={() => handleClose({ accept: true })}
        >
          {t(btnOkText)}
        </Button>
        {
          Boolean(warningText) &&
          <Grid className={classes.warningWrapper} container direction="row" wrap="nowrap">
            <ReportProblemOutlinedIcon
              style={{ color: "#AF994A", marginRight: 8, fontSize: 32 }}
            />
            <Typography
              variant={"body2"}
              className={classes.warningText}
            >
              {warningText}
            </Typography>
          </Grid>
        }
      </Grid>
    </DialogWrapper>
  );
};

export default DeleteWithWarningDialog;

const useStyles = makeStyles(theme => ({
  dialogRootWrapper: {
    maxWidth: 490,
    margin: '0 auto'
  },
  titleText: {
    fontWeight: 400,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 24,
  },
  titleDescription: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 8,
  },
  btn: {
    alignSelf: 'center',
    marginTop: 30,
    height: 44,
    width: 220,
    borderColor: `${theme.palette.error.main} !important`,
    "& .MuiButton-label": {
    },
  },
  message: {
    marginTop: 40,
    marginBottom: 40
  },
  warningText: {
    color: '#AF994A',
    fontSize: 13,
    fontWeight: 400
  },
  warningWrapper: {
    marginTop: 48,
    marginBottom: 20,
    alignItems: 'center',
  }
}));
