import { Grid, Typography, lighten, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Confirmed from "../../assets/icons/kyc/verified_confirmed.svg";
import Pending from "../../assets/icons/kyc/verified_pending.svg";
import NotConfirmed from "../../assets/icons/kyc/not_verified.svg";
import { VERIFICATION_STATUS } from "../../utils/constants/kyc";
import { useSelector } from "react-redux";

const VerificationStatus = ({ disabled, isShort, className }) => {
  const kycStatus = useSelector(({ kyc }) => kyc?.verificationStatus);

  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();

  const goTo = patchName => {
    if (!disabled) {
      history.push(patchName);
    }
  };
  return (<Grid
    item
    xs={6}
    container
    className={className}
    justifyContent='flex-end'
    alignItems='center'>
    {kycStatus === VERIFICATION_STATUS.CONFIRMED ? (
      <div className={clsx(classes.confirmed, classes.widget, isShort ? classes.short : {})}>
        <img src={Confirmed} alt={"confirmed"} loading={"lazy"} />
        {!isShort ?
          <Typography className={classes.label} variant='body1' align='center'>
            {t("Verified")}
          </Typography>
          : null}
      </div>
    ) : kycStatus === VERIFICATION_STATUS.WAITING ? (
      <div className={clsx(classes.pending, classes.widget, isShort ? classes.short : {})}>
        <img src={Pending} alt={"pending"} loading={"lazy"} />
        {!isShort ?
          <Typography className={classes.label} variant='body1' align='center'>
            {t("Pending")}
          </Typography>
          : null}
      </div>
    ) : (
      <div
        className={clsx(classes.notConfirmed, classes.widget, isShort ? classes.short : {}, disabled ? '' : 'hover')}
        onClick={() => goTo("/kyc")}>
        <img
          src={NotConfirmed}
          alt={"not_confirmed"}
          loading={"lazy"}
        />
        {!isShort ?
          <Typography className={classes.label} variant='body1' align='center'>
            {t("Not verified")}
          </Typography>
          : null
        }
      </div>
    )}
  </Grid>)
}

export default VerificationStatus;

const useStyles = makeStyles(theme => ({
  widget: {
    display: "flex",
    alignItems: "center",
    padding: "6px 8px",
    borderRadius: 4,
    fontWeight: 400,

    "& img": {
      marginRight: 12,
      width: 19,
      height: 19,
    },
  },
  short: {
    minWidth: 'auto',
    "& img": {
      marginRight: 0,
    },
  },
  label: {
    fontSize: 14,
    textWrap: 'nowrap',
  },
  confirmed: {
    background: "#008C39",
  },
  pending: {
    background: "#F9B630",
  },
  notConfirmed: {
    background: "#2A2E3A",
    "&.hover:hover": {
      cursor: "pointer",
      backgroundColor: lighten("#2A2E3A", 0.4),
    },
  },
}));