import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const SlicedAddress = ({ address = "", short = false, showFullAddress = false }) => {
  const classes = useStyles();

  if (address && address.length < 16) {
    return address;
  }
  const startAddress = address?.slice(0, 6);
  const endAddress = address?.slice(
    [...address].length - 6,
    [...address].length
  );
  return (
    <span style={{ whiteSpace: "nowrap" }} title={address}>
      {
        !showFullAddress ?
          <>
            <span className={clsx(short && classes.start)}>{startAddress}</span>
            {"****"}
            {endAddress}
          </>
          : address
      }
    </span>
  );
};

export default SlicedAddress;

const useStyles = makeStyles(theme => ({
  start: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
