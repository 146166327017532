import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import { Grid, Paper } from "@material-ui/core";
import Search from "../../theme/components/Search";
import { useState, useMemo } from "react";
import { dialogListStyles } from "../../theme/dialogs/AddNewWalletDialog";
import { useSelector } from "react-redux";
import CoinName from "../../theme/components/CoinName";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const DialogFrom = ({ open, onClose, filteredData }) => {
  const classes = dialogListStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const currencies = useSelector(({ wallets }) => wallets.currencies);
  const getCurrencyTitle = ticker =>
    currencies?.find(item => item?.ticker === ticker)?.name;

  const data = useMemo(
    () => [...new Set(filteredData.map(i => i.assetFrom))],
    [filteredData]
  );

  return (
    <DialogWrapper open={open} handleClose={onClose} title={t('Select coin')}>
      <Grid container direction='column' justifyContent='center'>
        <Search
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
        />
      </Grid>
      <div className={classes.list}>
        {data
          .filter(ticker => ticker.toLowerCase().includes(search.toLowerCase()))
          .map(ticker => (
            <Paper
              elevation={4}
              variant={"outlined"}
              key={ticker}
              className={clsx(classes.currency, "hover")}
              onClick={() => onClose(ticker)}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}>
                <Grid item>
                  <CoinName name={getCurrencyTitle(ticker)} ticker={ticker} />
                </Grid>
                <Grid item>
                  <ChevronRightOutlinedIcon color={"action"} />
                </Grid>
              </Grid>
            </Paper>
          ))}
      </div>
    </DialogWrapper>
  );
};

export default DialogFrom;
