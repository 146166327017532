import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NotInterestedRoundedIcon from "@material-ui/icons/NotInterestedRounded";
import { makeStyles, TableContainer } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { roundValue } from "../../utils/functions/roundValue";
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";

const structure = [
  {
    key: "ticker",
    label: "Currency name",
  },
  {
    key: "balance",
    label: "Available",
  },
];

const MoneyboxBalancesTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { allTickerSettings } = useSelector(
    ({ wallets }) => wallets
  );
  const marketPrice = useSelector(({ market }) => market.data);
  const mobeyboxBalancesList = useSelector(moneyboxSelectors.moneyboxBalancesList)

  const data = useMemo(() => {
    if (mobeyboxBalancesList?.length === 0) {
      return;
    }

    return mobeyboxBalancesList
      .map(coin => {
        const coinMarketPrice = marketPrice.find(
          i => i?.ticker === coin?.currency
        );
        return {
          ...coin,
          price: coinMarketPrice?.price || 0,
          minScale: allTickerSettings?.find(
            setting => setting?.ticker === coin?.currency
          )?.minScale,
        };
      }).sort((a, b) => b?.balanceInUsd - a?.balanceInUsd);
  }, [mobeyboxBalancesList, marketPrice, allTickerSettings]);

  return (
    <TableContainer className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            {structure.map(({ key, label, minWidth, colSpan, align }) => (
              <th
                key={key}
                style={{ minWidth }}
                colSpan={colSpan}
                align={align || "left"}
                className={clsx(
                  classes.th,
                  classes.paper,
                  key === "change24" && classes.textRight
                )}>
                {t(label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {data?.length > 0 ? (
            data?.map(
              (row, index) => (
                <TableRow key={row?.id || index} rowData={row} />
              )
            )
          ) : (
            <tr>
              <td colSpan={6} align={"center"} height={100}>
                <NotInterestedRoundedIcon />
                <br />
                {t('The list is empty')}...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default MoneyboxBalancesTable;

function TableRow({ rowData }) {
  const classes = useStyles();
  const history = useHistory();

  const ticker = rowData?.currency;
  const moneyboxId = rowData?.moneyboxId;
  const balanceId = rowData?.balanceId

  const handleBalanceClick = useCallback(() => {
    history.push(`/moneybox/${moneyboxId}/${balanceId}`)
  }, [history, moneyboxId, balanceId])

  return (
    <>
      <tr className={classes.spacing}></tr>
      <tr className={classes.hover}>
        <td
          style={{ width: "50%" }}
          className={clsx(classes.td, classes.paper)}
          onClick={handleBalanceClick}>
          <div className={classes.currency}>
            <img
              src={`https://cryptoneed.com/icons/${ticker}.svg`}
              alt={ticker}
              width={32}
              height={32}
              loading={"lazy"}
              onClick={handleBalanceClick}
            />
            <div>
              <p>
                {ticker?.toUpperCase()}
              </p>
              <span className={classes.priceText}>$ {roundValue(rowData?.price, 2)}</span>
            </div>
          </div>
        </td>
        <td
          style={{ width: "50%" }}
          className={clsx(classes.td, classes.paper)}
          onClick={handleBalanceClick}>
          <p>
            {roundValue(rowData?.balance, rowData?.minScale)}{" "}
            {ticker?.toUpperCase()}
          </p>
          <span className={classes.priceText}>$ {roundValue(rowData?.balanceInUsd, 2)}</span>
        </td>
      </tr>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 24,
    background: theme.palette.primary.light,
    borderRadius: 20,
  },
  table: {
    borderSpacing: 0,
    width: "100%",
    minWidth: 430,
  },
  paper: {
  },
  textRight: {
    textAlign: "right",
  },
  th: {
    padding: "14px 18px",
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 400,
    lineHeight: "20px",
    "&.hover:hover": {
      cursor: "pointer",
    },
    "&>svg": {
      fontSize: 19,
      verticalAlign: "middle",
      marginRight: 4,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px",
      fontSize: 14,
    },
  },

  hover: {
    background: theme.palette.background.row,
    "&:hover > td": {
      borderColor: theme.palette.primary.main
    },
  },
  td: {
    borderStyle: 'solid none solid none',
    borderWidth: '0.05em',
    borderColor: theme.palette.background.row,
    "&:first-child": {
      borderRadius: "12px 0 0 12px",
      borderStyle: 'solid none solid solid',
    },
    "&:last-child": {
      borderRadius: "0 12px 12px 0",
      borderStyle: 'solid solid solid none',
    },
    color: theme.palette.text.primary,
    cursor: "pointer",
    padding: "10px 18px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&>p, & $currency p": {
      color: theme.palette.text.primary,
      fontSize: 15,
      lineHeight: "20px",
      margin: 0,
      fontWeight: 400,
    },
    "&>span, & $currency span": {
      color: theme.palette.text.darkLighter,
      fontSize: 13,
      fontWeight: 300,
      letterSpacing: '-0.3px',
      lineHeight: "20px",
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 18px",
      "&>p, & $currency p": {
        fontSize: 14,
      },
      "&>span, & $currency span": {
        fontSize: 10,
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "4px 12px",
      "&>p, & $currency p": {
        fontSize: 12,
      },
      "&>span, & $currency span": {
        fontSize: 10,
      },
    },
  },
  spacing: {
    height: 8,
  },
  currency: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "&>img": {
      marginRight: 12,
    },
  },
}));
