import { Button as MButton, withStyles } from "@material-ui/core";

export const Button = withStyles(theme => ({
  root: {
    borderRadius: 12,
    color: "#fff",
    fontWeight: 400,
    textTransform: "none",
    minHeight: 38,
    minWidth: 220,
    fontSize: 16,
    lineHeight: "24px",
    padding: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&.MuiButton-contained": {
      color: theme.palette.text.default,
      background: theme.palette.primary.light,
      border: `0.08em solid ${theme.palette.primary.main}`,
      padding: "12px 24px",

      "&:hover": {
        background: theme.palette.primary.light,
      },
    },

    "&.MuiButton-outlined": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
      padding: "3px 13px",
      "&:hover": {
        border: "1px solid rgba(255, 255, 255, 0.4)",
      },
    },

    "&.MuiButton-sizeSmall": {
      minHeight: 36,
    },
    "&.MuiButton-textPrimary:not(.Mui-disabled)": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
    },
    "&.MuiButton-textSecondary:not(.Mui-disabled)": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
    "&.MuiButton-textPrimary.Mui-disabled": {
      backgroundColor: "rgba(74, 175, 87, 0.5)",
    },
  },
}))(MButton);
