import {connect} from 'react-redux';
import {LinearProgress, withStyles} from "@material-ui/core";

const ColorLinearProgress = withStyles((theme) => ({
	colorPrimary: {
		backgroundColor: theme.palette.primary.main,
	},
	barColorPrimary: {
		backgroundColor: theme.palette.primary.light
	},
}))(LinearProgress);

const Loading = ({show}) => {
	return (
		<div style={{position: "fixed", top: 0, left: 0, right: 0, zIndex: 10}}>
			{
				show && <ColorLinearProgress/>
			}
		</div>
	);
};

const mapStateToProps = ({loading}) => ({
	show: loading.show
});

export default connect(mapStateToProps)(Loading);
