import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import iconPassport from "../../../../assets/icons/kyc/passport.svg";
import iconIdCard from "../../../../assets/icons/kyc/id_card.svg";
import iconDriverLicense from "../../../../assets/icons/kyc/driver_license.svg";
import { DOCUMENT_TYPES } from "../../../../utils/constants/kyc";

const DocumentTypes = ({ selectDocumentType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSelect = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    selectDocumentType(type);
  };

  return (
    <Grid container >
      <Grid item container>
        <Typography
          variant='body1'
          className={classes.title}
          align='flex-start'>
          {t("Choose document")}
        </Typography>
      </Grid>
      <Grid item container direction="row" className={classes.boxIcons}>
        <Grid
          item
          container
          sm={3}
          onClick={e => handleSelect(e, DOCUMENT_TYPES.PASSPORT)}
          className={classes.iconWrap}>
          <img
            src={iconPassport}
            alt='passport'
            className={classes.icon}
          />
          <Typography
            variant='subtitle1'
            className={classes.subtitle}
            color='textPrimary'>
            {t("Passport")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm={3}
          onClick={e => handleSelect(e, DOCUMENT_TYPES.ID_CARD)}
          className={classes.iconWrap}>
          <img
            src={iconIdCard}
            alt='id_card'
            className={classes.icon}
          />
          <Typography
            variant='subtitle1'
            className={classes.subtitle}
            color='textPrimary'>
            {t("Identity card")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm={3}
          onClick={e => handleSelect(e, DOCUMENT_TYPES.DRIVER_LICENSE)}
          className={classes.iconWrap}>
          <img
            src={iconDriverLicense}
            alt='driver_license'
            name={"DriverLicense"}
            className={classes.icon}
          />
          <Typography
            variant='subtitle1'
            className={classes.subtitle}
            color='textPrimary'>
            {t("Driving license")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentTypes;
const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    width: '50%',
    borderBottom: `0.02rem solid ${theme.palette.secondary.disabled}`,
    paddingBottom: 8,
  },
  subtitle: {
    fontWeight: 400,
    flex: 1,
    fontStyle: "normal",
    lineHeight: "16px",
    whiteSpace: "pre-line",
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  boxIcons: {
    justifyContent: "space-around",
    flexWrap: 'nowrap',
    marginTop: 60,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    width: 50,
    height: 50,
    padding: 6,
    borderRadius: 10,
    background: theme.palette.primary.default,
  },
  iconWrap: {
    flexDirection: 'row',
    padding: 10,
    border: `1.5px solid ${theme.palette.primary.default}`,
    borderRadius: 20,
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
    "&:hover > img": {
      background: theme.palette.primary.main,
    },
    "&:hover > h6": {
      color: theme.palette.primary.main,
    },
  }
}));
