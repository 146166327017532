import axios from "axios";
import * as R from "ramda";
import { BASE_URL, AUTH_TOKEN } from "../utils/constants/api";
import { store } from "../redux/store/store";
import { logOut } from "../redux/actions/accountActions";

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    // Handle request process
    this.client.interceptors.request.use(
      async (config) => {
        if (localStorage.getItem("access_token")) {
          return R.assocPath(
            ["headers", "common", "Authorization"],
            `Bearer ${localStorage.getItem("access_token")}`,
            config
          );
        } else {
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Handle response process
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.error_description?.includes(
            "Invalid refresh token"
          )
        ) {
          store.dispatch(logOut(true));
        }
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;
          const refreshToken = localStorage.getItem("refresh_token");
          return new Promise(function (resolve, reject) {
            let formData = new FormData();
            formData.append("refresh_token", refreshToken);
            formData.append("grant_type", "refresh_token");
            const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
              "base64"
            );
            const headers = { Authorization: "Basic " + authToken };

            axios
              .post(`/api/accounts/oauth/token`, formData, {
                headers,
              })
              .then(({ data }) => {
                const access_token = data.access_token;
                const refresh_token = data.refresh_token;
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("refresh_token", refresh_token);
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${access_token}`;
                originalRequest.headers[
                  "Authorization"
                ] = `Bearer ${access_token}`;
                processQueue(null, access_token);
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
                store.dispatch(logOut(true));
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        return Promise.reject(error);
      }
    );
  }

  account = {
    register: (data) => this.client.post(`/api/accounts/register`, data),
    activate: (params) => this.client.get(`/api/accounts/activate`, { params }),
    login: (data, headers) =>
      this.client.post(`/api/accounts/oauth/token`, data, { headers }),
    refreshToken: (data, headers) =>
      this.client.post(`/api/accounts/oauth/token`, data, { headers }),
    loginGoogle: (data) => this.client.post(`/auth/google/signin`, data),
    loginApple: (data) => this.client.post(`/auth/apple/signin`, data),
    enableTwoFA: () => this.client.get(`/api/accounts/twofa/enable`),
    disableTwoFA: () => this.client.get(`/api/accounts/twofa/disable`),
    checkIsTwoFA: () => this.client.get(`/api/accounts/twofa/check-is`),
    checkCodeTwoFA: (data) =>
      this.client.post(`/api/accounts/twofa/check-code`, data),
    checkInstallTwoFA: (data) =>
      this.client.post(`/api/accounts/twofa/confirm`, data),
    logOut: () => this.client.post(`/api/accounts/current/logout`),
    getData: () => this.client.get(`/api/accounts/account/account`),
    permissionInfo: () => this.client.get(`/api/accounts/account/info`),
    update: (data) => this.client.patch(`/api/accounts/account`, data),
    initResetPassword: (data) =>
      this.client.post(`/api/accounts/account/reset-password/init`, data),
    finishResetPassword: (data) =>
      this.client.post(`/api/accounts/account/reset-password/finish`, data),
    changePassword: (data) =>
      this.client.post(`/api/accounts/account/change-password`, data),
  };

  wallets = {
    getList: (params) => this.client.get(`/api/finance/wallets`, { params }),
    getCurrencies: (params) =>
      this.client.get(`/api/finance/currencies`, { params }),
    addNew: (data) => this.client.post(`/api/finance/wallets/new`, data),
    withdraw: (data) => this.client.post(`/api/finance/send`, data),
    getWalletFee: (ticker) =>
      this.client.get(`/api/finance/wallets/fees`, { params: { ticker } }),
    checkInternalAddress: (ticker, address) =>
      this.client.get(`/api/finance/address/validate/${ticker}/${address}`),
    getAllTickerSettings: () =>
      this.client.get(`/api/finance/currencies/settings`),
    getTickerSettings: (ticker) =>
      this.client.get(`/api/finance/currencies/${ticker}/settings`),
    getSettingsList: () => this.client.get(`/api/finance/wallets/settings`),
    updateSettingsWallet: (data) =>
      this.client.patch(`/api/finance/wallets/settings`, data),
    receiveFiat: (data) => this.client.post(`/api/finance/invoice`, data),
  };

  transactions = {
    getList: (params) =>
      this.client.get(`/api/finance/transactions/history`, { params }),
    getListByTicker: (ticker, params) =>
      this.client.get(`/api/finance/transactions/${ticker}`, { params }),
  };

  addresses = {
    getList: (ticker) => this.client.get(`/api/finance/addresses/${ticker}`),
    addNew: (ticker, data) =>
      this.client.post(`/api/finance/addresses/${ticker}`, data),
    deleteById: (address_id) =>
      this.client.delete(`/api/finance/address/${address_id}`),
  };

  exchange = {
    getMarketList: () => this.client.get(`/api/exchange/market`),
    create: (data) => this.client.post(`/api/exchange/order/new`, data),
    getOrders: (params) =>
      this.client.get(`/api/exchange/order/list`, { params }),

    getExchangeMarketDetails: (market) =>
      this.client.get(`/api/exchange/market/${market}`),
  };

  deviceLogs = {
    getList: (params) =>
      this.client.get(`/api/userservice/device/log`, { params }),
  };

  market = {
    getMarketList: () => this.client.get(`/api/finance/wallets/balance/usd`),
  };
  bankCards = {
    getBankCardsList: () => this.client.get(`/api/userservice/bank_card`),
    createBankCard: (data) => this.client.post('/api/userservice/bank_card', data),
    setDefaultCard: (cardId) => this.client.patch(`/api/userservice/bank_card/${cardId}/make_default`),
    getBankCardById: (cardId) => this.client.get(`/api/userservice/bank_card/${cardId}`),
    deleteBankCard: (cardId) => this.client.delete(`/api/userservice/bank_card/${cardId}`)
  }

  kyc = {
    getKycStatus: () => this.client.get(`/api/userservice/kyc/status`),
    kycVerify: (data) => this.client.post(`/api/userservice/kyc`, data),
  };

  referral = {
    getReferralInfo: () => this.client.get(`/api/referral/info`),
    getReferralBalances: () => this.client.get(`/api/referral/balance`),
    getReferralBalancesByAsset: (asset) =>
      this.client.get(`/api/referral/balance/${asset}`),
    claimFundsFromReferralBalance: (data) =>
      this.client.post(`/api/referral/claim`, data),
    getClaimHistory: (params) =>
      this.client.get(`/api/referral/claim/history`, { params }),
    getClaimHistoryByAsset: (asset, params) =>
      this.client.get(`/api/referral/claim/history/${asset}`, { params }),
    getReferralRewards: (params) =>
      this.client.get(`/api/referral/rewards`, { params }),
    getReferralRewardsByAsset: (asset, params) =>
      this.client.get(`/api/referral/rewards/${asset}`, { params }),
  };

  autoInvest = {
    createAutoInvestPlan: (data) =>
      this.client.post(`/api/investment/program/auto_investment`, data),
    updateAutoInvestPlan: (data) => this.client.put(`/api/investment/program/auto_investment`, data),
    changeAutoInvestPlanStatus: (data) => this.client.patch(`/api/investment/program/auto_investment`, data),
    deleteAutoInvestPlanById: (planId) => this.client.delete(`/api/investment/program/auto_investment/${planId}`),
    getAutoInvestPlanById: (planId) => this.client.get(`/api/investment/program/auto_investment/${planId}`),
    getAllAutoInvestPlans: () => this.client.get(`/api/investment/program/auto_investment`),
  };
  moneybox = {
    getMoneyboxList: (params) => this.client.get(`/api/finance/moneybox`, { params }),
    createMoneybox: (data) => this.client.post(`/api/finance/moneybox`, data),
    updateMoneybox: (data) => this.client.put(`/api/finance/moneybox`, data),
    getMoneyboxById: (moneyboxId) => this.client.get(`/api/finance/moneybox/${moneyboxId}`),
    deleteMoneybox: (moneyboxId) => this.client.delete(`/api/finance/management/moneybox/${moneyboxId}`),
    createMoneyboxBalance: (data) => this.client.post(`/api/finance/moneybox/balance`, data),
    withdrawMoneyboxBalance: (data) => this.client.post(`/api/finance/moneybox/balance/transfer/withdraw`, data),
    depositMoneyboxBalance: (data) => this.client.post(`/api/finance/moneybox/balance/transfer/deposit`, data),
    getMoneyboxBalanceById: (balanceId) => this.client.get(`/api/finance/moneybox/balance/${balanceId}`),
    getMoneyboxBalancesList: (moneyboxId) => this.client.get(`/api/finance/moneybox/balances/${moneyboxId}`),
    getMoneyboxBalanceHistory: ({ balanceId, params }) => this.client.get(`/api/finance/moneybox/balance/transfer/${balanceId}`, { params })
  };
  companies = {
    getCompaniesList: () => this.client.get(`/api/finance/company/balance`),
    transferCompanyBalance: (data) => this.client.post(`/api/finance/company/balance/transfer`, data),
    getCompanyBalanceTransferHistory: ({ balanceId, params }) => this.client.get(`/api/finance/company/balance/transfer/history/${balanceId}`, { params }),
    getCompanyBalanceTransferInvoiceById: (invoiceId) => this.client.get(`/api/finance/company/balance/transfer/invoice/${invoiceId}`),
    makeDecisionByCompanyBalanceTransferInvoice: (data) => this.client.post(`/api/finance/company/balance/transfer/invoice`, data),
  }
}

const api = new Api();

export default api;
