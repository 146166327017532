import {
  FormControl,
  makeStyles,
  Select,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import propTypes from "prop-types";

export const SelectInput = ({
  children,
  label,
  helperText,
  error,
  ...props
}) => {
  const cls = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={cls.root}
      error={error || Boolean(helperText)}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label}>
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  value: propTypes.any,
  onChange: propTypes.func.isRequired,
  label: propTypes.string.isRequired,
  style: propTypes.object,
  helperText: propTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "&.MuiMenu-paper": { maxHeight: 200 },
    "& label, & label.Mui-focused:not(.Mui-error)": {
      color: "#707594",
      marginLeft: 8,
    },
    "& legend": {
      maxWidth: 0,
    },
    "& .MuiSelect-icon": {
      color: "#707594",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 8px) scale(0.75)",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#707594",
    },

    "& .MuiFormLabel-root.Mui-disabled ": {
      color: "#707594",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      marginTop: 56,
      fontFamily: "SF Pro Text, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "24px",
      marginRight: "0px !important",
      marginLeft: "0px !important",
    },
    "& .MuiSelect-selectMenu": {
      padding: "24px 0px 10px 22px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#FFFFFF",
      color: "#000000",
      fontSize: 20,
      fontWeight: 400,
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "&>input": {
        "& .MuiOutlinedInput-adornedEnd": {
          background: "#FFFFFF",
          color: "#000000",
        },
      },
      "&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
        {
          borderColor: "#FFFFFF",
        },
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
