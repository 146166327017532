import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoneyboxIcon } from '../../assets/images/moneybox_icon.svg'
import { ReactComponent as AutoInvestIcon } from '../../assets/images/auto_invest_icon.svg'
import { ReactComponent as CompanyIcon } from '../../assets/images/company_icon.svg'

import PageWrapper from "../../theme/PageWrapper";
import Breadcrumb from "../../theme/components/Breadcrumb";
import { useCallback } from "react";
import clsx from "clsx";

const PROJECTS_LIST = [
  {
    title: 'Moneybox',
    description: 'This is your moneybox that has its own address in any cryptocurrency that Cryptoneed supports. Set aside your crypto assets for any purpose you can think of.',
    icon: MoneyboxIcon,
    redirectLink: '/moneybox',
  },
  {
    title: 'AUTO INVEST',
    description: 'Auto-Invest allows you to automate crypto investments and grow your crypto holdings. It is a dollar-cost averaging (DCA) strategy. You can choose the cryptocurrencies you want to purchase on a regular basis.',
    icon: AutoInvestIcon,
    redirectLink: '/auto_invest'
  },
  {
    title: 'Company',
    description: 'An analogue of a bank account only in cryptocurrencies. Provide account access to your employees. Write comments on transactions. Appoint people who have the right to sign for the withdrawal of crypto assets.',
    icon: CompanyIcon,
    redirectLink: '/companies'
  },
]

const Projects = () => {
  const { t } = useTranslation();
  const classes = useTransactionsStyles();
  const history = useHistory();

  const handleProjectClick = useCallback((project) => {
    if (project.redirectLink) {
      history.push(project.redirectLink)
    }
  }, [history])
  return (
    <PageWrapper>
      <Grid container item xs={12} md={12} className={classes.breadcrumb} direction="row">
        <Breadcrumb to={"/"} pageName={"Main"} down={0} />
        <Typography variant={"h2"} color={"textPrimary"} className={classes.breadcrumbTitle}>
          {t('Projects')}
        </Typography>
      </Grid>
      <Grid spacing={4} container direction="row" justifyContent='flex-start'>
        {
          PROJECTS_LIST.map((project) => {
            const IconComponent = project.icon
            return (
              <Grid key={project.title} item lg={4} md={4} sm={6} xs={12}>
                <Paper className={clsx(classes.card)} onClick={() => handleProjectClick(project)} elevation={4}>
                  <Grid className={classes.header} container justifyContent="center">
                    <IconComponent className={classes.headerIcon} />
                    <Typography variant={"h3"} color={"textPrimary"} className={classes.titleText}>
                      {t(project.title)}
                    </Typography>
                    <div className={classes.emptyHeaderItem} />
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid className={classes.content} container justifyContent="center">
                    <Typography variant={"body2"} color={"textPrimary"} className={classes.descriptionText}>
                      {t(project.description)}
                    </Typography>
                    <Divider />
                  </Grid>
                </Paper>
              </Grid>
            )
          })
        }
      </Grid>
    </PageWrapper>
  );
};

export default Projects;


export const useTransactionsStyles = makeStyles(theme => ({
  root: {
    marginBottom: 12,
    padding: "4px 0",
    border: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down("sm")]: {
      // padding: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px 0px 8px",
      marginBottom: 16,
    },
  },
  header: {
    height: 60,
    alignItems: 'center',
    gap: 20,
    justifyContent: 'space-around',
    marginBottom: 24,
    flexWrap: 'nowrap',
  },
  headerIcon: {
    [theme.breakpoints.down("lg")]: {
      width: 60
    },
    [theme.breakpoints.down("md")]: {
      width: 50
    },
    minWidth: 30
  },
  card: {
    background: theme.palette.background.paper,
    padding: '32px 24px',
    marginTop: 0,
    cursor: 'pointer',
    height: '100%',
  },
  disabled: {
    cursor: 'not-allowed'
  },
  breadcrumb: {
    paddingBottom: 32,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 16,
    },
  },
  breadcrumbTitle: {
    textTransform: 'uppercase',
    marginLeft: 20
  },
  titleText: {
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  content: {
    padding: 10,
    paddingTop: 24,
  },
  descriptionText: {

  },
  divider: {
    width: 'calc(100% + 48px)',
    marginLeft: -24
  },
  emptyHeaderItem: {
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
    [theme.breakpoints.down("700")]: {
      display: 'none'
    },
    [theme.breakpoints.down("sm")]: {
      display: 'block'
    },
  }
}));
