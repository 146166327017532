import api from "../../services/api";
import { logOut } from "../actions/accountActions";

export default function apiMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (action?.type?.endsWith("FAILURE")) {
      const res = action?.payload?.error?.response;
      if (!res) {
        return;
      }
      if (
        res?.status === 403 &&
        res?.data?.error_description ===
          "Could not verify the provided CSRF token because your session was not found."
      ) {
        api.client[res?.config.method](res?.config.url, res?.config?.data)
          .then(({ data }) =>
            dispatch({
              type: action?.type?.replace("FAILURE", "SUCCESS"),
              payload: { data },
            })
          )
          .catch((error) =>
            dispatch({
              type: action.type,
              payload: { error },
            })
          );
      } else if (res?.status === 403) {
        dispatch(logOut);
      } else {
        next(action);
      }
    } else {
      next(action);
    }
  };
}
