import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  lighten,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import {
  getCurrencies,
} from "../../redux/actions/walletsActions";
import CoinName from "../../theme/components/CoinName";
import { Input } from "../../theme/components/Input";
import { useValidateForm } from "../../utils/hooks/useValidateForm";
import { newCompanyValidator } from "../../utils/functions/customValidators/validators";
import { Button } from "../../theme/components/Button";
import PlusIcon from '../../assets/icons/plus.svg'
import { isLoading, isSucceeded } from "../../utils/functions/requestStatuses";
import { usePrevious } from "../../utils/hooks/usePrevious";
import SelectWalletDialog from "../../theme/dialogs/SelectWalletDialog";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import { addNewCompany } from "../../redux/actions/companiesActions";


const initialForm = {
  name: "",
  shortName: "",
  description: ""
};

const CreateCompanyDialog = ({ open, onSubmit, onClose, onSubmitSuccess }) => {
  const classes = dialogListStyles();
  const dispatch = useDispatch();
  const [showSelectCoinsModal, setShowSelectCoinsModal] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [form, setForm] = useState(initialForm);
  const { validateForm, validateInput, errors } = useValidateForm({ form, customValidator: newCompanyValidator });
  const addNewCompanyRequestStatus = useSelector(companiesSelectors.addNewCompanyRequestStatus);
  const prevAddNewCompanyRequestStatus = usePrevious(addNewCompanyRequestStatus);
  const user = useSelector(({ account }) => account.data);

  const isLoaderNeeded = isLoading(addNewCompanyRequestStatus)

  const handleCloseModal = useCallback(() => {
    onClose();
    setSelectedCoins([]);
    setForm(initialForm)
  }, [onClose])

  const { currencies, params } = useSelector(
    ({ wallets }) => wallets
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoading(prevAddNewCompanyRequestStatus) && isSucceeded(addNewCompanyRequestStatus)) {
      handleCloseModal()
      onSubmitSuccess()
    }
  }, [addNewCompanyRequestStatus, handleCloseModal, onSubmitSuccess, prevAddNewCompanyRequestStatus])

  useEffect(() => {
    if (open && currencies.length === 0) {
      dispatch(getCurrencies(params));
    }
  }, [dispatch, open, currencies, params]);

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  }, [form])

  const handleSubmit = useCallback(async () => {
    if (!validateForm()) {
      return;
    }
    // create company
    dispatch(addNewCompany({
      companyName: form.name,
      shortName: form.shortName,
      description: form.description,
      selectedCoins: selectedCoins,
      accountEmail: user?.email,
      accountId: user?.id,
      accountLogin: user?.login,
    }))
  }, [dispatch, form, selectedCoins, user, validateForm])


  const coinsList = useMemo(
    () =>
      currencies?.filter(currency => {
        return !selectedCoins?.some(
          selectedCoin => selectedCoin === currency?.ticker,
        );
      }).map((coin) => coin.ticker),
    [currencies, selectedCoins],
  );

  const showAddCoinButton = useMemo(() => {
    return Boolean(coinsList.length);
  }, [coinsList]);

  const renderSelectedCoins = useCallback(() => {
    return (
      <Grid direction="column" container className={classes.coinsContainer}>
        <Grid className={classes.coinsListHeader} direction="row" container alignItems="center" justifyContent="space-between">
          <Typography variant="body1" display="inline">{t('Selected coins')}</Typography>
          {
            showAddCoinButton &&
            <IconButton
              onClick={() => setShowSelectCoinsModal(true)}>
              <img className={classes.addIcon} src={PlusIcon} alt='plus_icon' />
            </IconButton>
          }
        </Grid>
        <Grid direction="row" container gap={5} className={classes.coinsListContent}>
          {!selectedCoins.length && <Typography className={classes.emptyList} align="center" variant="body2" color="textSecondary">{t('Empty list')}</Typography>}
          {selectedCoins.map((coinTicker, index) => {
            return (
              <Box key={index} className={classes.coinIcon}>
                <CoinName
                  showOnlyIcon
                  ticker={coinTicker}
                  classNaem
                />
              </Box>
            )
          })}
        </Grid>
      </Grid>
    )
  }, [classes, selectedCoins, showAddCoinButton, t])

  const handleSelectBalanceCoinModal = useCallback((coin) => {
    if (coin?.ticker) {
      setSelectedCoins([...new Set([...selectedCoins, coin.ticker])])
    }
    setShowSelectCoinsModal(false);
  }, [selectedCoins])

  return (
    <DialogWrapper
      open={open}
      handleClose={handleCloseModal}
      title={t("Create company")}
    >
      <SelectWalletDialog
        open={showSelectCoinsModal}
        visibleCurrenciesListTickers={coinsList}
        onSelect={handleSelectBalanceCoinModal}
        onClose={() => setShowSelectCoinsModal(false)}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Input
            label={t("Company Name")}
            placeholder={t("Company Name")}
            variant="outlined"
            className={classes.input}
            name={"name"}
            value={form?.name || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.name?.invalid}
            helperText={t(errors?.name?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("Short Name")}
            placeholder={t("Short Name")}
            variant="outlined"
            className={classes.input}
            name={"shortName"}
            value={form?.shortName || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.shortName?.invalid}
            helperText={t(errors?.shortName?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        {renderSelectedCoins()}
        <Grid item xs={12}>
          <Input
            label={t("Description")}
            placeholder={t("Description")}
            variant="outlined"
            className={classes.input}
            name={"description"}
            value={form?.description || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.description?.invalid}
            helperText={t(errors?.description?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={isLoaderNeeded || !selectedCoins?.length}
            onClick={handleSubmit}
          >
            {t("Create")}
          </Button>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default CreateCompanyDialog;

export const dialogListStyles = makeStyles((theme) => ({
  input: {
    // marginBottom: 32,
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.light,
        fontSize: 14,
        marginRight: 16,
        cursor: "pointer",
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
          marginRight: 12,
        },
      },
    },
  },
  coinsListHeader: {
    paddingLeft: 24,
    paddingRight: 8
  },
  coinsListContent: {
    paddingLeft: 8,
    paddingRight: 8,
    overflowX: 'scroll',
    flexWrap: 'nowrap',
    "&::-webkit-scrollbar": {
      display: 'none',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    }
  },
  emptyList: {
    margin: '0 auto',
  },
  addIcon: {
    width: 24
  },
  coinIcon: {
    cursor: 'pointer',
    padding: 8,
  },
  coinsContainer: {
    minHeight: 88,
  },
  list: {
    maxHeight: "calc(100vh - 320px)",
    overflowY: "auto",
  },
  currency: {
    padding: "12px 0px",
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
    "&.hover:hover": {
      backgroundColor: lighten(theme.palette.background.paper, 0.05),
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: 400,
  },
  button: {
    marginTop: 8,
  },
}));
