import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeWithdrawalMethodDialog } from "../../../redux/actions/dialogActions";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import DialogWrapper from "../../../theme/dialogs/DialogWrapper";
import {
  clearAddresses,
  getAddresses,
} from "../../../redux/actions/addressesActions";
import {
  setIsWithdrawalCard,
  getTickerSettings,
} from "../../../redux/actions/walletsActions";
import wallets from "../../../assets/qr_code_app.svg";
import cards from "../../../assets/bank_cards.svg";

const WithdrawalMethodDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedWallet } = useSelector(
    ({ dialogs }) => dialogs.withdrawalMethod
  );
  const address = useSelector(({ addresses }) => addresses.data[0]);

  useEffect(() => {
    if (selectedWallet && address?.ticker !== selectedWallet?.ticker) {
      dispatch(getAddresses(selectedWallet?.ticker));
      dispatch(getTickerSettings(selectedWallet?.ticker));
    }
  }, [dispatch, selectedWallet, address]);

  useEffect(() => {
    return () => {
      dispatch(clearAddresses());
    };
  }, [dispatch]);

  const handleCloseDialog = () => {
    dispatch(setIsWithdrawalCard(false));
    dispatch(closeWithdrawalMethodDialog());
  };

  const handleOpenWithdrawalCard = () => {
    dispatch(setIsWithdrawalCard(true));
    dispatch(closeWithdrawalMethodDialog());
  };

  return (
    <DialogWrapper
      open={Boolean(selectedWallet)}
      handleClose={handleCloseDialog}
      title={t('Withdrawal method')}>
      <Grid
        container
        className={classes.box}
        direction='row'
        alignItems='center'
        onClick={handleCloseDialog}>
        <Grid item>
          <img src={wallets} alt='wallets' />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant={"subtitle1"} color={"textPrimary"} align='left'>
            {t("Withdrawal to wallet address")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.box}
        direction='row'
        alignItems='center'
        onClick={handleOpenWithdrawalCard}>
        <Grid item>
          <img src={cards} alt='wallets' />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant={"subtitle1"} color={"textPrimary"} align='left'>
            {t("Withdrawal to a bank card")}
          </Typography>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default WithdrawalMethodDialog;

const useStyles = makeStyles(theme => ({
  box: {
    background: "rgba(159, 169, 189, 0.2)",
    borderRadius: 12,
    padding: "10px 16px",
    color: theme.palette.text.primary,
    marginBottom: 24,
    "&:hover": {
      backgroundColor: "rgba(159, 169, 189, 0.4)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
    "& img": {
      marginRight: 16,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));
