const moneyboxList = state => state.moneybox.moneyboxList;
const updateMoneyboxRequestStatus = state => state.moneybox.updateMoneyboxRequestStatus;
const moneyboxBalancesList = state => state.moneybox.moneyboxBalancesList;

// BALANCE HISTORY ITEM INTERFACE
// interface MoneyboxBalanceTransaction {
//   id: number;
//   address: string;
//   balanceId: number;
//   targetUserId: string;
//   transactionType: MoneyboxBalanceTransactionType;
//   amount: number;
//   message?: string;
//   time: string;
// }
const moneyboxBalanceHistory = state => state.moneybox.moneyboxBalanceHistory;

const updateMoneyboxBalanceRequestStatus = state => state.moneybox.updateMoneyboxBalanceRequestStatus
const selectedMoneyboxBalance = state => state.moneybox.selectedMoneyboxBalance;
const selectedMoneybox = state => state.moneybox.selectedMoneybox;
const withdrawMoneyboxBalanceRequestStatus = state => state.moneybox.withdrawMoneyboxBalanceRequestStatus;

export const moneyboxSelectors = {
  moneyboxList,
  updateMoneyboxRequestStatus,
  moneyboxBalancesList,
  moneyboxBalanceHistory,
  updateMoneyboxBalanceRequestStatus,
  selectedMoneyboxBalance,
  selectedMoneybox,
  withdrawMoneyboxBalanceRequestStatus,
};
