import { useEffect, useState } from "react";
import clsx from "clsx";
import { Grid, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { validateInput } from "../../../../utils/functions/customValidators/validators";

const initialForm = {
    cardName: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    iban: "",
    itn: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerMiddleName: "",
    phoneNumber: "",
};

const IBAN_UKRAINE_PREFIX = 'UA'

const CreateCardForm = ({ onSubmitForm, defaultData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [form, setForm] = useState(defaultData ? defaultData : initialForm);
    const [errors, setErrors] = useState(null);

    const handleOnChange = ({ target: { name, value } }) => {
        setForm({ ...form, [name]: value.trim() });
    };

    const handleOnChangeDate = ({ target: { name, value } }) => {
        let result = value.trim()
        if (name === 'expirationMonth') {
            if (value > 12) {
                result = 12
            }
            if (value.length > 2) {
                result = value.slice(0, 2);
            }
        }
        if (name === 'expirationYear') {
            if (value.length > 2) {
                result = value.slice(0, 2);
            }
        }
        setForm({ ...form, [name]: result });
    };

    const handleInputValidation = (event) => {
        const { name, value } = event.target;
        setErrors({
            ...errors,
            [name]: validateInput(name, value, form),
        });
    };

    const handleChangeCard = ({ nativeEvent: { data }, target: { value } }) => {
        setErrors({ ...errors, cardNumber: "" });
        if (!data) {
            setForm({ ...form, cardNumber: value });
        }
        const card = value.replaceAll(" ", "");
        if (/^[0-9]*$/.test(card) && card.length < 17) {
            if (card.length <= 4) {
                setForm({ ...form, cardNumber: card });
            }
            if (card.length > 4 && card.length <= 8) {
                setForm({
                    ...form,
                    cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)}`,
                });
            }
            if (card.length > 8 && card.length <= 12) {
                setForm({
                    ...form,
                    cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)} ${card.slice(
                        8,
                        12
                    )}`,
                });
            }
            if (card.length > 12 && card.length <= 16) {
                setForm({
                    ...form,
                    cardNumber: `${card.slice(0, 4)} ${card.slice(4, 8)} ${card.slice(
                        8,
                        12
                    )} ${card.slice(12, 16)}`,
                });
            }
        }
    };

    const handleIbanChange = ({ nativeEvent: { data }, target: { value } }) => {
        setErrors({ ...errors, iban: "" });
        const ibanValue = value.replaceAll(" ", "");
        let formatedIbanValue = ibanValue;
        // only UA iban is allowed at the moment, to add all countriea just remove code below and use isIbanValid method from validators to validate form value
        if (/^UA/i.test(ibanValue)) {
            formatedIbanValue = ibanValue.substring(2);
        }
        if (!/^[0-9]*$/.test(formatedIbanValue)) {
            return;
        }
        setForm({
            ...form,
            iban: formatedIbanValue,
        })
    }

    const validateForm = () => {
        let valid = true;
        let errForm = {};
        Object.keys(form).forEach((key) => {
            errForm = {
                ...errForm,
                [key]: validateInput(key, form[key], form),
            };
            errForm[key].invalid && (valid = false);
        });
        setErrors(errForm);
        return valid;
    };

    const handleCreateCard = () => {
        if (!validateForm()) {
            return;
        }
        const formData = { ...form, cardNumber: form.cardNumber.replaceAll(" ", ""), iban: IBAN_UKRAINE_PREFIX + form.iban }
        onSubmitForm(formData);
        setForm(initialForm);
        setErrors(null);
    };

    useEffect(() => {
        return () => {
            setErrors();
            setForm(initialForm)
        }
    }, [])

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Input
                    label={t("Card Name")}
                    placeholder={t("Card Name")}
                    variant="outlined"
                    className={classes.input}
                    autoFocus
                    name={"cardName"}
                    value={form?.cardName}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.cardName?.invalid}
                    helperText={t(errors?.cardName?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    className={clsx(classes.input, classes.card)}
                    label={t(`Card Number`)}
                    variant="outlined"
                    placeholder={t("Card Number")}
                    name={"cardNumber"}
                    value={form?.cardNumber}
                    onChange={handleChangeCard}
                    onBlur={handleInputValidation}
                    error={errors?.cardNumber?.invalid}
                    helperText={t(errors?.cardNumber?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Input
                    label={t("MM")}
                    placeholder={t("MM")}
                    variant="outlined"
                    className={clsx(classes.input, classes.inputNumber)}
                    type="number"
                    name={"expirationMonth"}
                    value={form?.expirationMonth || ""}
                    onChange={handleOnChangeDate}
                    onBlur={handleInputValidation}
                    error={errors?.expirationMonth?.invalid}
                    helperText={t(errors?.expirationMonth?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Input
                    label={t("YY")}
                    placeholder={t("YY")}
                    variant="outlined"
                    className={clsx(classes.input, classes.inputNumber)}
                    type="number"
                    name={"expirationYear"}
                    value={form?.expirationYear || ""}
                    onChange={handleOnChangeDate}
                    onBlur={handleInputValidation}
                    error={errors?.expirationYear?.invalid}
                    helperText={t(errors?.expirationYear?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("IBAN")}
                    placeholder={t("IBAN")}
                    variant="outlined"
                    className={clsx(classes.input, classes.inputIBAN)}
                    name={"iban"}
                    value={form?.iban || ""}
                    onChange={handleIbanChange}
                    onBlur={handleInputValidation}
                    error={errors?.iban?.invalid}
                    helperText={t(errors?.iban?.errorMessage)}
                    style={{ background: 'white' }}
                    InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                            <InputAdornment position='start' >
                                <Typography className={classes.inputIBANStartAdornment}>{IBAN_UKRAINE_PREFIX}</Typography>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("ITN")}
                    placeholder={t("ITN")}
                    variant="outlined"
                    className={classes.input}
                    name={"itn"}
                    value={form?.itn || ""}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.itn?.invalid}
                    helperText={t(errors?.itn?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("First Name")}
                    placeholder={t("First Name")}
                    variant="outlined"
                    className={classes.input}
                    name={"ownerFirstName"}
                    value={form?.ownerFirstName || ""}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.ownerFirstName?.invalid}
                    helperText={t(errors?.ownerFirstName?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("Patronymic")}
                    placeholder={t("Patronymic")}
                    variant="outlined"
                    className={classes.input}
                    name={"ownerMiddleName"}
                    value={form?.ownerMiddleName || ""}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.ownerMiddleName?.invalid}
                    helperText={t(errors?.ownerMiddleName?.errorMessage)}
                    inputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("Last Name")}
                    placeholder={t("Last Name")}
                    variant="outlined"
                    className={classes.input}
                    name={"ownerLastName"}
                    value={form?.ownerLastName || ""}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.ownerLastName?.invalid}
                    helperText={t(errors?.ownerLastName?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label={t("Telephone Number")}
                    placeholder={t("Telephone Number")}
                    variant="outlined"
                    className={classes.input}
                    name={"phoneNumber"}
                    value={form?.phoneNumber || ""}
                    onChange={handleOnChange}
                    onBlur={handleInputValidation}
                    error={errors?.phoneNumber?.invalid}
                    helperText={t(errors?.phoneNumber?.errorMessage)}
                    InputProps={{
                        autoComplete: "off",
                    }}
                />
            </Grid>

            <Grid item xs={12} container justifyContent="center">
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleCreateCard}
                >
                    {t("Next")}
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreateCardForm;

const useStyles = makeStyles((theme) => ({
    root: {
    },
    input: {
        // marginBottom: 32,
        "&>.MuiOutlinedInput-adornedEnd": {
            paddingRight: 0,
            "& a": {
                color: theme.palette.primary.light,
                fontSize: 14,
                marginRight: 16,
                cursor: "pointer",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 13,
                    marginRight: 12,
                },
            },
        },
    },
    button: {
        marginTop: 8,
    },
    card: {
        "& input": {
            fontFamily: "'Orbitron', sans-serif",
            fontWeight: 400,
            letterSpacing: 2,
        },
    },
    inputNumber: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    inputIBANStartAdornment: {
        marginTop: '14px',
        marginLeft: '4px',
        fontSize: '18px',
        color: theme.palette.text.secondary,
    },
    inputIBAN: {
        '& .MuiOutlinedInput-root>input': {
            paddingLeft: 0,
            marginLeft: 0
        }
    }
}));
