import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ReferralBalances from "./ReferralBalances";
import TransferHistory from "./TransferHistory";
import DeleteConfirmDialog from "../../../theme/dialogs/DeleteConfirmDialog";
import CopyIcon from "../../../assets/icons/сopy_secondary.svg";
import Referrals1Icon from "../../../assets/icons/referrals_1.svg";
import Referrals2Icon from "../../../assets/icons/referrals_2.svg";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { useIsTablet } from "../../../utils/hooks/useIsTablet";
import {
  claimFundsFromReferralBalance,
  getClaimHistory,
  getReferralBalances,
  getReferralInfo,
} from "../../../redux/actions/referralProgramActions";
import { coloredFirstLetter } from "../../../utils/functions/coloredFirstLetter";
import AboutProgram from "./AboutProgram";

const ReferralMain = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const [page, setPage] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(null);
  const user = useSelector(({ account }) => account.data);
  const { referralInfo, referralBalances, claimHistory } = useSelector(
    ({ referral }) => referral
  );

  useEffect(() => {
    dispatch(getReferralInfo());
    dispatch(getReferralBalances());
    dispatch(getClaimHistory({ size: 10, page: 0 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClaimHistory({ size: 10, page: page }));
  }, [dispatch, page]);

  const claimFundsFromBalance = ({ accept }) => {
    if (accept) {
      dispatch(
        claimFundsFromReferralBalance({ balanceId: showConfirmDialog?.id })
      );
    }
    setShowConfirmDialog(null);
  };

  return (
    <>
      <DeleteConfirmDialog
        open={Boolean(showConfirmDialog)}
        title={t("Claim funds from the balance")}
        message={t("Are you sure you want to claim funds from the balance?")}
        handleClose={claimFundsFromBalance}
        confirm
      />

      <Grid container className={classes.personalInfo}>
        <Grid item xs={12} sm={6} container wrap="nowrap" alignItems="center" className={classes.header}>
          <Grid item>
            <Typography noWrap variant={"h4"} className={classes.title}>
              {coloredFirstLetter(t("Referral ID:"))} &nbsp;
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={"space-between"}
            alignItems="center"
            wrap="nowrap"
            className={classes.loginWrap}
          >
            <Grid></Grid>
            <Grid item className={classes.login}>
              <Typography
                noWrap
                align="center"
                variant={isTablet ? "body2" : "body1"}
                style={{ color: "#000000" }}
              >
                {user?.login.toUpperCase()}
              </Typography>
            </Grid>

            <Grid item style={{ padding: 0 }}>
              <CopyWithAlert text={"LOGIN" || ""} label={"LOGIN"}>
                <IconButton>
                  <img
                    src={CopyIcon}
                    alt="copy_icon"
                    className={classes.copyIcon}
                  />
                </IconButton>
              </CopyWithAlert>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} item container direction="column" className={classes.balanceInfo} alignItems="flex-end">
            <Typography variant={"h3"} className={classes.accountStatus}>
              {t("Account Status:")}{" "} {referralInfo?.type?.toUpperCase()}
            </Typography>
          <Grid
            item
            xs={12}
            md={12}
            container
            wrap="nowrap"
            justifyContent={"flex-end"}
            className={classes.referralsContainer}
          >
            <Grid item container wrap="nowrap" className={classes.referralsBox}>
              <Grid item>
                <img
                  src={Referrals1Icon}
                  alt="referrals_icon"
                  className={classes.referralsIcon}
                />
              </Grid>
              <Grid item container>
                <Typography
                  noWrap
                  variant={"body1"}
                  className={classes.referralsUser}
                >
                  {referralInfo?.firstLevelReferralsCount}{" "}
                  {t(
                    referralInfo?.firstLevelReferralsCount === 1
                      ? "user"
                      : "users"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              wrap="nowrap"
              className={clsx(classes.referralsBox, classes.lastReferralsBox)}
            >
              <Grid item>
                <img
                  src={Referrals2Icon}
                  alt="referrals_icon"
                  className={classes.referralsIcon}
                />
              </Grid>
              <Grid item container>
                <Typography
                  noWrap
                  variant={"body1"}
                  className={classes.referralsUser}
                >
                  {referralInfo?.secondLevelReferralsCount}{" "}
                  {t(
                    referralInfo?.secondLevelReferralsCount === 1
                      ? "user"
                      : "users"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} >

        <Grid container item xs={12} sm={7} direction="column">
          <Grid item className={classes.balances}>
            <ReferralBalances
              balances={referralBalances?.balances}
              claimFundsFromBalance={(balance) => setShowConfirmDialog(balance)}
              totalBalance={referralBalances?.totalInUsdt}
            />
          </Grid>
          <Grid item>
            <TransferHistory
              history={claimHistory}
              page={page}
              changePage={(page) => setPage(page)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <AboutProgram />
        </Grid>
      </Grid>
    </>
  );
};

export default ReferralMain;

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
      gap: '24px'
    },
  },
  personalInfo: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  loginWrap: {
    maxHeight: 38,
    borderRadius: 6,
    paddingLeft: 8,
    marginLeft: 30,
    backgroundColor: theme.palette.primary.default,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 32,
      marginLeft: 0
    },
  },
  copyIcon: {
    width: 20,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      width: 16,
      height: 16,
    },
    [theme.breakpoints.down("xs")]: {
      width: 12,
      height: 12,
    },
  },
  balanceInfo: {
    flexWrap: 'nowrap',
    marginBottom: 43,
    [theme.breakpoints.down("md")]: {
      marginBottom: 32,
    },
  },
  referralsBox: {
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      // marginTop: 32,
    },
  },
  referralsIcon: {
    marginRight: 20,
    width: 68,
    [theme.breakpoints.down("sm")]: {
      marginRight: 14,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 12,
    },
  },
  lastReferralsBox: {
    marginLeft: 40,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 24,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 12,
    },
  },
  balances: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
    },
    [theme.breakpoints.down("xs")]: {
    },
  },
  title: {
    textTransform: 'uppercase'
  },
  accountStatus: {
    textTransform: 'uppercase',
    marginBottom: 60,
    textWrap: 'nowrap',
    [theme.breakpoints.down("xs")]: {
      alignSelf: 'center',
      marginTop: 30,
      marginBottom: 30
    },
  },
  referralsUser: {
    alignSelf: 'center'
  },
  referralsContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center'
    },
  }
}));
