import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import AboutCompany from "./AboutCompany";
import { useTranslation } from "react-i18next";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import Breadcrumb from "../../theme/components/Breadcrumb";
import CompanyCard from "./CompanyCard";
import { Button } from "../../theme/components/Button";
import CreateCompanyDialog from "./CreateCompanyDialog";
import DeleteWithWarningDialog from "../../theme/dialogs/DeleteWithWarningDialog";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import { getCompaniesList } from "../../redux/actions/companiesActions";
import SuccessDialog from "../../theme/dialogs/SuccessDialog";

export const Companies = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const companiesList = useSelector(companiesSelectors.companiesList)

  const isCompaniesEmpty = !companiesList?.length

  const handleShowCreateCompanyDialog = () => {
    setShowCreateCompany(true)
  }

  const handleSelectCompanies = (id) => {
    history.push(`companies/${id}`)
  }

  const handleOpenDeleteDialog = (company) => {
    setShowDeleteDialog(true)
    setSelectedCompany(company)
  }

  const handleCloseDeleteConfirmDialog = ({ accept }) => {
    if (accept) {
      //  delete company
    }
    setShowDeleteDialog(false);
    setSelectedCompany(null)
  };

  useEffect(() => {
    dispatch(getCompaniesList());
  }, [dispatch])

  return (
    <PageWrapper>
      <CreateCompanyDialog
        open={showCreateCompany}
        onClose={() => setShowCreateCompany(false)}
        onSubmitSuccess={() => setShowSuccessModal(true)}
      />
      <DeleteWithWarningDialog
        open={Boolean(showDeleteDialog)}
        title={t("Delete company")}
        titleDescription={selectedCompany?.name}
        message={t("Are you sure you want to delete Company?")}
        handleClose={handleCloseDeleteConfirmDialog}
        warningText={t("You will not be able to delete this company as long as there are assets on your balance sheet!")}
      />
      <SuccessDialog  
        open={showSuccessModal}
        message={t('Your application has been sent for approval')}
        handleClose={() => setShowSuccessModal(false)}
      />
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to='/projects' pageName='Projects' />
            <Typography variant="h2" className={classes.title}>
              {coloredFirstLetter(t('Company'))}
            </Typography>
          </Box>
          <Button
            variant='contained'
            className={classes.btnSmall}
            onClick={handleShowCreateCompanyDialog}>
            {t("Create company")}
          </Button>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item {...(isCompaniesEmpty ? { xs: 12, sm: 12, md: 8 } : { spacing: 3, xs: 12, sm: 12, md: 8 })} container={!isCompaniesEmpty} >
            {isCompaniesEmpty ?
              <Typography>
                {t('You are not a member of any company, create your own company.')}
              </Typography>
              : companiesList?.map((item, index) => (
                <Grid item xs={6} key={item?.id}>
                  <CompanyCard
                    data={item}
                    onDeleteClick={handleOpenDeleteDialog}
                    onClick={handleSelectCompanies}
                  />
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <AboutCompany />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Companies;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
    textTransform: 'uppercase'
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
