import {
  ButtonBase,
  Grid,
  InputAdornment,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  checkInternalAddress,
  clearCheckInternalAddress,
  getWalletFee,
  getWalletsList,
  withdraw,
  setIsWithdrawalCard,
  getAllTickerSettings,
} from "../../redux/actions/walletsActions";
import { Input } from "../../theme/components/Input";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Button } from "../../theme/components/Button";
import SelectWalletDialog from "../../theme/dialogs/SelectWalletDialog";
import SendConfirm from "./SendConfirm";
import WithdrawalCard from "./fiat/WithdrawalCard";
import InfoIcon from "@material-ui/icons/Info";
import { validateInput } from "../../utils/functions/customValidators/validators";
import { withdrawValidator } from "../../utils/functions/customValidators/withdrawValidator";
import { accountSelectors } from "../../redux/selectors/accountSelectors";
import { ErrorCard } from "../../theme/components/ErrorCard/ErrorCard";
import ExpandMoreIcon from "../../assets/icons/arrow_down.svg";
import WithdrawalMethodDialog from "./fiat/WithdrawalMethodDialog";
import { openWithdrawalMethodDialog } from "../../redux/actions/dialogActions";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { cardValidator } from "../../utils/functions/customValidators/cardValidator";
import { roundValue } from "../../utils/functions/roundValue";

const networks = {
  slow: "cnSlow",
  medium: "cnMedium",
  fast: "cnFast",
};
const networkFeeIgnore = ["uaht"];
const networkFeeIgnoreInteger = ["usdt-trc20"];

const SendContainer = () => {
  const classes = sendStyles();
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const wallets = useSelector(({ wallets }) => wallets.data);
  const isWithdrawBlocked = useSelector((state) =>
    accountSelectors.isWithdrawBlocked(state)
  );
  const { fees, isInternal, isWithdrawalCard, allTickerSettings } = useSelector(
    ({ wallets }) => wallets
  );
  const market = useSelector(({ market }) => market);
  const [open, setOpen] = useState(false);
  const [minCoinScale, setMinCoinScale] = useState(8);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [form, setForm] = useState({ amount: 0, address: "", message: "" });
  const [network, setNetwork] = useState("");
  const [isMaxTotal, setIsMaxTotal] = useState(false);
  const timeout = useRef(null);
  const [errors, setErrors] = useState(null);
  const isMobile = useIsMobile();
  const price = useMemo(
    () => market?.data?.find((i) => i?.ticker === wallet?.ticker)?.price,
    [market, wallet]
  );

  useEffect(() => {
    setNetwork(networks.slow);
    dispatch(getAllTickerSettings());
    dispatch(getWalletsList({ number: 0, size: 100 }));
    return () => {
      dispatch(clearCheckInternalAddress());
      dispatch(setIsWithdrawalCard(false));
    };
  }, [dispatch, state]);

  useEffect(() => {
    if (wallet) {
      dispatch(getWalletFee(wallet?.ticker));
    }
  }, [dispatch, wallet]);

  useEffect(() => {
    if (isInternal) {
      setForm({
        ...form,
        amount: isMaxTotal ? selectedWallet?.balance : form?.amount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternal, isMaxTotal]);

  useEffect(() => {
    if (wallets.length === 0 || !!wallet) {
      return;
    }
    if (state?.ticker) {
      networkFeeIgnore.includes(state?.ticker)
        ? onSelect(wallets.find((w) => w.ticker === state.ticker))
        : setWallet(wallets.find((w) => w.ticker === state.ticker));
    } else {
      setWallet(wallets.find((w) => !networkFeeIgnore.includes(w?.ticker)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wallets, state, wallet]);

  const selectedWallet = useMemo(() => {
    if (!wallet || !allTickerSettings) {
      return;
    }

    const tickerSettings = allTickerSettings?.find(
      (setting) => setting.ticker === wallet?.ticker
    );
    setMinCoinScale(tickerSettings?.minScale);
    return {
      ...wallet,
      balance: roundValue(wallet?.balance, tickerSettings?.minScale),
    };
  }, [wallet, allTickerSettings]);

  const onSelect = (w) => {
    setForm({ amount: 0, address: "" });
    dispatch(clearCheckInternalAddress());
    setErrors(null);
    setWallet(wallets.find((i) => i?.ticker === w?.ticker));
    w.ticker === "uaht"
      ? dispatch(openWithdrawalMethodDialog(w))
      : dispatch(setIsWithdrawalCard(false));
    setOpen(false);
  };

  const handleWithdraw = () => {
    dispatch(withdraw({ ...form, ticker: wallet?.ticker }));
    setOpenConfirm(false);
    setForm({ amount: 0, address: "", message: "" });
  };

  const handleCLickForm = () => {
    if (!validateForm() || (isWithdrawalCard && !validateCard())) {
      return;
    }
    setOpenConfirm(true);
  };

  const checkAmount = (value) => {
    const errorAmount = withdrawValidator(
      value,
      fees?.minWithdraw,
      fees?.maxWithdraw,
      selectedWallet?.balance,
      data?.total,
      isInternal
    );
    setErrors({
      ...errors,
      amount: errorAmount,
    });
    return errorAmount;
  };

  const handleChangeAmount = (value) => {
    setIsMaxTotal(false);
    checkAmount(value);
    setForm({ ...form, amount: value });
  };

  const data = useMemo(() => {
    if (!+form?.amount || !fees)
      return {
        amount: 0,
        total: 0,
        totalUSD: 0,
      };

    const totalFee = isInternal
      ? 0
      : +Number(
        (Number(
          networkFeeIgnore.includes(selectedWallet?.ticker) ? fees?.system : 0
        ) /
          100) *
        Number(isMaxTotal ? selectedWallet?.balance : form?.amount) +
        Number(
          networkFeeIgnoreInteger.includes(selectedWallet?.ticker)
            ? fees?.system
            : !networkFeeIgnore.includes(selectedWallet?.ticker)
              ? Number(fees[network])
              : 0
        )
      ).toFixed(minCoinScale);

    return {
      totalFee,
      amount: form?.amount,
      total: roundValue(
        Number(isMaxTotal ? selectedWallet?.balance : form?.amount) +
        (isMaxTotal ? 0 : totalFee),
        minCoinScale
      ),
      totalUSD: roundValue(
        Number(isMaxTotal ? selectedWallet?.balance : form?.amount) *
        Number(price) +
        (isMaxTotal ? 0 : totalFee) * Number(price),
        minCoinScale
      ),
    };
  }, [
    isMaxTotal,
    minCoinScale,
    selectedWallet?.balance,
    form?.amount,
    fees,
    selectedWallet?.ticker,
    network,
    price,
    isInternal,
  ]);

  const handleChangeAddress = ({ target: { value } }) => {
    setForm({ ...form, address: value });
    setErrors({
      ...errors,
      address: validateInput("address", value, form),
    });
    clearTimeout(timeout.current);
    if (Boolean(value)) {
      timeout.current = setTimeout(() => {
        dispatch(checkInternalAddress(selectedWallet?.ticker, value));
      }, 500);
    } else {
      timeout.current = setTimeout(() => {
        dispatch(clearCheckInternalAddress());
      }, 500);
    }
  };

  const handleChangeMessage = ({ target: { value } }) => {
    setForm({ ...form, message: value });
  }

  const handleSelectedCard = ({ value, errorCard }) => {
    setForm({ ...form, address: value });
    setErrors({ ...errors, address: errorCard });
  };

  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(form).forEach((key) => {
      errForm = {
        ...errForm,
        [key]:
          key === "amount" && !isInternal
            ? checkAmount(form[key])
            : validateInput(key, form[key], form),
      };
      errForm[key].invalid && (valid = false);
    });
    setErrors(errForm);
    return valid;
  };

  const validateCard = () => {
    let valid = true;
    cardValidator("cardNumber", form?.address).invalid && (valid = false);
    return valid;
  };

  const handleMaxAmountClick = () => {
    if (!+selectedWallet?.balance) {
      return setForm({
        ...form,
        amount: 0,
      });
    }

    if (!isWithdrawBlocked) {
      setIsMaxTotal(true);
      setErrors(null);
      const maxAmount = roundValue(
        Number(selectedWallet?.balance) -
        +Number(
          (Number(
            networkFeeIgnore.includes(selectedWallet?.ticker)
              ? fees?.system
              : 0
          ) /
            100) *
          Number(selectedWallet?.balance) +
          Number(
            networkFeeIgnoreInteger.includes(selectedWallet?.ticker)
              ? fees?.system
              : !networkFeeIgnore.includes(selectedWallet?.ticker)
                ? Number(fees[network])
                : 0
          )
        ),
        minCoinScale
      );
      checkAmount(maxAmount);
      setForm({
        ...form,
        amount: maxAmount,
      });
    }
  };

  return (
    <>
      <WithdrawalMethodDialog />
      {isWithdrawBlocked && <ErrorCard text="Withdrawal Blocked" />}
      <Paper className={classes.root} elevation={4}>
        <Typography
          variant={"h4"}
          color={"textPrimary"}
          className={classes.title}
        >
          {t("Please enter and select details of your transfer")}
        </Typography>
        {selectedWallet && minCoinScale && (
          <Typography
            variant={"body2"}
            color={"textSecondary"}
            className={classes.smallText}
          >
            {t("Available")}: {selectedWallet?.balance}{" "}
            {selectedWallet?.ticker.toUpperCase()}
          </Typography>
        )}
        <Input
          className={classes.input}
          label={t("Amount")}
          variant="outlined"
          disabled={isWithdrawBlocked}
          name={"amount"}
          value={form.amount}
          onChange={({ target: { value } }) =>
            /^[0-9]*\.?[0-9]*$/.test(value) &&
            !(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
            value.length < 20 &&
            handleChangeAmount(value)
          }
          onFocus={() => !form?.amount && setForm({ ...form, amount: "" })}
          onBlur={() => !form?.amount && setForm({ ...form, amount: 0 })}
          error={errors?.amount?.invalid}
          helperText={t(errors?.amount?.errorMessage)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <Link onClick={handleMaxAmountClick}>MAX</Link>
                <ButtonBase
                  disabled={isWithdrawBlocked}
                  className={classes.select}
                  onClick={() => setOpen(true)}
                >
                  {selectedWallet?.ticker && (
                    <img
                      src={`https://cryptoneed.com/icons/${selectedWallet?.ticker}.svg`}
                      alt={selectedWallet?.ticker}
                      width={24}
                      height={24}
                      loading={"lazy"}
                    />
                  )}

                  {selectedWallet?.ticker.toUpperCase()}
                  <img
                    src={ExpandMoreIcon}
                    alt="expandMoreIcon"
                    loading={"lazy"}
                  />
                </ButtonBase>
              </InputAdornment>
            ),
          }}
        />
        {selectedWallet?.ticker === "xrp" && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  className={classes.wrapIcon}
                >
                  <ReportProblemOutlinedIcon
                    style={{ color: "#AF994A", marginRight: 8, fontSize: 24 }}
                  />
                  {t("For internal transaction enter Memo.")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {!isWithdrawalCard ? (
          <Input
            className={classes.input}
            label={t("Wallet address")}
            placeholder={t("Enter address")}
            variant="outlined"
            name={"address"}
            value={form?.address}
            disabled={isWithdrawBlocked}
            onChange={handleChangeAddress}
            error={errors?.address?.invalid}
            helperText={t(errors?.address?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        ) : (
          <WithdrawalCard handleSelectedCard={handleSelectedCard} />
        )}
        <Input
          className={classes.input}
          label={t("Description (optional)")}
          placeholder={t("Enter description")}
          variant="outlined"
          name={"message"}
          value={form?.message}
          disabled={isWithdrawBlocked}
          onChange={handleChangeMessage}
          error={errors?.message?.invalid}
          helperText={t(errors?.message?.errorMessage)}
          InputProps={{
            autoComplete: "off",
          }}
        />
        {isInternal ? (
          <Typography
            variant={"subtitle1"}
            style={{ marginBottom: 32, marginTop: 8, color: "#22dd22" }}
          >
            <InfoIcon
              style={{
                fontSize: 16,
                marginRight: 4,
                verticalAlign: "text-top",
              }}
            />
            {t(
              "This transaction is internal. Therefore, no transaction fee is charged."
            )}
          </Typography>
        ) : (
          <>
            <Grid
              container
              justifyContent={"space-between"}
              style={{
                marginBottom: isMobile ? 10 : 20,
              }}
            >
              <Grid item>
                <Typography variant={"body2"}>{t("Fee")}:</Typography>
              </Grid>
              <Grid item>
                <Typography variant={"body2"}>
                  {fees && Number(form?.amount) !== 0 ? data?.totalFee : 0}{" "}
                  {wallet?.ticker?.toUpperCase()}
                  {` ($${fees && Number(form?.amount) !== 0
                    ? roundValue(Number(data?.totalFee) * Number(price), 2)
                    : 0
                    })`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              style={{ marginBottom: isMobile ? 24 : 36 }}
            >
              <Grid item>
                <Typography variant={"body2"}>{t("Total")}:</Typography>
              </Grid>
              <Grid item>
                <Typography variant={"body2"}>
                  {fees && Number(form?.amount) !== 0 ? data?.total : 0}{" "}
                  {wallet?.ticker?.toUpperCase()}
                  {` ($${fees && +form?.amount !== 0 ? data?.totalUSD : 0})`}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Button className={classes.btn} onClick={() => handleCLickForm()}>
          {t("Continue")}
        </Button>
      </Paper>

      <SelectWalletDialog
        open={open}
        onClose={() => setOpen(false)}
        onSelect={onSelect}
      />
      <SendConfirm
        open={openConfirm}
        data={{
          ...data,
          address: form?.address,
          ticker: selectedWallet?.ticker,
          message: form?.message || '',
          isInternal,
        }}
        onClose={() => {
          setOpenConfirm(false);
        }}
        onConfirm={handleWithdraw}
      />
    </>
  );
};

export default SendContainer;

export const sendStyles = makeStyles((theme) => ({
  root: {
    padding: "32px 24px",
    marginTop: "14px",
    textAlign: "center",
    borderRadius: 20,
    minWidth: 380,
    [theme.breakpoints.down(1100)]: {
      padding: "40px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px",
      minWidth: "auto",
    },
  },
  title: {
    textAlign: 'left',
    marginBottom: 30,
  },
  smallText: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: "right"
  },
  btn: {
    minWidth: 180,
    height: 42,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  input: {
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.default,
        fontSize: 8,
        cursor: "pointer",
        textDecoration: "none",
        background: theme.palette.text.secondary,
        borderRadius: "4px",
        padding: "0px 10px",
      },
    },
  },
  select: {
    padding: "15.5px 12px 15.5px 18px",
    borderRadius: 12,
    fontSize: 16,
    color: '#000000',
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 0px 12px",
      marginRight: 8,
      fontSize: "12px",
      borderRadius: "4px",
    },
    "&>img:nth-child(1)": {
      width: 'auto',
      height: 30,
      marginRight: 8,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "& img:nth-child(2)": {
      marginLeft: 4,
      width: 14,
      [theme.breakpoints.down("xs")]: {
        width: 12
      },
    },
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    marginTop: -8,
    paddingBottom: "8px",
  },
}));
