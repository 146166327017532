import { CustomError } from "./errors";
import { handleError } from "./logging";

export const StorageItem = {
  AuthToken: "access_token",
  RefreshToken: "refresh_token",
  AppSettings: "AppSettings",
  AppLanguage: "AppLanguage",
  AppCurrency: "AppCurrency",
  PreviousVersion: "PreviousVersion",
  XSRF: "XSRF",
};

export const whiteList = [StorageItem.PreviousVersion, StorageItem.XSRF];

export function getStorageItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    handleError(new CustomError(e, `getStorageItem:${key}`));
    return null;
  }
}

export function setStorageItem(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    handleError(new CustomError(e, `setStorageItem(${key}:${value})`));
  }
}

export function removeStorageItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    handleError(new CustomError(e, `removeStorageItem:${key}`));
  }
}

export function clearStorage() {
  try {
    Object.values(StorageItem)
      .filter(item => !whiteList.includes(item))
      .forEach(item => {
        localStorage.removeItem(item);
      });
  } catch (e) {
    handleError(new CustomError(e, "clearStorage"));
  }
}
