import { useTranslation } from "react-i18next";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import ReferralMain from "./ReferrallMain/";

const ReferralProgram = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PageWrapper bgColor="secondary">
      <Grid container direction="column" className={classes.root}>
        <Grid
          container
          direction={"row"}
          wrap="nowrap"
        >
          {/* <Grid item className={classes.breadcrumbWrap}>
            <Breadcrumb to={"/"} pageName={"Main"} />
          </Grid> */}
          <Typography variant="h2" className={classes.title}>
            {t('Referral program')}
          </Typography>
          <Grid item>
          </Grid>
        </Grid>

        <ReferralMain />
      </Grid>
    </PageWrapper>
  );
};

export default ReferralProgram;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
    },
  },

  title: {
    marginTop: 24,
    fontSize: 32,
    textTransform: 'uppercase',
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      marginBottom: 20
    },
  },

  breadcrumbWrap: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down(725)]: {
      width: "100%",
    },
  },
}));
