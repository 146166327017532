import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputRegular } from "../../../components/Input";
import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

export const INVEST_PLAN_WALLET_TYPE = {
  'MAIN': 'MAIN',
  'MONEYBOX': 'MONEYBOX'
}

export const WALLET_TYPE_LIST = [{
  label: 'Main',
  value: INVEST_PLAN_WALLET_TYPE.MAIN,
},
{
  label: 'Moneybox',
  value: INVEST_PLAN_WALLET_TYPE.MONEYBOX
}
]

const WalletTypeSelect = ({ onSelect, inputValue, defaultInputValue, defaultWalletType, inputError = {}, onInputBlur, onInputChange, inputName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(defaultWalletType || WALLET_TYPE_LIST[0])

  const handleCycleClick = useCallback((value) => {
    setSelectedType(value)
    onSelect?.(value)
  }, [onSelect])

  useEffect(() => {
    if (defaultWalletType && defaultWalletType !== selectedType) {
      setSelectedType(defaultWalletType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWalletType])

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid container spacing={1}>
        {
          WALLET_TYPE_LIST.map((cycle, index) => {
            return (
              <Grid key={cycle.value} item className={classes.gridItem} md={4}>
                <InputRegular
                  key={cycle.value}
                  className={clsx(classes.input, classes.inputSelector, selectedType.value === cycle.value && classes.selectedInput)}
                  variant='outlined'
                  name={cycle.value}
                  value={cycle.label}
                  type={"text"}
                  onClick={() => handleCycleClick(cycle)}
                  InputProps={{
                    readOnly: true,
                    form: {
                      autoComplete: "off",
                    },
                    style: {
                      cursor: 'pointer'
                    }
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )
          })
        }
      </Grid>
      {
        selectedType?.value === INVEST_PLAN_WALLET_TYPE.MONEYBOX ?
          <Grid item md={8}>
            <InputRegular
              error={inputError?.invalid || Boolean(inputError?.errorMessage)}
              helperText={inputError?.errorMessage}
              className={classes.input}
              label={t("Address wallet")}
              variant='outlined'
              name={inputName}
              value={inputValue || ""}
              onChange={onInputChange}
              onBlur={onInputBlur}
              type={"text"}
              InputProps={{
                form: {
                  autoComplete: "off",
                },
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid> : null
      }
    </Grid>
  );
};

WalletTypeSelect.propTypes = {
  onSelect: PropTypes.func,
  inputValue: PropTypes.string,
  inputError: PropTypes.object,
  onInputBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  inputName: PropTypes.string,
};

export default WalletTypeSelect;

const useStyles = makeStyles(theme => ({
  gridItem: {
  },
  root: {
    marginTop: 6,
    marginBottom: 24,
  },
  title: {
    fontSize: 14,
    marginBottom: 5,
  },
  input: {
    marginBottom: 0,
    boxSizing: 'border-box',
    border: `1px solid #2B2F38`,
    cursor: 'pointer',
    "& .MuiOutlinedInput-root": {
      "&>input": {
      }
    }
  },
  inputSelector: {
    marginTop: 0,
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      padding: '14px 14px 14px 14px !important',
    },
  },
  selectedInput: {
    border: `1px solid ${theme.palette.border.main}`,
    "& .MuiOutlinedInput-root": {
      "&>input": {
        color: theme.palette.text.tertiary,
      }
    }
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    '& .MuiSwitch-thumb': {
      height: 14,
      width: 14
    }
  },
  switcherElement: {
    height: 20,
    width: 34,
    borderRadius: 0,
  },
  switcherLabel: {
    marginRight: 5,
    fontSize: 12,
  }
}));
