import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import { getKycStatus } from "../../redux/actions/kycActions";
import AboutAutoInvest from "./AboutAutoInvest";
import { useTranslation } from "react-i18next";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import Breadcrumb from "../../theme/components/Breadcrumb";
import CreatePlanBlock from "./CreatePlanBlock";
import AutoInvestPlanCard from "./AutoInvestPlanCard";
import CreateAutoInvestPlanDialog from "../../theme/dialogs/CreateAutoInvestPlanDialog/CreateAutoInvestPlanDialog";
import { closeCreateAutoInvestPlanDialog, openCreateAutoInvestPlanDialog } from "../../redux/actions/dialogActions";
import { getMarketList } from "../../redux/actions/exchangeActions";
import { getAllTickerSettings, getWalletsList, getWalletsSettingsList } from "../../redux/actions/walletsActions";
import { deleteAutoInvestPlan, getAllAutoInvestPlans, changeAutoInvestPlanStatus } from "../../redux/actions/autoInvestActions";
import { autoInvestSelectors } from "../../redux/selectors/autoInvestSelectors";
import DeleteConfirmDialog from "../../theme/dialogs/DeleteConfirmDialog";
import { Button } from "../../theme/components/Button";

export const AutoInvest = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, selectedPlan } = useSelector(({ dialogs }) => dialogs.createAutoInvestPlan);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedInvestPlan, setSelectedInvestPlan] = useState(null)
  const autoInvestPlansList = useSelector(autoInvestSelectors.autoInvestPlansList)

  useEffect(() => {
    dispatch(getKycStatus());
  }, [dispatch]);

  const showCreatePlanBlock = !autoInvestPlansList?.length

  const handleShowCreatePlanDialog = () => {
    dispatch(openCreateAutoInvestPlanDialog())
  }

  const handleShowEditPlanDialog = (planData) => {
    dispatch(openCreateAutoInvestPlanDialog(planData))
  }

  const handleCloseCreatePlanDialog = () => {
    dispatch(closeCreateAutoInvestPlanDialog())
  }

  const handleOpenDeleteDialog = (planData) => {
    setShowDeleteDialog(true)
    setSelectedInvestPlan(planData)
  }

  const handleCloseDeleteConfirmDialog = ({ accept }) => {
    if (accept) {
      dispatch(deleteAutoInvestPlan(selectedInvestPlan?.id))
    }
    setShowDeleteDialog(false);
    setSelectedInvestPlan(null)
  };

  const handleChangeInvestPlanStatus = ({ isEnabled, planId }) => {
    const requestData = {
      enabled: isEnabled,
      programId: planId,
    }
    dispatch(changeAutoInvestPlanStatus(requestData))
  }

  useEffect(() => {
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getMarketList());
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
    dispatch(getAllAutoInvestPlans());
  }, [dispatch])

  return (
    <PageWrapper bgColor="secondary">
      <CreateAutoInvestPlanDialog
        open={open}
        data={selectedPlan}
        handleCloseDialog={handleCloseCreatePlanDialog}
      />
      <DeleteConfirmDialog
        open={Boolean(showDeleteDialog)}
        title={t("Delete invest plan")}
        message={t("Are you sure you want to delete selected invest plan?")}
        handleClose={handleCloseDeleteConfirmDialog}
        btnOkText={t("Delete")}
        confirm
      />
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to='/projects'  pageName={t('Projects')} />
            <Typography variant="h2" className={classes.title}>
              {coloredFirstLetter(t('AUTO INVEST'))}
            </Typography>
          </Box>
          {
            !showCreatePlanBlock &&
            <Button
              variant='contained'
              className={classes.btnSmall}
              onClick={handleShowCreatePlanDialog}>
              {t("Create a plan")}
            </Button>
          }
        </Grid>
        <Grid container spacing={3}>
          <Grid item {...(showCreatePlanBlock ? { xs: 12, sm: 12, md: 8 } : { spacing: 3, xs: 12, sm: 12, md: 8 })} container={!showCreatePlanBlock} >
            {showCreatePlanBlock ?
              <Paper className={classes.paper}>
                <CreatePlanBlock onCreatePlanClick={handleShowCreatePlanDialog} />
              </Paper>
              : autoInvestPlansList?.map((item) => (
                <Grid item xs={6} key={item?.id}>
                  <AutoInvestPlanCard
                    data={item}
                    onEditClick={handleShowEditPlanDialog}
                    onDeleteClick={handleOpenDeleteDialog}
                    onChangeStatus={handleChangeInvestPlanStatus}
                  />
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <AboutAutoInvest />
          </Grid>

        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default AutoInvest;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
