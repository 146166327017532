const companiesList = state => state.companies.companiesList;
const getCompaniesListRequestStatus = state => state.companies.getCompaniesListRequestStatus
const getCompanyBalanceTransfersRequestStatus = state => state.companies.getCompanyBalanceTransfersRequestStatus
const companyBalanceTransferHistory = state => state.companies.companyBalanceTransferHistory
const companyBalanceTransferInvoices = state => state.companies.companyBalanceTransferInvoices
const transferCompanyBalanceRequestStatus = state => state.companies.transferCompanyBalanceRequestStatus
const makeDecisionByTransferCompanyBalanceRequestStatus = state => state.companies.makeDecisionByTransferCompanyBalanceRequestStatus
const getCompanyBalanceTransferInvoiceByIdRequestStatus = state => state.companies.getCompanyBalanceTransferInvoiceByIdRequestStatus
const selectedCompanyBalanceTransferInvoice = state => state.companies.selectedCompanyBalanceTransferInvoice
const addNewCompanyRequestStatus = state => state.companies.addNewCompanyRequestStatus


export const companiesSelectors = {
  companiesList,
  getCompaniesListRequestStatus,
  getCompanyBalanceTransfersRequestStatus,
  companyBalanceTransferHistory,
  companyBalanceTransferInvoices,
  transferCompanyBalanceRequestStatus,
  makeDecisionByTransferCompanyBalanceRequestStatus,
  getCompanyBalanceTransferInvoiceByIdRequestStatus,
  selectedCompanyBalanceTransferInvoice,
  addNewCompanyRequestStatus
};
