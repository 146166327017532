export const LANGUAGE = {
  English: "English",
  Russian: "Russian",
  Ukrainian: "Ukrainian",
};

export const LANGUAGE_TICKER = {
  en: "en",
  ru: "ru",
  ua: "ua",
};

export const LANGUAGE_LABELS = {
  [LANGUAGE_TICKER.en]: LANGUAGE.English,
  [LANGUAGE_TICKER.ru]: LANGUAGE.Russian,
  [LANGUAGE_TICKER.ua]: LANGUAGE.Ukrainian,
};
