import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const FormError = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  if (props?.isHidden) {
    return null;
  }
  return (
    <div className={classes.errorField} style={props?.style}>
      {t(props?.errorMessage)}
    </div>
  );
};
export default FormError;

const useStyles = makeStyles(theme => ({
  errorField: {
    color: theme.palette.error.main,
    fontSize: 12,
    position: "absolute",
  },
}));
