import { Typography, Paper, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";

const AboutCompany = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Paper className={classes.root}>
      {renderTitle(t("About company"))}
      <Typography
        variant={"body1"}
        className={classes.description}
      >
        {t("An analogue of a bank account only in cryptocurrencies. Provide account access to your employees. Write comments on transactions. Appoint people who have the right to sign for the withdrawal of crypto assets.")}
      </Typography>
    </Paper>
  );
};

export default AboutCompany;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
  },
  coloredText: {
    color: theme.palette.primary.main
  },
  rewardIcon: {
    maxHeight: 72
  },
  noteText: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  rewardContainer: {
    marginTop: 40
  },
  link: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
    color: theme.palette.text.quaternary,
  }
}));
