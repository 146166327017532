import {
    GET_DEVICE_LOGS_LIST_FAILURE,
    GET_DEVICE_LOGS_LIST_STARTED,
    GET_DEVICE_LOGS_LIST_SUCCESS, SET_DEVICE_LOGS_PARAMS
} from "../types";
import api from "../../services/api";

export const getDeviceLogsStarted = () => ({
    type: GET_DEVICE_LOGS_LIST_STARTED
});

export const getDeviceLogsSuccess = (data, total) => ({
    type: GET_DEVICE_LOGS_LIST_SUCCESS,
    payload: {
        data,
        total
    }
});

export const getDeviceLogsFailure = error => ({
    type: GET_DEVICE_LOGS_LIST_FAILURE,
    payload: {
        error
    }
});

export const getDeviceLogs = (params) => {
    return async (dispatch) => {
        dispatch(getDeviceLogsStarted());
        api.deviceLogs.getList(params)
            .then(res => dispatch(getDeviceLogsSuccess(res.data, Number(res.headers['x-total-count']))))
            .catch(error => dispatch(getDeviceLogsFailure(error)));
    }
};

export const setDeviceLogsPage = payload => ({
    type: SET_DEVICE_LOGS_PARAMS,
    payload
})
