import {HIDE_LOADER, SHOW_LOADER} from "../types";

export default function loading(
	state = {
		show: false
	}, action
) {
	switch (action.type) {
		case SHOW_LOADER:
			return { ...state, show: true };
		case HIDE_LOADER:
			return { ...state, show: false };
		default:
			return state;
	}
}
