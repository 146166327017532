import { MONEYBOX } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
// import { enqueueSnackbar } from "../../redux/actions/notistackActions";
// import i18next from "../../i18n.config";

export const getMoneyboxListAction = actionCreator(
  MONEYBOX.GET_MONEYBOX_LIST
);

export const getMoneyboxList = () => {
  return async (dispatch) => {
    dispatch(getMoneyboxListAction.started());
    api.moneybox
      .getMoneyboxList({number: 0, size: 100})
      .then((res) => {
        dispatch(getMoneyboxListAction.done(res.data));
      })
      .catch((error) => dispatch(getMoneyboxListAction.failed(error)));
  };
};

export const createMoneyboxAction = actionCreator(
  MONEYBOX.CREATE_MONEYBOX
);

export const createMoneybox = ({ name, description }) => {
  return async (dispatch) => {
    dispatch(createMoneyboxAction.started());
    api.moneybox
      .createMoneybox({ name, description })
      .then((res) => {
        dispatch(createMoneyboxAction.done(res.data))
        dispatch(getMoneyboxList());
      })
      .catch((error) => dispatch(createMoneyboxAction.failed(error)));
  };
};

export const updateMoneyboxAction = actionCreator(
  MONEYBOX.UPDATE_MONEYBOX
);

export const updateMoneybox = (data) => {
  return async (dispatch) => {
    dispatch(updateMoneyboxAction.started());
    api.moneybox
      .updateMoneybox(data)
      .then((res) => {
        dispatch(updateMoneyboxAction.done(res.data));
        dispatch(getMoneyboxList());
      })
      .catch((error) => dispatch(updateMoneyboxAction.failed(error)));
  };
};

export const deleteMoneyboxAction = actionCreator(
  MONEYBOX.DELETE_MONEYBOX
);

export const deleteMoneybox = (moneyboxId) => {
  return async (dispatch) => {
    dispatch(deleteMoneyboxAction.started());
    api.moneybox
      .deleteMoneybox(moneyboxId)
      .then((res) => {
        dispatch(deleteMoneyboxAction.done(res.data));
        dispatch(getMoneyboxList());
      })
      .catch((error) => {
        dispatch(deleteMoneyboxAction.failed(error))
      }
      );
  };
};

export const getMoneyboxByIdAction = actionCreator(
  MONEYBOX.GET_MONEYBOX_BY_ID
);

export const getMoneyboxById = (moneyboxId) => {
  return async (dispatch) => {
    dispatch(getMoneyboxByIdAction.started());
    api.moneybox
      .getMoneyboxById(moneyboxId)
      .then((res) => {
        dispatch(getMoneyboxByIdAction.done(res.data));
      })
      .catch((error) => dispatch(getMoneyboxByIdAction.failed(error)));
  };
};

export const getMoneyboxBalanceByIdAction = actionCreator(
  MONEYBOX.GET_MONEYBOX_BALANCE_BY_ID
);

export const getMoneyboxBalanceById = (balanceId) => {
  return async (dispatch) => {
    dispatch(getMoneyboxBalanceByIdAction.started());
    api.moneybox
      .getMoneyboxBalanceById(balanceId)
      .then((res) => {
        dispatch(getMoneyboxBalanceByIdAction.done(res.data));
      })
      .catch((error) => dispatch(getMoneyboxBalanceByIdAction.failed(error)));
  };
};

export const getMoneyboxBalancesListAction = actionCreator(
  MONEYBOX.GET_MONEYBOX_BALANCES_LIST
);

export const getMoneyboxBalanceList = (moneyboxId) => {
  return async (dispatch) => {
    dispatch(getMoneyboxBalancesListAction.started());
    api.moneybox
      .getMoneyboxBalancesList(moneyboxId)
      .then((res) => {
        dispatch(getMoneyboxBalancesListAction.done(res.data));
      })
      .catch((error) => dispatch(getMoneyboxBalancesListAction.failed(error)));
  };
};

export const createMoneyboxBalanceAction = actionCreator(
  MONEYBOX.CREATE_MONEYBOX_BALANCE
);

export const createMoneyboxBalance = (data) => {
  return async (dispatch) => {
    dispatch(createMoneyboxBalanceAction.started());
    api.moneybox
      .createMoneyboxBalance(data)
      .then((res) => {
        dispatch(createMoneyboxBalanceAction.done(res.data))
        if (data?.moneyboxId) {
          dispatch(getMoneyboxBalanceList(data.moneyboxId));
        }
      })
      .catch((error) => dispatch(createMoneyboxBalanceAction.failed(error)));
  };
};

export const withdrawMoneyboxBalanceAction = actionCreator(
  MONEYBOX.WITHDRAW_MONEYBOX_BALANCE
);

export const withdrawMoneyboxBalance = ({ balanceId, amount, message, moneyboxId }) => {
  return async (dispatch) => {
    dispatch(withdrawMoneyboxBalanceAction.started());
    api.moneybox
      .withdrawMoneyboxBalance({
        balanceId,
        amount,
        message,
      })
      .then((res) => {
        dispatch(withdrawMoneyboxBalanceAction.done(res.data))
        if (balanceId) {
          dispatch(getMoneyboxBalanceById(balanceId))
        }
        if (moneyboxId) {
          dispatch(getMoneyboxBalanceList(moneyboxId))
        }
      })
      .catch((error) => dispatch(withdrawMoneyboxBalanceAction.failed(error)));
  };
};

export const getMoneyboxBalanceHistoryAction = actionCreator(
  MONEYBOX.GET_MONEYBOX_BALANCE_HISTORY
);

export const getMoneyboxBalanceHistory = ({ balanceId, params }) => {
  return async (dispatch) => {
    dispatch(getMoneyboxBalanceHistoryAction.started());
    api.moneybox
      .getMoneyboxBalanceHistory({
        balanceId,
        params: {
          page: params?.page || 0,
          size: params?.size || 20
        }
      })
      .then((res) => {
        dispatch(getMoneyboxBalanceHistoryAction.done(res.data));
      })
      .catch((error) => dispatch(getMoneyboxBalanceHistoryAction.failed(error)));
  };
};

