import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Button } from "../../../theme/components/Button";
import { useColoredTitle } from "../../../utils/hooks/useColoredTitle";

  // const balancesMock = [{
  //   asset: 'btc',
  //   name: 'Bitcoin',
  //   balance: 1,
  //   inUsdt: 20000,
  // },
  // {
  //   asset: 'eth',
  //   name: 'Ethereum',
  //   balance: 2,
  //   inUsdt: 3000,
  // }]

const ReferralBalances = ({ balances, claimFundsFromBalance, totalBalance }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Paper className={classes.root}>
      <Grid container direction="row" justifyContent="space-between">
        {renderTitle(t("Referral token"))}
        <Box>
          <Typography className={classes.title} display="inline"  variant={"h4"} color={"textPrimary"}>
            {t("Balance")}{": "}{totalBalance}
          </Typography>
          <Typography display="inline" variant={"body2"} color="textSecondary" className="">{" USDT"}</Typography>
        </Box>
      </Grid>
      {balances?.length === 0 ? (
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          className={classes.description}
        >
          {t("You have no referral tokens...")}
        </Typography>
      ) : (
        balances?.map((balance, index) => (
          <Balance
            key={index}
            balance={balance}
            claimFunds={claimFundsFromBalance}
          />
        ))
      )}
    </Paper>
  );
};

export default ReferralBalances;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 400
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
    textAlign: "center",
  }
}));

export const Balance = ({ balance, claimFunds }) => {
  const classes = useTransactionsStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        wrap="nowrap"
        className={classes.root}
      >
        <Grid
          item
          xs={5}
          className={classes.currency}
          container
          alignItems="center"
        >
          <img
            src={`https://cryptoneed.com/icons/${balance?.asset}.svg`}
            alt={balance?.asset}
            loading={"lazy"}
            className={classes.tokenImg}
          />
          <Typography
            variant={"body1"}
            color="textPrimary"
            noWrap
            className={classes.typography}
          >
            {balance?.name} ({balance?.asset?.toUpperCase()})
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography
            variant={"body2"}
            color="textPrimary"
            noWrap
            className={classes.typography}
          >
            {balance?.balance}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            width: "max-content",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <Typography
            variant={"body2"}
            color="textPrimary"
            noWrap
            className={classes.typography}
          >
            {balance?.inUsdt}
          </Typography>
          <Typography variant="body1" color="inherit" noWrap>
            &nbsp;
          </Typography>
          <Typography
            variant={"subtitle2"}
            color="textSecondary"
            className={classes.tether}
          >
            {" USDT"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            className={classes.btnSmall}
            onClick={() => claimFunds(balance)}
          >
            <Typography
              variant={"body1"}
              color="primary"
              className={clsx(classes.typography, classes.textNormal)}
            >
              {t("Claim")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const useTransactionsStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 0px",
  },
  tokenImg: {
    width: 42,
    height: 42,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
    },
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  },
  btnSmall: {
    "&.MuiButton-contained ": {
      borderRadius: 10,
      background: "none",
      boxShadow: "none",
      minHeight: 40,
      minWidth: 140,
      padding: '10px 24px'
    },
    "&:hover": { background: "inherit", boxShadow: "inherit" },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "11px",
    },
    [theme.breakpoints.down("xs")]: {
      "&.MuiButton-contained ": {
        fontSize: 9,
        lineHeight: "11px",
        padding: "10px 20px",
        maxHeight: 24,
        minHeight: 22,
        minWidth: 70,
      },
    },
  },
  typography: {
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: 9,
      lineHeight: "11px",
    },
  },
  textNormal: {
    fontWeight: 400,
  },
  tether: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
      lineHeight: "10px",
    },
  },
  disabled: {
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary
  }
}));
