import { EXCHANGE } from "../types";
import api from "../../services/api";
import { getWalletsList } from "./walletsActions";
import { enqueueSnackbar } from "../../redux/actions/notistackActions";
import i18next from "../../i18n.config";

export const getMarketListStarted = () => ({
  type: EXCHANGE.GET_MARKET_LIST_STARTED,
});

export const getMarketListSuccess = payload => ({
  type: EXCHANGE.GET_MARKET_LIST_SUCCESS,
  payload,
});

export const getMarketListFailure = error => ({
  type: EXCHANGE.GET_MARKET_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getMarketList = () => {
  return async dispatch => {
    dispatch(getMarketListStarted());
    api.exchange
      .getMarketList()
      .then(res => dispatch(getMarketListSuccess(res.data)))
      .catch(error => dispatch(getMarketListFailure(error)));
  };
};

export const createExchangeStarted = () => ({
  type: EXCHANGE.CREATE_STARTED,
});

export const createExchangeSuccess = payload => ({
  type: EXCHANGE.CREATE_SUCCESS,
  payload,
});

export const createExchangeFailure = error => ({
  type: EXCHANGE.CREATE_FAILURE,
  payload: {
    error,
  },
});

export const createExchange = data => {
  return async dispatch => {
    dispatch(createExchangeStarted());
    api.exchange
      .create(data)
      .then(res => {
        if (res.data?.payment_url) {
          window.open(res.data?.payment_url);
        }
        dispatch(createExchangeSuccess(res.data));
        dispatch(getOrderList({ page: 0, size: 10 }));
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(
          enqueueSnackbar({
            message: i18next.t("Your exchange has been processed successfully"),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
      })
      .catch(error => dispatch(createExchangeFailure(error)));
  };
};

export const getOrderListStarted = () => ({
  type: EXCHANGE.GET_ORDERS_STARTED,
});

export const getOrderListSuccess = payload => ({
  type: EXCHANGE.GET_ORDERS_SUCCESS,
  payload,
});

export const getOrderListFailure = error => ({
  type: EXCHANGE.GET_ORDERS_FAILURE,
  payload: {
    error,
  },
});

export const getOrderList = params => {
  return async dispatch => {
    dispatch(getOrderListStarted());
    api.exchange
      .getOrders(params)
      .then(res => {
        dispatch(getOrderListSuccess(res.data));
      })
      .catch(error => dispatch(getOrderListFailure(error)));
  };
};

export const exchangeClearRequestStatus = () => ({
  type: EXCHANGE.CLEAR_REQUEST_STATUS,
});

export const exchangeClearError = () => ({
  type: EXCHANGE.CLEAR_ERROR,
});

export const getExchangeMarketDetailsStarted = () => ({
  type: EXCHANGE.GET_MARKET_DETAILS_STARTED,
});

export const getExchangeMarketDetailsSuccess = payload => ({
  type: EXCHANGE.GET_MARKET_DETAILS_SUCCESS,
  payload,
});

export const getExchangeMarketDetailsFailure = error => ({
  type: EXCHANGE.GET_MARKET_DETAILS_FAILURE,
  payload: {
    error,
  },
});

export const getExchangeMarketDetails = ticker => {
  return async dispatch => {
    dispatch(getExchangeMarketDetailsStarted());
    api.exchange
      .getExchangeMarketDetails(ticker)
      .then(res => dispatch(getExchangeMarketDetailsSuccess(res.data)))
      .catch(error => dispatch(getExchangeMarketDetailsFailure(error)));
  };
};
