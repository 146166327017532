export default function getStatusColor(status) {
  if (!status) {
    return;
  }
  switch (status.toUpperCase()) {
    case "DONE":
    case "COMPLETED":
    case "EXECUTED":
      return "#49AB5B";
    case "CANCELED":
    case "REJECTED":
      return "#FF2F51";
    case "NEW":
    case "PENDING":
    case "SENT":
      return "yellow";
    default:
      return "#ffffff";
  }
}
