import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import StepperKYC from "./components/StepperKYC";
import PersonalInfo from "./components/PersonalInfo";
import AddressInfo from "./components/AddressInfo";
import DocumentsInfo from "./components/DocumentsInfo/index";
import Selfie from "./components/Selfie";
import VerificationInfo from "./components/VerificationInfo";
import { KYC_STEPS, VERIFICATION_STATUS } from "../../utils/constants/kyc";
import { kycVerify } from "../../redux/actions/kycActions.js";

const initialForm = {
  documentType: "",
  documentAvers: null,
  documentRevers: null,
  selfie: null,
  gender: "",
  firstName: "",
  lastName: "",
  middleName: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  countryAlphaCode: "",
  city: "",
  address: "",
  postIndex: "",
};

const NormalVerification = () => {
  const dispatch = useDispatch();
  const [KYCForm, setKYCForm] = useState(initialForm);
  const { activeStep, verificationStatus } = useSelector(({ kyc }) => kyc);
  useEffect(() => {
    if (
      activeStep === KYC_STEPS.length - 1 &&
      verificationStatus === VERIFICATION_STATUS.NOT_EXIST
    ) {
      dispatch(kycVerify(KYCForm));
    }
  }, [dispatch, activeStep, KYCForm, verificationStatus]);

  const updateKYCForm = data => {
    setKYCForm(Object.assign(KYCForm, data));
  };

  return (
    <Grid container justifyContent='center' alignContent='center'>
      {activeStep !== 4 && <StepperKYC />}
      {activeStep === 0 && (
        <PersonalInfo updateKYCForm={updateKYCForm} KYCForm={KYCForm} />
      )}
      {activeStep === 1 && (
        <AddressInfo updateKYCForm={updateKYCForm} KYCForm={KYCForm} />
      )}
      {activeStep === 2 && (
        <DocumentsInfo updateKYCForm={updateKYCForm} KYCForm={KYCForm} />
      )}
      {activeStep === 3 && (
        <Selfie updateKYCForm={updateKYCForm} KYCForm={KYCForm} />
      )}
      {activeStep === 4 && <VerificationInfo />}
    </Grid>
  );
};

export default NormalVerification;
