import { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import parseURI from "otpauth-uri-parser";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import DialogWrapper from "./DialogWrapper";
import {
  getAccountData,
  checkInstallTwoFA,
} from "../../redux/actions/accountActions";
import { setErrorMessage } from "../../utils/functions/customValidators/validators";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { useCallback } from "react";
import { InputCopiable } from "../components/InputCopiable";

const TwoFaQrCodeDialog = ({ open, handleClose, data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [totpPass, setTotpPass] = useState("");

  const parsedQRCodeData = parseURI(data.qr_code);

  const handleSubmit = () => {
    if (!totpPass) {
      setErrors(setErrorMessage("totpPass"));
      return;
    }
    dispatch(checkInstallTwoFA({ totpPass })).then(response => {
      if (response?.data) {
        dispatch(getAccountData());
        onClose();
      } else {
        setErrors(setErrorMessage("totpPass"));
      }
      setTotpPass("");
    });
  };

  const onClose = () => {
    setTotpPass("");
    setErrors("");
    handleClose();
  };

  const handleChangeCode = value => {
    if (value) setErrors("");
    setTotpPass(value);
  };

  const renderQRCodeParsedData = useCallback(() => {
    const accountName = parsedQRCodeData?.label?.account;
    const secret = parsedQRCodeData?.query?.secret;
    return (
      <Grid className={classes.qrCodeParsedBlock}>
        <CopyWithAlert
          text={(Boolean(accountName) && accountName) || ""}
          label={t("Account name")}>
          <Grid item container justifyContent="center">
            <Typography display="inline-block" variant={"body2"}>{t("Account name") + `: `}</Typography>
            <Typography
              display="inline-block"
              variant={"body2"}
              color={"textPrimary"}
              className={classes.link}>
              {` ${accountName}`}
            </Typography>
          </Grid>
        </CopyWithAlert>
        <Grid item>
          <InputCopiable className={classes.inputCopiable} label="Secret key" value={secret} />
        </Grid>
      </Grid>
    );
  }, [
    parsedQRCodeData?.label?.account,
    parsedQRCodeData?.query?.secret,
    classes,
    t,
  ]);

  return (
    <DialogWrapper dialogClassName={classes.dialog} open={open} handleClose={onClose} maxWidth={520} title={t("ACTIVATE 2FA")}>
      <Typography variant={"h6"} color={"textPrimary"} className={classes.sectionText}>
        {`1. ${t("Configure the App:")}`}
      </Typography>
      <Typography
        variant={"body2"}
        color={"textPrimary"}
        className={classes.descriptionText}
        style={{ textAlign: "justify" }}>
        {t(
          "Open your two factor authentication app and add account by scanning the below QR code."
        )}
      </Typography>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <div className={classes.qr}>
            {Boolean(data) && (
              <QRCode
                value={data?.qr_code}
                size={140}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {renderQRCodeParsedData()}
      <Typography variant={"h6"} color={"textPrimary"} className={classes.sectionText}>
        {`2. ${t("Store Backup Codes:")}`}
      </Typography>
      <Typography
        variant={"body2"}
        color={"textPrimary"}
        className={classes.descriptionText}
        style={{ textAlign: "justify" }}>
        {t(
          "Backup codes can be used to access your account in the event you loose access to your device and cannot receive two-factor authentication codes. Following backup codes are only for one time use, we recommend you to save them securely."
        )}
      </Typography>
      <Grid container className={classes.codes} xs={12}>
        <InputCopiable className={classes.inputCopiable} showFullValue label="Emergency codes" value={Boolean(data) ? data?.emergency_codes.join(", ") : ''} />
      </Grid>
      <Typography
        variant={"h6"}
        color={"textPrimary"}
        className={classes.sectionText}
        style={{ marginBottom: 14 }}>
        {`3. ${t("Enter token code that the application generates:")}`}
      </Typography>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 32 }}>
          <form>
            <Input
              label={t("Enter code")}
              variant='outlined'
              name={"totpPass"}
              type={"text"}
              autoComplete='off'
              error={Boolean(errors)}
              helperText={errors}
              onChange={({ target: { value } }) =>
                /^[0-9]*$/.test(value) &&
                value.length <= 6 &&
                handleChangeCode(value)
              }
              value={totpPass}
            />
          </form>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-around"}
        className={classes.footer}>
        <Button variant='contained' className={classes.btnCancel} onClick={handleClose}>
          {t("Cancel")}
        </Button>
        <Button variant='contained' className={classes.btn} onClick={handleSubmit}>
          {t("Confirm")}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default TwoFaQrCodeDialog;

const useStyles = makeStyles(theme => ({
  dialog: {
    paddingTop: 25,
    overflowY: 'scroll'
  },
  qr: {
    marginTop: 32,
    marginBottom: 32,
    width: 160,
    height: 160,
    padding: 10,
    backgroundColor: "#fff",
  },
  codes: {
    marginTop: 16,
  },
  input: {
    width: "100%",
    minWidth: 200,
    marginTop: 32,
  },
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    marginBottom: 24,
    paddingLeft: 6,
  },
  qrCodeParsedBlock: {
    marginBottom: 16,
    textAlign: "center",
  },
  footer: {
    "& .MuiButton-outlined": {
      padding: "12px 24px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: 16,
      },
    },
    "& button": {
      minWidth: 200,
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
    },
  },
  sectionText: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10,
  },
  descriptionText: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  inputCopiable: {
    width: '100%',
    marginBottom: 40,
  },
  btnCancel: {
    marginTop: 20,
    height: 42,
    minWidth: 180,
    borderColor: `${theme.palette.text.secondary} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.secondary,
    },
  },
  btn: {
    marginTop: 20,
    height: 42,
    minWidth: 180,
    borderColor: `${theme.palette.primary.main} !important`,
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
}));
