import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilityButton from "../../theme/components/VisibilityButton";
import { Button } from "../../theme/components/Button";
import { InputSmall } from "../../theme/components/Input";
import { changePassword } from "../../redux/actions/accountActions";
import { changePasswordValidate } from "../../utils/functions/customValidators/validators";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

const initialForm = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = ({ passError }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [passForm, setPassForm] = useState(initialForm);
  const [hideCurrentPassword, setHideCurrentPassword] = useState(false);
  const [hideNewPassword, setHideNewPassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const isMobile = useIsMobile();

  const handleOnChange = event => {
    event.preventDefault();
    const { name, value } = event?.target;
    errors[name]?.invalid && handleInputValidation({ target: { name, value } });
    setPassForm({ ...passForm, [name]: value });
  };

  const handleChangePassword = () => {
    if (!validateForm()) {
      return;
    }
    let data = { ...passForm };
    delete data.confirmPassword;
    dispatch(changePassword(data));
  };

  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(passForm).forEach(key => {
      errForm = {
        ...errForm,
        [key]: changePasswordValidate(key, passForm[key], passForm, isMobile),
      };
      errForm[key].invalid && (valid = false);
    });
    setErrors(errForm);

    return valid;
  };

  const handleInputValidation = event => {
    const { name, value } = event.target;
    const { invalid, errorMessage } = changePasswordValidate(
      name,
      value,
      passForm,
      isMobile
    );
    setErrors({
      ...errors,
      [name]: { invalid: invalid, errorMessage: errorMessage },
    });
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item container alignContent='center' className={classes.sectionHeader}>
          <Typography className={classes.sectionTitle} variant='h4'>{t("Change password")}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <form>
            <Grid>
              <InputSmall
                error={errors?.currentPassword?.invalid || Boolean(passError)}
                helperText={
                  errors?.currentPassword?.errorMessage ||
                  passError?.response?.data?.title
                }
                className={classes.input}
                label={t("Current password")}
                variant='outlined'
                name={"currentPassword"}
                value={passForm?.currentPassword || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                type={!hideCurrentPassword ? "password" : "text"}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  endAdornment: (
                    <VisibilityButton
                      isLite
                      visibility={hideCurrentPassword}
                      size={24}
                      onChange={setHideCurrentPassword}
                      className={classes.visibilityIcon}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid>
              <InputSmall
                error={errors?.newPassword?.invalid}
                helperText={errors?.newPassword?.errorMessage}
                className={classes.input}
                label={t("New password")}
                variant='outlined'
                name={"newPassword"}
                value={passForm?.newPassword}
                type={hideNewPassword ? "text" : "password"}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  endAdornment: (
                    <VisibilityButton
                      isLite
                      visibility={hideNewPassword}
                      size={24}
                      onChange={setHideNewPassword}
                      className={classes.visibilityIcon}
                    />
                  ),
                }}
              />
              <InputSmall
                error={errors?.confirmPassword?.invalid}
                helperText={errors?.confirmPassword?.errorMessage}
                className={classes.input}
                label={t("Repeat new password")}
                variant='outlined'
                name={"confirmPassword"}
                value={passForm?.confirmPassword}
                type={hideConfirmPassword ? "text" : "password"}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  endAdornment: (
                    <VisibilityButton
                      isLite
                      visibility={hideConfirmPassword}
                      size={24}
                      onChange={setHideConfirmPassword}
                      className={classes.visibilityIcon}
                    />
                  ),
                }}
              />
            </Grid>
          </form>
        </Grid>
        <Grid container item className={classes.buttonColumn} xs={12} sm={5} justifyContent="flex-end" alignItems="flex-end" alignContent="flex-end">
          <Button
            className={classes.changeBtn}
            variant='contained'
            color='primary'
            onClick={handleChangePassword}>
            {t("Change")}
          </Button>
        </Grid>
        <Grid item container justifyContent='flex-start' xs={12} >
          <Typography
            className={classes.descriptionText}>
            {t("The new password must consist of Latin characters (at least 8), have a special character, number, capital letter.")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
  },
  titleBox: {
    marginBottom: 24,
    textAlign: "center",
  },
  title: {
    marginBottom: 24
  },

  sectionTitle: {
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 700,
    marginTop: 24
  },

  sectionHeader: {
    borderBottom: `1px solid rgba(255,255,255,0.2)`,
    paddingBottom: 8,
    marginTop: 24,
    marginBottom: 24,
  },

  statusText: {
    fontSize: 20,
    fontWeight: 700,
  },

  descriptionText: {
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  changeBtn: {
    marginTop: 40,
    height: 42,
    minWidth: 180,
    "& .MuiButton-label": {
      color: theme.palette.text.tertiary,
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: 'center',
      marginTop: 24,
    },
  },
  input: {
    marginBottom: 24,
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0 !important',
    }
  },
  visibilityIcon: {
    transform: 'scale(0.9)',
  },
  iconVisibility: {
    cursor: "pointer",
    fontSize: 20,
    color: "#5ADB6B",
  },
  buttonColumn: {
    marginBottom: 26,
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center',
    },
  }
}));
