export const ErrorFieldsReplacer = ["message", "statusCode", "error"];

export class CustomError extends Error {
  constructor(message, suffix) {
    const stringifiedMessage = JSON.stringify(message, ErrorFieldsReplacer, 2);
    super(stringifiedMessage);

    if (suffix) {
      this.name = `${this.name}[${suffix}]`;
    }
  }
}
