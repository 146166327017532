import { connect } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { useFirebaseAuth } from './hooks/useFirebaseAuth';
import { useEffect } from 'react';

const PrivateRoute = ({ authorized, children, ...routeProps }) => {
  const { loginToFirebase, checkIsLoggedInFirebase } = useFirebaseAuth();

  useEffect(() => {
    if (authorized) {
      const isFirebaseAuthenticated = checkIsLoggedInFirebase();
      if (!isFirebaseAuthenticated) {
        loginToFirebase();
      }
    }
  }, [authorized, checkIsLoggedInFirebase, loginToFirebase]);
  
  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        authorized ? children :
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
      }
    />
  )
}

const mapStateToProps = ({ account }) => ({
  authorized: account.authorized
});

export default connect(mapStateToProps)(PrivateRoute);
