import { REFERRAL_PROGRAM } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
// import { enqueueSnackbar } from "../../redux/actions/notistackActions";
// import i18next from "../../i18n.config";

export const getReferralInfoAction = actionCreator(
  REFERRAL_PROGRAM.GET_REFERRAL_INFO
);

export const getReferralInfo = () => {
  return async (dispatch) => {
    dispatch(getReferralInfoAction.started());
    api.referral
      .getReferralInfo()
      .then((res) => dispatch(getReferralInfoAction.done(res.data)))
      .catch((error) => dispatch(getReferralInfoAction.failed(error)));
  };
};

export const getReferralBalancesAction = actionCreator(
  REFERRAL_PROGRAM.GET_REFERRAL_BALANCE
);

export const getReferralBalances = () => {
  return async (dispatch) => {
    dispatch(getReferralBalancesAction.started());
    api.referral
      .getReferralBalances()
      .then((res) => {
        dispatch(getReferralBalancesAction.done(res.data));
      })
      .catch((error) => dispatch(getReferralBalancesAction.failed(error)));
  };
};

export const getReferralBalancesByAssetAction = actionCreator(
  REFERRAL_PROGRAM.GET_REFERRAL_BALANCE_BY_ASSET
);

export const getReferralBalancesByAsset = (asset) => {
  return async (dispatch) => {
    dispatch(getReferralBalancesByAssetAction.started());
    api.referral
      .getReferralBalancesByAsset(asset)
      .then((res) => {
        dispatch(getReferralBalancesByAssetAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getReferralBalancesByAssetAction.failed(error))
      );
  };
};

export const claimFundsFromReferralBalanceAction = actionCreator(
  REFERRAL_PROGRAM.CLAIM_FUNDS_FROM_REFERRAL_BALANCE
);

export const claimFundsFromReferralBalance = (data) => {
  return async (dispatch) => {
    dispatch(claimFundsFromReferralBalanceAction.started());
    api.referral
      .claimFundsFromReferralBalance(data)
      .then((res) => {
        dispatch(claimFundsFromReferralBalanceAction.done(res.data));
        dispatch(getReferralBalances());
      })
      .catch((error) =>
        dispatch(claimFundsFromReferralBalanceAction.failed(error))
      );
  };
};

export const getClaimHistoryAction = actionCreator(
  REFERRAL_PROGRAM.GET_CLAIM_HISTORY
);

export const getClaimHistory = (params) => {
  return async (dispatch) => {
    dispatch(getClaimHistoryAction.started());
    api.referral
      .getClaimHistory(params)
      .then((res) => {
        dispatch(getClaimHistoryAction.done(res.data));
      })
      .catch((error) => dispatch(getClaimHistoryAction.failed(error)));
  };
};

export const getClaimHistoryByAssetAction = actionCreator(
  REFERRAL_PROGRAM.GET_CLAIM_HISTORY_BY_ASSET
);

export const getClaimHistoryByAsset = (asset, params) => {
  return async (dispatch) => {
    dispatch(getClaimHistoryByAssetAction.started());
    api.referral
      .getClaimHistoryByAsset(asset, params)
      .then((res) => {
        dispatch(getClaimHistoryByAssetAction.done(res.data));
      })
      .catch((error) => dispatch(getClaimHistoryByAssetAction.failed(error)));
  };
};

export const getReferralRewardsAction = actionCreator(
  REFERRAL_PROGRAM.GET_REFERRAL_REWARDS
);

export const getReferralRewards = (params) => {
  return async (dispatch) => {
    dispatch(getReferralRewardsAction.started());
    api.referral
      .getReferralRewards(params)
      .then((res) => {
        dispatch(getReferralRewardsAction.done(res.data));
      })
      .catch((error) => dispatch(getReferralRewardsAction.failed(error)));
  };
};

export const getReferralRewardsByAssetAction = actionCreator(
  REFERRAL_PROGRAM.GET_REFERRAL_REWARDS_BY_ASSET
);

export const getReferralRewardsByAsset = (asset, params) => {
  return async (dispatch) => {
    dispatch(getReferralRewardsByAssetAction.started());
    api.referral
      .getReferralRewardsByAsset(asset, params)
      .then((res) => {
        dispatch(getReferralRewardsByAssetAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getReferralRewardsByAssetAction.failed(error))
      );
  };
};

export const clearReferralStatus = () => ({
  type: REFERRAL_PROGRAM.CLEAR_REFERRAL_STATUS,
});
