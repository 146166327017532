import { Typography, Paper, makeStyles, Grid, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from '../../assets/icons/delete_plan_icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/edit_plan_icon.svg'
import { SwitchButton } from "../../theme/components/SwitchButton";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { INVEST_PLAN_CYCLE } from "../../theme/dialogs/CreateAutoInvestPlanDialog/components/InvestPlanCyclesList";
import { capitalizeString, formatTicker } from "../../utils/functions/string";
import { getDaysDiffFromNow } from "../../utils/functions/date";
import { useSelector } from "react-redux";
import { getOnlyVisiblePairs } from "../../theme/dialogs/CreateAutoInvestPlanDialog/CreateAutoInvestPlanDialog";
import clsx from "clsx";
import { roundValue } from "../../utils/functions/roundValue";
import { getNumberSign, isNegativeValue } from "../../utils/functions/numbers";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import { theme } from "../../theme/theme";

// PLAN OBJECT EXAMPLE
// {
//     "id": 61,
//     "programName": "Test",
//     "assetFrom": "btc",
//     "assetTo": "usdt-trc20",
//     "amount": "0.001",
//     "recurringCycle": {
//         "type": "DAILY",
//         "timeZone": "Europe/Kiev",
//         "time": "17:21"
//     },
//     "enabled": true,
//     "totalInvested": "0.011",
//     "totalReward": "0",
//     "averagePrice": "0",
//     "lastTriggerTime": "2023-11-06T17:21:00.001+02:00[Europe/Kiev]",
//     "nextTriggerTime": "2023-11-07T17:21+02:00[Europe/Kiev]",
//     "creationTime": "2023-11-06T15:15:04.384025Z",  (UTC)
//     "executionCount": 2
// }

const AutoInvestPlanCard = ({ data, onEditClick, onDeleteClick, onChangeStatus }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { settings, allTickerSettings } = useSelector(({ wallets }) => wallets);
  const { pairs } = useSelector(
    ({ exchange }) => exchange
  );
  const visiblePairsList = getOnlyVisiblePairs({ pairs, settings })
  const coinPairSettings = useMemo(
    () =>
      visiblePairsList.find(
        (i) => i.assetFrom === data?.assetFrom && i.assetTo === data?.assetTo
      ),
    [data?.assetFrom, data?.assetTo, visiblePairsList]
  );
  const minScaleInvestedAssetFrom = useMemo(() => {
    return allTickerSettings?.find(
      (setting) => setting?.ticker === data?.assetFrom
    )?.minScale;
  }, [allTickerSettings, data?.assetFrom]);
  const investedFromCoinLastTotalAmountPrice = data?.totalReward / coinPairSettings?.price
  const unrealizedInvestmentProfit = roundValue(investedFromCoinLastTotalAmountPrice - data?.totalInvested, minScaleInvestedAssetFrom)
  const unrealizedInvestmentProfitPercentage = roundValue((unrealizedInvestmentProfit / data?.totalInvested * 100) || 0, 2)

  const investedFromCoinTicker = formatTicker(data?.assetFrom)
  const investedToCoinTicker = formatTicker(data?.assetTo)
  const isOneTimeInvestment = data?.recurringCycle?.type === INVEST_PLAN_CYCLE.ONE_TIME
  const investingDays = getDaysDiffFromNow(data?.creationTime)

  const investmentInterval = useMemo(() => {
    const investmentType = data?.recurringCycle?.type
    if (isOneTimeInvestment) {
      return ''
    }
    if (investmentType === INVEST_PLAN_CYCLE.DAILY) {
      return 'Daily'
    }
    if (investmentType === INVEST_PLAN_CYCLE.MONTHLY) {
      return `Monthly (${data?.recurringCycle?.dayOfMonth}th)`
    }
    if (investmentType === INVEST_PLAN_CYCLE.WEEKLY) {
      return `Weekly (${capitalizeString(data?.recurringCycle?.dayOfWeek)})`
    }
    if (investmentType === INVEST_PLAN_CYCLE.HOURLY_12) {
      return 'Twice a day'
    }
    return 'Hourly'
  }, [data?.recurringCycle?.dayOfMonth, data?.recurringCycle?.dayOfWeek, data?.recurringCycle?.type, isOneTimeInvestment])

  const handleEditPlan = useCallback(() => {
    onEditClick?.(data)
  }, [data, onEditClick])

  const handleDeletePlan = useCallback(() => {
    onDeleteClick?.(data)
  }, [data, onDeleteClick])

  const handleChangeStatus = useCallback((event) => {
    const isChecked = event.target.checked
    onChangeStatus({ isEnabled: isChecked, planId: data?.id })
  }, [data?.id, onChangeStatus])

  return (
    <Paper className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item>
          <Typography className={classes.title}>
            {data?.programName}
          </Typography>
          <Typography className={classes.description}>
            {`${t("Invest")} ${data?.amount} ${investedFromCoinTicker} ${investmentInterval}`} {isOneTimeInvestment && <span className={classes.oneTimeLabel}>{t('One time')}</span>}
          </Typography>
        </Grid>
        <Grid item className={classes.actionsBlock}>
          <EditIcon onClick={handleEditPlan} className={classes.actionIcon} />
          <DeleteIcon onClick={handleDeletePlan} className={classes.actionIcon} />
        </Grid>
      </Grid>
      <Grid className={classes.content} container justifyContent="space-between">
        <Grid item>
          <Box className={classes.contentSection}>
            <div className={classes.coinLogo}>
              <img
                src={`https://cryptoneed.com/icons/${data?.assetTo}.svg`}
                alt={data?.assetTo}
                width={32}
                height={32}
                loading={"lazy"}
              />
              <Typography className={classes.ticker}>
                {data?.assetTo?.toUpperCase?.()}
              </Typography>
            </div>
          </Box>
          <Box className={classes.contentSection}>
            <Typography className={classes.label}>
              {t("Total Invested")}
            </Typography>
            <Typography className={classes.priceText}>
              {coloredFirstLetter(`${data?.totalInvested || 0} ${investedFromCoinTicker}`)}
            </Typography>
          </Box>
          <Box className={classes.contentSection}>
            <Typography className={classes.label}>
              {t("Total buy")}
            </Typography>
            <Typography className={classes.priceText}>
              {coloredFirstLetter(`${data?.totalReward} ${investedToCoinTicker}`)}
            </Typography>
            <Typography className={[classes.description, classes.noMargin]}>
              {`${roundValue(investedFromCoinLastTotalAmountPrice, minScaleInvestedAssetFrom)} ${investedFromCoinTicker}`}
            </Typography>
          </Box>
          <Box className={classes.contentSection}>
            <Typography className={classes.label}>
              {t("Average price")}
            </Typography>
            <Typography className={classes.priceText}>
              {coloredFirstLetter(`${data?.averagePrice} ${investedFromCoinTicker}`)}
            </Typography>
            <Typography className={[classes.description, classes.noMargin]}>
              {`1 ${investedToCoinTicker}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.rightContentColumn}>
          <Box textAlign={'right'}>
            <Typography className={[classes.description, classes.noMargin]} >
              {t("Status")}
            </Typography>
            <div className={classes.switcher}>
              <SwitchButton checked={data?.enabled || false}
                onChange={handleChangeStatus}
              />
              <Typography className={[classes.switcherLabel]}>
                {data?.enabled ? t('Ondoing') : t('Paused')}
              </Typography>
            </div>
          </Box>
          <Box className={classes.contentSection} textAlign={'right'}>
            <Typography className={clsx(classes.label)}>
              {t("Profit")}
            </Typography>
            <Typography className={clsx(classes.priceText, isNegativeValue(unrealizedInvestmentProfit) ? classes.negativePriceText : {})}>
              {coloredFirstLetter(`${getNumberSign(unrealizedInvestmentProfit)} ${investedFromCoinTicker}`, isNegativeValue(unrealizedInvestmentProfit) ? theme.palette.error.main : undefined)}
            </Typography>
            <Typography className={[classes.description, classes.noMargin]}>
              {`${getNumberSign(unrealizedInvestmentProfitPercentage)} %`}
            </Typography>
          </Box>
          <Box className={classes.contentSection} textAlign={'right'}>
            <Typography className={classes.description}>
              {t("Plan created")}
            </Typography>
            <Typography className={classes.description}>
              {moment(data?.creationTime).format('DD/MM/yyyy HH:mm')}
            </Typography>
          </Box>
          <Box className={classes.contentSection} textAlign={'right'}>
            <Typography className={classes.description}>
              {t("Investing time")}
            </Typography>
            <Typography className={classes.description}>
              {investingDays} {t("days")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AutoInvestPlanCard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "20px",
    // },
  },
  header: {
    borderBottom: `0.2px solid rgba(255,255,255,0.1)`,
    padding: '30px 30px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: '20px 30px 20px',
  },
  rightContentColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  coinLogo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginBottom: 10,
    marginTop: 6,
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    marginBottom: 7,
  },
  description: {
    fontSize: 13,
    marginTop: 6,
    fontWeight: 400,
    color: theme.palette.text.darkLighter,
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    fontSize: 13,
    marginTop: 6,
    fontWeight: 300,
  },
  priceText: {
    fontSize: 18,
    marginTop: 8,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  negativePriceText: {
    color: theme.palette.error.main,
  },
  ticker: {
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  coloredText: {
    color: theme.palette.primary.main
  },
  rewardIcon: {
    maxHeight: 72
  },
  noteText: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  rewardContainer: {
    marginTop: 40
  },
  oneTimeLabel: {
    fontSize: 10,
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
    padding: '2px 6px',
    borderRadius: 4,
    marginLeft: 6
  },
  link: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
    color: theme.palette.text.quaternary,
  },
  actionIcon: {
    width: 34,
    height: 34,
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.8,
    },
  },
  actionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  },
  contentSection: {
    marginBottom: 20
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
  },
  switcherLabel: {
    fontSize: 13,
    fontWeight: 400,
  }
}));
