import { useMemo, useState } from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilityButton from "../../theme/components/VisibilityButton";
import getEmoji from "../../services/emoji";
import { VerticalButton } from "../../theme/components/VerticalButton";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import OverviewBlock from "../../theme/components/OverviewBlock";
import {
  closeReceiveDialog,
  closeReplenishmentMethodDialog,
  openReceiveDialog,
  openReplenishmentMethodDialog,
} from "../../redux/actions/dialogActions";

import { useHistory } from "react-router-dom";
import SelectWalletDialog from "../../theme/dialogs/SelectWalletDialog";
import ReceiveDialog from "../../theme/dialogs/ReceiveDialog";
import ReplenishmentMethodDialog from "../Receive/fiat/ReplenishmentMethodDialog";
import { roundValue } from "../../utils/functions/roundValue";

const Overview = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isReceiveDialog = useSelector(({ dialogs }) =>
    Boolean(dialogs?.receive?.selectedWallet)
  );
  const { total, data } = useSelector(({ market }) => market);
  const { filteredData, settings } = useSelector(({ wallets }) => wallets);
  const [visibility, setVisibility] = useState(true);
  const [open, setOpen] = useState(false);
  const active = useMemo(() => {
    return open || isReceiveDialog;
  }, [open, isReceiveDialog]);

  const handleSelect = wallet => {
    if (wallet?.ticker !== "uaht") {
      dispatch(openReceiveDialog(wallet));
    } else {
      dispatch(openReplenishmentMethodDialog(wallet));
    }
    setOpen(false);
  };

  const btcTotal = useMemo(() => {
    const btcPrice = data?.find(i => i?.ticker === "btc")?.price;
    const round = 1000000;
    return Math.round((total / btcPrice) * round) / round || 0;
  }, [data, total]);

  const isHiddenBalances = useMemo(() => {
    return filteredData?.find(
      w =>
        w?.balance > 0 && !settings?.find(i => i?.ticker === w?.ticker)?.visible
    );
  }, [settings, filteredData]);


  const handleBackReceive = () => {
    dispatch(closeReceiveDialog());
    dispatch(closeReplenishmentMethodDialog());
    setOpen(true);
  };
  const handleClick = name => {
    switch (name) {
      case "exchange":
        history.push({ pathname: "/exchange" });
        return;
      case "receive":
        setOpen(true);
        return;
      case "send":
        history.push("/send");
        return;
      default:
        setOpen(false);
    }
  };

  return (
    <>
      <OverviewBlock>
        <Grid container spacing={1} alignItems={"center"} className={classes.header}>
          <Grid item>
            <VisibilityButton
              visibility={visibility}
              size={36}
              onChange={setVisibility}
            />
          </Grid>
          <Grid item>
            <Typography
              variant={"h4"}
              className={classes.title}>
              {t("Total balance")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          style={{ marginTop: 8 }}>
          <Grid item>
            <Typography variant={"h2"} className={classes.priceText}>
              {visibility ? `$ ${roundValue(total, 2)}` : `$ ${getEmoji()}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.btcEqText}>
              {"= "}
              {visibility ? btcTotal : getEmoji()}
              {" BTC"}
            </Typography>
          </Grid>
        </Grid>
        {isHiddenBalances && (
          <Grid container style={{ marginTop: 8 }}>
            <Typography className={classes.attention} variant={"body1"}>
              {isHiddenBalances && t("You have hidden balances")}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          style={{ marginTop: isHiddenBalances ? 48 : 76 }}>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='receiveBtn'
                style={{
                  color: active ? "#5ADB6B" : "#fff",
                  borderColor: active ? "#5ADB6B" : theme.palette.border.secondary,
                }}
                onClick={() => handleClick("receive")}
                className={active === "receive" ? "active" : ""}>
                <GetAppOutlinedIcon />
                {t("Receive")}
              </VerticalButton>
            </Grid>
          </Grid>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='send'
                onClick={() => handleClick("send")}>
                <PublishOutlinedIcon />
                {t("Withdraw")}
              </VerticalButton>
            </Grid>
          </Grid>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                onClick={() => handleClick("exchange")}>
                <SyncAltIcon />
                {t("Exchange")}
              </VerticalButton>
            </Grid>
          </Grid>
        </Grid>
      </OverviewBlock>
      <SelectWalletDialog
        open={open}
        onClose={() => setOpen(false)}
        onSelect={handleSelect}
      />
      <ReceiveDialog handleBackReceive={handleBackReceive} />
      <ReplenishmentMethodDialog handleBackReceive={handleBackReceive} />
    </>
  );
};

export default Overview;

export const useStyles = makeStyles(theme => ({
  header: {
    marginLeft: -12,
    marginTop: -14
  },
  title: {
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  priceText: {
    marginTop: -8,
    fontWeight: 700,
    marginLeft: 6,
  },
  btcEqText: {
    marginLeft: 6,
    fontWeight: 300,
    color: theme.palette.text.darkLighter,
  },
  verticalButton: {
    width: "calc((100% - 20px) / 3)",
  },
  positive: {
    color: theme.palette.success.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
  active: {
    color: `${theme.palette.primary.light} !important`,
    borderColor: `${theme.palette.primary.light} !important`,
  },
  attention: {
    color: theme.palette.attention.main,
  },
}));
