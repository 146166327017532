import { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import {
  Container,
  Grid,
  makeStyles,
  Link,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { closeInfoDialog } from "../../../redux/actions/dialogActions";
import { backToMain } from "../../../redux/actions/accountActions";
import MailIcon from "../../../assets/icons/mail.svg";
import TelegramIcon from "../../../assets/icons/telegram.svg";
import InfoDialog from "../../dialogs/InfoDialog";
import Logo from "../../../assets/images/logo_header.png";
import {
  SUPPORT_EMAIL,
  SUPPORT_TELEGRAM_CHAT,
} from "../../../utils/constants/support";
import HeaderMenu from "./components/Menu";
import { getKycStatus } from "../../../redux/actions/kycActions";
import LanguageMenu from "./components/LanguageMenu";
import BurgerIcon from "../BurgerIcon/BurgerIcon";
import NavigationMenu from "./components/NavigationMenu";

const Header = ({ dispatch }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authorized } = useSelector(({ account }) => account);
  const showInfoDialog = useSelector(({ dialogs }) => dialogs?.info?.open);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if (authorized) {
      dispatch(getKycStatus());
    }
  }, [dispatch, authorized]);

  const handleBurgerClick = useCallback((isOpened) => {
    setShowMobileMenu(isOpened);
  }, []);

  const handleGoBackToMain = useCallback(() => {
    dispatch(backToMain())
  }, [dispatch])

  return (
    <>
      <div className={classes.header} style={showMobileMenu ? { background: 'rgba(24, 24, 24, 0.97)' } : {}}>
        <Container maxWidth="lg" className={classes.headerContent}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            className={classes.laptop}
          >
            <Grid item>
              <img
                src={Logo}
                className={classes.logo}
                alt="Cryptoneed"
                onClick={handleGoBackToMain}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} direction={"row"}>
                <Grid item>
                  <Grid container alignItems={"center"} direction={"row"}>
                    {authorized && (
                      <Grid item>
                        <NavigationMenu />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems={"center"} direction={"row"}>
                    {authorized && (
                      <Grid item>
                        <HeaderMenu />
                      </Grid>
                    )}
                    <Grid item>
                      <LanguageMenu />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* MOBILE */}
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            className={clsx(classes.emptySpace, classes.mobile)}
          >
            <Grid item>
              <img
                src={Logo}
                className={classes.logo}
                alt="Cryptoneed"
                onClick={handleGoBackToMain}
              />
            </Grid>
            <Grid item>
              <div className={classes.mobileHeaderWrapper}>
                <BurgerIcon onChange={handleBurgerClick} />
              </div>
            </Grid>
          </Grid>
          {showMobileMenu && (
            <Grid
              className={classes.navContainerMobile}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid className={classes.navContainerMobileContent} container item alignItems={"flex-start"} direction={"column"}>
                {authorized && (
                  <>
                    <Grid item>
                      <NavigationMenu />
                    </Grid>
                    <Grid item>
                      <LanguageMenu />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item container direction="row" alignItems={"flex-end"} className={classes.accountLogoWrapper}>
                {authorized && (
                  <Grid item>
                    <HeaderMenu />
                  </Grid>
                )}
                {authorized && (
                  <Grid item className={classes.mobileLogoutText}>
                    <Typography className={classes.mobileMenuTextSmall}>{t('Log out')}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      <div>
        <InfoDialog
          withNoButton
          open={showInfoDialog}
          title={t("Support")}
          handleClose={() => dispatch(closeInfoDialog())}
        >
          <Grid container direction="column">
            <Typography
              variant={"body1"}
              align={"center"}
              style={{ marginBottom: "16px" }}
            >
              {t("If you have any questions, please contact")}
            </Typography>
            <Grid item container className={classes.linkWrap}>
              <img
                src={MailIcon}
                alt={"email"}
                loading={"lazy"}
                style={{ width: 24, height: 24 }}
              />
              <Link
                variant={"body1"}
                href={`mailto:${SUPPORT_EMAIL}`}
                style={{ marginLeft: 20 }}
              >
                {SUPPORT_EMAIL}
              </Link>
            </Grid>
            <Grid item container className={classes.linkWrap}>
              <img
                src={TelegramIcon}
                alt={"telegram"}
                loading={"lazy"}
                style={{ width: 24, height: 24 }}
              />
              <Link
                variant={"body1"}
                href={SUPPORT_TELEGRAM_CHAT}
                style={{ marginLeft: 20 }}
              >
                {t("open telegram chat")}
              </Link>
            </Grid>
          </Grid>
        </InfoDialog>
      </div>
    </>
  );
};

const mapStateToProps = ({ account }) => ({
  data: account.data,
});

export default connect(mapStateToProps)(Header);

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.header,
    position: "absolute",
    height: 90,
    width: "100%",
    zIndex: 9,
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down(800)]: {
      "&:after": {
        opacity: 0
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: "14px 0px",
    },
  },
  headerContent: {
    position: 'relative',
    "&:after": {
      content: "''",
      position: "absolute",
      background: theme.palette.primary.default,
      bottom: "-20px",
      right: '12px',
      width: "62%",
      height: "0.5px",
      zIndex: 1,
      opacity: 0.2,
      [theme.breakpoints.down(800)]: {
        width: 0,
      },
    },
  },
  mobile: {
    display: "none",
    backgroundColor: theme.palette.background.header,
    zIndex: 10,
    [theme.breakpoints.down(800)]: {
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "stretch",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.background.header,
    },
  },
  mobileMenuTextSmall: {
    fontSize: 14,
    textTransform: 'uppercase',
    marginTop: 10
  },
  laptop: {
    display: "flex",
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  mobileHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  navContainerMobile: {
    display: "none",

    [theme.breakpoints.down(800)]: {
      backgroundColor: 'rgba(24, 24, 24, 0.97)',
      display: "flex",
      transition: "all 0.3s ease",
      flexDirection: "row",
      padding: "26px",
      paddingTop: "44px",
      alignItems: "flex-start",
      transform: "translateY(-300px)",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      right: "0px",
      height: "fit-content",
      top: "362px",
      zIndex: 1,
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  accountLogoWrapper: {
    [theme.breakpoints.down(800)]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
      paddingTop: 10,
      flex: 1
    }
  },
  navContainerMobileContent: {
    flex: 1,
    minWidth: 100,
  },
  mobileLogoutText: {
    marginRight: 27,
  },
  logo: {
    height: 46,
    cursor: "pointer",
    [theme.breakpoints.down(1440)]: {
      height: 42,
    },
    [theme.breakpoints.down(1128)]: {
      height: 38,
    },
  },
  menuBtn: {
    "&.MuiButton-outlined": {
      minWidth: 114,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& img": {
      marginLeft: 11,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginLeft: 16,
      "& img": {
        marginLeft: 8,
        width: 12,
        height: 12,
      },
    },
  },
  menuBtnLang: {
    color: theme.palette.text.primary,
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    marginLeft: 24,
    minWidth: 0,
    "& img": {
      marginLeft: 10,
      width: 24,
      height: 24,
    },
    "&:hover": {
      backgroundColor: "inherit",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginLeft: 16,
      "& img": {
        marginLeft: 8,
        width: 12,
        height: 12,
      },
    },
  },
  widget: {
    display: "flex",
    alignItems: "center",
    marginLeft: 12,
    padding: "4px 8px",
    borderRadius: 4,
    fontWeight: 400,
    "& img": {
      marginRight: 6,
      width: 12,
      height: 12,
    },
  },
  confirmed: {
    background: "#008C39",
  },
  pending: {
    background: "#F9B630",
  },
  notConfirmed: {
    background: "#2A2E3A",
  },
  linkWrap: {
    paddingLeft: "calc(100% / 5)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },
  popOverRoot: {
    pointerEvents: "none",
  },
}));
