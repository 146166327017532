import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { Transaction, TransactionDialog, TransactionInvoice } from "../Transactions";
import Pagination from "@material-ui/lab/Pagination";
import { isLoading } from "../../utils/functions/requestStatuses";
import { getCompanyBalanceTransferHistory } from "../../redux/actions/companiesActions";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import WithdrawCompanyBalanceConfirm from "./WithdrawCompanyBalanceConfirm";

const format = time => moment(time).startOf("day").format("DD.MM.yyyy");

const size = 10;

const CompanyBalanceTransactions = ({ balanceId, balanceTicker, companyName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [showWithdrawalConfirmDialog, setShowWithdrawalConfirmDialog] = useState(false);
  const [selectedTransferInvoice, setSelectedTransferInvoice] = useState(null);
  const { content, totalElements } = useSelector(companiesSelectors.companyBalanceTransferHistory);
  const companyBalanceTransferInvoices = useSelector(companiesSelectors.companyBalanceTransferInvoices);
  const [detail, setDetail] = useState(null);
  const getCompanyBalanceTransfersRequestStatus = useSelector(companiesSelectors.getCompanyBalanceTransfersRequestStatus)
  const isLoaderNeeded = isLoading(getCompanyBalanceTransfersRequestStatus)

  const transactions =
    content
      .sort((a, b) => moment.utc(b.time).diff(moment.utc(a.time)))
      .reduce(
        (res, t) => ({
          ...res,
          [format(t.time)]: [...(res[format(t.time)] || []), t],
        }),
        {}
      )

  useEffect(() => {
    if (balanceId) {
      dispatch(getCompanyBalanceTransferHistory({ balanceId: balanceId, params: { page, size } }));
    }
  }, [balanceId, dispatch, page]);

  const handleTransferInvoiceClick = useCallback((invoice) => {
    setShowWithdrawalConfirmDialog(true);
    setSelectedTransferInvoice(invoice);
  }, [])

  return (
    <Box className={classes.block}>
      <Typography variant={"h4"} style={{ marginTop: 16 }}>
        {coloredFirstLetter(t("Transactions history").toUpperCase())}
      </Typography>
      {totalElements === 0 && !companyBalanceTransferInvoices?.length ? (
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          style={{
            textAlign: "center",
            marginTop: 24,
          }}>
          {t("You have no transactions...")}
        </Typography>
      ) : (
        <>
          {companyBalanceTransferInvoices?.map((invoice) => {
            return (
              <TransactionInvoice
                key={invoice?.id}
                invoice={invoice}
                onClick={handleTransferInvoiceClick}
              />
            )
          })
          }
          {Object.keys(transactions).map(transactionTime => (
            <div key={transactionTime}>
              <Typography
                variant={"body2"}
                color={"textSecondary"}
                style={{ textAlign: "right", marginBottom: 10, marginTop: 20 }}>
                {format() === transactionTime ? t("Today") : transactionTime}
              </Typography>
              {transactions[transactionTime].map(transaction => {
                const transactionData = {
                  address: transaction?.address,
                  amount: transaction?.amount,
                  create: transaction?.time,
                  description: transaction?.description || "",
                  id: transaction?.id,
                  ticker: balanceTicker,
                  transactionStatus: "Completed",
                  transactionType: transaction?.transactionType,
                  user: transaction?.user,
                }
                return (
                  <Transaction
                    key={transaction?.id}
                    transaction={transactionData}
                  />
                )
              })}
            </div>
          ))}
        </>

      )}
      {totalElements > size && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 32,
          }}>
          <Pagination
            count={Math.ceil(totalElements / size)}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
            color='primary'
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
      <TransactionDialog
        transaction={detail}
        handleClose={() => setDetail(null)}
      />
      <WithdrawCompanyBalanceConfirm
        invoice={selectedTransferInvoice}
        open={showWithdrawalConfirmDialog}
        onClose={() => setShowWithdrawalConfirmDialog(false)}
        companyName={companyName}
        companyBalanceId={balanceId}
      />
      {
        isLoaderNeeded &&
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      }
    </Box>
  );
};

export default CompanyBalanceTransactions;

const useStyles = makeStyles(theme => ({
  block: {
    padding: 24,
    background: theme.palette.primary.light,
    borderRadius: 20,
    position: 'relative',
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#212328',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }
}));
