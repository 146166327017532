import { useMemo, useState } from "react";
import { Box, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { VerticalButton } from "../../theme/components/VerticalButton";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import OverviewBlock from "../../theme/components/OverviewBlock";
import {
  closeReceiveDialog,
  openReceiveDialog,
} from "../../redux/actions/dialogActions";

import SelectWalletDialog from "../../theme/dialogs/SelectWalletDialog";
import ReceiveDialog from "../../theme/dialogs/ReceiveDialog";
import { roundValue } from "../../utils/functions/roundValue";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import WithdrawCompanyBalance from "../CompanyBalance/WithdrawCompanyBalance";

const COMPANY_TRANSACTION_TYPES = {
  SEND: 'send',
  RECEIVE: 'receive'
}

const CompanyOverview = ({ totalInUsd = 0, companyId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isReceiveDialog = useSelector(({ dialogs }) =>
    Boolean(dialogs?.receive?.selectedWallet)
  );
  const { data } = useSelector(({ market }) => market);
  const companiesList = useSelector(companiesSelectors.companiesList)
  const selectedCompanyData = useMemo(() => {
    return companiesList.find((company) => Number(company?.companyId) === Number(companyId));
  }, [companiesList, companyId])
  const companyBalancesList = useMemo(() => {
    return selectedCompanyData?.balances;
  }, [selectedCompanyData?.balances])
  const balanceListTickers = useMemo(() => companyBalancesList?.map((item) => item.currency), [companyBalancesList])
  const [open, setOpen] = useState(false);
  const [showWithdrawBalanceModal, setShowWithdrawBalanceModal] = useState(false);
  const [transactionType, setTransactionType] = useState('');
  const [selectedCompanyBalanceToTransfer, setSelectedCompanyBalanceToTransfer] = useState(null);
  const active = useMemo(() => {
    return open || isReceiveDialog;
  }, [open, isReceiveDialog]);

  const handleSelect = wallet => {
    const selectedCompanyBalance = companyBalancesList.find((item) => wallet?.ticker === item.currency);

    setSelectedCompanyBalanceToTransfer({
      ticker: selectedCompanyBalance?.currency || '',
      currency: selectedCompanyBalance?.currency || '',
      address: selectedCompanyBalance?.address || '',
      balance: selectedCompanyBalance?.balance || '',
      balanceId: selectedCompanyBalance?.balanceId || '',
      companyId: selectedCompanyBalance?.companyId || '',
    })
    if (transactionType === COMPANY_TRANSACTION_TYPES.RECEIVE && selectedCompanyBalance) {
      dispatch(openReceiveDialog(wallet));
    }
    if (transactionType === COMPANY_TRANSACTION_TYPES.SEND && selectedCompanyBalance) {
      setShowWithdrawBalanceModal(true);
    }
    setOpen(false);
  };

  const btcTotal = useMemo(() => {
    const btcPrice = data?.find(i => i?.ticker === "btc")?.price;
    const round = 1000000;
    return Math.round((totalInUsd / btcPrice) * round) / round || 0;
  }, [data, totalInUsd]);


  const handleBackReceive = () => {
    dispatch(closeReceiveDialog());
    setOpen(true);
  };
  const handleClick = name => {
    switch (name) {
      case COMPANY_TRANSACTION_TYPES.RECEIVE:
        setOpen(true);
        setTransactionType(COMPANY_TRANSACTION_TYPES.RECEIVE)
        return;
      case COMPANY_TRANSACTION_TYPES.SEND:
        setOpen(true);
        setTransactionType(COMPANY_TRANSACTION_TYPES.SEND)
        return;
      default:
        setOpen(false);
    }
  };

  return (
    <>
      <OverviewBlock>
        <Grid container spacing={1} alignItems={"center"} className={classes.header}>
          <Grid item>
            <Typography
              variant={"h3"}
              className={classes.title}>
              {t("Company balance")}
            </Typography>
          </Grid>
          <Box className={classes.shortName}>
            {selectedCompanyData?.shortName}
          </Box>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          style={{ marginTop: 8 }}>
          <Grid item>
            <Typography variant={"h2"} className={classes.priceText}>
              {`$ ${roundValue(totalInUsd, 2)}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant={"body1"} className={classes.btcEqText}>
              {"= "}
              {btcTotal}
              {" BTC"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-around"}
          style={{ marginTop: 76 }}>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='receiveBtn'
                style={{
                  color: active ? theme.palette.primary.active : "#fff",
                  borderColor: active ? theme.palette.primary.active : theme.palette.border.secondary,
                }}
                onClick={() => handleClick("receive")}
                className={active === "receive" ? "active" : ""}>
                <GetAppOutlinedIcon />
                {t("Receive")}
              </VerticalButton>
            </Grid>
          </Grid>
          <Grid item className={classes.verticalButton}>
            <Grid container justifyContent='center'>
              <VerticalButton
                variant='outlined'
                name='send'
                onClick={() => handleClick("send")}>
                <PublishOutlinedIcon />
                {t("Withdraw")}
              </VerticalButton>
            </Grid>
          </Grid>
        </Grid>
      </OverviewBlock>
      <SelectWalletDialog
        open={open}
        onClose={() => setOpen(false)}
        onSelect={handleSelect}
        visibleCurrenciesListTickers={balanceListTickers}
      />
      <ReceiveDialog handleBackReceive={handleBackReceive} customData={selectedCompanyBalanceToTransfer} />
      <WithdrawCompanyBalance
        open={showWithdrawBalanceModal}
        onClose={() => setShowWithdrawBalanceModal(false)}
        companyBalanceData={selectedCompanyBalanceToTransfer}
      />
    </>
  );
};

export default CompanyOverview;

export const useStyles = makeStyles(theme => ({
  header: {
    marginLeft: 4,
    marginBottom: 12,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  shortName: {
    width: 40,
    height: 40,
    borderRadius: 8,
    background: '#2852A0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    marginRight: 12,
    fontWeight: 'bold'
  },
  priceText: {
    marginTop: -8,
    fontWeight: 700,
    marginLeft: 6,
  },
  btcEqText: {
    marginLeft: 6,
    fontWeight: 300,
    color: theme.palette.text.darkLighter,
  },
  verticalButton: {
    width: "calc((100% - 20px) / 3)",
  },
  positive: {
    color: theme.palette.success.main,
  },
  negative: {
    color: theme.palette.error.main,
  },
  active: {
    color: `${theme.palette.primary.light} !important`,
    borderColor: `${theme.palette.primary.light} !important`,
  },
  attention: {
    color: theme.palette.attention.main,
  },
}));
