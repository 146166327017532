import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { Button } from "../../Button";
import { useTranslation } from "react-i18next";
import {
  LANGUAGE,
  LANGUAGE_TICKER,
} from "../../../../utils/constants/languages";
import { setStorageItem, StorageItem } from "../../../../utils/local-storage";
import { setLanguage } from "../../../../redux/actions/languageActions";
import clsx from "clsx";

const LanguageMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { language } = useSelector(({ language }) => language);
  const [langEl, setLangEl] = useState(null);
  let currentlyHoveringLang = false;
  const { i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleLangChange = (value) => {
    dispatch(setLanguage(value));
    setStorageItem(StorageItem.AppLanguage, value);
    i18n.changeLanguage(value);
    handleLangClose();
  };

  const handleLangClick = (event) => {
    if (langEl !== event.currentTarget) {
      setTimeout(setLangEl(event.currentTarget), 200);
    }
  };

  function handleLangHover() {
    currentlyHoveringLang = true;
  }

  const handleLangClose = () => {
    setLangEl(null);
  };

  function handleLangCloseHover() {
    currentlyHoveringLang = false;
    setTimeout(() => {
      if (!currentlyHoveringLang) {
        handleLangClose();
      }
    }, 50);
  }

  return (
    <>
      {!isMobile ?
        <>
          <Button
            onClick={handleLangClick}
            onMouseOver={handleLangClick}
            onMouseLeave={handleLangCloseHover}
            className={classes.menuBtnLang}
          >
            {language?.toUpperCase()}
          </Button>
          <StyledMenuLang
            id="customized-lang-menu"
            anchorEl={langEl}
            keepMounted
            open={Boolean(langEl)}
            onClose={handleLangClose}
            style={{ left: "23px" }}
            MenuListProps={{
              onMouseEnter: handleLangHover,
              onMouseLeave: handleLangCloseHover,
              style: { pointerEvents: "auto" },
            }}
            getContentAnchorEl={null}
            PopoverClasses={{
              root: classes.popOverRoot,
            }}
          >
            <StyledMenuItem>
              <ListItemText
                onClick={() => handleLangChange(LANGUAGE_TICKER.en)}
                primary={LANGUAGE.English}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                onClick={() => handleLangChange(LANGUAGE_TICKER.ua)}
                primary={LANGUAGE.Ukrainian}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                onClick={() => handleLangChange(LANGUAGE_TICKER.ru)}
                primary={LANGUAGE.Russian}
              />
            </StyledMenuItem>
          </StyledMenuLang>
        </>
        :
        <Grid container direction="row" className={classes.mobileContainer}>
          {Object.keys(LANGUAGE_TICKER).map((item) => (
            <Box className={clsx(classes.mobileItem, language === item ? classes.activeItem : {})} onClick={() => handleLangChange(item)}>
              <Typography className={classes.mobileItemText}>
                {item}
              </Typography>
            </Box>
          ))}
        </Grid>
      }

    </>
  );
};

export default LanguageMenu;

const StyledMenuLang = withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    background: theme.palette.background.backwardsPaper,
  },
}))((props) => (
  <Menu
    elevation={12}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBottom: "0px",
    paddingTop: "0px",
    "& .MuiMenuItem-root": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },

    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      color: theme.palette.common.white,
      minWidth: 28,
      margin: 4,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "19.5px",
    },

    "&.active:not(:hover)": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.light,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  menuBtnLang: {
    color: theme.palette.text.primary,
    width: '44px',
    height: '44px',
    background: '#181818',
    opacity: 0.5,
    borderRadius: '50%',
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    marginLeft: 24,
    minWidth: 0,
    "& img": {
      marginLeft: 10,
      width: 24,
      height: 24,
    },
    "&:hover": {
      backgroundColor: "inherit",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginLeft: 16,
      "& img": {
        marginLeft: 8,
        width: 12,
        height: 12,
      },
    },
  },
  popOverRoot: {
    pointerEvents: "none",
  },
  mobileContainer: {
    gap: '12px',
    marginLeft: 10,
    marginBottom: 16
  },
  mobileItem: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.default,
    borderRadius: 5,
    padding: '0px 10px',
    background: '#1E1E1E',
  },
  mobileItemText: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 700
  },
  activeItem: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  }
}));
