import { IconButton } from "@material-ui/core";
import VisibilityIcon from "../../assets/icons/visibility.svg";
import VisibilityGrayIcon from "../../assets/icons/visibility_gray.svg";
import VisibilityOffIcon from "../../assets/icons/visibilityOff.svg";
import propTypes from "prop-types";
import { useMemo } from "react";

const VisibilityButton = ({ visibility, onChange, size, isLite, className }) => {
  const visibilityIcon = useMemo(() => {
    if(isLite) {
      return VisibilityGrayIcon
    }else {
      return visibility ? VisibilityIcon : VisibilityOffIcon
    }
  }, [isLite, visibility])
  return (
    <IconButton className={className} onClick={() => onChange(!visibility)}>
      <img
        style={{ width: size, height: size }}
        src={visibilityIcon}
        alt='visibility'
      />
    </IconButton>
  );
};

VisibilityButton.propTypes = {
  visibility: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
};

export default VisibilityButton;
