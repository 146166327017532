import { EXCHANGE } from "../types";

export default function exchange(
  state = {
    pairs: [],
    orderList: [],
    requestStatus: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case EXCHANGE.CREATE_SUCCESS:
      return { ...state, requestStatus: true };
    case EXCHANGE.GET_MARKET_LIST_SUCCESS:
      return { ...state, pairs: action.payload };
    case EXCHANGE.GET_ORDERS_SUCCESS:
      return { ...state, orderList: action.payload };
    case EXCHANGE.CREATE_FAILURE:
      return { ...state, error: action.payload?.error?.response?.data };
    case EXCHANGE.CLEAR_REQUEST_STATUS:
      return { ...state, requestStatus: false };
    case EXCHANGE.CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
