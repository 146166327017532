import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Grid } from "@material-ui/core";
import { Button } from "../../theme/components/Button";
import clsx from "clsx";
import VerificationStatus from "../../theme/components/VerificationStatus";
import { VERIFICATION_STATUS } from "../../utils/constants/kyc";

const KYCStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const kycStatus = useSelector(({ kyc }) => kyc?.verificationStatus);
  const history = useHistory();

  const isKycNotPassed = useMemo(() => {
    return kycStatus !== VERIFICATION_STATUS.CONFIRMED && kycStatus !== VERIFICATION_STATUS.WAITING
  }, [kycStatus])

  const handleStartKYC = () => {
    if(isKycNotPassed) {
      history.push("kyc");
    }
  };

  return (
    <>
      <Grid
        container
        wrap='nowrap'
        justifyContent='center'>
        <Grid item container alignContent='center' className={classes.sectionHeader}>
          <Typography className={classes.sectionTitle} variant='h4'>KYC</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={4}>
          <Typography
            className={classes.statusText}>
            {t("KYC status")}
          </Typography>
        </Grid>
        <Grid item container justifyContent='flex-start' xs={6} sm={4} className={classes.statusContainer}>
          <VerificationStatus disabled />
        </Grid>
        <Grid item container justifyContent='flex-end' xs={12} sm={4}>
          <Button
            variant='contained'
            onClick={handleStartKYC}
            className={clsx(classes.btnTwofa, !isKycNotPassed ? classes.disabled : '')}>
            {t("Start to verification")}
          </Button>
        </Grid>
        <Grid item container justifyContent='flex-start' xs={12} >
          <Typography
            className={classes.descriptionText}>
            {t("KYC means Know Your Customer. KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time. If you passed verification before, but your data has changed, contact support to make changes.")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default KYCStatus;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 70,
    background: theme.palette.primary.light,
    padding: "18px 22px",
    gap: 24
  },
  details: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 24,
    [theme.breakpoints.down("xs")]: {
      gap: 16,
      padding: "40px 16px",
    },
  },

  title: {
    marginBottom: 24
  },

  sectionTitle: {
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 700,
    marginTop: 24,
  },

  sectionHeader: {
    borderBottom: `1px solid rgba(255,255,255,0.2)`,
    paddingBottom: 8,
    marginTop: 24,
    marginBottom: 24,
  },

  statusText: {
    fontSize: 20,
    fontWeight: 700,
  },

  descriptionText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: 10,
  },

  topPaper: {
    padding: "18px 22px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      gap: 4,
      padding: "40px 16px",
    },
  },
  twofaIcon: {
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
      marginRight: "20px",
    },
  },

  changeBtn: {
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
      marginBottom: 48,
    },
  },
  btnTwofa: {
    height: 42,
    minWidth: 180,
    "& .MuiButton-label": {
      color: theme.palette.text.tertiary,
      fontSize: 14
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },

  input: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 14px) scale(1)",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
  },
  disabled: {
    borderColor: `${theme.palette.text.secondary} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.secondary,
      fontSize: 14
    },
  },
  statusContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'flex-end',
    },
  }
}));
