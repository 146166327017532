import { useCallback, useMemo, useState } from "react";
import { signInAnonymously } from "firebase/auth";
import { firebaseAuth } from "../../services/firebase";

export const useFirebaseAuth = () => {
  const [initializing, setInitializing] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState(null);

  const isFirebaseAuthenticated = useMemo(
    () => Boolean(!initializing && firebaseUser),
    [firebaseUser, initializing]
  );

  const loginToFirebase = useCallback(async () => {
    try {
      const response = await signInAnonymously(firebaseAuth);
      if (response.user) {
        setFirebaseUser(response.user);
      }
    } catch (e) {
      setFirebaseUser(null);
      console.log("FIREBASE AUTH FAIL", e);
    }
    setInitializing(false);
  }, []);

  const checkIsLoggedInFirebase = useCallback(() => {
    setFirebaseUser(firebaseAuth.currentUser);
    return !!firebaseAuth.currentUser
  }, [])

  return {
    loginToFirebase,
    checkIsLoggedInFirebase,
    firebaseUser,
    initializing,
    isFirebaseAuthenticated,
  };
};
