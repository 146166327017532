import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { ReactComponent as ExchangeIcon } from "../../assets/exchange.svg";
import { Button } from "../../theme/components/Button";
import { createExchange } from "../../redux/actions/exchangeActions";
import { EXCHANGE_FEE } from "../../utils/constants/exchangeFee";

const ExchangeConfirm = ({ open, data, exchangeRate, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(
      createExchange({
        assetFrom: data?.assetFrom,
        assetTo: data?.assetTo,
        amount: data?.amount,
      })
    );
    onClose();
  };

  return (
    <DialogWrapper open={open} handleClose={onClose} title={t('Confirm exchange')}>
      <Typography
        variant={"body1"}
        style={{ marginBottom: 32, color: "#B38C5F", fontSize: 15 }}>
        <ReportProblemOutlinedIcon
          style={{ color: "#AF994A", marginRight: 8, marginBottom: -8, fontSize: 28 }}
        />
        {t("The cryptocurrency rate at the time of exchange may change")}
        <Box alignItems={'center'} display="flex" alignSelf='center' justifyContent="center" marginTop="32px">
          <img
            src={`https://cryptoneed.com/icons/${data?.assetFrom}.svg`}
            alt={data?.assetFrom}
            width={38}
            height={38}
            loading={"lazy"}
          />
          <ExchangeIcon style={{ margin: '0px 12px', height: 14 }} />
          <img
            src={`https://cryptoneed.com/icons/${data?.assetTo}.svg`}
            alt={data?.assetTo}
            width={38}
            height={38}
            loading={"lazy"}
          />
        </Box>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant={"body1"}>{t("You give")}</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Typography variant={"body1"}>
            {data?.amount} {data?.assetFrom?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"body1"}>{t("You get")}</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Typography variant={"body1"}>
            ~ {data?.toAmount} {data?.assetTo?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"body1"}>{t("Fee")}</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Typography variant={"body1"}>{EXCHANGE_FEE} %</Typography>
        </Grid>
        {Boolean(exchangeRate) && (
          <>
            <Grid item xs={6}>
              <Typography variant={"body1"}>{t("Exchange rate")}</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Typography variant={"body1"}>
                {`1 ${data?.assetFrom.toUpperCase()} = ${+exchangeRate} ${data?.assetTo.toUpperCase()}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container justifyContent='center'>
        <Button
          style={{ margin: "40px auto 0px auto", border: `1px solid ${theme.palette.primary.main}`, }}
          onClick={handleConfirm}>
          {t("Confirm")}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default ExchangeConfirm;
