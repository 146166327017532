import {
    GET_DEVICE_LOGS_LIST_FAILURE,
    GET_DEVICE_LOGS_LIST_STARTED, GET_DEVICE_LOGS_LIST_SUCCESS,
    SET_DEVICE_LOGS_PARAMS
} from "../types";

export default function deviceLogs(
    state = {
        page: 0,
        total: 0,
        data: [],
        error: null
    }, action
) {
    switch (action.type) {
        case SET_DEVICE_LOGS_PARAMS:
            return { ...state, page: action.payload };
        case GET_DEVICE_LOGS_LIST_STARTED:
            return { ...state, error: null };
        case GET_DEVICE_LOGS_LIST_SUCCESS:
            return { ...state, data: action.payload.data, total: action.payload.total };
        case GET_DEVICE_LOGS_LIST_FAILURE:
            return { ...state, error: action.payload.error };
        default:
            return state;
    }
}
