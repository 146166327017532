import moment from "moment";

export const KYC_STEPS = [
  "Personal information",
  "Address information",
  "Documents information",
  "Selfie",
  "Verification status",
];

export const FILE_TYPES = ["image/jpeg", "image/png"];

export const VERIFICATION_STATUS = {
  NOT_EXIST: "DECLINED_OR_NOT_EXIST",
  WAITING: "WAITING_FOR_CONFIRMATION",
  CONFIRMED: "CONFIRMED",
};

export const STEP_DOCUMENTS_INFO = {
  TYPE: "documentType",
  DOCUMENT_AVERS: "documentAvers",
  DOCUMENT_REVERS: "documentRevers",
};

export const DOCUMENT_TYPES = {
  ID_CARD: "IDENTITY_CARD",
  DRIVER_LICENSE: "DRIVING_LICENSE",
  PASSPORT: "PASSPORT",
};

export const VERIFICATION_METHODS = {
  quick: "Fast verification",
  normal: "Regular verification",
};

export const GENDERS = ["Male", "Female", "Other"];

export const YEARS = () => {
  const maxYear = moment().format("YYYY") - 17;
  const arr = [];
  for (let index = 1900; index < maxYear; index++) {
    arr.push(index + "");
  }
  return arr.reverse();
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_MONTH = {
  January: 31,
  February: 29,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

export const COUNTRIES = [
  "AUS",
  "AUT",
  "AZE",
  "ALA",
  "ALB",
  "DZA",
  "VIR",
  "ASM",
  "AIA",
  "AGO",
  "AND",
  "ATA",
  "ATG",
  "ARG",
  "ARM",
  "ABW",
  // "AFG",
  "BHS",
  "BGD",
  "BRB",
  "BHR",
  "BLZ",
  "BLR",
  "BEL",
  "BEN",
  "BMU",
  "BGR",
  "BOL",
  "BES",
  "BIH",
  "BWA",
  "BRA",
  "IOT",
  "VGB",
  "BRN",
  "BFA",
  "BDI",
  "BTN",
  "VUT",
  "VAT",
  "GBR",
  "HUN",
  "VEN",
  "UMI",
  "TLS",
  "VNM",
  "GAB",
  "HTI",
  "GUY",
  "GMB",
  "GHA",
  "GLP",
  "GTM",
  "GUF",
  "GIN",
  "GNB",
  "DEU",
  "GGY",
  "GIB",
  "HND",
  "HKG",
  "GRD",
  "GRL",
  "GRC",
  "GEO",
  "GUM",
  "DNK",
  "JEY",
  "DJI",
  "DMA",
  "DOM",
  "COD",
  "EGY",
  // "ZMB",
  "ESH",
  "ZWE",
  "ISR",
  "IND",
  "IDN",
  "JOR",
  // "IRQ",
  // "IRN",
  "IRL",
  "ISL",
  "ESP",
  "ITA",
  "YEM",
  "CPV",
  "KAZ",
  "CYM",
  "KHM",
  "CMR",
  "CAN",
  "QAT",
  "KEN",
  "CYP",
  "KGZ",
  "KIR",
  "TWN",
  // "PRK",
  "CHN",
  "CCK",
  "COL",
  "COM",
  "CRI",
  "CIV",
  "CUB",
  "KWT",
  "CUW",
  "LAO",
  "LVA",
  "LSO",
  "LBR",
  "LBN",
  "LBY",
  "LTU",
  "LIE",
  "LUX",
  "MUS",
  "MRT",
  "MDG",
  "MYT",
  "MAC",
  "MKD",
  "MWI",
  "MYS",
  "MLI",
  "MDV",
  "MLT",
  "MAR",
  "MTQ",
  "MHL",
  "MEX",
  "FSM",
  "MOZ",
  "MDA",
  "MCO",
  "MNG",
  "MSR",
  "MMR",
  "NAM",
  "NRU",
  // "NPL",
  "NER",
  "NGA",
  "NLD",
  "NIC",
  "NIU",
  "NZL",
  "NCL",
  "NOR",
  "ARE",
  "OMN",
  "BVT",
  "IMN",
  "COK",
  "NFK",
  "CXR",
  "PCN",
  "SHN",
  // "PAK",
  "PLW",
  "PSE",
  "PAN",
  "PNG",
  "PRY",
  "PER",
  "POL",
  "PRT",
  "PRI",
  "COG",
  "KOR",
  "REU",
  "RUS",
  "RWA",
  "ROU",
  "SLV",
  "WSM",
  "SMR",
  "STP",
  "SAU",
  "SWZ",
  "MNP",
  "SYC",
  "BLM",
  "MAF",
  "SPM",
  "SEN",
  "VCT",
  "KNA",
  "LCA",
  "SRB",
  "SGP",
  "SXM",
  // "SYR",
  "SVK",
  "SVN",
  "SLB",
  "SOM",
  "SDN",
  "SUR",
  // "USA",
  "SLE",
  "TJK",
  "THA",
  "TZA",
  "TCA",
  "TGO",
  "TKL",
  "TON",
  "TTO",
  "TUV",
  "TUN",
  "TKM",
  "TUR",
  "UGA",
  "UZB",
  "UKR",
  "WLF",
  "URY",
  "FRO",
  "FJI",
  "PHL",
  "FIN",
  "FLK",
  "FRA",
  "PYF",
  "ATF",
  "HMD",
  "HRV",
  "CAF",
  "TCD",
  "MNE",
  "CZE",
  "CHL",
  "CHE",
  "SWE",
  "SJM",
  "LKA",
  "ECU",
  "GNQ",
  "ERI",
  "EST",
  "ETH",
  "ZAF",
  "SGS",
  "SSD",
  "JAM",
  "JPN",
];
