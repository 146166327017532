import { LANGUAGE } from "../types";
import { LANGUAGE_TICKER } from "../../utils/constants/languages";

export default function language(
  state = {
    language: LANGUAGE_TICKER.en,
    error: null,
  },
  action
) {
  switch (action.type) {
    case LANGUAGE.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case LANGUAGE.CLEAR_LANGUAGE:
      return { ...state, language: null };
    default:
      return state;
  }
}
