import { RequestStatus } from "../../utils/functions/requestStatuses";
import { COMPANIES } from "../types";

export default function companies(
  state = {
    companiesList: [],
    companyBalanceTransferHistory: {
      content: [],
      totalElements: 0
    },
    companyBalanceTransferInvoices: [],
    selectedCompanyBalanceTransferInvoice: {},
    getCompaniesListRequestStatus: RequestStatus.NOT_ASKED,
    transferCompanyBalanceRequestStatus: RequestStatus.NOT_ASKED,
    makeDecisionByTransferCompanyBalanceRequestStatus: RequestStatus.NOT_ASKED,
    getCompanyBalanceTransfersRequestStatus: RequestStatus.NOT_ASKED,
    getCompanyBalanceTransferInvoiceByIdRequestStatus: RequestStatus.NOT_ASKED,
    addNewCompanyRequestStatus: RequestStatus.NOT_ASKED,
    error: null,
  },
  action
) {
  switch (action.type) {
    case COMPANIES.GET_COMPANIES_LIST.REQUEST:
      return { ...state, error: null, getCompaniesListRequestStatus: RequestStatus.LOADING }
    case COMPANIES.GET_COMPANIES_LIST.FAILURE:
      return { ...state, error: action.payload.error, getCompaniesListRequestStatus: RequestStatus.FAILED }
    case COMPANIES.GET_COMPANIES_LIST.SUCCESS:
      return {
        ...state,
        companiesList: action.payload,
        getCompaniesListRequestStatus: RequestStatus.SUCCEEDED,
      }

    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_HISTORY.REQUEST:
      return { ...state, error: null, getCompanyBalanceTransfersRequestStatus: RequestStatus.LOADING }
    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_HISTORY.FAILURE:
      return { ...state, error: action.payload.error, getCompanyBalanceTransfersRequestStatus: RequestStatus.FAILED }
    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_HISTORY.SUCCESS:
      return { ...state, companyBalanceTransferHistory: action.payload.transfers, companyBalanceTransferInvoices: action.payload.invoices, getCompanyBalanceTransfersRequestStatus: RequestStatus.SUCCEEDED }

    case COMPANIES.TRANSFER_COMPANY_BALANCE.REQUEST:
      return { ...state, error: null, transferCompanyBalanceRequestStatus: RequestStatus.LOADING }
    case COMPANIES.TRANSFER_COMPANY_BALANCE.FAILURE:
      return { ...state, error: action.payload.error, transferCompanyBalanceRequestStatus: RequestStatus.FAILED }
    case COMPANIES.TRANSFER_COMPANY_BALANCE.SUCCESS:
      return { ...state, transferCompanyBalanceRequestStatus: RequestStatus.SUCCEEDED }

    case COMPANIES.MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE.REQUEST:
      return { ...state, error: null, makeDecisionByTransferCompanyBalanceRequestStatus: RequestStatus.LOADING }
    case COMPANIES.MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE.FAILURE:
      return { ...state, error: action.payload.error, makeDecisionByTransferCompanyBalanceRequestStatus: RequestStatus.FAILED }
    case COMPANIES.MAKE_DECISION_BY_COMPANY_BALANCE_TRANSFER_INVOICE.SUCCESS:
      return { ...state, makeDecisionByTransferCompanyBalanceRequestStatus: RequestStatus.SUCCEEDED }

    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID.REQUEST:
      return { ...state, error: null, getCompanyBalanceTransferInvoiceByIdRequestStatus: RequestStatus.LOADING }
    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID.FAILURE:
      return { ...state, error: action.payload.error, getCompanyBalanceTransferInvoiceByIdRequestStatus: RequestStatus.FAILED }
    case COMPANIES.GET_COMPANY_BALANCE_TRANSFER_INVOICE_BY_ID.SUCCESS:
      return { ...state, selectedCompanyBalanceTransferInvoice: action.payload, getCompanyBalanceTransferInvoiceByIdRequestStatus: RequestStatus.SUCCEEDED }

    case COMPANIES.ADD_NEW_COMPANY.REQUEST:
      return { ...state, error: null, addNewCompanyRequestStatus: RequestStatus.LOADING }
    case COMPANIES.ADD_NEW_COMPANY.FAILURE:
      return { ...state, addNewCompanyRequestStatus: RequestStatus.FAILED }
    case COMPANIES.ADD_NEW_COMPANY.SUCCESS:
      return { ...state, addNewCompanyRequestStatus: RequestStatus.SUCCEEDED }


    default:
      return state;
  }
}
