import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import Breadcrumb from "../../theme/components/Breadcrumb";
import CompanyOverview from "./Overview";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";
import CompanyBalancesTable from "./BalancesTable";
import { getCompaniesList } from "../../redux/actions/companiesActions";

export const CompanyDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const companiesList = useSelector(companiesSelectors.companiesList)
  const selectedCompanyData = useMemo(() => {
    return companiesList.find((company) => Number(company?.companyId) === Number(companyId));
  }, [companiesList, companyId])

  useEffect(() => {
    // dispatch(getMoneyboxBalanceList(moneyboxId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    dispatch(getCompaniesList());
  }, [dispatch])

  return (
    <PageWrapper>
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box className={classes.titleWrapper}>
            <Breadcrumb to='/companies' pageName='Company' />
            <Typography variant="h2" className={classes.title}>
              {selectedCompanyData?.companyName}
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CompanyOverview totalInUsd={selectedCompanyData?.totalInUsd} companyId={selectedCompanyData?.companyId} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <CompanyBalancesTable companyBalances={selectedCompanyData?.balances || []} companyId={selectedCompanyData?.companyId} />
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default CompanyDetails;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 34,
    marginTop: 18.5,
  },
  title: {
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  description: {
    fontSize: 20,
    marginLeft: 28,
    alignSelf: 'center',
    marginTop: 7,
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },
}));
