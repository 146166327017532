import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback } from "react";
import { coloredFirstLetter } from "../functions/coloredFirstLetter";

export const useColoredTitle = ({ withMargin = false } = {}) => {
  const classes = useStyles();

  const renderTitle = useCallback((title, options = {}) => {
    return (<Typography variant={"h4"} color={"textPrimary"} style={options?.style} className={clsx(classes.titleText, 'dialogTitle', withMargin ? classes.margin : {})}>
      {coloredFirstLetter(title)}
    </Typography>)
  }, [classes.margin, classes.titleText, withMargin])

  return {
    renderTitle
  }
};

const useStyles = makeStyles(theme => ({
  titleText: {
    fontWeight: 400,
    textTransform: 'uppercase'
  },
  margin: {
    marginBottom: 24
  }
}));
