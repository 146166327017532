import ListIcon from "../../assets/icons/menu/list.svg";
import SettingsIcon from "../../assets/icons/menu/settings.svg";
import SupportIcon from "../../assets/icons/menu/support.svg";
import PrivacyPolicyIcon from "../../assets/icons/menu/privacy_policy.svg";
import TermOfUseIcon from "../../assets/icons/menu/terms_of_use.svg";
import Wallets from "../../assets/icons/menu/wallets.svg";
import ReferralProgramIcon from "../../assets/icons/menu/referral_program.svg";
import ListIcon_Active from "../../assets/icons/menu/active/list_active.svg";
import SettingsIcon_Active from "../../assets/icons/menu/active/settings_active.svg";
import SupportIcon_Active from "../../assets/icons/menu/active/support_active.svg";
import PrivacyPolicyIcon_Active from "../../assets/icons/menu/active/privacy_policy_active.svg";
import TermOfUseIcon_Active from "../../assets/icons/menu/active/terms_of_use_active.svg";
import Wallets_Active from "../../assets/icons/menu/active/wallets_active.svg";
import ReferralProgramIcon_Active from "../../assets/icons/menu/active/referral_program_active.svg";
// import SyncAltIcon from "../../assets/icons/menu/exchange.svg";
// import SendIcon from "../../assets/icons/menu/send.svg";
// import KYC_Icon from "../../assets/icons/menu/kyc.svg";
// import SyncAltIcon_Active from "../../assets/icons/menu/active/exchange_active.svg";
// import SendIcon_Active from "../../assets/icons/menu/active/send_active.svg";
// import KYC_Icon_Active from "../../assets/icons/menu/active/kyc_active.svg";

export const MENU_LIST = [
  {
    title: "Wallets",
    path: "/",
    activeSrc: Wallets_Active,
    altImg: "wallets",
    src: Wallets,
  },
  // {
  //   title: "Exchange",
  //   path: "/exchange",
  //   activeSrc: SyncAltIcon_Active,
  //   altImg: "exchange",
  //   src: SyncAltIcon,
  // },
  // {
  //   title: "Send",
  //   path: "/send",
  //   activeSrc: SendIcon_Active,
  //   altImg: "send",
  //   src: SendIcon,
  // },
  {
    title: "Transactions",
    path: "/transactions",
    activeSrc: ListIcon_Active,
    altImg: "transactions",
    src: ListIcon,
  },
  {
    title: "Settings",
    path: "/settings",
    activeSrc: SettingsIcon_Active,
    altImg: "settings",
    src: SettingsIcon,
  },
  // {
  //   title: "KYC",
  //   path: "/kyc",
  //   activeSrc: KYC_Icon_Active,
  //   altImg: "kyc",
  //   src: KYC_Icon,
  // },
  {
    title: "Referral program",
    path: "/referral_program",
    activeSrc: ReferralProgramIcon_Active,
    altImg: "referral_program",
    src: ReferralProgramIcon,
  },
  {
    title: "Support",
    path: "/support",
    activeSrc: SupportIcon_Active,
    altImg: "support",
    src: SupportIcon,
  },
  {
    title: "Privacy Policy",
    path: "/privacy",
    activeSrc: PrivacyPolicyIcon_Active,
    altImg: "privacy_policy",
    src: PrivacyPolicyIcon,
  },
  {
    title: "Terms of Use",
    path: "/terms",
    activeSrc: TermOfUseIcon_Active,
    altImg: "term_of_use",
    src: TermOfUseIcon,
  },
];

export const HEADER_NAV_LIST = [
  {
    label: "Projects",
    href: "/projects",
  },
  {
    label: "Capital",
    href: "capital",
  },
  // {
  //   label: "CryptoHarvester",
  //   href: "crypto_harvester",
  // },
  {
    label: "Wallet",
    href: "/",
  },
];

export const HEADER_MOBILE_NAV_LIST = [
  {
    label: "Wallet",
    href: "/",
  },
  {
    label: "Projects",
    href: "/projects",
  },
  {
    label: "Capital",
    href: "capital",
  },
  {
    label: "Transactions",
    href: "/transactions",
  },
  {
    label: "Support",
    href: "/support",
  },
  {
    label: "Settings",
    href: "/settings",
  },
  {
    label: "Referral program",
    href: "/referral_program",
  },
];

export const PRIVACY_TERMS_NAV_LIST = [
  {
    label: "Privacy Policy",
    href: "/privacy",
  },
  {
    label: "Terms of Use",
    href: "/terms",
  },
]

