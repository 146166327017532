import { RequestStatus } from "../../utils/functions/requestStatuses";
import { MONEYBOX } from "../types";

export default function moneybox(
  state = {
    moneyboxList: [],
    moneyboxBalancesList: [],
    selectedMoneybox: null,
    selectedMoneyboxBalance: null,
    selectedMoneyboxBalanceTotal: 0,
    moneyboxBalanceHistory: {
      content: [],
      totalElements: 0
    },
    updateMoneyboxRequestStatus: RequestStatus.NOT_ASKED,
    updateMoneyboxBalanceRequestStatus: RequestStatus.NOT_ASKED,
    withdrawMoneyboxBalanceRequestStatus: RequestStatus.NOT_ASKED,
    error: null,
  },
  action
) {
  switch (action.type) {
    case MONEYBOX.CREATE_MONEYBOX.REQUEST:
    case MONEYBOX.UPDATE_MONEYBOX.REQUEST:
    case MONEYBOX.GET_MONEYBOX_BY_ID.REQUEST:
      return { ...state, error: null, updateMoneyboxRequestStatus: RequestStatus.LOADING }
    case MONEYBOX.WITHDRAW_MONEYBOX_BALANCE.REQUEST:
      return { ...state, error: null, withdrawMoneyboxBalanceRequestStatus: RequestStatus.LOADING }
    case MONEYBOX.CREATE_MONEYBOX_BALANCE.REQUEST:
    case MONEYBOX.GET_MONEYBOX_BALANCE_BY_ID.REQUEST:
      return { ...state, error: null, updateMoneyboxBalanceRequestStatus: RequestStatus.LOADING }
    case MONEYBOX.GET_MONEYBOX_LIST.REQUEST:
    case MONEYBOX.GET_MONEYBOX_BALANCES_LIST.REQUEST:
    case MONEYBOX.GET_MONEYBOX_BALANCE_HISTORY.REQUEST:
      return { ...state, error: null };
    case MONEYBOX.CREATE_MONEYBOX.FAILURE:
    case MONEYBOX.UPDATE_MONEYBOX.FAILURE:
    case MONEYBOX.GET_MONEYBOX_BY_ID.FAILURE:
      return { ...state, error: action.payload.error, updateMoneyboxRequestStatus: RequestStatus.FAILED }
    case MONEYBOX.CREATE_MONEYBOX_BALANCE.FAILURE:
    case MONEYBOX.GET_MONEYBOX_BALANCE_BY_ID.FAILURE:
      return { ...state, error: action.payload.error, updateMoneyboxBalanceRequestStatus: RequestStatus.FAILED }
    case MONEYBOX.GET_MONEYBOX_LIST.FAILURE:
    case MONEYBOX.GET_MONEYBOX_BALANCES_LIST.FAILURE:
    case MONEYBOX.GET_MONEYBOX_BALANCE_HISTORY.FAILURE:
      return { ...state, error: action.payload.error };
    case MONEYBOX.WITHDRAW_MONEYBOX_BALANCE.FAILURE:
      return { ...state, error: null, withdrawMoneyboxBalanceRequestStatus: RequestStatus.FAILED }
    case MONEYBOX.CREATE_MONEYBOX.SUCCESS:
    case MONEYBOX.UPDATE_MONEYBOX.SUCCESS:
      return {
        ...state,
        updateMoneyboxRequestStatus: RequestStatus.SUCCEEDED,
      }
    case MONEYBOX.CREATE_MONEYBOX_BALANCE.SUCCESS:
      return {
        ...state,
        updateMoneyboxBalanceRequestStatus: RequestStatus.SUCCEEDED,
      }
    case MONEYBOX.GET_MONEYBOX_LIST.SUCCESS:
      return {
        ...state,
        moneyboxList: action.payload?.content || [],
      }
    case MONEYBOX.GET_MONEYBOX_BALANCES_LIST.SUCCESS:
      return {
        ...state,
        moneyboxBalancesList: action.payload?.balances || [],
        selectedMoneyboxBalanceTotal: action.payload?.totalInUsd || 0,
      }
    case MONEYBOX.GET_MONEYBOX_BY_ID.SUCCESS:
      return {
        ...state,
        selectedMoneybox: action.payload,
        updateMoneyboxRequestStatus: RequestStatus.SUCCEEDED,
      }
    case MONEYBOX.GET_MONEYBOX_BALANCE_BY_ID.SUCCESS:
      return {
        ...state,
        selectedMoneyboxBalance: action.payload,
        updateMoneyboxBalanceRequestStatus: RequestStatus.SUCCEEDED,
      }
    case MONEYBOX.GET_MONEYBOX_BALANCE_HISTORY.SUCCESS:
      return {
        ...state,
        moneyboxBalanceHistory: {
          content: action.payload?.content || [],
          totalElements: action.payload?.totalElements || 0
        },
      }
    case MONEYBOX.WITHDRAW_MONEYBOX_BALANCE.SUCCESS:
      return { ...state, error: null, withdrawMoneyboxBalanceRequestStatus: RequestStatus.SUCCEEDED }
    default:
      return state;
  }
}
