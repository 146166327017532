import { useSelector } from "react-redux";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import { roundValue } from "../../../utils/functions/roundValue";
import { useColoredTitle } from "../../../utils/hooks/useColoredTitle";

const size = 10;

// const historyMock = {
//   content: [
//     {
//       asset: 'btc',
//       name: 'Bitcoin',
//       amount: 0.5,
//       time: "2022-10-03T18:04:56.523105Z"
//     },
//     {
//       asset: 'eth',
//       name: 'Ethereum',
//       amount: 2,
//       time: "2022-10-03T18:04:56.523105Z"
//     },
//   ]
// }

const TransferHistory = ({ history, page, changePage, settings }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true });
  return (
    <Paper className={classes.root}>
      {renderTitle(t("Claim history"))}
      {history?.content?.length === 0 ? (
        <Typography
          variant={"body1"}
          color={"textSecondary"}
          className={classes.description}
        >
          {t("You have no transactions...")}
        </Typography>
      ) : (
        history?.content.map((transaction, index) => (
          <Transaction key={index} transaction={transaction} />
        ))
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 32,
        }}
      >
        <StyledPagination
          count={Math.ceil(history?.totalElements / size)}
          page={page + 1}
          boundaryCount={2}
          siblingCount={0}
          color="secondary"
          onChange={(event, page) => changePage(page - 1)}
        />
      </div>
    </Paper>
  );
};

export default TransferHistory;

const StyledPagination = withStyles({
  root: {},
  ul: {
    "& li": {
      "& button": {
        "&.Mui-selected": {
          background: "#2A2E3A",
        },
      },
      "& button:hover": {
        background: "#2A2E3A",
      },
    },
  },
})(Pagination);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
      overflowX: 'scroll',
    },
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    textAlign: "center",
  }
}));

export const Transaction = ({ transaction }) => {
  const classes = useTransactionsStyles();
  const marketPrice = useSelector(
    ({ market }) =>
      market?.data?.find((i) => i?.ticker === transaction?.asset)?.price || 0
  );
  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        wrap="nowrap"
        className={classes.root}
      >
        <Grid item xs={5} className={classes.currency} container>
          <Typography
            variant={"body1"}
            color="textPrimary"
            className={classes.typography}
          >
            {transaction?.name}
          </Typography>
        </Grid>

        <Grid
          item
          xs={5}
          style={{
            width: "max-content",
            // display: "flex",
            // alignItems: "baseline",
          }}
        >
          <Typography
            variant={"body2"}
            color="textPrimary"
            className={classes.typography}
          >
            {`${+transaction?.amount} ${transaction?.asset?.toUpperCase()} `}
          </Typography>
          <Typography
            variant={"body2"}
            color="textSecondary"
            className={clsx(classes.typography, classes.textNormal)}
          >
            {`${roundValue(Number(marketPrice * transaction?.amount), 2)} USDT`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent={"flex-end"}
          className={classes.col}
          direction="column"
        >
          <Typography
            variant={"body2"}
            color="textPrimary"
            className={clsx(classes.typography, classes.textNormal)}
            align="right"
          >
            {moment(transaction?.time).format("DD.MM.yyyy")}
          </Typography>
          <Typography
            variant={"body2"}
            color="textPrimary"
            className={clsx(classes.typography, classes.textNormal)}
            align="right"
          >
            {moment(transaction?.time).format("HH:mm:ss")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const useTransactionsStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: '12px',
    },
  },

  col: {
    width: "100",
    padding: "6px 8px 6px 0px",
  },

  tokenImg: {
    width: 32,
    height: 32,
    marginRight: 12,
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
    },
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  },

  typography: {
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: 9,
      lineHeight: "11px",
    },
  },
  typographySmall: {
    fontSize: 12
  },
  textNormal: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  tether: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
      lineHeight: "10px",
    },
  }
}));
