import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { getAccountData } from "../redux/actions/accountActions";
import { useQuery } from "../utils/hooks/useQuery";
import { EXTERNAL_BASE_URL, EXTERNAL_URL } from "../utils/constants/api";
import { setLanguage } from "../redux/actions/languageActions";

const Loader = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();
  const { authorized } = useSelector(({ account }) => account);
  const pathNameFromUrl = query.get("path_name");
  const accessTokenFromUrl = query.get("access_token");
  const isAccessTokenFromUrlExist =
    accessTokenFromUrl && accessTokenFromUrl !== "null";
  const isAccessTokenSavedInStorage = useMemo(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    return storedAccessToken && storedAccessToken !== "null";
  }, []);

  const languageFromUrl = query.get("appLanguage");

  useEffect(() => {
    if (languageFromUrl && languageFromUrl !== "null") {
      dispatch(setLanguage(languageFromUrl));
      localStorage.setItem("AppLLanguage", languageFromUrl);
    } else {
      dispatch(setLanguage(localStorage.getItem("AppLanguage")));
    }
  }, [dispatch, languageFromUrl]);

  const handleCheckUrlToken = useCallback(() => {
    if (!isAccessTokenSavedInStorage && isAccessTokenFromUrlExist) {
      const accessToken = query.get("access_token");
      const refreshToken = query.get("refresh_token");
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
    }
  }, [query, isAccessTokenFromUrlExist, isAccessTokenSavedInStorage]);

  useEffect(() => {
    handleCheckUrlToken();
    if (
      !authorized &&
      (pathNameFromUrl === "privacy" || pathNameFromUrl === "terms")
    ) {
      history.push(pathNameFromUrl);
    } else if (localStorage.getItem("access_token") && !authorized) {
      dispatch(getAccountData());
    } else if (authorized) {
      history.push(state?.from?.pathname || pathNameFromUrl || "/");
    } else {
      // redirect to login 
      if (!isAccessTokenFromUrlExist || !isAccessTokenSavedInStorage) {
        window.location.replace(`${EXTERNAL_BASE_URL}${EXTERNAL_URL.login}`);
      }
    }
  }, [
    dispatch,
    history,
    authorized,
    state,
    handleCheckUrlToken,
    isAccessTokenFromUrlExist,
    isAccessTokenSavedInStorage,
    pathNameFromUrl,
  ]);

  return (
    <>
      <Grid className={classes.root}>
        <CircularProgress />
      </Grid>
    </>
  );
};
export default Loader;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
