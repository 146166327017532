import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button } from "../../../theme/components/Button";
import {
  setActiveStep,
  setFilledStep,
} from "../../../redux/actions/kycActions.js";
import { imageValidator } from "../../../utils/functions/customValidators/kycValidator";
import { enqueueSnackbar } from "../../../redux/actions/notistackActions";
import { DOCUMENT_TYPES } from "../../../utils/constants/kyc";
import SelfieIdCard from "../../../assets/icons/kyc/selfie_id_card.svg";
import SelfiePassport from "../../../assets/icons/kyc/selfie_passport.svg";
import { snakeCaseToString } from "../../../utils/functions/string";

const Selfie = ({ updateKYCForm, KYCForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep } = useSelector(({ kyc }) => kyc);
  const [selectedFile, setSelectedFile] = useState(KYCForm?.selfie);
  let fileInput;

  const imageUrl = useMemo(() => {
    if (selectedFile) {
      return window.URL.createObjectURL(selectedFile);
    }
  }, [selectedFile]);

  const handleNext = () => {
    if (!selectedFile) {
      dispatch(
        enqueueSnackbar({
          message: t("This is a required verification step."),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            autoHideDuration: 3000,
          },
        })
      );
      return;
    }
    updateKYCForm({ selfie: selectedFile });
    dispatch(setFilledStep(activeStep));
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const openFileUploader = () => {
    fileInput?.click();
  };

  const onFileChanged = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target.files[0];
    if (imageValidator(dispatch, file)?.invalid) {
      return;
    }
    setSelectedFile(file);
  };

  const imageMock = useMemo(() => {
    if (KYCForm?.documentType === DOCUMENT_TYPES.PASSPORT) {
      return SelfiePassport
    } else if (KYCForm?.documentType === DOCUMENT_TYPES.ID_CARD) {
      return SelfieIdCard
    } else if (KYCForm?.documentType === DOCUMENT_TYPES.DRIVER_LICENSE) {
      return SelfiePassport
    }
    return SelfiePassport
  }, [KYCForm?.documentType])

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <Grid item container>
        <Typography
          variant='body1'
          className={classes.title}
          align='flex-start'>
          {t(snakeCaseToString(KYCForm?.documentType || 'Selfie'))}
        </Typography>
      </Grid>

      <Grid container justifyContent="center" alignContent="center">
        <Grid item xs={12} sm={6} md={4} className={classes.wrapper}>
          <div className={classes.descriptionBox}>
            <Typography variant="body2" className={classes.description}>
              <span className={classes.highlight}>{t("Upload your selfie with the document,")}</span>
              {t(
                "used in the previous step and a piece of paper that says Cryptoneed for the current date."
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.widgetWrap}>
          {!selectedFile ? (
            <div className={clsx(classes.imgWrap)}>
              <img src={imageMock} alt="selfie" className={classes.img} />
            </div>
          ) : (
            <div className={clsx(classes.imgWrap, classes.withBorder)}>
              <img
                src={imageUrl || ""}
                alt="document"
                id="showDocumentImage"
                className={classes.img}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={false} sm={false} md={4}></Grid>
      </Grid>
      <Grid>
        <input
          type="file"
          onChange={onFileChanged}
          hidden={true}
          id="fileSelect"
          ref={(refParam) => (fileInput = refParam)}
          accept=".jpg, .jpeg, .png"
        ></input>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={5}
        className={classes.btnBox}
      >
        <Grid item>
          <Button
            className={classes.btn}
            variant="outlined"
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={handleBack}
          >
            {t("Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.btn}
            variant="contained"
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={openFileUploader}
          >
            {t("Upload the file")}
          </Button>
        </Grid>

        <Grid item>
          <Button
            className={classes.btn}
            variant="contained"
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={handleNext}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Selfie;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    width: '50%',
    borderBottom: `0.02rem solid ${theme.palette.secondary.disabled}`,
    paddingBottom: 8,
    textTransform: 'capitalize',
    marginBottom: 60,
  },
  root: {
    width: "100%",
    padding: "24px",
    [theme.breakpoints.down(800)]: { padding: "60px 32px 60px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "48px 32px" },
  },
  btnBox: {
    marginTop: 52,
    [theme.breakpoints.down("sm")]: { marginTop: "90px" },
    [theme.breakpoints.down("xs")]: { marginTop: "32px" },
  },
  btn: {
    minWidth: 165,
    marginTop: "0px !important",
    "&.MuiButton-outlined": {
      padding: "12px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 152,
    },
  },
  widgetWrap: {
    display: "flex",
    justifyContent: "center",
  },
  imgWrap: {
    width: "220px",
    height: "220px",
    position: "relative",
    margin: 0,
    overflow: "hidden",
  },
  withBorder: {
    border: "1px solid #FFFFFF",
    borderRadius: 20,
  },
  img: {
    width: "100%",
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "auto",
    transform: "translate(-50%, -50%)",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    paddingBottom: "24px",
  },
  description: {
    fontSize: 13,
    maxWidth: 260,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
    whiteSpace: "pre-line",
  },
  highlight: {
    color: theme.palette.text.quaternary,
    display: 'block',
  }
}));
