import { Typography, Paper, makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";
import { SUPPORT_EMAIL, SUPPORT_TELEGRAM_CHAT } from "../../utils/constants/support";
import TelegramIcon from "../../assets/icons/telegram.svg";
import EmailIcon from "../../assets/icons/mail.svg";

const UpdateVerificationData = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Paper className={classes.root}>
      {renderTitle(t("Update Verification Data"))}
      <Typography
        variant={"body1"}
        color={"textSecondary"}
        className={classes.description}
      >
        {t("If you passed verification before, but your data has changed, contact support to make changes.")}
      </Typography>
      <Grid container alignItems="center" justifyContent="flex-end" style={{marginBottom: 20}}>
        <Typography className={classes.contactText}>
          {t('open')} <a href={SUPPORT_TELEGRAM_CHAT} className={classes.highlight}>Telegram</a> {t('chat')}
        </Typography>
        <div className={classes.imgWrap}>
          <img
            src={TelegramIcon}
            alt='telegram'
            className={classes.img}
          />
        </div>
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" style={{marginBottom: 20}}>
        <Typography className={classes.contactText}>
          <a
            className={classes.link}
            href={`mailto:${SUPPORT_EMAIL}`}
            rel="noreferrer"
            target="_blank"
          >
          {SUPPORT_EMAIL}
          </a>
        </Typography>
        <div className={classes.imgWrap}>
          <img
            src={EmailIcon}
            alt='mail'
            className={classes.img}
          />
        </div>
      </Grid>
    </Paper>
  );
};

export default UpdateVerificationData;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  highlight: {
    color: theme.palette.text.quaternary,
    textDecoration: 'none',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
  },
  coloredText: {
    color: theme.palette.primary.main
  },
  rewardIcon: {
    maxHeight: 72
  },
  noteText: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  rewardContainer: {
    marginTop: 40
  },
  imgWrap: {
    width: "30px",
    position: "relative",
    margin: 0,
    marginLeft: 30,
    marginRight: 20,
    flexWrap: 'nowrap',
    [theme.breakpoints.down("md")]: { marginRight: "10px" },
  },
  img: {
    height: "100%",
    maxHeight: "100%",
  },
  contactText: {
    fontSize: 15,
    [theme.breakpoints.down("md")]: { fontSize: "14px" },
  }
}));
