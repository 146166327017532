import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import DialogWrapper from "./DialogWrapper";

const InfoDialog = ({
  open,
  title,
  message,
  children,
  handleClose,
  closeButtonText = "Close",
  withNoButton = false,
}) => {
  const { t } = useTranslation();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      title={title}>
      {children ? (
        children
      ) : (
        <Typography
          variant={"body1"}
          align={"left"}
          color={"textSecondary"}
          style={{ marginBottom: 24 }}>
          {t(message)}
        </Typography>
      )}
      {withNoButton ? null : (
        <Grid container justifyContent='center'>
          <Button
            variant='contained'
            color={"primary"}
            style={{ width: "max(25%, 50px)", marginTop: 12 }}
            onClick={() => handleClose({ accept: false })}>
            {t(closeButtonText)}
          </Button>
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default InfoDialog;
