import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { validateInput } from "../../../utils/functions/customValidators/validators";
import { Button } from "../../../theme/components/Button";
import DialogWrapper from "../../../theme/dialogs/DialogWrapper";
import {
  closeReceiveCardDialog,
  openReplenishmentMethodDialog,
} from "../../../redux/actions/dialogActions";
import { receiveFiat } from "../../../redux/actions/walletsActions";
import { Input } from "../../../theme/components/Input";

const ReceiveCardDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ amount: 0 });
  const [errors, setErrors] = useState(null);
  const { selectedWallet } = useSelector(({ dialogs }) => dialogs.receiveCard);
  const { invoice } = useSelector(({ wallets }) => wallets);
  const handleCloseDialog = () => {
    dispatch(closeReceiveCardDialog());
  };

  useEffect(() => {
    if (!invoice) {
      return;
    }
    var win = window.open();
    win.document.body.style.margin = "0";
    win.document.body.style.height = "100vh";
    var iframe = win.document.createElement("iframe");
    iframe.style.cssText = `
    border: none;
    width: 100%;
    height: 100%;
    background-color: #fff;
    `;

    iframe.src = invoice;
    win.document.body.appendChild(iframe);

    iframe.onload = () => {
      let hideFormInfo = win.document.createElement("div");
      let hideKunaIcon = win.document.createElement("div");
      let basicStyle = `background-color: #fff;
      position: absolute;
      z-index: 1000;
      width: 350px;
      height: 50px;
      left: 50%;
      transform: translate(-50%, -50%);
      `;
      hideFormInfo.style.cssText = basicStyle + `top: calc(50% - 240px);`;
      hideKunaIcon.style.cssText = basicStyle + `top: calc(50% + 170px);`;
      win.document.body.appendChild(hideFormInfo);
      win.document.body.appendChild(hideKunaIcon);
    };
  }, [invoice]);

  const handleBack = () => {
    dispatch(openReplenishmentMethodDialog(selectedWallet));
    dispatch(closeReceiveCardDialog());
  };

  const handleChangeAmount = (value) => {
    setErrors({
      ...errors,
      amount: validateInput("amount", value, form),
    });
    setForm({ ...form, amount: value });
  };
  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(form).forEach((key) => {
      errForm = {
        ...errForm,
        [key]: validateInput(key, form[key], form),
      };
      errForm[key].invalid && (valid = false);
    });
    setErrors(errForm);
    return valid;
  };

  const handleReceive = () => {
    if (!validateForm()) {
      return;
    }
    dispatch(receiveFiat({ ...form, ticker: selectedWallet.ticker }));
    setForm({ amount: 0 });
    setErrors(null);
    handleCloseDialog();
  };

  return (
    <DialogWrapper
      open={Boolean(selectedWallet)}
      handleClose={handleCloseDialog}
      handleBack={handleBack}
      className={classes.root}
      title={`${t("Deposit")} ${selectedWallet?.ticker?.toUpperCase()}`}
    >
      <Input
        className={classes.input}
        label={t("Deposit amount")}
        variant="outlined"
        name={"amount"}
        value={form.amount}
        onChange={({ target: { value } }) =>
          /^[0-9]*\.?[0-9]*$/.test(value) &&
          !(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
          value.length < 25 &&
          handleChangeAmount(value)
        }
        onFocus={() => !form?.amount && setForm({ ...form, amount: "" })}
        onBlur={() => !form?.amount && setForm({ ...form, amount: 0 })}
        error={errors?.amount?.invalid}
        helperText={t(errors?.amount?.errorMessage)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Grid container justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleReceive}>
          {t("Deposit")}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default ReceiveCardDialog;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
}));
