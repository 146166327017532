import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import QRCode from "react-qr-code";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Address from "../../WalletsDetails/Address";
import DialogWrapper from "../../../theme/dialogs/DialogWrapper";
import wallets from "../../../assets/qr_code_app.svg";
import cards from "../../../assets/bank_cards.svg";
import {
  closeReplenishmentMethodDialog,
  openReceiveCardDialog,
} from "../../../redux/actions/dialogActions";
import {
  clearAddresses,
  getAddresses,
} from "../../../redux/actions/addressesActions";
import { getTickerSettings } from "../../../redux/actions/walletsActions";
import { isReceiveUahtCardFeatureEnabled } from "../../../utils/constants/featureSwitch";
import { VERIFICATION_STATUS } from "../../../utils/constants/kyc";
import { kycRequired } from "../../../redux/actions/kycActions";

const ReplenishmentMethodDialog = ({ handleBackReceive = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openWalletAddress, setOpenWalletAddress] = useState(false);
  const kycStatus = useSelector(({ kyc }) => kyc?.verificationStatus);
  const { selectedWallet } = useSelector(
    ({ dialogs }) => dialogs.replenishmentMethodReceive
  );
  const address = useSelector(({ addresses }) => addresses.data[0]);
  const tickerSettings = useSelector(({ wallets }) => wallets.tickerSettings);

  useEffect(() => {
    if (selectedWallet && address?.ticker !== selectedWallet?.ticker) {
      dispatch(getAddresses(selectedWallet?.ticker));
      dispatch(getTickerSettings(selectedWallet?.ticker));
    }
  }, [dispatch, selectedWallet, address]);

  useEffect(() => {
    return () => {
      dispatch(clearAddresses());
    };
  }, [dispatch]);

  const handleCloseDialog = () => {
    setOpenWalletAddress(false);
    dispatch(closeReplenishmentMethodDialog());
  };

  const handleOpenReceiveCard = () => {
    if (kycStatus !== VERIFICATION_STATUS.CONFIRMED) {
      dispatch(kycRequired());
      return;
    }
    if (isReceiveUahtCardFeatureEnabled) {
      dispatch(openReceiveCardDialog(selectedWallet));
      dispatch(closeReplenishmentMethodDialog());
    }
  };

  const handleBack = () => {
    if (openWalletAddress) {
      setOpenWalletAddress(false);
    } else {
      setOpenWalletAddress(false);
      handleBackReceive();
    }
  };

  return (
    <DialogWrapper
      open={Boolean(selectedWallet)}
      handleClose={handleCloseDialog}
      handleBack={handleBackReceive && handleBack}
      maxWidth={520}
      title={
        !openWalletAddress
          ? t("Deposit method")
          : selectedWallet?.ticker?.toUpperCase()
      }>
      {!openWalletAddress && (
        <>
          <Grid
            container
            className={classes.box}
            direction='row'
            alignItems='center'
            onClick={() => setOpenWalletAddress(true)}>
            <Grid item>
              <img src={wallets} alt='wallets' />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                variant={"subtitle1"}
                color={"textPrimary"}
                align='left'>
                {t("Deposit funds by wallet address")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={clsx(
              classes.box,
              !isReceiveUahtCardFeatureEnabled && classes.disable
            )}
            direction='row'
            alignItems='center'
            onClick={handleOpenReceiveCard}>
            <Grid item>
              <img src={cards} alt='wallets' />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography
                variant={"subtitle1"}
                color={"textPrimary"}
                align='left'>
                {t("Deposit from a bank card")}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {openWalletAddress && (
        <>
          {
            Boolean(address?.address) ? (
              <>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <div className={classes.qr}>
                      {Boolean(address?.address) && (
                        <QRCode
                          value={address?.address}
                          size={140}
                          fgColor={"#000"}
                          bgColor={"#fff"}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Address
                      variant={"outlined"}
                      address={address}
                      short
                      tickerSettings={tickerSettings}
                    />
                  </Grid>
                </Grid>
              </>
            )
              :
              (
                <Typography variant={"body1"} className={classes.text}>
                  {t("To deposit funds, please add the appropriate wallet in the wallet settings.")}
                </Typography>
              )
          }
        </>
      )}
    </DialogWrapper>
  );
};

export default ReplenishmentMethodDialog;

const useStyles = makeStyles(theme => ({
  box: {
    background: "rgba(159, 169, 189, 0.2)",
    borderRadius: 12,
    padding: "10px 16px",
    color: theme.palette.text.primary,
    marginBottom: 24,
    "&:hover": {
      backgroundColor: "rgba(159, 169, 189, 0.4)",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
    "& img": {
      marginRight: 16,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  qr: {
    marginTop: 32,
    marginBottom: 32,
    width: 160,
    height: 160,
    padding: 10,
    backgroundColor: "#fff",
  },
  disable: {
    background: "rgba(159, 169, 189, 0.1 )",
    "&:hover": {
      cursor: "default",
      background: "rgba(159, 169, 189, 0.1 )",
    },
  },
  text: {
    margin: '20px 0px'
  }
}));
