import { TextField, withStyles } from "@material-ui/core";

export const Input = withStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: 12,

    "& legend": {
      maxWidth: 0,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 6px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(10px, -6px) scale(0.75)",
    },
    "& label, & label.Mui-focused": {
      color: "#707594",
      marginTop: 16,
      marginLeft: 8,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#707594",
    },

    "& .MuiFormLabel-root.Mui-disabled ": {
      color: "#707594",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      marginTop: 59,
      fontFamily: "Lato, Nunito Sans, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "24px",
    },
    "& .MuiFormHelperText-contained.Mui-error": {
      marginLeft: 20,
      marginRight: 8,
    },
    "& .MuiInputAdornment-root > svg": {
      color: theme.palette.text.primary,
      fontSize: 16,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      width: "100%",
      paddingRight: 12,
      backgroundColor: "#FFFFFF",
    },

    "& .MuiOutlinedInput-root": {
      "&>input": {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "18px",
        padding: "26px 0px 12px 18px",
        borderRadius: 12,
        "& .MuiOutlinedInput-adornedEnd": {
          background: "#FFFFFF",
          color: "#000000",
        },
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
      "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
        borderRadius: 12,
        borderWidth: 1,
      },
      "&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
      {
        borderColor: "#FFFFFF",
      },
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
    "&>input[type=number]::-webkit-outer-spin-button": {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
  },
}))(TextField);


export const InputSmall = withStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: 10,

    "& legend": {
      maxWidth: 0,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(12px, 0px) scale(1)",
      fontSize: 15
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(8px, -9px) scale(0.7)",
    },
    "& label, & label.Mui-focused": {
      color: "#707594",
      marginTop: 16,
      marginLeft: 8,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#707594",
    },

    "& .MuiFormLabel-root.Mui-disabled ": {
      color: "#707594",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      marginTop: 48,
      fontFamily: "Lato, Nunito Sans, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
    },
    "& .MuiFormHelperText-contained.Mui-error": {
      marginLeft: 20,
      marginRight: 8,
    },
    "& .MuiInputAdornment-root > svg": {
      color: theme.palette.text.primary,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      width: "100%",
      paddingRight: 12,
      backgroundColor: "#FFFFFF",
    },

    "& .MuiOutlinedInput-root": {
      "&>input": {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        fontSize: 18,
        fontWeight: 400,
        lineHeight: "18px",
        padding: "16px 0px 6px 16px",
        borderRadius: 10,
        "& .MuiOutlinedInput-adornedEnd": {
          background: "#FFFFFF",
          color: "#000000",
        },
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
      "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
        borderRadius: 10,
        borderWidth: 1,
      },
      "&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
      {
        borderColor: "#FFFFFF",
      },
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(TextField);


export const InputRegular = withStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: 16,
    marginBottom: 28,
    marginTop: 28,
    backgroundColor: '#2B2F38',

    "& legend": {
      maxWidth: 0,
    },
    "& .MuiInputLabel-outlined": {
      // transform: "translate(12px, 0px) scale(1)",
      fontSize: 14
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(-8px, -24px) scale(1)",
    },
    "& label, & label.Mui-focused": {
      color: "#fff",
      marginLeft: 8,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#fff",
    },

    "& .MuiFormLabel-root.Mui-disabled ": {
      color: "#878787",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      marginTop: 48,
      fontFamily: "Lato, Nunito Sans, sans-serif",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
    },
    "& .MuiFormHelperText-contained.Mui-error": {
      marginLeft: 0,
      marginRight: 8,
      whiteSpace: 'nowrap',
    },
    "& .MuiInputAdornment-root > svg": {
      color: theme.palette.text.primary,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      width: "100%",
      paddingRight: 10,
    },

    "& .MuiOutlinedInput-root": {
      "&>input": {
        backgroundColor: "#2B2F38",
        color: "#ffffff",
        fontSize: 15,
        fontWeight: 400,
        lineHeight: "18px",
        padding: "14px 4px 14px 14px",
        borderRadius: 16,
        "& .MuiOutlinedInput-adornedEnd": {
          background: "#2B2F38",
          color: "#000000",
        },
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
      "&>input[type=number]::-webkit-outer-spin-button": {
        '-webkit-appearance': 'none',
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
        borderRadius: 16,
        borderWidth: 1,
      },
      "&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
      {
        borderColor: "#2B2F38",
      },
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(TextField);
