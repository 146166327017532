import { LANGUAGE } from "../types";

export const setLanguage = (payload) => ({
  type: LANGUAGE.SET_LANGUAGE,
  payload,
});

export const clearLanguage = (payload) => ({
  type: LANGUAGE.CLEAR_LANGUAGE,
});
