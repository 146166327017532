import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const ErrorCard = ({ text }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Paper className={classes.errorInfo} variant='outlined' elevation={4}>
        <Typography variant='body2' className={classes.errorText}>
          {t(text)}
        </Typography>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  errorInfo: {
    padding: "8px 32px",
    backgroundColor: "rgba(228, 74, 74, 0.3)",
    color: theme.palette.error.main,
  },
  errorText: {
    fontWeight: 600,
    textTransform: "capitalize",
    textAlign: "center",
    marginBottom: 4,
  },
}));
