import { CLOSE_DIALOG, OPEN_DIALOG } from "../types";

export default function dialogs(
  state = {
    addNewWallet: {
      open: false,
    },
    receive: {
      selectedWallet: null,
    },
    receiveCard: {
      selectedWallet: null,
    },
    createCard: {
      open: false,
    },
    replenishmentMethodReceive: {
      selectedWallet: null,
    },
    withdrawalMethod: {
      selectedWallet: null,
    },
    info: {
      open: false,
    },
    createAutoInvestPlan: {
      open: false,
      selectedPlan: null,
    }
  },
  action
) {
  switch (action.type) {
    case OPEN_DIALOG.ADD_NEW_WALLET:
      return { ...state, addNewWallet: { ...state.addNewWallet, open: true } };
    case CLOSE_DIALOG.ADD_NEW_WALLET:
      return { ...state, addNewWallet: { ...state.addNewWallet, open: false } };

    case OPEN_DIALOG.INFO:
      return { ...state, info: { open: true } };
    case CLOSE_DIALOG.INFO:
      return { ...state, info: { open: false } };

    case OPEN_DIALOG.RECEIVE:
      return { ...state, receive: { selectedWallet: action.payload } };
    case CLOSE_DIALOG.RECEIVE:
      return { ...state, receive: { selectedWallet: null } };

    case OPEN_DIALOG.RECEIVE_CARD:
      return {
        ...state,
        receiveCard: {
          selectedWallet: action.payload,
        },
      };
    case CLOSE_DIALOG.RECEIVE_CARD:
      return { ...state, receiveCard: { selectedWallet: null } };

    case OPEN_DIALOG.CREATE_CARD:
      return {
        ...state,
        createCard: { open: true },
      };
    case CLOSE_DIALOG.CREATE_CARD:
      return { ...state, createCard: { open: false } };

    case OPEN_DIALOG.REPLENISHMENT_METHOD:
      return {
        ...state,
        replenishmentMethodReceive: { selectedWallet: action.payload },
      };
    case CLOSE_DIALOG.REPLENISHMENT_METHOD:
      return { ...state, replenishmentMethodReceive: { selectedWallet: null } };

    case OPEN_DIALOG.WITHDRAWAL_METHOD:
      return {
        ...state,
        withdrawalMethod: { selectedWallet: action.payload },
      };
    case CLOSE_DIALOG.WITHDRAWAL_METHOD:
      return { ...state, withdrawalMethod: { selectedWallet: null } };

    case OPEN_DIALOG.CREATE_AUTO_INVEST_PLAN:
      return {
        ...state,
        createAutoInvestPlan: { open: true, selectedPlan: action.payload || null }
      }
    case CLOSE_DIALOG.CREATE_AUTO_INVEST_PLAN:
      return {
        ...state,
        createAutoInvestPlan: { open: false, selectedPlan: null }
      }

    default:
      return state;
  }
}
