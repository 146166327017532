import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import OverviewBlock from "../../theme/components/OverviewBlock";
import { VerticalButton } from "../../theme/components/VerticalButton";
import { getMarketList } from "../../redux/actions/exchangeActions";
import { getWalletsList } from "../../redux/actions/walletsActions";
import {
  openReceiveDialog,
  openReplenishmentMethodDialog,
} from "../../redux/actions/dialogActions";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ReceiveDialog from "../../theme/dialogs/ReceiveDialog";
import ReplenishmentMethodDialog from "../Receive/fiat/ReplenishmentMethodDialog";
import { roundValue } from "../../utils/functions/roundValue";

const Overview = ({ ticker }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const tickerSettings = useSelector(({ wallets }) =>
    wallets?.allTickerSettings?.find(i => i.ticker === ticker)
  );
  const isExchangeTicker = useSelector(({ exchange }) =>
    Boolean(exchange.pairs.find(i => i.assetFrom === ticker))
  );
  const currentWallet = useSelector(({ wallets }) =>
    wallets.data.find(i => i.ticker === ticker)
  );
  const marketPrice = useSelector(
    ({ market }) => market.data.find(i => i?.ticker === ticker)?.price || 0
  );

  useEffect(() => {
    if (!currentWallet) {
      dispatch(getWalletsList({ number: 0, size: 30 }));
    }
  }, [dispatch, ticker, currentWallet]);

  useEffect(() => {
    if (ticker) {
      dispatch(getMarketList());
    }
  }, [dispatch, ticker]);

  const handleSelect = () => {
    if (currentWallet.ticker !== "uaht") {
      dispatch(openReceiveDialog(currentWallet));
    } else {
      dispatch(openReplenishmentMethodDialog(currentWallet));
    }
  };

  return (
    <OverviewBlock>
      <Grid container alignItems='center' spacing={0}>
        <Grid item>
          <img
            src={`https://cryptoneed.com/icons/${ticker}.svg`}
            alt={ticker}
            width={36}
            height={36}
            loading={"lazy"}
          />
        </Grid>
        <Grid item>
          <Typography variant={"h4"} className={classes.title}>
            {ticker.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant={"h2"} className={classes.amountText}>
            {`${roundValue(
              currentWallet?.balance,
              tickerSettings?.minScale
            )} ${ticker.toUpperCase()}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant={"body1"} className={classes.priceText}>
            {`= ${roundValue(currentWallet?.balance * marketPrice, 2)} USD`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ marginTop: 76 }}>
        <Grid
          item
          style={{
            width: "calc((100% - 20px) / 3)",
          }}>
          <VerticalButton variant='outlined' onClick={handleSelect}>
            <GetAppOutlinedIcon />
            {t("Receive")}
          </VerticalButton>
        </Grid>
        <Grid
          item
          style={{
            width: "calc((100% - 20px) / 3)",
            display: "flex",
            justifyContent: "center",
          }}>
          <VerticalButton
            variant='outlined'
            onClick={() =>
              history.push({ pathname: "/send", state: { ticker } })
            }>
            <PublishOutlinedIcon />
            {t("Withdraw")}
          </VerticalButton>
        </Grid>
        <Grid
          item
          style={{
            width: "calc((100% - 20px) / 3)",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <VerticalButton
            variant='outlined'
            disabled={!isExchangeTicker}
            onClick={() =>
              history.push({ pathname: "/exchange", state: { ticker } })
            }>
            <SyncAltIcon />
            {t("Exchange")}
          </VerticalButton>
        </Grid>
      </Grid>
      <ReceiveDialog />
      <ReplenishmentMethodDialog />
    </OverviewBlock>
  );
};

export default Overview;

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 400,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  amountText: {
    fontWeight: 700,
    marginTop: 20,
  },
  priceText: {
    fontWeight: 300,
    color: theme.palette.text.darkLighter,
  },
  verticalButton: {
    width: "calc((100% - 20px) / 3)",
  },
}));
