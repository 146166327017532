import { KYC } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
import { enqueueSnackbar } from "../../redux/actions/notistackActions";
import i18next from "../../i18n.config";

export const setVerificationMethod = payload => ({
  type: KYC.SET_VERIFICATION_METHOD,
  payload,
});

export const clearVerificationMethod = payload => ({
  type: KYC.CLEAR_VERIFICATION_METHOD,
});

export const setActiveStep = payload => ({
  type: KYC.SET_ACTIVE_STEP,
  payload,
});

export const setFilledStep = payload => ({
  type: KYC.SET_FILLED_STEP,
  payload,
});

export const getKycStatusAction = actionCreator(KYC.GET_KYC_STATUS);

export const getKycStatus = () => {
  return async dispatch => {
    dispatch(getKycStatusAction.started());
    api.kyc
      .getKycStatus()
      .then(res => {
        dispatch(getKycStatusAction.done(res.data));
      })
      .catch(error => dispatch(getKycStatusAction.failed(error)));
  };
};

export const kycVerifyAction = actionCreator(KYC.KYC_VERIFY);

export const kycVerify = data => {
  data = {
    ...data,
    address: `${data?.street} ${data?.house} ${data?.apartment}`,
  };
  delete data?.street;
  delete data?.house;
  delete data?.apartment;
  const formData = new FormData();
  Object.keys(data).forEach(field => {
    formData.append([field], data[field]);
  });
  return async dispatch => {
    dispatch(kycVerifyAction.started());
    api.kyc
      .kycVerify(formData)
      .then(res => {
        dispatch(getKycStatus());
        dispatch(kycVerifyAction.done(res.data));
      })
      .catch(error => dispatch(kycVerifyAction.failed(error)));
  };
};

export const kycRequired = () => {
  return dispatch => {
    dispatch(
      enqueueSnackbar({
        message: i18next.t("Pass KYC"),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
          autoHideDuration: 3000,
        },
      })
    );
  };
};
