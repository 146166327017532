import { useDispatch, useSelector } from "react-redux";
import { Grid, InputAdornment, Link, makeStyles, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useValidateForm } from "../../utils/hooks/useValidateForm";
import { withdrawMoneyboxBalanceValidator } from "../../utils/functions/customValidators/validators";
import { isLoading, isSucceeded } from "../../utils/functions/requestStatuses";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import CoinName from "../../theme/components/CoinName";
import { roundValue } from "../../utils/functions/roundValue";
import { InfoOutlined } from "@material-ui/icons";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { transferCompanyBalance } from "../../redux/actions/companiesActions";
import { companiesSelectors } from "../../redux/selectors/companiesSelectors";

const initialForm = {
  amount: "",
  description: "",
};

const WithdrawCompanyBalance = ({ open, onClose, companyBalanceData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialForm);
  const { validateForm, validateInput, errors } = useValidateForm({ form, customValidator: withdrawMoneyboxBalanceValidator });

  const withdrawCompanyBalanceRequestStatus = useSelector(companiesSelectors.transferCompanyBalanceRequestStatus);
  const prevWithdrawCompanyBalanceRequestStatus = usePrevious(withdrawCompanyBalanceRequestStatus);

  const isLoaderNeeded = isLoading(withdrawCompanyBalanceRequestStatus)

  const tickerSettings = useSelector(({ wallets }) => wallets.tickerSettings);

  const ticker = companyBalanceData?.currency
  const balanceId = companyBalanceData?.balanceId
  const balance = companyBalanceData?.balance

  const marketPrice = useSelector(
    ({ market }) => market.data.find(i => i?.ticker === ticker)?.price || 0
  );

  const handleClose = useCallback(() => {
    onClose?.();
    setForm(initialForm);
  }, [onClose])

  useEffect(() => {
    if (isLoading(prevWithdrawCompanyBalanceRequestStatus) && isSucceeded(withdrawCompanyBalanceRequestStatus)) {
      handleClose();
    }
  }, [handleClose, prevWithdrawCompanyBalanceRequestStatus, withdrawCompanyBalanceRequestStatus])

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    if (name === 'amount' && !/^[0-9]*\.?[0-9]*$/.test(value)) {
      return;
    }
    setForm({ ...form, [name]: value });
  }, [form])

  const handleSubmit = useCallback(() => {
    if (!validateForm()) {
      return;
    }
    const submitData = {
      balanceId: balanceId,
      amount: form.amount,
      description: form.description || '',
    }
    if (submitData?.balanceId) {
      dispatch(transferCompanyBalance(submitData))
    }
  }, [validateForm, balanceId, form.amount, form.description, dispatch])

  const handleMaxAmountClick = useCallback(() => {
    setForm({ ...form, amount: Number(balance) })
  }, [balance, form])

  return (
    <DialogWrapper
      open={Boolean(open)}
      handleClose={handleClose}
      title={`${t("Withdraw")} (${ticker?.toUpperCase()})`}
      isCenteredTitle
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.balanceTitle}>
            {t('Balance')}
          </Typography>
          <Grid direction="row" container alignItems="center">
            <CoinName
              ticker={ticker}
              showOnlyIcon
              iconSize={36}
            />
            <Typography className={classes.balanceValue} variant="h3">
              {`${roundValue(balance, tickerSettings?.minScale)} ${ticker?.toUpperCase()}`}
              &nbsp;&nbsp;/
            </Typography>
            <Typography className={classes.balanceValueUSD} variant="body2">
              {`${roundValue(balance * marketPrice, 2)} USD`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("Amount")}
            placeholder={t("Amount")}
            variant="outlined"
            className={classes.input}
            name={"amount"}
            value={form?.amount || ""}
            type="number"
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.amount?.invalid}
            helperText={t(errors?.amount?.errorMessage)}
            InputProps={{
              autoComplete: "off",
              endAdornment: (
                <InputAdornment position="end">
                  <Link onClick={handleMaxAmountClick}>MAX</Link>
                </InputAdornment>
              ),
            }}
            disabled={isLoaderNeeded}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("Description")}
            placeholder={t("Description")}
            variant="outlined"
            className={classes.input}
            name={"description"}
            value={form?.description || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.description?.invalid}
            helperText={t(errors?.description?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
            disabled={isLoaderNeeded}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={isLoaderNeeded}
            onClick={handleSubmit}
          >
            {t("Withdraw")}
          </Button>
        </Grid>
        <Typography variant="body2" className={classes.infoText}>
          <InfoOutlined
            style={{ color: "#878787", fontSize: 14 }}
          />
          {t('Funds will be withdrawn to the main wallet')}
        </Typography>
      </Grid>
    </DialogWrapper>
  );
};

export default WithdrawCompanyBalance;

const useStyles = makeStyles((theme) => ({
  input: {
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.default,
        fontSize: 8,
        cursor: "pointer",
        textDecoration: "none",
        background: theme.palette.text.secondary,
        opacity: 0.8,
        borderRadius: "4px",
        padding: "2px 12px",
        marginRight: 20,
      },
    },
  },
  titleDialog: {
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
  balanceTitle: {
    marginBottom: 16,
  },
  balanceValue: {
    marginLeft: 14,
  },
  balanceValueUSD: {
    color: theme.palette.text.secondary,
    marginLeft: 8,
    marginBottom: -8
  },
  infoText: {
    fontSize: 12,
    color: theme.palette.text.darkLighter,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    marginLeft: 20,
    marginBottom: 10,
    marginTop: 14
  },
  button: {
    marginTop: 10,
  }
}));
