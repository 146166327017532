import {
  CHECK_INTERNAL_ADDRESS_FAILURE,
  CHECK_INTERNAL_ADDRESS_SUCCESS,
  CLEAR_CHECK_INTERNAL_ADDRESS,
  CREATE_NEW_WALLET_FAILURE,
  CREATE_NEW_WALLET_STARTED,
  CREATE_NEW_WALLET_SUCCESS,
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_STARTED,
  GET_CURRENCIES_SUCCESS,
  GET_WALLET_FEE_FAILURE,
  GET_WALLET_FEE_STARTED,
  GET_WALLET_FEE_SUCCESS,
  GET_ALL_TICKER_SETTINGS_FAILURE,
  GET_ALL_TICKER_SETTINGS_STARTED,
  GET_ALL_TICKER_SETTINGS_SUCCESS,
  GET_TICKER_SETTINGS_FAILURE,
  GET_TICKER_SETTINGS_STARTED,
  GET_TICKER_SETTINGS_SUCCESS,
  GET_WALLETS_LIST_FAILURE,
  GET_WALLETS_LIST_STARTED,
  GET_WALLETS_LIST_SUCCESS,
  SET_WALLETS_PARAMS,
  WITHDRAW_FAILURE,
  WITHDRAW_STARTED,
  WITHDRAW_SUCCESS,
  GET_WALLETS_SETTINGS_LIST_FAILURE,
  GET_WALLETS_SETTINGS_LIST_STARTED,
  GET_WALLETS_SETTINGS_LIST_SUCCESS,
  UPDATE_WALLET_SETTINGS_FAILURE,
  UPDATE_WALLET_SETTINGS_STARTED,
  UPDATE_WALLET_SETTINGS_SUCCESS,
  RECEIVE_FIAT_FAILURE,
  RECEIVE_FIAT_STARTED,
  RECEIVE_FIAT_SUCCESS,
  SET_WITHDRAWAL_CARD,
  CLEAR_TICKER_SETTINGS,
} from "../types";
import api from "../../services/api";
import { enqueueSnackbar } from "./notistackActions";
import i18next from "../../i18n.config";

export const getWalletsListStarted = () => ({
  type: GET_WALLETS_LIST_STARTED,
});

export const getWalletsListSuccess = (data) => ({
  type: GET_WALLETS_LIST_SUCCESS,
  payload: {
    data,
  },
});

export const getWalletsListFailure = (error) => ({
  type: GET_WALLETS_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getWalletsList = (params) => {
  return async (dispatch) => {
    dispatch(getWalletsListStarted());
    api.wallets
      .getList(params)
      .then((res) => dispatch(getWalletsListSuccess(res.data)))
      .catch((error) => dispatch(getWalletsListFailure(error)));
  };
};

export const setWalletsParams = (params) => ({
  type: SET_WALLETS_PARAMS,
  payload: {
    params,
  },
});

export const getCurrenciesStarted = () => ({
  type: GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = (data) => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: {
    data,
  },
});

export const getCurrenciesFailure = (error) => ({
  type: GET_CURRENCIES_FAILURE,
  payload: {
    error,
  },
});

export const getCurrencies = (params) => {
  return async (dispatch) => {
    dispatch(getCurrenciesStarted());
    api.wallets
      .getCurrencies(params)
      .then((res) => dispatch(getCurrenciesSuccess(res.data)))
      .catch((error) => dispatch(getCurrenciesFailure(error)));
  };
};

export const createWalletStarted = () => ({
  type: CREATE_NEW_WALLET_STARTED,
});

export const createWalletSuccess = (data) => ({
  type: CREATE_NEW_WALLET_SUCCESS,
  payload: {
    data,
  },
});

export const createWalletFailure = (error) => ({
  type: CREATE_NEW_WALLET_FAILURE,
  payload: {
    error,
  },
});

export const createWallet = (ticker, params) => {
  return async (dispatch) => {
    dispatch(createWalletStarted());
    api.wallets
      .addNew({ ticker })
      .then((res) => {
        dispatch(createWalletSuccess(res.data));
        dispatch(getWalletsList(params));
      })
      .catch((error) => dispatch(createWalletFailure(error)));
  };
};

export const withdrawStarted = () => ({
  type: WITHDRAW_STARTED,
});

export const withdrawSuccess = (data) => ({
  type: WITHDRAW_SUCCESS,
  payload: {
    data,
  },
});

export const withdrawFailure = (error) => ({
  type: WITHDRAW_FAILURE,
  payload: {
    error,
  },
});

export const withdraw = (data) => {
  return async (dispatch) => {
    dispatch(withdrawStarted());
    api.wallets
      .withdraw(data)
      .then((res) => {
        dispatch(withdrawSuccess(res.data));
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(
          enqueueSnackbar({
            message: i18next.t(`Your withdraw has been successfully processed`),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
      })
      .catch((error) => {
        dispatch(withdrawFailure(error));
      });
  };
};

export const getWalletFeeStarted = () => ({
  type: GET_WALLET_FEE_STARTED,
});

export const getWalletFeeSuccess = (payload) => ({
  type: GET_WALLET_FEE_SUCCESS,
  payload,
});

export const getWalletFeeFailure = (error) => ({
  type: GET_WALLET_FEE_FAILURE,
  payload: {
    error,
  },
});

export const getWalletFee = (ticker) => {
  return async (dispatch) => {
    dispatch(getWalletFeeStarted());
    api.wallets
      .getWalletFee(ticker)
      .then((res) => dispatch(getWalletFeeSuccess(res.data)))
      .catch((error) => dispatch(getWalletFeeFailure(error)));
  };
};

export const checkInternalAddressSuccess = (payload) => ({
  type: CHECK_INTERNAL_ADDRESS_SUCCESS,
  payload,
});

export const checkInternalAddressFailure = (error) => ({
  type: CHECK_INTERNAL_ADDRESS_FAILURE,
  payload: {
    error,
  },
});

export const checkInternalAddress = (ticker, address) => {
  return async (dispatch) => {
    api.wallets
      .checkInternalAddress(ticker, address)
      .then((res) => dispatch(checkInternalAddressSuccess(res.data)))
      .catch((error) => dispatch(checkInternalAddressFailure(error)));
  };
};

export const clearCheckInternalAddress = () => ({
  type: CLEAR_CHECK_INTERNAL_ADDRESS,
});

export const getAllTickerSettingsStarted = () => ({
  type: GET_ALL_TICKER_SETTINGS_STARTED,
});

export const getAllTickerSettingsSuccess = (payload) => ({
  type: GET_ALL_TICKER_SETTINGS_SUCCESS,
  payload,
});

export const getAllTickerSettingsFailure = (error) => ({
  type: GET_ALL_TICKER_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getAllTickerSettings = () => {
  return async (dispatch) => {
    dispatch(getAllTickerSettingsStarted());
    api.wallets
      .getAllTickerSettings()
      .then((res) => dispatch(getAllTickerSettingsSuccess(res.data)))
      .catch((error) => dispatch(getAllTickerSettingsFailure(error)));
  };
};

export const getTickerSettingsStarted = () => ({
  type: GET_TICKER_SETTINGS_STARTED,
});

export const getTickerSettingsSuccess = (payload) => ({
  type: GET_TICKER_SETTINGS_SUCCESS,
  payload,
});

export const getTickerSettingsFailure = (error) => ({
  type: GET_TICKER_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getTickerSettings = (ticker) => {
  return async (dispatch) => {
    dispatch(getTickerSettingsStarted());
    api.wallets
      .getTickerSettings(ticker)
      .then((res) => dispatch(getTickerSettingsSuccess(res.data)))
      .catch((error) => dispatch(getTickerSettingsFailure(error)));
  };
};

export const getWalletsSettingsListStarted = () => ({
  type: GET_WALLETS_SETTINGS_LIST_STARTED,
});

export const getWalletsSettingsListSuccess = (data) => ({
  type: GET_WALLETS_SETTINGS_LIST_SUCCESS,
  payload: {
    data,
  },
});

export const getWalletsSettingsListFailure = (error) => ({
  type: GET_WALLETS_SETTINGS_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getWalletsSettingsList = () => {
  return async (dispatch) => {
    dispatch(getWalletsSettingsListStarted());
    api.wallets
      .getSettingsList()
      .then((res) => dispatch(getWalletsSettingsListSuccess(res.data)))
      .catch((error) => dispatch(getWalletsSettingsListFailure(error)));
  };
};

export const updateWalletSettingsStarted = () => ({
  type: UPDATE_WALLET_SETTINGS_STARTED,
});

export const updateWalletSettingsSuccess = (data) => ({
  type: UPDATE_WALLET_SETTINGS_SUCCESS,
  payload: {
    data,
  },
});

export const updateWalletSettingsFailure = (error) => ({
  type: UPDATE_WALLET_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const updateWalletSettings = (data) => {
  return async (dispatch) => {
    dispatch(updateWalletSettingsStarted());
    api.wallets
      .updateSettingsWallet(data)
      .then((res) => {
        dispatch(updateWalletSettingsSuccess(res.data));
        dispatch(getWalletsSettingsList());
      })
      .catch((error) => dispatch(updateWalletSettingsFailure(error)));
  };
};

export const receiveFiatStarted = () => ({
  type: RECEIVE_FIAT_STARTED,
});

export const receiveFiatSuccess = (data) => ({
  type: RECEIVE_FIAT_SUCCESS,
  payload: {
    data,
  },
});

export const receiveFiatFailure = (error) => ({
  type: RECEIVE_FIAT_FAILURE,
  payload: {
    error,
  },
});

export const receiveFiat = (data) => {
  return async (dispatch) => {
    dispatch(receiveFiatStarted());
    api.wallets
      .receiveFiat(data)
      .then((res) => {
        dispatch(receiveFiatSuccess(res.data));
      })
      .catch((error) => dispatch(receiveFiatFailure(error)));
  };
};

export const setIsWithdrawalCard = (data) => ({
  type: SET_WITHDRAWAL_CARD,
  payload: data,
});

export const clearTickerSettings = () => ({
  type: CLEAR_TICKER_SETTINGS,
});
