import {
  ACCOUNT_ACTIVATE_FAILURE,
  ACCOUNT_ACTIVATE_STARTED,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_LOGIN_FAILURE,
  ACCOUNT_LOGIN_STARTED,
  ACCOUNT_LOGIN_SUCCESS,
  LOGIN_TWO_FA_SUCCESS,
  ACCOUNT_REGISTER_FAILURE,
  ACCOUNT_REGISTER_STARTED,
  ACCOUNT_REGISTER_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  FINISH_RESET_PASSWORD_FAILURE,
  FINISH_RESET_PASSWORD_STARTED,
  FINISH_RESET_PASSWORD_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  GET_ACCOUNT_DATA_STARTED,
  GET_ACCOUNT_DATA_SUCCESS,
  LOG_OUT,
  BACK_TO_MAIN,
  RESET_PASSWORD_INIT_FAILURE,
  RESET_PASSWORD_INIT_STARTED,
  RESET_PASSWORD_INIT_SUCCESS,
  RESET_PASSWORD_RESET,
  RESET_REGISTER_STATUS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_STARTED,
  ENABLE_TWO_FA_STARTED,
  ENABLE_TWO_FA_SUCCESS,
  ENABLE_TWO_FA_FAILURE,
  DISABLE_TWO_FA_STARTED,
  DISABLE_TWO_FA_SUCCESS,
  DISABLE_TWO_FA_FAILURE,
  CHECK_IS_TWO_FA_STARTED,
  CHECK_IS_TWO_FA_SUCCESS,
  CHECK_IS_TWO_FA_FAILURE,
  CHECK_CODE_TWO_FA_STARTED,
  CHECK_CODE_TWO_FA_SUCCESS,
  CHECK_CODE_TWO_FA_FAILURE,
  CHECK_INSTALL_TWO_FA_STARTED,
  CHECK_INSTALL_TWO_FA_SUCCESS,
  CHECK_INSTALL_TWO_FA_FAILURE,
  CLEAR_TWO_FA_QR_CODE,
  GET_ACCOUNT_PERMISSIONS_INFO,
} from "../types";
import api from "../../services/api";
import { enqueueSnackbar } from "../../redux/actions/notistackActions";
import { actionCreator } from "../actionCreator";
import { EXTERNAL_BASE_URL, AUTH_TOKEN, EXTERNAL_URL } from "../../utils/constants/api";
import i18next from "../../i18n.config";

export const accountRegisterStarted = () => ({
  type: ACCOUNT_REGISTER_STARTED,
});

export const accountRegisterSuccess = () => ({
  type: ACCOUNT_REGISTER_SUCCESS,
});

export const accountRegisterFailure = (error) => ({
  type: ACCOUNT_REGISTER_FAILURE,
  payload: {
    error,
  },
});

export const accountRegister = (data) => {
  return async (dispatch) => {
    dispatch(accountRegisterStarted());
    api.account
      .register(data)
      .then((res) => {
        if (res.status === 201) {
          dispatch(accountRegisterSuccess());
        }
      })
      .catch((error) => {
        dispatch(accountRegisterFailure(error));
      });
  };
};

export const resetRegisterStatus = () => ({
  type: RESET_REGISTER_STATUS,
});

export const resetPasswordStarted = () => ({
  type: RESET_PASSWORD_INIT_STARTED,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_INIT_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_INIT_FAILURE,
  payload: {
    error,
  },
});

export const resetPassword = (data) => {
  return async (dispatch) => {
    dispatch(resetPasswordStarted());
    api.account
      .initResetPassword(data)
      .then((res) => dispatch(resetPasswordSuccess(res.data)))
      .catch((error) => dispatch(resetPasswordFailure(error)));
  };
};

export const finishResetPasswordStarted = () => ({
  type: FINISH_RESET_PASSWORD_STARTED,
});

export const finishResetPasswordSuccess = () => ({
  type: FINISH_RESET_PASSWORD_SUCCESS,
});

export const finishResetPasswordFailure = (error) => ({
  type: FINISH_RESET_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const finishResetPassword = (data) => {
  return async (dispatch) => {
    dispatch(finishResetPasswordStarted());
    api.account
      .finishResetPassword(data)
      .then((res) => dispatch(finishResetPasswordSuccess(res.data)))
      .catch((error) => dispatch(finishResetPasswordFailure(error)));
  };
};

export const resetPasswordReset = () => ({
  type: RESET_PASSWORD_RESET,
});

export const accountActivateStarted = () => ({
  type: ACCOUNT_ACTIVATE_STARTED,
});

export const accountActivateSuccess = () => ({
  type: ACCOUNT_ACTIVATE_SUCCESS,
});

export const accountActivateFailure = (error) => ({
  type: ACCOUNT_ACTIVATE_FAILURE,
  payload: {
    error,
  },
});

export const accountActivate = (params) => {
  return async (dispatch) => {
    dispatch(accountActivateStarted());
    api.account
      .activate(params)
      .then((res) => {
        dispatch(accountActivateSuccess(res.data));
        dispatch(
          enqueueSnackbar({
            message: i18next.t("Successfully registered"),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
      })
      .catch((error) => dispatch(accountActivateFailure(error)));
  };
};

export const accountLoginStarted = () => ({
  type: ACCOUNT_LOGIN_STARTED,
});

export const accountLoginSuccess = () => ({
  type: ACCOUNT_LOGIN_SUCCESS,
});

export const loginTwoFaSuccess = () => ({
  type: LOGIN_TWO_FA_SUCCESS,
});

export const accountLoginFailure = (error) => ({
  type: ACCOUNT_LOGIN_FAILURE,
  payload: { error: error },
});

export const accountLogin = (data) => {
  return async (dispatch) => {
    dispatch(accountLoginStarted());
    let formData = new FormData();
    formData.append("password", data.password);
    formData.append("username", data.username);
    formData.append("grant_type", "password");
    if (formData.secondAuthCode) {
      formData.append("secondAuthCode", data.secondAuthCode);
    }
    const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
      "base64"
    );
    const headers = { Authorization: "Basic " + authToken };
    api.account
      .login(formData, headers)
      .then((res) => {
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        dispatch(accountLoginSuccess(res.data));
        dispatch(getAccountPermissionsInfo());
      })
      .catch((error) => {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.error_description === "2FA"
        ) {
          dispatch(loginTwoFaSuccess());
          return;
        } else if (
          error?.response?.status === 403 &&
          error?.response?.data === "secret and code not equals"
        ) {
          dispatch(
            enqueueSnackbar({
              message: i18next.t("Invalid Google Authenticator code."),
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
                autoHideDuration: 3000,
              },
            })
          );
          return;
        }
        dispatch(accountLoginFailure(error));
      });
  };
};

export const accountLoginWithGoogle = (data) => {
  return async (dispatch) => {
    dispatch(accountLoginStarted());
    api.account
      .loginGoogle(data)
      .then((res) => {
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        dispatch(accountLoginSuccess());
      })
      .catch((error) => dispatch(accountLoginFailure(error)));
  };
};

export const accountLoginWithApple = (data) => {
  return async (dispatch) => {
    dispatch(accountLoginStarted());
    api.account
      .loginApple(data)
      .then((res) => {
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        dispatch(accountLoginSuccess());
      })
      .catch((error) => dispatch(accountLoginFailure(error)));
  };
};
export const backToMain = () => {
  window.location.replace(
    `${EXTERNAL_BASE_URL}?appLanguage=${localStorage.getItem(
      "AppLanguage"
    )}&access_token=${localStorage.getItem(
      "access_token"
    )}&refresh_token=${localStorage.getItem("refresh_token")}`,
    "_self"
  );
  // localStorage.clear();
  // document.cookie = "";
  return {
    type: BACK_TO_MAIN,
  };
};

export const logOut = (forceLogout = false) => {
  return async () => {
    api.account
      .logOut()
      .then((res) => {
        if (forceLogout) {
          window.location.replace(`${EXTERNAL_BASE_URL}${EXTERNAL_URL.login}`)
        } else {
          window.location.replace(
            `${EXTERNAL_BASE_URL}?appLanguage=${localStorage.getItem(
              "AppLanguage"
            )}&logout=true`,
            "_self"
          );
        }
        localStorage.clear();
        document.cookie = "";
        return {
          type: LOG_OUT,
        };
      })
      .catch((error) => {
        if (forceLogout) {
          window.location.replace(`${EXTERNAL_BASE_URL}${EXTERNAL_URL.login}`)
        } else {
          window.location.replace(
            `${EXTERNAL_BASE_URL}?appLanguage=${localStorage.getItem(
              "AppLanguage"
            )}&logout=true`,
            "_self"
          );
        }
        localStorage.clear();
        document.cookie = "";
        return {
          type: LOG_OUT,
        };
      });
  };
};

export const getAccountDataStarted = () => ({
  type: GET_ACCOUNT_DATA_STARTED,
});

export const getAccountDataSuccess = (data) => ({
  type: GET_ACCOUNT_DATA_SUCCESS,
  payload: {
    data,
  },
});

export const getAccountDataFailure = (error) => ({
  type: GET_ACCOUNT_DATA_FAILURE,
  payload: {
    error,
  },
});

export const getAccountData = () => {
  return async (dispatch) => {
    dispatch(getAccountDataStarted());
    api.account
      .getData()
      .then((res) => {
        dispatch(getAccountDataSuccess(res.data));
        dispatch(getAccountPermissionsInfo());
      })
      .catch((error) => dispatch(getAccountDataFailure(error)));
  };
};

export const updateAccountStarted = () => ({
  type: UPDATE_ACCOUNT_STARTED,
});

export const updateAccountFailure = (error) => ({
  type: UPDATE_ACCOUNT_FAILURE,
  payload: {
    error,
  },
});

export const updateAccount = (data) => {
  return async (dispatch) => {
    dispatch(updateAccountStarted());
    api.account
      .update(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getAccountData());
          dispatch(
            enqueueSnackbar({
              message: i18next.t("Data saved successfully."),
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                autoHideDuration: 3000,
              },
            })
          );
        }
      })
      .catch((error) => {
        dispatch(updateAccountFailure(error));
      });
  };
};

export const changePasswordStarted = () => ({
  type: CHANGE_PASSWORD_STARTED,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: {
    data,
  },
});

export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const changePassword = (data) => {
  return async (dispatch) => {
    dispatch(changePasswordStarted());
    api.account
      .changePassword(data)
      .then((res) => {
        dispatch(changePasswordSuccess(res.data));
        dispatch(logOut());
      })
      .catch((error) => dispatch(changePasswordFailure(error)));
  };
};

export const enableTwoFAStarted = () => ({
  type: ENABLE_TWO_FA_STARTED,
});

export const enableTwoFASuccess = (payload) => ({
  type: ENABLE_TWO_FA_SUCCESS,
  payload,
});

export const enableTwoFAFailure = (error) => ({
  type: ENABLE_TWO_FA_FAILURE,
  payload: {
    error,
  },
});

export const enableTwoFA = () => {
  return async (dispatch) => {
    dispatch(enableTwoFAStarted());
    api.account
      .enableTwoFA()
      .then((res) => {
        dispatch(enableTwoFASuccess(res.data));
      })
      .catch((error) => dispatch(enableTwoFAFailure(error)));
  };
};

export const disableTwoFAStarted = () => ({
  type: DISABLE_TWO_FA_STARTED,
});

export const disableTwoFASuccess = (payload) => ({
  type: DISABLE_TWO_FA_SUCCESS,
  payload,
});

export const disableTwoFAFailure = (error) => ({
  type: DISABLE_TWO_FA_FAILURE,
  payload: {
    error,
  },
});

export const disableTwoFA = () => {
  return async (dispatch) => {
    dispatch(disableTwoFAStarted());
    return api.account
      .disableTwoFA()
      .then((res) => {
        dispatch(disableTwoFASuccess());
        dispatch(
          enqueueSnackbar({
            message: i18next.t("Google Authenticator (2FA) disabled"),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              autoHideDuration: 3000,
            },
          })
        );
        return res;
      })
      .catch((error) => dispatch(disableTwoFAFailure(error)));
  };
};

export const checkIsTwoFAStarted = () => ({
  type: CHECK_IS_TWO_FA_STARTED,
});

export const checkIsTwoFASuccess = (payload) => ({
  type: CHECK_IS_TWO_FA_SUCCESS,
  payload,
});

export const checkIsTwoFAFailure = (error) => ({
  type: CHECK_IS_TWO_FA_FAILURE,
  payload: {
    error,
  },
});

export const checkIsTwoFA = () => {
  return async (dispatch) => {
    dispatch(checkIsTwoFAStarted());
    api.account
      .checkIsTwoFA()
      .then((res) => {
        dispatch(checkIsTwoFASuccess(res.data));
      })
      .catch((error) => dispatch(checkIsTwoFAFailure(error)));
  };
};

export const checkCodeTwoFAStarted = () => ({
  type: CHECK_CODE_TWO_FA_STARTED,
});

export const checkCodeTwoFASuccess = (payload) => ({
  type: CHECK_CODE_TWO_FA_SUCCESS,
  payload,
});

export const checkCodeTwoFAFailure = (error) => ({
  type: CHECK_CODE_TWO_FA_FAILURE,
  payload: {
    error,
  },
});

export const checkCodeTwoFA = (data) => {
  return async (dispatch) => {
    dispatch(checkCodeTwoFAStarted());
    api.account
      .checkCodeTwoFA(data)
      .then((res) => {
        dispatch(checkCodeTwoFASuccess(res.data));
        if (!res.data) {
          dispatch(
            enqueueSnackbar({
              message: i18next.t("Invalid Google Authenticator code."),
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
                autoHideDuration: 3000,
              },
            })
          );
        }
      })
      .catch((error) => dispatch(checkCodeTwoFAFailure(error)));
  };
};

export const checkInstallTwoFAStarted = () => ({
  type: CHECK_INSTALL_TWO_FA_STARTED,
});

export const checkInstallTwoFASuccess = (payload) => ({
  type: CHECK_INSTALL_TWO_FA_SUCCESS,
  payload,
});

export const checkInstallTwoFAFailure = (error) => ({
  type: CHECK_INSTALL_TWO_FA_FAILURE,
  payload: {
    error,
  },
});

export const checkInstallTwoFA = (data) => {
  return async (dispatch) => {
    dispatch(checkInstallTwoFAStarted());
    return api.account
      .checkInstallTwoFA(data)
      .then((res) => {
        dispatch(checkInstallTwoFASuccess(res.data));
        dispatch(
          enqueueSnackbar({
            message: res.data
              ? i18next.t("Google Authenticator (2FA) enabled")
              : i18next.t(`Invalid Google Authenticator code.`),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: res.data ? "success" : "error",
              autoHideDuration: 3000,
            },
          })
        );
        return res;
      })
      .catch((error) => {
        dispatch(checkInstallTwoFAFailure(error));
        dispatch(
          enqueueSnackbar({
            message: error.response.data.title,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              autoHideDuration: 3000,
            },
          })
        );
        return { data: false };
      });
  };
};

export const clearTwoFaQrCode = () => ({
  type: CLEAR_TWO_FA_QR_CODE,
});

export const getAccountPermissionsInfoAction = actionCreator(
  GET_ACCOUNT_PERMISSIONS_INFO
);

export const getAccountPermissionsInfo = () => {
  return async (dispatch) => {
    dispatch(getAccountPermissionsInfoAction.started());
    api.account
      .permissionInfo()
      .then((res) => {
        dispatch(getAccountPermissionsInfoAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getAccountPermissionsInfoAction.failed(error))
      );
  };
};
