import { intervalToDuration, isAfter } from "date-fns";
import moment from "moment";

export const getDaysDiffFromNow = (endDateString) => {
  if (endDateString) {
    var current = moment().startOf('day');
    var end = moment(endDateString, "YYYY-MM-DD");
    //Difference in number of days
    return Math.floor(moment.duration(current.diff(end)).asDays());
  }
  return 0
}

export const formatTimeout = (finishTime) => {
  if (!finishTime) {
    return '';
  }
  const isDateAfterNow = isAfter(new Date(finishTime), new Date());
  if (!isDateAfterNow) {
    return '00:00';
  }
  try {
    const endDate = new Date(finishTime);
    const { minutes = 0, seconds = 0 } = intervalToDuration({
      start: new Date(),
      end: endDate,
    });
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds
      }`;
  } catch (e) {
    console.log('e', e);
  }
  return '';
};