import React, { useRef, useState } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatTimeout } from '../../utils/functions/date';

const TIMEOUT_DEFAULT = '00:00';

export const Countdown = ({
  finishTime,
  finishTimeMiliseconds,
  showFinishText,
  textAlign = 'center',
}) => {
  const [time, setTime] = useState(formatTimeout(finishTime || finishTimeMiliseconds));
  const timerRef = useRef(time);
  const { t } = useTranslation();
  const isTimeExpired = time === TIMEOUT_DEFAULT;

  React.useEffect(() => {
    const timerId = setInterval(() => {
      timerRef.current = formatTimeout(finishTime || finishTimeMiliseconds);
      if (timerRef.current === TIMEOUT_DEFAULT) {
        setTime(timerRef.current);
        clearInterval(timerId);
      } else {
        setTime(timerRef.current);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [finishTime, finishTimeMiliseconds]);

  return showFinishText && isTimeExpired ? (
    <Typography
      style={{ alignSelf: textAlign }}
      variant='body2'
      color={'error'}>
      {t('Expired')}
    </Typography>
  ) : (
    <Typography
      style={{ alignSelf: textAlign }}
      variant='body1'>
      {time}
    </Typography>
  );
};
