import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeCreateCardDialog } from "../../../redux/actions/dialogActions";
import {
  setDefaultCard,
  createCard,
  deleteBankCard
} from "../../../redux/actions/cardsActions";
import { Button } from "../../components/Button";
import DialogWrapper from "../DialogWrapper";
import CardName from "../../components/CardName";
import CreateCardForm from "./components/CreateCardForm";
import AddCardPhoto from "./components/AddCardPhoto";
import AddSelfiePhoto from "./components/AddSelfiePhoto";
import DeleteConfirmDialog from './../DeleteConfirmDialog';

const CREATE_CARD_STEPS = {
  ADD_CARD_INFO: 1,
  ADD_CARD_PHOTO: 2,
  ADD_SELFI_PHOTO: 3
}

const CreateCardDialog = ({ cards, handleSelectedCard }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCreate, setIsCreate] = useState(false);
  const [form, setForm] = useState(null);
  const [activeStep, setActiveStep] = useState(CREATE_CARD_STEPS.ADD_CARD_INFO)
  const [showDeleteCard, setShowDeleteCard] = useState(null);
  const { open } = useSelector(({ dialogs }) => dialogs.createCard);

  useEffect(() => {
    if (!open) {
      setForm(null)
      setActiveStep(CREATE_CARD_STEPS.ADD_CARD_INFO)
      setIsCreate(false);
    }
  }, [open])

  const handleCloseDialog = () => {
    dispatch(closeCreateCardDialog());
    setIsCreate(false);
  };

  const handleBack = () => {
    if (isCreate) {
      if (activeStep === CREATE_CARD_STEPS.ADD_CARD_INFO) {
        setIsCreate(false);
      } else if (activeStep === CREATE_CARD_STEPS.ADD_CARD_PHOTO) {
        setActiveStep(CREATE_CARD_STEPS.ADD_CARD_INFO)
      } else if (activeStep === CREATE_CARD_STEPS.ADD_SELFI_PHOTO) {
        setActiveStep(CREATE_CARD_STEPS.ADD_CARD_PHOTO)
      }
    } else {
      handleCloseDialog();
    }
  };

  const handleSubmitCardForm = (formData) => {
    // submit step 1
    setForm(formData)
    setActiveStep(CREATE_CARD_STEPS.ADD_CARD_PHOTO)
  };

  const handleSubmitCardPhoto = (file) => {
    // submit step 2
    setActiveStep(CREATE_CARD_STEPS.ADD_SELFI_PHOTO)
    setForm({ ...form, cardPhoto: file })
  }

  const handleSubmitCreateCard = (file) => {
    // submit createCard
    const submitData = { ...form, selfieWithCard: file, expirationDate: `${form?.expirationMonth}/${form?.expirationYear}` }
    delete submitData?.expirationMonth
    delete submitData?.expirationYear
    dispatch(createCard(submitData));
  }

  const handleSelect = (card) => {
    handleSelectedCard(card);
    handleCloseDialog();
  };

  const handleSetDefaultCard = (card) => {
    if (card?.defaultCard) {
      return;
    }
    dispatch(setDefaultCard(card?.id));
  };

  const handleDeleteCardClick = (cardData) => {
    setShowDeleteCard(cardData);
  }

  const handleCloseDeleteConfirmDialog = ({ accept }) => {
    if (accept && showDeleteCard?.id) {
      // call api delete;
      dispatch(deleteBankCard(showDeleteCard?.id))
    }
    setShowDeleteCard(null);
  }

  return (
    <>
      <DialogWrapper
        open={Boolean(open)}
        handleClose={handleCloseDialog}
        dialogClassName={classes.dialog}
        handleBack={handleBack}
        className={classes.root}
        isCenteredTitle
        title={isCreate ? t("Add bank card") : t("Bank card selection")}
      >
        {!isCreate && (
          <>
            <Grid container>
              <Grid item xs={12}>
                {cards.length > 0 &&
                  cards.map((card, i) => (
                    <CardName
                      key={card?.id || i}
                      card={card}
                      handleSetDefaultCard={handleSetDefaultCard}
                      handleSelectCard={() => handleSelect(card)}
                      onDeleteClick={handleDeleteCardClick}
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsCreate(true)}
                className={classes.cardButton}
              >
                <AddIcon />
                {t("Add bank card")}
              </Button>
            </Grid>{" "}
          </>
        )}

        {isCreate && (
          <>
            <Typography
              variant='h5'
              align='right'
              className={classes.step}
              noWrap>
              {`${t('Step')} ${activeStep}/3`}
            </Typography>
            {
              activeStep === CREATE_CARD_STEPS.ADD_CARD_INFO &&
              <CreateCardForm onSubmitForm={handleSubmitCardForm} defaultData={form} />
            }
            {activeStep === CREATE_CARD_STEPS.ADD_CARD_PHOTO &&
              <AddCardPhoto onSubmit={handleSubmitCardPhoto} defaultPhotoFile={form?.['cardPhoto']} />
            }
            {activeStep === CREATE_CARD_STEPS.ADD_SELFI_PHOTO &&
              <AddSelfiePhoto onSubmit={handleSubmitCreateCard} defaultPhotoFile={form?.['selfieWithCard']} />
            }
          </>
        )}
      </DialogWrapper>
      <DeleteConfirmDialog
        open={Boolean(showDeleteCard)}
        title={t("Delete card")}
        message={t("Are you sure you want to delete selected card?")}
        handleClose={handleCloseDeleteConfirmDialog}
      />
    </>
  );
};

export default CreateCardDialog;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialog: {
    paddingTop: 25,
    overflowY: 'scroll'
  },
  cardButton: {
    marginTop: 16,
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
  input: {
    // marginBottom: 32,
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.light,
        fontSize: 14,
        marginRight: 16,
        cursor: "pointer",
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
          marginRight: 12,
        },
      },
    },
  },
  button: {
    marginTop: 8,
  },
  card: {
    "& input": {
      fontFamily: "'Orbitron', sans-serif",
      fontWeight: 400,
      letterSpacing: 2,
    },
  },
  step: {
    marginRight: 20,
    marginBottom: 20
  }
}));
