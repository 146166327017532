import { Button as MButton, withStyles } from "@material-ui/core";

export const VerticalButton = withStyles(theme => ({
  root: {
    borderRadius: 12,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    textTransform: "none",
    alignItems: "stretch",
    padding: 10,
    maxWidth: 108,
    minHeight: 82,
    width: "100%",
    "& .MuiButton-label": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      "& svg": {
        fontSize: 24,
        marginTop: 12,
      },
    },
    "&.MuiButton-outlined.Mui-disabled": {
      background: "rgba(217, 219, 233, 0.3)",
    },

    "&.MuiButton-outlined": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.border.secondary,
      "&:hover": {
        background: theme.palette.background.hover,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: 88,
        minHeight: 68,
        padding: 10,
      },
    },
  },
}))(MButton);
