import BackIcon from "../../assets/icons/back.svg";
import { IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { backToMain } from "../../redux/actions/accountActions";

const Breadcrumb = ({ to, pageName, down, goBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const backTo = event => {
    event.preventDefault();
    event.stopPropagation();
    if (goBack) {
      history.goBack?.()
    }
    pageName === "Main" ? dispatch(backToMain()) : history.push(to);
  };

  return (
    <Paper
      elevation={4}
      className={classes.root}
      style={{ marginBottom: down || 0 }}>
      <IconButton className={classes.iconBtn} size={"small"} onClick={backTo}>
        <img className={classes.icon} src={BackIcon} alt='backIcon' loading={"lazy"} />
        <Typography variant={"body2"}>{t(pageName)}</Typography>
      </IconButton>
    </Paper>
  );
};

Breadcrumb.propTypes = {
  to: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default Breadcrumb;

const useStyles = makeStyles(theme => ({
  root: {
    height: 38,
    maxWidth: "max-content",
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: '16px',
    marginRight: '10px',
  },
  iconBtn: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "6px 20px 6px 14px",
    borderRadius: 12,
    background: theme.palette.background.paper,
    color: theme.palette.primary.default,
    "& svg": {
      fontSize: 24,
    },
    "&:hover": {
      background: theme.palette.background.header,
    },
  },
  link: {
    color: theme.palette.primary.default,
    textDecoration: "none",
  },
}));
