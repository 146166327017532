import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { KYC_STEPS } from "../../../utils/constants/kyc";
import { useColoredTitle } from "../../../utils/hooks/useColoredTitle";
function getSteps() {
  return KYC_STEPS;
}

export default function StepperKYC() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { activeStep } = useSelector(({ kyc }) => kyc);
  const steps = getSteps();
  const theme = useTheme();

  const { renderTitle } = useColoredTitle()

  // const isConfirmed = useMemo(() => {
  //   if (verificationStatus) {
  //     return verificationStatus === VERIFICATION_STATUS.CONFIRMED;
  //   }
  // }, [verificationStatus]);

  return (
    <div className={classes.root}>
      <Typography
        variant='h4'
        align='center'
        noWrap
        className={clsx(classes.stepInfo, classes.active)}>
        {`${activeStep + 1} ${t(getSteps()[activeStep]).toUpperCase()}`}
      </Typography>

      <div className={classes.steps}>
        <div
          className={classes.step}>
          {renderTitle(t(steps[activeStep]))}
          <Typography className={classes.description}>{`${t("Step")} `}<span style={{color: theme.palette.text.quaternary}}>{activeStep + 1}</span>/5</Typography>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: 82,
    paddingLeft: 24,
    paddingRight: 24,
    position: "relative",
    [theme.breakpoints.down(800)]: {
      paddingTop: 56,
      height: "auto",
    },
  },
  stepInfo: {
    fontWeight: 400,
    display: "none",
    [theme.breakpoints.down(800)]: {
      display: "block",
      fontSize: 16,
    },
  },
  steps: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: -50,
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  step: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    flex: 1,
  },
  labelStep: {
    whiteSpace: "pre-line",
  },
  circles: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 12,
    // cursor: "pointer",
  },
  outerCircle: {
    borderRadius: "50%",
    backgroundColor: theme.palette.text.primary,
    opacity: 0.5,
    width: 16,
    height: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(5px)",
  },
  innerCircle: {
    borderRadius: "50%",
    backgroundColor: theme.palette.text.secondary,
    width: 8,
    height: 8,
  },
  numberStep: {
    marginTop: 16,
  },
  lineSteps: {
    width: "100%",
    height: 4,
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  line: {
    width: `calc(100% / ${getSteps().length})`,
    height: 4,
    backgroundColor: theme.palette.text.secondary,
  },
  lineStart: {
    width: `calc(100% / ${getSteps().length} / 2)`,
    height: 4,
    backgroundColor: theme.palette.text.secondary,
  },
  lineEnd: {
    width: `calc(100% / ${getSteps().length} / 2)`,
    height: 4,
    backgroundColor: theme.palette.text.secondary,
  },
  activeStep: {
    backgroundColor: theme.palette.success.main,
    opacity: 1,
  },
  activeStepLine: {
    backgroundColor: theme.palette.success.main,
  },

  currentStep: {
    backgroundColor: theme.palette.primary.default,
    opacity: 1,
  },
  active: {
    color: theme.palette.success.main,
  },
  description: {
    textTransform: 'uppercase',
    fontSize: 20
  }
}));
