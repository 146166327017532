import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Button } from "../../../../theme/components/Button";
import {
  setActiveStep,
  setFilledStep,
} from "../../../../redux/actions/kycActions.js";
import { imageValidator } from "../../../../utils/functions/customValidators/kycValidator";
import { enqueueSnackbar } from "../../../../redux/actions/notistackActions";
import DocumentTypes from "./DocumentTypes";
import UploadDocument from "./UploadDocuments";
import {
  DOCUMENT_TYPES,
  STEP_DOCUMENTS_INFO,
} from "../../../../utils/constants/kyc";

const initialForm = {
  documentType: null,
  documentAvers: null,
  documentRevers: null,
};

const DocumentsInfo = ({ updateKYCForm, KYCForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep, stepsFilled } = useSelector(({ kyc }) => kyc);
  const [state, setState] = useState(STEP_DOCUMENTS_INFO.TYPE);
  const [form, setForm] = useState(initialForm);
  let fileInput;

  useEffect(() => {
    let data = {};
    Object.keys(form).forEach((key) => (data[key] = KYCForm[key]));
    setForm(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepsFilled[activeStep]) {
      setState(STEP_DOCUMENTS_INFO.DOCUMENT_AVERS);
    }
  }, [stepsFilled, activeStep]);

  const handleNext = () => {
    if (validateStep()) {
      return;
    }
    if (
      state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS &&
      form.documentType !== DOCUMENT_TYPES.PASSPORT
    ) {
      setState(STEP_DOCUMENTS_INFO.DOCUMENT_REVERS);
      return;
    }
    updateKYCForm(form);
    dispatch(setFilledStep(activeStep));
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleBack = () => {
    switch (state) {
      case STEP_DOCUMENTS_INFO.DOCUMENT_AVERS:
        setForm({ ...form, documentAvers: null });
        setState(STEP_DOCUMENTS_INFO.TYPE);
        return;
      case STEP_DOCUMENTS_INFO.DOCUMENT_REVERS:
        setForm({ ...form, documentRevers: null });
        setState(STEP_DOCUMENTS_INFO.DOCUMENT_AVERS);
        return;
      default:
        setForm(initialForm);
        updateKYCForm(initialForm);
        dispatch(setActiveStep(activeStep - 1));
        return;
    }
  };

  const selectDocumentType = (type) => {
    setForm({ ...initialForm, documentType: type });
    setState(STEP_DOCUMENTS_INFO.DOCUMENT_AVERS);
  };

  const openFileUploader = () => {
    fileInput?.click();
  };

  const onFileChanged = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (imageValidator(dispatch, e.target.files[0])?.invalid) {
      return;
    }
    const file = e.target.files[0];
    if (typeof state === "string") {
      if (state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS) {
        setForm({ ...form, documentAvers: file });
      } else {
        setForm({ ...form, documentRevers: file });
      }
    }
  };

  const validateStep = () => {
    if (
      (state === STEP_DOCUMENTS_INFO.DOCUMENT_AVERS && !form.documentAvers) ||
      (form.documentType !== DOCUMENT_TYPES.PASSPORT &&
        state === STEP_DOCUMENTS_INFO.DOCUMENT_REVERS &&
        !form.documentRevers)
    ) {
      dispatch(
        enqueueSnackbar({
          message: t("This is a required verification step."),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            autoHideDuration: 3000,
          },
        })
      );
      return true;
    }
    if (
      form?.documentAvers &&
      imageValidator(dispatch, form.documentAvers)?.invalid
    ) {
      return true;
    }
    if (
      form.documentType !== DOCUMENT_TYPES.PASSPORT &&
      state === STEP_DOCUMENTS_INFO.DOCUMENT_REVERS &&
      form.documentRevers &&
      imageValidator(dispatch, form.documentAvers)?.invalid
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid container className={classes.root}>
      {state === STEP_DOCUMENTS_INFO.TYPE ? (
        <DocumentTypes selectDocumentType={selectDocumentType}></DocumentTypes>
      ) : (
        <UploadDocument state={state} form={form}></UploadDocument>
      )}
      <Grid>
        <input
          type="file"
          onChange={onFileChanged}
          hidden={true}
          id="fileSelect"
          ref={(refParam) => (fileInput = refParam)}
          accept=".jpg, .jpeg, .png"
        ></input>
      </Grid>

      <Grid
        container
        justifyContent="center"
        spacing={5}
        className={classes.btnBox}
      >
        <Grid item>
          <Button
            className={clsx(classes.btn, classes.btnBack)}
            variant="outlined"
            style={{ marginTop: 32 }}
            onClick={handleBack}
          >
            {t("Back")}
          </Button>
        </Grid>
        {state !== STEP_DOCUMENTS_INFO.TYPE && (
          <>
            <Grid item>
              <Button
                className={clsx(classes.btn)}
                variant="contained"
                style={{ marginTop: 32 }}
                onClick={openFileUploader}
              >
                {t("Upload the file")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={classes.btn}
                variant="contained"
                style={{ marginTop: 32 }}
                onClick={handleNext}
              >
                {t("Next")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentsInfo;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "24px 24px 50px",
    [theme.breakpoints.down(800)]: { padding: "108px 32px 80px 32px" },
    [theme.breakpoints.down("xs")]: { padding: "48px 32px" },
  },
  btnBox: {
    marginTop: 60,
    [theme.breakpoints.down("sm")]: { marginTop: "120px" },
    [theme.breakpoints.down("xs")]: { marginTop: "32px" },
  },
  btn: {
    minWidth: 165,
    height: 42,
    marginTop: "0px !important",
    "&.MuiButton-outlined": {
      padding: "12px 24px",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 152,
    },
  },
  btnBack: {
    borderColor: `${theme.palette.text.secondary} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.secondary,
    },
  },
}));
