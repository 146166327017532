import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const CoinName = ({ icon, ticker, name, showName = false, showOnlyIcon = false, isSmallIcon = false, iconSize = null }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {icon || (
        <img
          src={`https://cryptoneed.com/icons/${ticker}.svg`}
          width={iconSize || (isSmallIcon ? 24 : 32)}
          alt={ticker}
          height={iconSize || (isSmallIcon ? 24 : 32)}
          loading={"lazy"}
          className={classes.logo}
        />
      )}
      {
        !showOnlyIcon &&
        <div className={classes.text}>
          <Typography
            variant={"body1"}
            color={"textPrimary"}
            style={{ fontSize: showName ? 16 : 18 }}>
            {showName ?
              <span className={classes.name}>{name}</span>
              :
              <span className={"ticker"}>{ticker?.toUpperCase()}</span>
            }
          </Typography>
        </div>
      }
    </div>
  );
};

CoinName.propType = {
  icon: PropTypes.any,
  ticker: PropTypes.string.isRequired,
};

export default CoinName;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& p>span:not(.ticker)": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
    },
  },
  text: {
    marginLeft: 16,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 8,
    },
  },
  name: {
    fontWeight: 400
  }
}));
