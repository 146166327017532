import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import Search from "../../theme/components/Search";
import clsx from "clsx";
import CoinName from "../../theme/components/CoinName";
import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import { dialogListStyles } from "../../theme/dialogs/AddNewWalletDialog";
import { useTranslation } from "react-i18next";

const SelectMoneyboxBalanceDialog = ({ open, onClose, data }) => {
  const classes = dialogListStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const currencies = useSelector(({ wallets }) => wallets.currencies);
  const getCurrencyTitle = ticker =>
    currencies?.find(item => item?.ticker === ticker)?.name;

  return (
    <DialogWrapper open={open} handleClose={onClose} title={t('Select coin')}>
      <Grid container direction='column' justifyContent='center'>
        <Search
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          className={classes.search}
        />
      </Grid>
      <div className={classes.list}>
        {data
          .filter(coin =>
            (coin?.ticker || '').toLowerCase().includes(search.toLowerCase())
          )
          .map(coin => (
            <Paper
              variant={"outlined"}
              key={coin?.ticker}
              className={clsx(classes.currency, "hover")}
              onClick={() => onClose(coin?.ticker)}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}>
                <Grid item>
                  <CoinName
                    name={getCurrencyTitle(coin?.ticker)}
                    ticker={coin?.ticker}
                  />
                </Grid>
                <Grid item>
                  <ChevronRightOutlinedIcon color={"action"} />
                </Grid>
              </Grid>
            </Paper>
          ))}
      </div>
    </DialogWrapper>
  );
};

export default SelectMoneyboxBalanceDialog;
