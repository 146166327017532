import { makeStyles, IconButton, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import visa from "../../assets/icons/visa.svg";
import mastercard from "../../assets/icons/mastercard.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import DefaultCardIcon from "../../assets/icons/default_card.svg";
import ConfirmDefaultCardIcon from "../../assets/icons/confirm_default_card.svg";
import ScanCardIcon from '../../assets/icons/scan.svg'
import { useCallback } from "react";

export const BANK_CARD_STATUSES = {
  WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  REMOVED_BY_USER: 'REMOVED_BY_USER'
}

const BANK_CARD_STATUS_LABEL = {
  WAITING_FOR_REVIEW: 'for verification',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  APPROVED: 'Approved',
  REMOVED_BY_USER: 'Removed',
}

const CardName = ({
  card,
  handleSetDefaultCard,
  handleSelectCard,
  onDeleteClick,
}) => {
  const classes = useStyles();
  const isCardDeclined = card?.status === BANK_CARD_STATUSES.DECLINED
  const isCardExpired = card?.status === BANK_CARD_STATUSES.EXPIRED
  const isCardPending = card?.status === BANK_CARD_STATUSES.WAITING_FOR_REVIEW
  const isCardApproved = card?.status === BANK_CARD_STATUSES.APPROVED

  const handleDeleteCard = useCallback((e) => {
    e?.stopPropagation?.();
    onDeleteClick(card);
  }, [card, onDeleteClick])
  return (
    <Grid
      container
      className={clsx(classes.root, !isCardApproved ? classes.inactiveCard : {})}
      alignItems='center'
      justifyContent='space-between'
      onClick={() => handleSelectCard(card)}>
      <Grid item>
        <Grid container>
          <img
            src={card?.defaultCard ? ConfirmDefaultCardIcon : DefaultCardIcon}
            alt='default_card_icon'
            style={{
              marginRight: 18,
              marginTop: 11,
              cursor: card?.defaultCard ? "grab" : "pointer",
              height: 26
            }}
            onClick={event => {
              event.stopPropagation();
              handleSetDefaultCard(card);
            }}
          />

          <Grid item>
            <Grid container direction='column' justifyContent='flex-start'>
              <Typography
                variant={"body2"}
                className={classes.label}
                align='left'>
                {card?.cardName}
              </Typography>
              <Grid container alignItems='center'>
                <div className={classes.bankProviderWrapper}>
                  <img
                    src={card?.cardNumber[0] === "5" ? mastercard : visa}
                    alt={card?.cardNumber[0] === "5" ? mastercard : visa}
                    style={{ height: 16 }}
                  />
                </div>
                <Typography
                  variant={"body1"}
                  className={classes.cardNumber}
                  align='left'>
                  {`${card?.cardNumber?.slice(0, 4)}****${card?.cardNumber?.slice(
                    card?.cardNumber?.length - 4,
                    card?.cardNumber?.length
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.rightColumn}>
        {
          !isCardApproved ? (
            <Grid className={clsx(classes.cardStatusWrapper, isCardDeclined ? classes.declinedStatus : {}, isCardExpired ? classes.expiredStatus : {})}>
              <Typography
                variant={"body2"}
                className={clsx(classes.label, isCardDeclined ? classes.declinedStatus : {}, isCardExpired ? classes.expiredStatus : {})}>
                {BANK_CARD_STATUS_LABEL?.[card?.status]}
              </Typography>
              {
                isCardPending &&
                <img src={ScanCardIcon} alt='verifying' className={classes.pendingIcon} />
              }
            </Grid>)
            : null
        }
        {/* FOR NOW DELETE OF THE CARD IS IMMPOSSIBLE, HIDDEN IN CSS */}
        <Grid item className={classes.iconWrap}>
          <IconButton
            onClick={handleDeleteCard}>
            <img src={DeleteIcon} alt='delete_icon' />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardName;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 24,
    width: "100%",
    color: theme.palette.text.dark,
    background: theme.palette.text.primary,
    borderRadius: 12,
    padding: "0px 10px 4px 16px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  inactiveCard: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
  default: {
    cursor: "pointer",
  },
  label: {
    color: "#2B2F38",
    marginBottom: 2,
    fontSize: 12,
  },
  cardNumber: {
    fontSize: 20,
  },

  iconWrap: {
    // visibility: 'hidden'
  },
  bankProviderWrapper: {
    width: 40,
    height: 20,
    boxShadow: '5px 3px 6px 0px rgba(0,0,0,0.31)',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
    marginBottom: 2,
  },
  cardStatusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #9FA9BD',
    padding: '5px 10px',
    borderRadius: 8,
    marginRight: 8,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
  },
  declinedStatus: {
    color: '#FF2F51',
    borderColor: '#FF2F51',
    padding: '1px 4px'
  },
  expiredStatus: {
    color: '#AF994A',
    borderColor: '#AF994A',
    padding: '1px 4px'
  },
  pendingIcon: {
    height: 22,
    marginLeft: 8,
    marginRight: -4
  }
}));
