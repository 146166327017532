import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Transaction, TransactionDialog } from "../Transactions";
import { getTransactions } from "../../redux/actions/transactionsActions";
import clsx from "clsx";

const format = time =>
  moment(time).startOf("day").locale(moment.locale()).format("DD.MM.yyyy");
const size = 10;

const History = ({ sendPage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { total } = useSelector(({ transactions }) => transactions);
  const transactions = useSelector(({ transactions }) =>
    transactions.data
      .sort((a, b) => moment.utc(b.create).diff(moment.utc(a.create)))
      .reduce(
        (res, t) => ({
          ...res,
          [format(t.create)]: [...(res[format(t.create)] || []), t],
        }),
        {}
      )
  );
  const [page, setPage] = useState(0);
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    dispatch(getTransactions({ page: page, size: 10 }));
  }, [dispatch, page]);

  return (
    <div className={classes.root}>
      {sendPage && (
        <Box>
          <Typography display="inline" variant={"h4"} className={clsx(classes.title, classes.coloredText)}>
            {t("Transaction")}{" "}
          </Typography>
          <Typography display="inline" variant={"h4"} className={classes.title}>{t("history")}</Typography>
        </Box>
      )}
      {Object.keys(transactions).length < 1 ? (
        <Typography
          variant={"body2"}
          color={"textSecondary"}
          style={{
            textAlign: "center",
          }}>
          {t("You have no transactions...")}
        </Typography>
      ) : (
        Object.keys(transactions).map(date => (
          <div key={date}>
            <Typography
              variant={"body2"}
              color={"textSecondary"}
              style={{ textAlign: "right", marginBottom: 10, marginTop: 20 }}>
              {format() === date ? t("Today") : date}
            </Typography>
            {transactions[date].map(transaction => (
              <Transaction
                key={transaction?.id}
                transaction={transaction}
                setDetail={setDetail}
              />
            ))}
          </div>
        ))
      )}
      {total > size && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 32,
          }}>
          <Pagination
            count={Math.ceil(total / size)}
            page={page + 1}
            boundaryCount={1}
            siblingCount={1}
            color='primary'
            onChange={(_, page) => setPage(page - 1)}
          />
        </div>
      )}
      <TransactionDialog
        transaction={detail}
        handleClose={() => setDetail(null)}
      />
    </div>
  );
};

export default History;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '32px 24px',
    marginTop: 14,
    background: theme.palette.primary.light,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
    },
  },
  title: {
    textTransform: 'uppercase'
  },
  coloredText: {
    color: theme.palette.text.tertiary
  }
}));
