import { memo, useEffect } from "react";
import { Paper } from "@material-ui/core";

const Chart = ({ ticker }) => {
  useEffect(() => {
    new window.TradingView.MediumWidget({
      symbols: [[`${ticker.toUpperCase()}USD|1D`]],
      chartOnly: true,
      width: "100%",
      height: "100%",
      locale: "en",
      colorTheme: "dark",
      gridLineColor: "rgba(146, 147, 151, 0.5)",
      trendLineColor: "#5ADB6B",
      fontColor: "#878787",
      underLineColor: "transparent",
      underLineBottomColor: "rgba(28, 64, 48, 0)",
      isTransparent: true,
      autosize: true,
      showFloatingTooltip: true,
      container_id: "tradingview_5a0d8",
    })
}, [ticker]);

return (
  <Paper
    variant={"outlined"}
    elevation={4}
    style={{
      backgroundColor: "transparent",
      paddingTop: 0,
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 24,
      border: 'none',
      position: 'relative',
    }}>
    <div id={"tradingview_5a0d8"}>
      <div id={"el"} style={{
        position: 'absolute',
      }}></div>
    </div>
  </Paper>
);
};

export default memo(Chart);
