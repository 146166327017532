import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  lighten,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogWrapper from "./DialogWrapper";
import { closeAddNewWalletDialog } from "../../redux/actions/dialogActions";
import {
  createWallet,
  getCurrencies,
  getWalletsSettingsList,
  updateWalletSettings,
} from "../../redux/actions/walletsActions";
import Search from "../components/Search";
import CoinName from "../components/CoinName";
import { SwitchButton } from "../../theme/components/SwitchButton";

const AddNewWalletDialog = () => {
  const classes = dialogListStyles();
  const dispatch = useDispatch();
  const { open } = useSelector(({ dialogs }) => dialogs.addNewWallet);
  const { currencies, settings, data, params } = useSelector(
    ({ wallets }) => wallets
  );
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (open && currencies.length === 0) {
      dispatch(getCurrencies(params));
    }
    return () => {
      setSearch("");
    };
  }, [dispatch, open, currencies, params]);

  useEffect(() => {
    if (open && settings.length === 0) {
      dispatch(getWalletsSettingsList());
    }
  }, [dispatch, open, settings]);

  const handleChange = (coin) => {
    if (!Boolean(data.find((i) => i.ticker === coin.ticker))) {
      dispatch(createWallet(coin?.ticker, params));
    }
    dispatch(
      updateWalletSettings({
        ticker: coin?.ticker,
        visible: !coin?.visible,
      })
    );
  };

  const list = useMemo(() => {
    return currencies.map((coin) => {
      return {
        ...coin,
        visible:
          Boolean(data.find((i) => i.ticker === coin.ticker)) &&
          settings.find((i) => i.ticker === coin.ticker)?.visible,
      };
    });
  }, [currencies, data, settings]);

  const searchedList = useMemo(() => {
    return list.filter(
      ({ ticker = "", name = "" }) =>
        ticker.toLowerCase().includes(search.toLowerCase())
      //  ||name.toLowerCase().includes(search.toLowerCase())
    );
  }, [list, search]);

  return (
    <DialogWrapper
      open={open}
      handleClose={() => dispatch(closeAddNewWalletDialog())}
      title={t("Wallet settings")}
    >
      {list.length > 0 ? (
        <>
          <Grid container justifyContent="center">

            <Search
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              className={classes.search}
            />
          </Grid>
          <div className={classes.list}>
            {searchedList?.length > 0 ? (
              searchedList.map((coin) => {
                return (
                  <Paper
                    elevation={4}
                    variant={"outlined"}
                    key={coin?.id}
                    className={classes.currency}
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <CoinName
                          name={coin?.name}
                          ticker={coin?.ticker}
                          price={"= $42,234.76"}
                          showName
                        />
                      </Grid>
                      <Grid item>
                        <SwitchButton
                          sx={{ m: 1 }}
                          checked={coin?.visible || false}
                          onChange={() => handleChange(coin)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })
            ) : (
              <Typography
                variant={"subtitle1"}
                color={"textSecondary"}
                style={{ textAlign: "center" }}
              >
                Not found...
              </Typography>
            )}
          </div>
        </>
      ) : (
        <Typography
          variant={"subtitle1"}
          color={"textSecondary"}
          style={{ textAlign: "center" }}
        >
          All possible wallets have already been added...
        </Typography>
      )}
    </DialogWrapper>
  );
};

export default AddNewWalletDialog;

export const dialogListStyles = makeStyles((theme) => ({
  search: {
    position: 'absolute',
    top: '33px',
    right: '40px',
    minWidth: 120,
    width: 'auto',
    marginBottom: 12,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  list: {
    maxHeight: "calc(100vh - 320px)",
    overflowY: "auto",
  },
  currency: {
    padding: "12px 0px",
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
    "&.hover:hover": {
      backgroundColor: lighten(theme.palette.background.paper, 0.05),
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: 400,
  }
}));
