import { Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";


const TextReadableInput = ({ label, value, children, noUnderline }) => {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.root, noUnderline ? classes.noUnderline : undefined)}>
      <Typography className={classes.label}>{label}</Typography>
      {value ?
        <Typography className={classes.value}>
          {value}
        </Typography> :
        children
      }
    </Grid>
  );
};

export default TextReadableInput;

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    borderBottom: `2px solid ${theme.palette.border.gold}`,
    paddingBottom: 10,
    marginBottom: 24,
  },
  noUnderline: {
    borderBottomWidth: 0,
  },
  label: {
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  value: {
    textTransform: 'uppercase',
  }
}));
