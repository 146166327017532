import {
	GET_TRANSACTIONS_LIST_FAILURE,
	GET_TRANSACTIONS_LIST_STARTED, GET_TRANSACTIONS_LIST_SUCCESS
} from "../types";

export default function transactions(
	state = {
		total: 0,
		data: [],
		error: null
	}, action
) {
	switch (action.type) {
		case GET_TRANSACTIONS_LIST_STARTED:
			return {...state, error: null};
		case GET_TRANSACTIONS_LIST_SUCCESS:
			return {...state, data: action.payload.data, total: action.payload.total};
		case GET_TRANSACTIONS_LIST_FAILURE:
			return {...state, error: action.payload.error};
		default:
			return state;
	}
}
