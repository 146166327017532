import PageWrapper from "../../theme/PageWrapper";
import { Grid, makeStyles } from "@material-ui/core";
import Breadcrumb from "../../theme/components/Breadcrumb";
import ExchangeContainer from "./ExchangeContainer";
import History from "./History";

const Exchange = () => {
  const classes = useStyles()
  return (
    <PageWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={3} className={classes.btn}>
          <Breadcrumb to={"/"} pageName={"Wallets"} down={16} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <ExchangeContainer />
        </Grid>
        <Grid item xs={12} md={7}>
          <History exchangePage={true} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Exchange;

const useStyles = makeStyles(theme => ({
  btn: {
    marginTop: 20
  }
}));
