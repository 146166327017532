import { useDispatch } from "react-redux";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import actionQuick from "../../assets/icons/kyc/diya.svg";
import actionNormal from "../../assets/icons/kyc/docs.svg";
import { setVerificationMethod } from "../../redux/actions/kycActions.js";
import { VERIFICATION_METHODS } from "../../utils/constants/kyc";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";

const methods = [
  {
    title: VERIFICATION_METHODS.normal,
    description:
      "Fill out the form, upload the documents and get an answer within 24 hours",
    type: "normal",
    image: actionNormal,
  },
  {
    title: VERIFICATION_METHODS.quick,
    description: "Get verified in minutes.",
    type: "quick",
    image: actionQuick,
  },
];

const VerificationMethods = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  const handleClick = (method) => {
    if (method === VERIFICATION_METHODS.quick) {
      return;
    }
    dispatch(setVerificationMethod(method));
    // method === VERIFICATION_METHODS.normal
    //   ? localStorage.setItem("verification_method", VERIFICATION_METHODS.normal)
    //   : localStorage.setItem("verification_method", VERIFICATION_METHODS.quick);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <Grid item alignItems="flex-start" container>
        {renderTitle(t("Methods of verification"))}
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h5"
            className={clsx(classes.description)}
          >
            {t(
              "You can go through the usual verification process by filling out the form and uploading copies of documents manually, or choose the option of quick verification through Action application, confirming the transfer of copies of documents."
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {methods.map((card, i) => (
          <Grid
            item
            container
            xs={12}
            md={6}
            key={i}
            className={
              i % 2 === 0 ? classes.cardWrapLeft : classes.cardWrapRight
            }
          >
            <Paper
              className={clsx(classes.card, i % 2 !== 0 && 'disabled')}
              elevation={0}
              onClick={() => handleClick(card.title)}
            >
              <Grid container alignItems="center" className={clsx(classes.titleWrap, i % 2 !== 0 && classes.disabled)}>
                <img src={card.image} alt="action" className={classes.icon} />
                <Typography className={classes.titleCard}>
                  {t(card.title)}
                </Typography>
              </Grid>

              <Typography
                variant="body1"
                className={clsx(classes.captionCardWrap, classes.caption)}
              >
                {t(card.description)}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default VerificationMethods;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: { padding: "20px" },
  },

  titleBox: {
    fontWeight: 500,
    marginBottom: 64,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 28,
    },
  },
  icon: {
    width: 65,
    height: 65,
    padding: 2,
    paddingTop: 3,
    [theme.breakpoints.down("xs")]: { width: 60, height: 60 },
  },
  description: {
    marginBottom: 68,
    marginTop: 50,
    float: "right",
    fontWeight: 400,
    [theme.breakpoints.down(1010)]: {
      marginRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: 50,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40,
    },
  },
  card: {
    height: "auto",
    border: 'none',
    opacity: 1,
    width: 320,
    "&.disabled": {
      "&:hover": {
        opacity: 1,
        cursor: "default",
      },
    },
    "&:hover": {
      opacity: 0.75,
      cursor: "pointer",
    },
    [theme.breakpoints.down(1010)]: {
      width: 320,
    },
    [theme.breakpoints.down("sm")]: {
      width: 320,
    },
  },
  disabled: {
    opacity: 0.3,
    cursor: "default",
  },
  cardWrapLeft: {
    justifyContent: "flex-end",
    paddingRight: 20,
    [theme.breakpoints.down(1010)]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingRight: 0,
      marginBottom: 20,
    },
  },
  cardWrapRight: {
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down(1010)]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingLeft: 0,
    },
  },

  titleWrap: {
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
  },
  titleCard: {
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "24px",
    color: "#464B58",
    textTransform: "uppercase",
    flex: 1,
    marginRight: 20,
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "18px",
    },
  },
  caption: {
    textAlign: "justify",
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
  },
  captionCardWrap: {
    padding: "12px",
    color: theme.palette.text.secondary,
    textTransform: 'lowercase',
    [theme.breakpoints.down("xs")]: {
      padding: "8px 20px 16px 40px",
      fontSize: 11,
      lineHeight: "13px",
    },
  },
}));
