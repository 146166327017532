import { makeStyles } from "@material-ui/core";
import propTypes from "prop-types";
import clsx from "clsx";

const DayChange = ({ value }) => {
  const cls = useStyles();

  return (
    <div className={clsx(cls.root, value >= 0 ? cls.positive : cls.negative)}>
      {value >= 0 ? '+' : ''}
      {value}
      {"%"}
    </div>
  );
};

DayChange.propTypes = {
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
};

export default DayChange;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      width: "85px",
      fontSize: 12,
    },
  },

  positive: {
    color: theme.palette.text.tertiary,
  },
  negative: {
    color: theme.palette.error.main,
  },
}));
