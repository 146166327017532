export const snakeCaseToString = (text) => {
  if (text) {
    return text[0].toUpperCase() + text?.slice(1)?.replace('_', ' ')?.toLowerCase();
  }
}

export const formatTicker = (ticker) => {
  if (ticker && ticker?.includes('-')) {
    const formatedTicker = ticker?.substring(0, ticker.indexOf('-'));
    return formatedTicker?.toUpperCase?.() || '';
  }
  return ticker?.toUpperCase?.() || '';
};

export const capitalizeString = (string) => {
  if (string) {
    const capitalized =
      string?.charAt(0)?.toUpperCase?.()
      + string?.slice?.(1)?.toLowerCase?.()
    return capitalized
  }
  return ''
}