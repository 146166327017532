import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  lighten,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogWrapper from "../../theme/dialogs/DialogWrapper";
import {
  getCurrencies,
  getWalletsSettingsList,
} from "../../redux/actions/walletsActions";
import CoinName from "../../theme/components/CoinName";
import { Input } from "../../theme/components/Input";
import { useValidateForm } from "../../utils/hooks/useValidateForm";
import { newMoneyboxValidator } from "../../utils/functions/customValidators/validators";
import { Button } from "../../theme/components/Button";
import PlusIcon from '../../assets/icons/plus.svg'
import { moneyboxSelectors } from "../../redux/selectors/moneyboxSelectors";
import SelectMoneyboxBalanceDialog from "./SelectMoneyboxBalanceDialog";
import { createMoneybox, createMoneyboxBalance, updateMoneybox } from "../../redux/actions/moneyboxActions";
import { isLoading, isSucceeded } from "../../utils/functions/requestStatuses";
import { usePrevious } from "../../utils/hooks/usePrevious";


const initialForm = {
  name: "",
  description: ""
};

const CreateMoneyboxDialog = ({ open, onSubmit, onClose, moneyboxData }) => {
  const classes = dialogListStyles();
  const dispatch = useDispatch();
  const [showSelectBalanceModal, setShowSelectBalanceModal] = useState(false);
  const [form, setForm] = useState(initialForm);
  const { validateForm, validateInput, errors } = useValidateForm({ form, customValidator: newMoneyboxValidator });
  const updateMoneyboxRequestStatus = useSelector(moneyboxSelectors.updateMoneyboxRequestStatus)
  const prevUpdateMoneyboxRequestStatus = usePrevious(updateMoneyboxRequestStatus)

  const isLoaderNeeded = isLoading(updateMoneyboxRequestStatus)

  useEffect(() => {
    if (moneyboxData) {
      setForm({
        name: moneyboxData?.name || initialForm.name,
        description: moneyboxData?.description || initialForm.description,
      })
    }
  }, [moneyboxData])

  const { currencies, settings, params } = useSelector(
    ({ wallets }) => wallets
  );
  const moneyboxBalancesList = useSelector(moneyboxSelectors.moneyboxBalancesList)
  const { t } = useTranslation();

  const isEditMode = useMemo(() => {
    return Boolean(moneyboxData?.id);
  }, [moneyboxData]);

  useEffect(() => {
    if (isLoading(prevUpdateMoneyboxRequestStatus) && isSucceeded(updateMoneyboxRequestStatus)) {
      onClose();
    }
  }, [onClose, prevUpdateMoneyboxRequestStatus, updateMoneyboxRequestStatus])

  useEffect(() => {
    if (open && currencies.length === 0) {
      dispatch(getCurrencies(params));
    }
  }, [dispatch, open, currencies, params]);

  useEffect(() => {
    if (open && settings.length === 0) {
      dispatch(getWalletsSettingsList());
    }
  }, [dispatch, open, settings]);

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  }, [form])

  const handleSubmit = useCallback(() => {
    if (!validateForm()) {
      return;
    }
    if (!isEditMode) {
      dispatch(createMoneybox({ name: form.name, description: form.description }))
    } else {
      dispatch(updateMoneybox({ id: moneyboxData?.id, name: form.name, description: form.description }))
    }
    onSubmit?.();
  }, [dispatch, form.description, form.name, isEditMode, moneyboxData?.id, onSubmit, validateForm])

  const coinsList = useMemo(
    () =>
      currencies?.filter(currency => {
        return !moneyboxBalancesList?.some(
          moneyboxBalance => moneyboxBalance?.currency === currency?.ticker,
        );
      }),
    [currencies, moneyboxBalancesList],
  );

  const showAddBalanceButton = useMemo(() => {
    return Boolean(isEditMode && coinsList.length);
  }, [coinsList, isEditMode]);

  const renderSelectedCoins = useCallback(() => {
    return (
      <Grid direction="column" container>
        <Grid className={classes.coinsListHeader} direction="row" container alignItems="center" justifyContent="space-between">
          <Typography variant="body1" display="inline">{t('Selected coins')}</Typography>
          {
            showAddBalanceButton &&
            <IconButton
              onClick={() => setShowSelectBalanceModal(true)}>
              <img className={classes.addIcon} src={PlusIcon} alt='plus_icon' />
            </IconButton>
          }
        </Grid>
        <Grid direction="row" container gap={5} className={classes.coinsListContent}>
          {moneyboxBalancesList.map((balance, index) => {
            return (
              <Box key={index} className={classes.coinIcon}>
                <CoinName
                  showOnlyIcon
                  ticker={balance?.currency}
                  classNaem
                />
              </Box>
            )
          })}
        </Grid>
      </Grid>
    )
  }, [classes, moneyboxBalancesList, showAddBalanceButton, t])

  const handleCloseSelectBalanceCoinModal = useCallback((ticker) => {
    if (ticker && moneyboxData?.id) {
      // add balance to moneybox
      dispatch(createMoneyboxBalance({
        moneyboxId: moneyboxData?.id,
        currency: ticker,
      }))
    }
    setShowSelectBalanceModal(false);
  }, [dispatch, moneyboxData?.id])

  return (
    <DialogWrapper
      open={open}
      handleClose={onClose}
      title={t(isEditMode ? "Edit moneybox" : "Create moneybox")}
    >
      <SelectMoneyboxBalanceDialog
        open={showSelectBalanceModal}
        data={coinsList}
        onClose={handleCloseSelectBalanceCoinModal}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Input
            label={t("Moneybox Name")}
            placeholder={t("Moneybox Name")}
            variant="outlined"
            className={classes.input}
            name={"name"}
            value={form?.name || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.name?.invalid}
            helperText={t(errors?.name?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("Description")}
            placeholder={t("Description")}
            variant="outlined"
            className={classes.input}
            name={"description"}
            value={form?.description || ""}
            onChange={handleOnChange}
            onBlur={validateInput}
            error={errors?.description?.invalid}
            helperText={t(errors?.description?.errorMessage)}
            InputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        {isEditMode && renderSelectedCoins()}
        <Grid item xs={12} container justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={isLoaderNeeded}
            onClick={handleSubmit}
          >
            {t(isEditMode ? "Save" : "Create")}
          </Button>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default CreateMoneyboxDialog;

export const dialogListStyles = makeStyles((theme) => ({
  input: {
    // marginBottom: 32,
    "&>.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      "& a": {
        color: theme.palette.primary.light,
        fontSize: 14,
        marginRight: 16,
        cursor: "pointer",
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
          marginRight: 12,
        },
      },
    },
  },
  coinsListHeader: {
    paddingLeft: 24,
    paddingRight: 8
  },
  coinsListContent: {
    paddingLeft: 8,
    paddingRight: 8,
    overflowX: 'scroll',
    flexWrap: 'nowrap',
    "&::-webkit-scrollbar": {
      display: 'none',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    }
  },
  addIcon: {
    width: 24
  },
  coinIcon: {
    cursor: 'pointer',
    padding: 8,
  },
  list: {
    maxHeight: "calc(100vh - 320px)",
    overflowY: "auto",
  },
  currency: {
    padding: "12px 0px",
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
    "&.hover:hover": {
      backgroundColor: lighten(theme.palette.background.paper, 0.05),
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: 400,
  },
  button: {
    marginTop: 8,
  },
}));
