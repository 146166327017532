import { TextField, withStyles } from "@material-ui/core";

export const AutocompleteInput = withStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: 12,

    "& .MuiFormHelperText-root": {
      color: "red",
      position: "absolute",
      marginTop: 56,
      fontFamily: "SF Pro Text, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "24px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 22,
      marginRight: 8,
    },

    "& legend": {
      maxWidth: 0,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 6px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label, & label.Mui-focused": {
      color: "#707594",
      marginTop: 16,
      marginLeft: 8,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#707594",
    },

    "& .MuiFormLabel-root.Mui-disabled ": {
      color: "#707594",
    },
    "& .MuiInputAdornment-root > svg": {
      color: theme.palette.text.primary,
      fontSize: 16,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      width: "100%",
      paddingRight: 12,
      backgroundColor: "#FFFFFF",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: 0,

      "& .MuiAutocomplete-popupIndicator": {
        color: "#707594",
      },
      "& .MuiAutocomplete-clearIndicator": {
        color: "#707594",
      },

      "& .MuiAutocomplete-input": {
        padding: "24px 0px 10px 22px",
        "&:first-child": {
          paddingLeft: "22px",
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      "&>input": {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: "20px",
        padding: "24px 0px 10px 22px",
        borderRadius: 12,
        "& .MuiOutlinedInput-adornedEnd": {
          background: "#FFFFFF",
          color: "#000000",
        },
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
      "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
        borderRadius: 12,
        borderWidth: 1,
      },
      "&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
        {
          borderColor: "#FFFFFF",
        },
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(TextField);
