import {MARKET} from "../types";

export default function market(
  state = {
    total: 0,
    data: [],
  }, action
) {
  switch (action.type) {
    case MARKET.GET_MARKET_LIST_SUCCESS:
      return {...state, total: action.payload.total, data: action.payload.marketList}
    default:
      return state;
  }
}
