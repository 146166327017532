import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Input } from "../../../theme/components/Input";
import { Button } from "../../../theme/components/Button";
import { AutocompleteInput } from "../../../theme/components/AutocompleteInput";
import {
  setActiveStep,
  setFilledStep,
} from "../../../redux/actions/kycActions.js";
import { COUNTRIES } from "../../../utils/constants/kyc";
import { validateInput } from "../../../utils/functions/customValidators/kycValidator";

const initialForm = {
  countryAlphaCode: "",
  city: "",
  street: "",
  house: "",
  apartment: "",
  postIndex: "",
};

const AddressInfo = ({ updateKYCForm, KYCForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep } = useSelector(({ kyc }) => kyc);
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let data = {};
    Object.keys(form).forEach(key => (data[key] = KYCForm[key]));
    setForm(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countries = useMemo(
    () => COUNTRIES.map(code => ({ label: t(code), code: code })),
    [t]
  );

  const handleOnChange = ({ target: { value, name } }) => {
    setErrors({ ...errors, [name]: validateInput(name, value, form, false) });
    setForm({ ...form, [name]: value.trim() });
  };

  const handleOnSelectCountry = (name, value) => {
    setErrors({ ...errors, [name]: validateInput(name, value, form, false) });
    setForm({ ...form, [name]: value });
  };

  const handleNext = e => {
    e.preventDefault();
    if (validateForm()) {
      updateKYCForm(form);
      dispatch(setActiveStep(activeStep + 1));
      dispatch(setFilledStep(activeStep));
    }
  };

  const validateForm = () => {
    let valid = true;
    let errForm = {};
    Object.keys(form).forEach(key => {
      errForm = { ...errForm, [key]: validateInput(key, form[key]) };
      errForm[key].invalid && (valid = false);
    });
    setErrors(errForm);
    return valid;
  };

  const handleInputValidation = event => {
    const { name, value } = event.target;
    const errorField = validateInput(name, value);
    setErrors({
      ...errors,
      [name]: errorField,
    });
  };

  const handleBack = () => {
    updateKYCForm(initialForm);
    dispatch(setActiveStep(activeStep - 1));
  };
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      className={classes.root}>
      <Grid item xs={12}>
        <form>
          <Grid container spacing={8} justifyContent='center'>
            <Grid item xs={12} sm={4} className={classes.rowSpacing}>
              <Autocomplete
                id='country-select-demo'
                options={countries}
                autoHighlight
                getOptionLabel={option => option?.label || ""}
                onChange={(_, newValue) => {
                  handleOnSelectCountry("countryAlphaCode", newValue?.code);
                }}
                renderOption={option => (
                  <React.Fragment>{option.label}</React.Fragment>
                )}
                renderInput={params => (
                  <AutocompleteInput
                    {...params}
                    name={"countryAlphaCode"}
                    label={t("Country")}
                    value={t(form?.countryAlphaCode) || ""}
                    error={errors?.countryAlphaCode?.invalid}
                    variant='outlined'
                    onBlur={handleInputValidation}
                    helperText={t(errors?.countryAlphaCode?.errorMessage)}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.rowSpacing}>
              <Input
                label={t("City")}
                variant='outlined'
                name={"city"}
                value={form?.city || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.city?.invalid}
                helperText={t(errors?.city?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.rowSpacing}>
              <Input
                label={t("Street")}
                variant='outlined'
                name={"street"}
                value={form?.street || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.street?.invalid}
                helperText={t(errors?.street?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.paddingSmall}>
              <Input
                label={t("House")}
                variant='outlined'
                name={"house"}
                value={form?.house || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.house?.invalid}
                helperText={t(errors?.house?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.paddingSmall}>
              <Input
                label={t("Apartment")}
                variant='outlined'
                name={"apartment"}
                value={form?.apartment || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.apartment?.invalid}
                helperText={t(errors?.apartment?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.rowSpacing}>
              <Input
                label={t("Postcode")}
                variant='outlined'
                name={"postIndex"}
                value={form?.postIndex || ""}
                onChange={handleOnChange}
                onBlur={handleInputValidation}
                error={errors?.postIndex?.invalid}
                helperText={t(errors?.postIndex?.errorMessage)}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        container
        justifyContent='center'
        spacing={5}
        className={classes.btnBox}>
        <Grid item>
          <Button
            className={clsx(classes.btn, classes.btnBack)}
            variant='outlined'
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={handleBack}>
            {t("Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.btn}
            variant='contained'
            color={"primary"}
            style={{ marginTop: 32 }}
            onClick={handleNext}>
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressInfo;
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("md")]: { padding: "48px 100px 60px 100px" },
    [theme.breakpoints.down("sm")]: { padding: "112px 64px" },
    [theme.breakpoints.down("xs")]: { padding: "48px 32px" },
  },
  title: {
    fontWeight: 500,
    marginBottom: 48,
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },
  rowSpacing: {
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "10px !important",
      paddingTop: "10px !important",
    },
  },
  paddingSmall: {
    paddingBottom: "20px !important",
    paddingTop: "20px !important",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "10px !important",
      paddingTop: "40px !important",
    },
  },
  btnBox: {
    marginTop: 60,
    [theme.breakpoints.down("sm")]: { marginTop: "120px" },
    [theme.breakpoints.down("xs")]: { marginTop: "32px" },
  },
  btn: {
    minWidth: 165,
    height: 42,
    marginTop: "0px !important",
    "&.MuiButton-outlined": {
      padding: "12px 24px",
    },
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 152,
    },
  },
  btnBack: {
    borderColor: `${theme.palette.text.secondary} !important`,
    "& .MuiButton-label": {
      color: theme.palette.text.secondary,
    },
  },
  dropdownStyle: {
    maxHeight: 250,
    marginTop: -6,
  },
}));
