import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import DialogWrapper from "./DialogWrapper";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success_img.svg";


const SuccessDialog = ({
  open,
  title = "",
  message,
  children,
  handleClose,
  closeButtonText = "",
  withNoButton = false,
}) => {
  const { t } = useTranslation();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      isCenteredTitle>
      <Grid container alignItems="center" direction="column">
        <Typography color="primary" variant={"h4"} style={{ textTransform: 'uppercase', marginBottom: 40 }}>
          {title || t('Successfully')}
        </Typography>
        <SuccessIcon style={{ margin: '40px 0', width: 140, height: 140 }} />
        {children ? (
          children
        ) : (
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            style={{ margin: '40px 0' }}>
            {t(message)}
          </Typography>
        )}
        {withNoButton ? null : (
          <Grid container justifyContent='center'>
            <Button
              variant='contained'
              color={"primary"}
              style={{ width: "max(25%, 50px)", marginTop: 12 }}
              onClick={() => handleClose({ accept: false })}>
              {closeButtonText || t('Close')}
            </Button>
          </Grid>
        )}
      </Grid>
    </DialogWrapper>
  );
};

export default SuccessDialog;
