import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import NormalVerification from "./NormalVerification";
import VerificationMethods from "./VerificationMethods";
import { VERIFICATION_METHODS } from "../../utils/constants/kyc";
import { getKycStatus } from "../../redux/actions/kycActions";
import AboutKYC from "./AboutKYC";
import { useTranslation } from "react-i18next";
import { coloredFirstLetter } from "../../utils/functions/coloredFirstLetter";
import UpdateVerificationData from "./UpdateVerificationData";

const KYC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { verificationMethod, verificationStatus, activeStep } = useSelector(
    ({ kyc }) => kyc
  );

  useEffect(() => {
    dispatch(getKycStatus());
  }, [dispatch]);

  return (
    <PageWrapper bgColor="secondary">
      <Grid container className={classes.root}>
        {/* <Grid item xs={12} sm={4} md={3}>
          <Breadcrumb to={"/"} pageName={"Wallets"} down={24} />
        </Grid> */}
        <Typography variant="h2" className={classes.title}>
          {coloredFirstLetter(t('KYC VERIFICATION'))}
        </Typography>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={verificationMethod && activeStep !== 4 ? 12 : 8}>

            <Paper className={classes.paper}>
              {verificationStatus && !verificationMethod && <VerificationMethods />}
              {verificationMethod === VERIFICATION_METHODS.normal && (
                <NormalVerification />
              )}
              {verificationMethod === VERIFICATION_METHODS.quick && (
                <NormalVerification />
              )}
            </Paper>
          </Grid>
          {
            !verificationMethod &&
            <Grid item xs={12} sm={12} md={4}>
              <AboutKYC />
            </Grid>
          }
          {
            verificationMethod && activeStep === 4 &&
            <Grid item xs={12} sm={12} md={4}>
              <UpdateVerificationData />
            </Grid>
          }

        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default KYC;
const useStyles = makeStyles((theme) => ({
  root: {
  },
  title: {
    marginTop: 24,
    marginBottom: 26,
    marginLeft: 24,
    fontSize: 32,
    [theme.breakpoints.down("md")]: {
      fontSize: 32
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      marginBottom: 20
    },
  },
  paper: {
    background: theme.palette.background.paper,
    borderRadius: 12,
    width: "100%",
  },
}));
