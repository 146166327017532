import { Typography, Paper, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useColoredTitle } from "../../utils/hooks/useColoredTitle";

const AboutAutoInvest = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderTitle } = useColoredTitle({ withMargin: true })

  return (
    <Paper className={classes.root}>
      {renderTitle(t("About Program"))}
      <Typography
        variant={"body1"}
        className={classes.description}
      >
        {t("Auto-Invest allows you to automate crypto investments and grow your crypto holdings. It is a dollar-cost averaging (DCA) strategy. You can choose the cryptocurrencies you want to purchase on a regular basis")}
      </Typography>
    </Paper>
  );
};

export default AboutAutoInvest;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
  },
  coloredText: {
    color: theme.palette.primary.main
  },
  rewardIcon: {
    maxHeight: 72
  },
  noteText: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  rewardContainer: {
    marginTop: 40
  },
  link: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 24,
    color: theme.palette.text.quaternary,
  }
}));
