
import { Grid, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SummaryFooter = ({ createDate, amountPerPeriod, firstAutoInvestDate, transactionFee }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container md={6} sm={12}>
      <Typography className={classes.title}>
        {t("Summary")}
      </Typography>
      <Grid className={classes.section}>
        <Typography className={classes.label}>
          {t("Create date")}
        </Typography>
        <Typography className={classes.value}>
          {createDate}
        </Typography>
      </Grid>
      <Grid className={classes.section}>
        <Typography className={classes.label}>
          {t("Amount per period")}
        </Typography>
        <Typography className={classes.value}>
          {amountPerPeriod}
        </Typography>
      </Grid>
      <Grid className={classes.section}>
        <Typography className={classes.label}>
          {t("First Auto Invest Date")}
        </Typography>
        <Typography className={classes.value}>
          {firstAutoInvestDate}
        </Typography>
      </Grid>
      <Grid className={classes.section}>
        <Typography className={classes.label}>
          {t("Transaction Fee (10%)")}
        </Typography>
        <Typography className={classes.value}>
          {transactionFee}
        </Typography>
      </Grid>
    </Grid>
  );
};

SummaryFooter.propTypes = {
  onSelect: PropTypes.func,
};

export default SummaryFooter;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 2,
    marginBottom: 26,
    flexDirection: 'column'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 15,
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
    color: theme.palette.text.secondary,
  },
  value: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  }
}));
