import { useEffect, useState } from "react";
import { Grid, lighten, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAllTickerSettings,
  getCurrencies,
  getWalletsList,
  getWalletsSettingsList,
} from "../../redux/actions/walletsActions";
import { openAddNewWalletDialog } from "../../redux/actions/dialogActions";

import PageWrapper from "../../theme/PageWrapper";
import { FILTER_WALLETS_LIST } from "../../redux/types";
import WalletsTable from "./WalletsTable";
import Breadcrumb from "../../theme/components/Breadcrumb";
import Overview from "./Overview";
import Search from "../../theme/components/Search";
import { Button } from "../../theme/components/Button";
// import VerificationStatus from "../../theme/components/VerificationStatus";

// import promo from "../../assets/images/promo.png";

const Wallets = ({ dispatch, params }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getWalletsList({ ...params }));
    dispatch(getCurrencies({ number: 0, size: 100 }));
    dispatch(getWalletsSettingsList());
    dispatch(getAllTickerSettings());
  }, [dispatch, params]);

  useEffect(() => {
    dispatch({ type: FILTER_WALLETS_LIST, payload: search });
  }, [dispatch, search]);

  return (
    <PageWrapper isWallet={true}>
      <Grid container direction={"row"} justifyContent='space-between'>
        <Grid item className={classes.btnWrap}>
          <Breadcrumb to={"/"} pageName={"Main"} />
          <Grid direction="row" container justifyContent="flex-end">
            {/* <VerificationStatus isShort className={classes.verificationStatus} /> */}
            <Button
              variant='contained'
              className={classes.btnSmall}
              onClick={() => dispatch(openAddNewWalletDialog())}>
              {t("Wallet settings")}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Search
            value={search}
            className={classes.search}
            onChange={({ target }) => setSearch(target.value)}
          />
        </Grid>
        <div className={classes.mobileSearchWrapper}>
          <Search
            value={search}
            className={classes.mobileSearch}
            onChange={({ target }) => setSearch(target.value)}
          />
        </div>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Overview />
            </Grid>

            {/* <Grid item xs={12} sm={7} md={12}>
              <Overview />
            </Grid>
            <Grid item xs={12} sm={5} md={12}>
              <img style={{ width: "100%" }} src={promo} alt='' />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={7} lg={8}>
          <WalletsTable />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const mapStateToProps = ({ wallets }) => ({
  params: wallets.params,
  data: wallets.data,
  currencies: wallets.currencies,
  error: wallets.error,
});

export default connect(mapStateToProps)(Wallets);

const useStyles = makeStyles(theme => ({
  btnWrap: {
    width: "calc(100% - 244px)",
    display: "flex",
    justifyContent: "space-between",
    marginTop: '20px',
    [theme.breakpoints.down(725)]: {
      width: "100%",
    },
  },

  btnSmall: {
    marginRight: 16,
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 96,
    },
    [theme.breakpoints.down(725)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(390)]: {
      marginTop: 0,
      fontSize: 14,
      "&.MuiButton-contained ": {
        padding: "12px 20px",
      },
    },
  },

  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  row: {
    borderRadius: 8,
    padding: 16,
    fontSize: 14,
    marginBottom: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(theme.palette.background.paper, 0.04),
    },
  },
  available: {
    width: "100%",
    marginBottom: 24,
    marginTop: 8,
    "&>span": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  search: {
    width: 244,
    display: "block",
    marginBottom: 34,
    marginTop: 20,
    [theme.breakpoints.down(725)]: {
      display: "none",
    },
  },
  mobileSearchWrapper: {
    display: "none",
    [theme.breakpoints.down(725)]: {
      display: 'block',
      width: '100%'
    },
  },
  mobileSearch: {
    width: "100%",
    display: "none",
    marginBottom: 16,

    [theme.breakpoints.down(725)]: {
      display: "block",
      marginTop: 24,
      marginBottom: 24,
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: 16,
    },
  },
  verificationStatus: {
    width: 35,
    height: 38,
    transform: 'scale(1.2)',
    alignSelf: 'flex-start',
    marginRight: 16,
    flex: 0,
  }
}));
