import DialogWrapper from "./DialogWrapper";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

const DeleteConfirmDialog = ({
  open,
  title,
  message,
  handleClose,
  btnOkText = "Ok",
  btnCancelText = "Cancel",
  confirm,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      title={title}
    >
      <Typography
        variant={"body1"}
        align={"left"}
        color={"textPrimary"}
        style={{ marginBottom: 24 }}
        className={classes.message}
      >
        {t(message)}
      </Typography>
      <Grid container justifyContent="space-between">
        <Button
          variant="contained"
          className={!confirm ? classes.btn : classes.btnCancel}
          style={{
            minWidth: isMobile ? "100%" : "max(25%, 50px)",
            marginTop: 12,
          }}
          onClick={() => handleClose({ accept: false })}
        >
          {t(btnCancelText)}
        </Button>
        <Button
          className={!confirm ? classes.btnCancel : classes.btn}
          variant="contained"
          style={{
            minWidth: isMobile ? "100%" : "max(25%, 50px)",
            marginTop: 12,
          }}
          onClick={() => handleClose({ accept: true })}
        >
          {t(btnOkText)}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default DeleteConfirmDialog;

const useStyles = makeStyles(theme => ({
  btnCancel: {
    marginTop: 20,
    height: 42,
    minWidth: 180,
    borderColor: `${theme.palette.error.main} !important`,
    "& .MuiButton-label": {
      color: theme.palette.error.main,
    },
  },
  btn: {
    marginTop: 20,
    height: 42,
    minWidth: 180,
    borderColor: `${theme.palette.primary.main} !important`,
    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
  message: {
    color: theme.palette.text.secondary,
  }
}));
