import { KYC } from "../types";
import {
  VERIFICATION_STATUS,
  VERIFICATION_METHODS,
} from "../../utils/constants/kyc";

export default function kyc(
  state = {
    stepsFilled: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
    },
    activeStep: 0,
    verificationMethod: null,
    verificationStatus: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case KYC.GET_KYC_STATUS.REQUEST:
    case KYC.KYC_VERIFY.REQUEST:
      return { ...state, error: null };
    case KYC.SET_VERIFICATION_METHOD:
      return { ...state, verificationMethod: action.payload };
    case KYC.CLEAR_VERIFICATION_METHOD:
      return { ...state, verificationMethod: null };
    case KYC.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case KYC.SET_FILLED_STEP:
      return {
        ...state,
        stepsFilled: state.stepsFilled[action.payload]
          ? { ...state.stepsFilled, [action.payload]: false }
          : state.stepsFilled,
      };
    case KYC.GET_KYC_STATUS.SUCCESS:
      let stepsFilled = state.stepsFilled;
      if (action.payload.status !== VERIFICATION_STATUS.NOT_EXIST) {
        Object.keys(stepsFilled).forEach(
          (key) =>
            (stepsFilled = {
              ...stepsFilled,
              [key]: true,
            })
        );
      }
      return action.payload.status !== VERIFICATION_STATUS.NOT_EXIST
        ? {
            ...state,
            verificationMethod: VERIFICATION_METHODS.normal,
            stepsFilled,
            activeStep: Object.keys(stepsFilled).length - 1,
            verificationStatus: action.payload.status,
          }
        : { ...state, verificationStatus: action.payload.status };
    case KYC.KYC_VERIFY.FAILURE:
      return {
        ...state,
        error: action.payload.error,
        activeStep: state.activeStep - 1,
      };
    case KYC.GET_KYC_STATUS.FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
