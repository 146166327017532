export function roundValue(value, scale = 8) {
  const updatedValue = (value + '')?.replaceAll(/\s+|[,]/g, '');
  let arr = (updatedValue + "").split(".");
  arr[1] = arr[1]?.slice(0, scale);
  let res = arr.join(".");

  return toPlainString(res) || 0;
}
function toPlainString(num) {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
}
